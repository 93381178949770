// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/utils";
import { Message } from "../../../framework/src/Message";
import { Post, PostActivityInterface, User } from "../../activityfeed/src/ActivityFeedController";
import { cloneDeep } from 'lodash';
import { IReportIssue, IUserProfile } from "../../customisableuserprofiles/src/ProfileController.web";
import { toast } from "react-toastify";

export const configJSON = require("./config");

interface ITag {
	name: string
	post_count: number
}

export interface Props {
	classes?: any
	navigation: any
	checked: boolean
}

interface S {
	activeIndex: number
	postPage: number
	trendingList: ITag[]
	postList: Array<Post>
	postPagination: any
	loggedUser: IUserProfile | null
	selectedPost: Post | null
	activePostUser: User | null
	showComments: boolean
	showPostActivity: boolean
	postActivity: PostActivityInterface | null
	showReportPostModal: boolean
	reportPostMainList: Array<IReportIssue>
	reportPostSubCatList: Array<IReportIssue>
	reportIssue: number | undefined
	reportSubIssue: number | undefined
	reportCurrentView: string
	reportPostLoading: boolean
	reportPageLoading: boolean
	showMuteModal: boolean
	muteUndoPopup: boolean
	showDeletePostModal: boolean
	showPinPostModal: boolean
	isPinned: boolean
	showRepostModal: boolean
	showQuote: boolean
	isLoading: boolean
	showPostList: boolean
	searchValue: string
}

interface SS {
}

export default class AdvancedSearchScreenController extends BlockComponent<
	Props,
	S,
	SS
> {
	getTrendingListId: string = "";
	getPostListId: string = "";
	getLoggedUserId: string = "";
	followId: string = "";
	likePostId: string = "";
	savePostId: string = "";
	getPostActivityId: string = "";
	submitReportId: string = "";
	getReportUserSubCategoriesId: string = "";
	getReportUserMainCategoriesId: string = "";
	muteUserAccountId: string = "";
	unmuteUserAccountId: string = "";
	blockAccountId: string = "";
	deletePostId: string = "";
	pinPostId: string = "";
	repostId: string = "";
	undoRepostId: string = "";

	postsContainerRef = React.createRef<HTMLDivElement>();

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		this.subScribedMessages = [
			getName(MessageEnum.AccoutLoginSuccess),
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.SessionResponseMessage)
		];

		this.state = {
			activeIndex: 1,
			postPage: 1,
			trendingList: [],
			postList: [],
			postPagination: {},
			loggedUser: null,
			selectedPost: null,
			activePostUser: null,
			showComments: false,
			showPostActivity: false,
			postActivity: null,
			showReportPostModal: false,
			reportPostMainList: [],
			reportPostSubCatList: [],
			reportIssue: undefined,
			reportSubIssue: undefined,
			reportCurrentView: 'main',
			reportPostLoading: false,
			reportPageLoading: false,
			showMuteModal: false,
			muteUndoPopup: false,
			showDeletePostModal: false,
			showPinPostModal: false,
			isPinned: false,
			showRepostModal: false,
			showQuote: false,
			isLoading: true,
			showPostList: false,
			searchValue: '',
		};

		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

	async componentDidMount() {
		this.getTrendingList()
		this.getLoggedUser()
	}

	async receive(from: string, message: Message) {
		const apiRequestCallId = message.getData(
			getName(MessageEnum.RestAPIResponceDataMessage)
		);
		const responseJson = message.getData(
			getName(MessageEnum.RestAPIResponceSuccessMessage)
		);
		const errorResponse = message.getData(
			getName(MessageEnum.RestAPIResponceErrorMessage)
		);

		if (errorResponse) {
			this.parseApiErrorResponse(errorResponse)
		}

		this.apiSuccessCallBackController(apiRequestCallId, responseJson)
	}

	apiSuccessCallBackController = (
		apiRequestCallId: string,
		responseJson: any
	) => {
		const successCallbackMap = {
			[this.getPostListId]: this.handleGetPostListResponse,
			[this.getLoggedUserId]: this.handleGetLoggedUserResponse,
			[this.followId]: this.handleFollowResponse,
			[this.likePostId]: this.handleLikePostResponse,
			[this.savePostId]: this.handleSavePostResponse,
			[this.getPostActivityId]: this.handleGetPostActivityResponse,
			[this.submitReportId]: this.handleSubmitReportResponse,
			[this.getReportUserSubCategoriesId]: this.handleGetReportUserSubCategoriesResponse,
			[this.getReportUserMainCategoriesId]: this.handleGetReportUserMainCategorieResponse,
			[this.muteUserAccountId]: this.handleMuteUserAccountResponse,
			[this.unmuteUserAccountId]: this.handleUnmuteUserAccountResponse,
			[this.blockAccountId]: this.handleBlockAccountResponse,
			[this.deletePostId]: this.handleDeletePostResponse,
			[this.pinPostId]: this.handlePinPostResponse,
			[this.repostId]: this.handleRepostResponse,
			[this.undoRepostId]: this.handleUndoRepostResponse,
			[this.getTrendingListId]: this.handleGetTrendingListResponse,
		};

		if (apiRequestCallId) {
			const successCallback: ((responseJson: any) => void) = successCallbackMap[apiRequestCallId];
			!!successCallback && successCallback(responseJson);
		}
	};

	handleUndoRepostResponse = (responseJson: any) => {
		if (responseJson?.messages[0] === 'Your reposted post has been deleted') {
			const updateFeeds = [...this.state.postList].filter(post => post.id !== this.state.selectedPost?.id)
			this.setState({ postList: updateFeeds })
		}
	}

	handleRepostResponse = (responseJson: any) => {
		if (responseJson?.errors?.[0]) {
			toast.error(responseJson?.errors?.[0])
		}
	}

	handlePinPostResponse = (responseJson: any) => {
		if (responseJson && responseJson?.data) {
			const posts = [...this.state.postList]
			const updatePosts = posts.map(post => {
				if (post.id === responseJson.data.id) {
					return {
						...post,
						attributes: {
							...post.attributes,
							is_pinned: responseJson.data.attributes.is_pinned,
						}
					};
				}
				return post;
			});
			const updateSelectedPost = updatePosts?.find(post => post.id === this.state.selectedPost?.id)
			this.setState({ postList: updatePosts, selectedPost: cloneDeep(updateSelectedPost!) })
		}
	}

	handleDeletePostResponse = (responseJson: any) => {
		if (!responseJson?.errors) {
			const posts = [...this.state.postList]
			const updatePosts = posts.filter(post => post.id !== this.state.selectedPost?.id)

			this.setState({ postList: updatePosts, showComments: false }, this.handleCloseDeletePost)
		}
	}

	handleBlockAccountResponse = (responseJson: any) => {
		if (responseJson && responseJson?.data) {
			this.filterPostListByPostUser()
			toast.success(`${this.state.activePostUser?.full_name} has blocked successfully`)
			this.handleCloseCommentsModal()
		}
	}

	filterPostListByPostUser = () => {
		const posts = [...this.state.postList]
		const updatePosts = posts.filter(post => post.attributes.post_by.id !== this.state.activePostUser?.id)
		this.setState({ postList: updatePosts })
	}

	handleUnmuteUserAccountResponse = (responseJson: any) => {
		if (!responseJson?.errors) {
			this.setState({ muteUndoPopup: false })
		}
	}

	handleMuteUserAccountResponse = (responseJson: any) => {
		if (!responseJson?.errors) {
			this.setState({ showMuteModal: false, muteUndoPopup: true })
			setTimeout(() => {
				this.handlePostListDataAfterMute()
			}, 2000);
		}
	}

	handlePostListDataAfterMute = () => {
		this.setState(
			{ muteUndoPopup: false, showComments: false, selectedPost: null },
			() => {
				this.filterPostListByPostUser()
			}
		)
	}

	handleGetReportUserMainCategorieResponse = (responseJson: any) => {
		if (!responseJson.errors) {
			this.setState({
				reportPostMainList: responseJson.data,
			})
		}
	}

	handleGetReportUserSubCategoriesResponse = (responseJson: any) => {
		if (!responseJson.errors) {
			this.setState({
				reportPostSubCatList: responseJson.data,
				reportPostLoading: false
			})
		}
	}

	handleSubmitReportResponse = (responseJson: any) => {
		if (!responseJson.errors) {
			this.setState({
				reportCurrentView: "submit"
			})
		}
	}

	handleGetPostActivityResponse = (responseJson: any) => {
		if (responseJson && responseJson?.data) {
			this.setState({ postActivity: responseJson.data?.attributes?.post_activity })
		}
	}

	handleSavePostResponse = (responseJson: any) => {
		if (responseJson && responseJson?.data) {
			const posts = [...this.state.postList]

			if (this.state.activeIndex === 0) {
				const updatePosts = posts.filter(post => post.id !== responseJson.data.id)
				this.setState({ postList: updatePosts })
			} else {
				const updatePosts = posts.map(post => {
					if (post.attributes.id == responseJson.data.id) {
						return {
							...post,
							attributes: {
								...post.attributes,
								is_saved: responseJson.data.attributes.is_saved
							}
						};
					}
					return post;
				});

				if (responseJson.data.id === this.state.selectedPost?.id) {
					const updateCommentsPost = updatePosts?.find(post => post.id === this.state.selectedPost?.id)
					this.setState({ selectedPost: cloneDeep(updateCommentsPost!) })
				}
				this.setState({ postList: updatePosts })
			}
		}
	}

	handleLikePostResponse = (responseJson: any) => {
		if (responseJson && responseJson?.messages) {
			const posts = [...this.state.postList]
			const updatePosts = posts.map(post => {
				if (post.attributes.id === this.state.selectedPost?.attributes.id) {
					return {
						...post,
						attributes: {
							...post.attributes,
							is_liked: responseJson.messages[0] == "Liked Successfully" ? true : false,
							total_likes: responseJson.messages[0] == "Liked Successfully" ? post.attributes.total_likes + 1 : post.attributes.total_likes - 1
						}
					};
				}
				return post;
			});
			const updateSelectedPost = updatePosts?.find(post => post.id === this.state.selectedPost?.id)
			this.setState({ postList: updatePosts, selectedPost: cloneDeep(updateSelectedPost!) })
		}
	}

	handleFollowResponse = (responseJson: any) => {
		if (responseJson && responseJson.data) {
			const posts = [...this.state.postList]
			const updatePosts = posts.map((post) =>
				post.attributes.post_by.id == responseJson.data.id
					? {
						...post,
						attributes: {
							...post.attributes,
							post_by: {
								...post.attributes.post_by,
								is_following: responseJson.data.attributes.is_following
							},
						},
					}
					: post
			);
			if (responseJson.data?.attributes?.id === this.state.selectedPost?.attributes.id) {
				const updateCommentsPost = updatePosts?.find(post => post.attributes.post_by.id === this.state.selectedPost?.attributes.post_by.id)
				if (updateCommentsPost) {
					this.setState({ selectedPost: cloneDeep(updateCommentsPost) })
				}
			}
			this.setState({ postList: updatePosts })
		}
	}

	handleGetLoggedUserResponse = (responseJson: any) => {
		if (responseJson && responseJson?.data) {
			this.setState({
				loggedUser: responseJson.data
			})
		}
	}

	handleGetPostListResponse = (responseJson: any) => {
		if (responseJson && responseJson?.data) {
			this.setState({
				postList: responseJson?.data?.attributes?.posts,
				isLoading: false
			})
		}
	}

	handleGetTrendingListResponse = (responseJson: any) => {
		if (responseJson?.tags) {
			this.setState({
				trendingList: [...this.state.trendingList, ...responseJson.tags],
				postPage: this.state.postPage + 1,
				postPagination: responseJson.pagination_details,
				isLoading: false
			})
		}
	}

	getTrendingList = async () => {
		const token = await getStorageData("authToken")
		const url = `bx_block_posts/list_tags_with_post_count?page=${this.state.postPage}&per_page=10`

		this.getTrendingListId = sendAPIRequest(
			url,
			{
				method: 'GET',
				headers: {
					token,
				},
			}
		)
	}

	getPostList = async (query: string) => {
		const token = await getStorageData("authToken")
		this.setState({ isLoading: true })
		const url = `bx_block_advanced_search/advanced_search?query=${query}`

		this.getPostListId = sendAPIRequest(
			url,
			{
				method: 'GET',
				headers: {
					token,
				},
			}
		)
	}

	getLoggedUser = async () => {
		const token = await getStorageData("authToken")

		this.getLoggedUserId = sendAPIRequest(
			'account_block/accounts/logged_user',
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					token,
				},
			}
		)
	}

	handleClickFollow = async (id: number, type: string) => {
		const token = await getStorageData("authToken")

		this.followId = sendAPIRequest(
			'bx_block_followers/follows',
			{
				method: type == "follow" ? 'POST' : 'DELETE',
				headers: {
					"Content-Type": 'application/json',
					token,
				},
				body: {
					account_id: id,
				}
			}
		)
	}

	handleClickLike = async (id: string, type: string, likeable_type: string) => {
		const selectingPost = this.state.postList.find(post => post.id === id)
		this.setState({ selectedPost: selectingPost! });

		const token = await getStorageData("authToken")

		this.likePostId = sendAPIRequest(
			'bx_block_like/likes',
			{
				method: type == "like" ? 'POST' : 'DELETE',
				headers: {
					"Content-Type": 'application/json',
					token,
				},
				body: {
					likeable_id: id,
					likeable_type: likeable_type
				}
			}
		)
	}

	handleClickSave = async (id: string, type: string) => {
		const token = await getStorageData("authToken")
		const url = type === "save" ? "bx_block_posts/save_post" : "bx_block_posts/unsave_post"

		this.savePostId = sendAPIRequest(
			url,
			{
				method: type == "save" ? 'POST' : 'DELETE',
				headers: {
					"Content-Type": "application/json",
					token,
				},
				body: {
					post_id: id,
				}
			}
		)
	}

	handleClickMoreOption = (activeUser: User, postId: string) => {
		const selectingPost = this.state.postList.find(post => post.id === postId)

		this.setState({ activePostUser: activeUser, selectedPost: selectingPost! });
	};

	handleClickComments = (postId: string) => {
		const selectingPost = this.state.postList.find(post => post.id === postId)

		this.setState({ showComments: true, selectedPost: selectingPost! })
	}

	handleCloseCommentsModal = () => {
		this.setState({ showComments: false, selectedPost: null })
	}

	handleClickPostActivity = (postId?: string, postedById?: number) => {
		const selectingPost = this.state.postList.find(post => post.id === postId)

		if (postedById === this.state.loggedUser?.attributes.id && postId) {
			this.setState({ showPostActivity: true, selectedPost: selectingPost! })
			this.getPostAcitivity(postId)
		}
	}

	handleClosePostActivity = () => {
		this.setState({ showPostActivity: false })
	}

	getPostAcitivity = async (id: string) => {
		const token = await getStorageData("authToken")
		const url = 'bx_block_posts/post_activity' + `?post_id=${id}`

		this.getPostActivityId = sendAPIRequest(
			url,
			{
				method: 'GET',
				headers: {
					'Content-Type': "application/json",
					token,
				},
			}
		)
	}

	setReportPostIssue = (id: number) => {
		this.setState({
			reportIssue: id
		})
	}

	setReportPostSubIssues = (id: number) => {
		this.setState({
			reportSubIssue: id
		})
	}

	closeReportPostModal = () => {
		this.setState({
			showReportPostModal: false,
			reportIssue: undefined,
			reportSubIssue: undefined,
			reportCurrentView: "main",
			reportPostSubCatList: []
		})
	}

	changeReportView = () => {
		if (this.state.reportIssue !== undefined && this.state.reportSubIssue !== undefined) {
			this.handleSubmitReport()
		} else if (this.state.reportIssue !== undefined) {
			this.setState({
				reportCurrentView: "subcat",
				reportPostLoading: true
			}, this.getReportUserSubCategories)
		}
	}

	showReportPost = () => {
		this.setState({
			showReportPostModal: true,
			reportPageLoading: true,
		}, this.getReportUserMainCategories)
	}

	getReportUserMainCategories = async () => {
		const token = await getStorageData("authToken")

		this.getReportUserMainCategoriesId = sendAPIRequest(
			"bx_block_posts/report_issues",
			{
				method: 'GET',
				headers: {
					"Content-Type": "application/json",
					token,
				},
			}
		)
	}

	handleSubmitReport = async () => {
		const token = await getStorageData("authToken")
		const body = {
			"report": {
				"post_id": this.state.selectedPost?.id,
				"report_issue_id": this.state.reportIssue,
				"report_sub_issue_id": this.state.reportSubIssue
			}
		}

		this.submitReportId = sendAPIRequest(
			'bx_block_posts/report_post',
			{
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					token,
				},
				body: body,
			}
		)
	}

	getReportUserSubCategories = async () => {
		const token = await getStorageData("authToken")

		this.getReportUserSubCategoriesId = sendAPIRequest(
			`bx_block_posts/report_sub_issues?id=${this.state.reportIssue}`,
			{
				method: 'GET',
				headers: {
					"Content-Type": "application/json",
					token,
				},
			}
		)
	}

	handleShowMuteConfirmModal = () => {
		this.setState({ showMuteModal: true })
	}

	handleCloseMuteConfirmModal = () => {
		this.setState({ showMuteModal: false })
	}

	handleCLoseMuteUndoPopup = () => {
		this.setState({ muteUndoPopup: false })
	}

	handleClickMuteModal = (status: string) => {
		if (status == "no") {
			this.setState({ showMuteModal: false })
		} else {
			this.muteUserAccount(String(this.state.activePostUser?.id))
		}
	}

	muteUserAccount = async (id: string) => {
		const token = await getStorageData("authToken")

		this.muteUserAccountId = sendAPIRequest(
			"bx_block_followers/mute_account",
			{
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					token,
				},
				body: {
					account_id: id
				}
			}
		)
	}

	unmuteUserAccount = async (id: string) => {
		const token = await getStorageData("authToken")

		this.unmuteUserAccountId = sendAPIRequest(
			"bx_block_followers/unmute_account",
			{
				method: 'DELETE',
				headers: {
					"Content-Type": "application/json",
					token,
				},
				body: {
					account_id: id
				}
			}
		)
	}

	blockAccount = async (id: number) => {
		const token = await getStorageData("authToken")

		this.blockAccountId = sendAPIRequest(
			"bx_block_followers/block_account",
			{
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					token,
				},
				body: {
					account_id: id
				}
			}
		)
	}

	handleUpdateNumberCommentOfPost = () => {
		const posts = [...this.state.postList]
		const updateFeeds = posts.map((post) => {
			if (post.id === this.state.selectedPost?.id) {
				return {
					...post,
					attributes: {
						...post.attributes,
						total_comments: post.attributes.total_comments + 1
					}
				}
			} else {
				return post
			}
		})
		this.setState({ postList: updateFeeds })
	}

	handleCloseDeletePost = () => {
		this.setState({ showDeletePostModal: false })
	}

	handleClickDeletePost = () => {
		this.setState({ showDeletePostModal: true })
	}

	deletePost = async () => {
		const token = await getStorageData("authToken")

		this.deletePostId = sendAPIRequest(
			`bx_block_posts/posts/${this.state.selectedPost?.id}`,
			{
				method: 'DELETE',
				headers: {
					"Content-Type": "application/json",
					token,
				},
			}
		)
	}

	getPinnedPost = (pinned: boolean) => {
		this.setState({ showPinPostModal: true, isPinned: pinned })
	}

	handleClosePinPost = () => {
		this.setState({ showPinPostModal: false })
	}

	pinPost = async (confirmation: string) => {
		if (confirmation !== 'cancel') {
			const token = await getStorageData("authToken")
			const url = confirmation === "pin" ? "bx_block_posts/pin_post" : "bx_block_posts/unpin_post"

			this.pinPostId = sendAPIRequest(
				url,
				{
					method: confirmation === "pin" ? 'POST' : 'DELETE',
					headers: {
						'Content-Type': 'application/json',
						token,
					},
					body: {
						post_id: this.state.selectedPost?.id
					},
				})
		}
		this.handleClosePinPost()
	}

	handleShowRepostModal = (postId: string) => {
		const selectingPost = this.state.postList.find(post => post.id === postId)

		this.setState({ showRepostModal: true, selectedPost: selectingPost! })
	}

	handleCloseRepostModal = () => {
		this.setState({ showRepostModal: false })
	}

	handleRepost = async () => {
		const token = await getStorageData("authToken")

		this.repostId = sendAPIRequest(
			`bx_block_posts/repost`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					token,
				},
				body: {
					parent_post_id: this.state.selectedPost?.id
				},
			}
		)
		this.setState({ showRepostModal: false })
	}

	handleUndoRepost = async () => {
		const token = await getStorageData("authToken")

		this.undoRepostId = sendAPIRequest(
			`bx_block_posts/undo_repost`,
			{
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					token,
				},
				body: {
					parent_post_id: this.state.selectedPost?.attributes.parent_post?.id
				},
			}
		)
		this.setState({ showRepostModal: false })
	}

	handleQuote = () => {
		this.setState({ showQuote: true, showRepostModal: false })
	}

	handleGoBackAfterSubmit = () => {
		this.setState({ showQuote: false })
	}

	handleCloseQuote = () => {
		this.setState({ showQuote: false })
	}

	handleSelectTag = (selectedTag: string) => {
		this.setState({
			showPostList: true,
			searchValue: selectedTag,
		},() => this.getPostList('%23' + selectedTag.slice(1)))
	}

	handleGoBackFromPosts = () => {
		this.setState({ showPostList: false, searchValue: '' })
	}

	handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({searchValue: event.target.value})
	}

}
// Customizable Area End
