import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface ApiData{
  contentType: string;
  method: string;
  endPoint: string; 
  body?: {}
}
interface SubscriptionPlan {
  id: number;
  account_id: number;
  title: string;
  details: string;
  period: string;
  price: number;
}

// Define the interface for Attributes
interface Attributes {
  id: number;
  subscription_plan: SubscriptionPlan;
  expires_on: string;
  powered_by: string | null;
  auto_renewal: boolean;
}

// Define the main interface for the data
export interface UserSubscription {
  id: string;
  type: string;
  attributes: Attributes;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any;
  subscriptionType: string;
  isAutoRenewal: boolean;
  isFormOpen: boolean;
  updateId: number | null;
  token: any;
  subscriptionsList:UserSubscription[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AutomaticRenewalsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAutomaticRenewalsCallId: string = "";
  createAutomaticRenewalsCallId: string = "";
  updateAutomaticRenewalsCallId: string = "";
  subscriptionListId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      subscriptionType: "",
      isAutoRenewal: false,
      isFormOpen: false,
      updateId: null,
      token: "",
      subscriptionsList:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
     this.getMySubscriptions()

    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.onSubscriptionsApiRes(message)
    this.onAutoRenwalUpdateRes(message)
     if (
      this.getAutomaticRenewalsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      apiResponse &&
        this.setState({
          data: apiResponse.data,
        });
    }
    if (
      this.createAutomaticRenewalsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.showAlert("", configJSON.successMessage);
      this.getAutomaticRenewals();
      this.onCancelHandler();
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getAutomaticRenewals() {
    const getAutomaticRenewalsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAutomaticRenewalsCallId = getAutomaticRenewalsMsg.messageId;

    getAutomaticRenewalsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.automaticRenewalEndpoint
    );

    getAutomaticRenewalsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.automaticRenewalsApiContentType,
        token: this.state.token,
      })
    );

    getAutomaticRenewalsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.automaticRenewalsListApiMethod
    );

    runEngine.sendMessage(getAutomaticRenewalsMsg.id, getAutomaticRenewalsMsg);
  }

  createAutoRenewal() {
    if (this.state.subscriptionType.length < 1) {
      this.showAlert("", "Please enter subscription type!");
    } else {
      const httpBody = {
        auto_renew: {
          subscription_type: this.state.subscriptionType,
          is_auto_renewal: this.state.isAutoRenewal,
        },
      };

      const createAutomaticRenewalsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createAutomaticRenewalsCallId = createAutomaticRenewalsMsg.messageId;

      createAutomaticRenewalsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.automaticRenewalEndpoint
      );

      createAutomaticRenewalsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Content-Type": configJSON.automaticRenewalsApiContentType,
          token: this.state.token,
        })
      );

      createAutomaticRenewalsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      createAutomaticRenewalsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.automaticRenewalsCreateApiMethod
      );

      runEngine.sendMessage(
        createAutomaticRenewalsMsg.id,
        createAutomaticRenewalsMsg
      );
    }
  }
  onCancelHandler() {
    this.setState({
      isFormOpen: false,
      subscriptionType: "",
      isAutoRenewal: false,
      updateId: null,
    });
  }
  onUpdateHandler(item: any) {
    this.setState({
      subscriptionType: item.attributes.subscription_type,
      isAutoRenewal: item.attributes.is_auto_renewal,
      isFormOpen: true,
      updateId: item.attributes.id,
    });
  }

  updateAutoRenewal() {
    if (this.state.subscriptionType.length < 1) {
      this.showAlert("", "Please enter subscription type!");
    } else {
      const httpBody = {
        auto_renew: {
          subscription_type: this.state.subscriptionType,
          is_auto_renewal: this.state.isAutoRenewal,
        },
      };

      const updateAutomaticRenewalsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateAutomaticRenewalsCallId = updateAutomaticRenewalsMsg.messageId;

      updateAutomaticRenewalsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.automaticRenewalEndpoint}/${this.state.updateId}`
      );

      updateAutomaticRenewalsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Content-Type": configJSON.automaticRenewalsApiContentType,
          token: this.state.token,
        })
      );

      updateAutomaticRenewalsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      updateAutomaticRenewalsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.automaticRenewalsUpdateApiMethod
      );

      runEngine.sendMessage(
        updateAutomaticRenewalsMsg.id,
        updateAutomaticRenewalsMsg
      );
    }
  }
  apiCall = (data: ApiData) => {
    const { contentType, method, endPoint, body } = data;
    const token =  localStorage.getItem("authToken") || "";
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  getMySubscriptions = () =>{
    this.subscriptionListId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: "bx_block_custom_user_subs/my_subscriptions",
    }) 
  }
  onSubscriptionsApiRes = (message:Message) => {
    const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
    const responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage) );
    if(getName(MessageEnum.RestAPIResponceMessage)===message.id &&  this.subscriptionListId === apiRequestCallId && responseJson){
        const data = responseJson.my_subscriptions.data||[]
        this.setState({
          ...this.state,
          subscriptionsList:  data,
        })
    }
  }

  onAutoRenwalUpdateRes = (message:Message) => {
    const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
    const responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage) );
    if(getName(MessageEnum.RestAPIResponceMessage)===message.id &&  this.updateAutomaticRenewalsCallId === apiRequestCallId && responseJson){
        this.setState({
          ...this.state,
        })
    }
  }
  formatDate = (date:Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`
  };
  updateAuotRenewal = (event:any,id: string) =>{
    let autoRenewalSubscription:any;
    const subscriptionList = this.state.subscriptionsList.map((item) =>{
      if(item.id == id) {
        autoRenewalSubscription = item;
        return {
          ...item,
          attributes: {
            ...item.attributes,
            auto_renewal:event.target.checked
          }
        }
      }
      return item;
    })
    this.setState({
      subscriptionsList:subscriptionList
    })
    this.updateAutomaticRenewalsCallId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.patchMethod,
      endPoint: "bx_block_custom_user_subs/update_plan",
      body:{
        user_subscription_id:autoRenewalSubscription.id,
        auto_renewal: event.target.checked
      }
    }) 
  }
  // Customizable Area End
}
