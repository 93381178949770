import React from "react";
import { IPropMessageRenderer } from "./CometChatBubbleViewTemplate";
import ViewChatPreviewAttachment from "./ViewChatPreviewAttachment.web";
import { Box, styled, Typography } from "@material-ui/core";

export interface IMessageAttach {
    url: string;
}

interface IPropCometChatBubbleViewMessageImageRenderer extends IPropMessageRenderer {
    isViewAttachment?: boolean;
    onViewImage?: () => void;
    onCloseViewAttachment?: () => void;
}

const CometChatBubbleViewMessageImageRenderer = ({ message, isViewAttachment, onViewImage, onCloseViewAttachment }: IPropCometChatBubbleViewMessageImageRenderer) => {
    const getDataMessage = message.getData();
    const senderName = message.getSender().getName() || "";
    const { attachments = [] } = getDataMessage as unknown as { url: string, attachments: IMessageAttach[] };

    return <CometChatBubbleViewMessageImageRendererStyled>
        {attachments?.map((file, index) =>
            <Box key={file.url} className="imageItem" onClick={onViewImage}>
                <img src={file.url} alt={"i"} />
                {index === 3 && <Box className="imageItem-last">
                    <Typography className="countRestImage" component="p">
                        {(attachments.length - 4) > 0 && `${attachments.length - 4}+`}
                    </Typography>
                </Box>
                }
            </Box>)}
        {
            isViewAttachment &&
            <ViewChatPreviewAttachment
                files={attachments}
                senderName={senderName}
                action="view"
                onCloseSelectedFile={onCloseViewAttachment}
            />
        }
    </CometChatBubbleViewMessageImageRendererStyled>
}

const CometChatBubbleViewMessageImageRendererStyled = styled(Box)({
    display: "flex",
    width: "100%",
    gap: "8px",
    flexWrap: "wrap",
    maxWidth: "248px",
    maxHeight: "248px",
    overflow: "hidden",
    borderRadius: "10px",
    "& .imageItem": {
        position: "relative",
        cursor: "pointer",
        "& img": {
            width: "120px",
            height: "120px",
            display: "block",
            margin: "auto"
        },
        "& .imageItem-last": {
            position: "absolute",
            height: "100%",
            width: "100%",
            background: "#000000B2",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& .countRestImage": {
                fontFamily: "Manrope",
                fontWeight: 800,
                fontSize: 18,
                color: "#FFFFFF"
            }
        }
    },
});

export default CometChatBubbleViewMessageImageRenderer