import React from 'react';
import { FieldProps, getIn } from 'formik';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

export interface SelectFieldProps extends FieldProps {
  label: string;
  options: { value: string | number; label: string }[];
}

const FormikSelectField: React.FC<SelectFieldProps> = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  options,
  ...props
}) => {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return (
    <FormControl  size={"medium"} variant="outlined"  error={showError} style={{width:'106px'}}>
      <Select
        {...field}
        {...props}
        onChange={(event) => setFieldValue(field.name, event.target.value)}
        inputProps={{ 'aria-label': 'Without label' }}

      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FormikSelectField;
