// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { withStyles } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import registerServiceWorker from '../../components/src/registerServiceWorker';


const styles = theme => ({
  '@global': {
    html: {
      fontSize: 10,
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: 10
      },
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: 11.5
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        fontSize: 14
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 16
      }
    }
  }
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('../firebase-messaging-sw.js')
  .then(function(registration) {
    console.log('Registration successful, scope is:', registration.scope);
  }).catch(function(err) {
    console.log('Service worker registration failed, error:', err);
  });
}


const App1 = withStyles(styles)(App);
ReactDOM.render(
  <Router>
    <div style={{ height: '100vh', width: '100vw' }}>
      <App1 />
      <ToastContainer />
    </div>
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();