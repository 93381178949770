// Customizable Area Start
export const imagIcon = require("../assets/gallery.png");
export const ArrowBackIcons = require("../assets/arrowBack.svg");
export const badgeIcon = require("../assets/badge.svg");
export const ArrowColorIcon = require("../assets/arrowColor.svg")
export const verifyIcon = require("../assets/verifyIcon.svg");
export const verifyOrange = require("../assets/verifyOrange.svg");
export const verifyYellow = require("../assets/verifyYellow.svg");
export const ownerShipIcon = require("../assets/ownerShipIcon.svg");
export const ArrowDown = require("../assets/ArrowDown.png");
// Customizable Area End
