import { IBlock } from "framework/src/IBlock";
// Customizable Area Start
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    id: string;
    // Customizable Area Start
    checked:boolean;
    open:boolean;
    onClose:()=>void;
    isPinned:boolean;
    onPinnedConfirmation:(confirm:string)=>void
    // Customizable Area End
  }
  
  interface S {
     // Customizable Area Start
    // Customizable Area End
  }
  
  interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
  }
  
export default class PinedPostModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  handleCancel=()=>{
    this.props.onPinnedConfirmation("cancel")
  }

  handleConfirmation=()=>{
    if(this.props.isPinned){
      this.props.onPinnedConfirmation("unpin")
    }else{
      this.props.onPinnedConfirmation("pin")
    }
  }
  // Customizable Area End
}