// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Post } from "./ActivityFeedController";

export const configJSON = require("./config");

export interface Props {
  classes?: any
  checked: boolean
  postData: Post
  onLikePost: (id: string, type: string, likeableType: string) => void
  onSavePost: (id: string, type: string) => void
  showPostActivity?:(id?:string,postedById?:number) => void
  isComments?: boolean
  onClickComments: (postId: string) => void
  onClickRepost: (postId: string) => void
}

interface S {
}

interface SS {
}

export default class PostFooterController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

}
// Customizable Area End