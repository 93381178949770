import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, styled } from '@material-ui/core';
import ImageRenderer from './ImageRenderer.web';
import { IFileWithUrl } from './ViewChatPreviewAttachment.web';
const leftIcon = require("./assets/leftArrow.svg");
const rightIcon = require("./assets/rightArrow.svg");

interface IPropCustomReactSlick {
    files: any[];
    currentFileSelected: number;
    onSelectCurrentFile: (index: number) => void;
}

const CustomArrow: React.FC<{
    type?: 'next' | 'prev';
    style?: React.CSSProperties;
    onClick?: () => void;
}> = ({ style, type = 'next', onClick }) => {
    const isNext = type === 'next';
    const icon = isNext ? rightIcon : leftIcon;
    const className = isNext ? 'slick-arrow-next' : 'slick-arrow-prev';

    return (
        <div
            className={`slick-arrow ${className}`}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
            <ImageRenderer src={icon} className={`btnArrow ${className}`} width={20} height={20} />
        </div>
    );
};

function CustomReactSlick({ files, currentFileSelected, onSelectCurrentFile }: IPropCustomReactSlick) {
    const [listSlide, setListSlide] = useState<IFileWithUrl[]>([]);

    useEffect(() => {
        setListSlide(files)
    }, [files])

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: listSlide.length < 5 ? listSlide.length : 5,
        slidesToScroll: 1,
        centerMode: false,
        swipe: false,
        touchMove: false,
        variableWidth: true,
        nextArrow: <CustomArrow type="next" />,
        prevArrow: <CustomArrow type="prev" />,
    };

    return (
        <StyledSlider key={listSlide.length} {...settings}>
            {listSlide.map((file, index) => (
                <Box
                    className={`itemSlider ${!!(currentFileSelected === index) && "itemSlider-active"
                        }`}
                    key={file.url}
                    onClick={() => onSelectCurrentFile(index)}
                >
                    <ImageRenderer src={file.url} className="imgItem" />
                </Box>
            ))}
        </StyledSlider>
    );
};

const StyledSlider = styled(Slider)({
    display: "flex",
    alignItems: "center",
    height: "100%",
    "& .itemSlider": {
        width: "60px",
        height: "60px",
        border: "1px solid #FFFFFF",
        cursor: "pointer",
        "& img.imgItem": {
            height: "100%!important",
            boxShadow: "0px 8px 32px 0px #0000000F",
        }
    },
    "& .itemSlider.itemSlider-active": {
        "& img.imgItem": {
            opacity: 0.5
        }
    },
    "& .slick-slide": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "60px",
        width: "60px!important",
        padding: "4px",
    },
    "& .slick-list": {
        overflow: "hidden",
        padding: "0!important",
        height: "68px",
        "& .slick-track": {
            width: "100%important"
        }
    },
    "& .slick-arrow": {
        minWidth: "84px",
        "& .slick-arrow-prev.btnArrow": {
            marginLeft: "16px",
            padding: "8px",
            color: "#FFFFFF"
        },
        "& .slick-arrow-next.btnArrow": {
            marginRight: "16px",
            padding: "8px",
            textAlign: "right",
            color: "#FFFFFF"
        }
    },
});

export default CustomReactSlick;