import React from 'react';
import { ReactSVG } from 'react-svg';

interface ImageRendererProps {
  src: string;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
}

const ImageRenderer: React.FC<ImageRendererProps> = ({
  src,
  alt = "i",
  className = '',
  style,
  width = '100%',
  height = 'auto',
}) => {
  const isSvg = src.endsWith('.svg');

  return isSvg ? (
    <ReactSVG
      src={src}
      className={className}
      beforeInjection={(svg) => {
        svg.setAttribute('style', `width: ${width}; height: ${height};`);
      }}
      style={style}
    />
  ) : (
    <img
      src={src}
      alt={alt}
      className={className}
      style={{ width, height, ...style }}
    />
  );
};

export default ImageRenderer;