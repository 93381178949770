import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import codes from "country-calling-code";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export interface ApiData{
  contentType: string;
  method: string;
  endPoint: string; 
  body?: {}
}

export interface Props {
  navigation: any;
  id: string;
  checked:boolean;
  classes?:{[key:string]:string}
}
interface S {
  renderAdditionalResources: string,
  selectedTab: number,
  name: string,
  phoneNumber: string,
  email: string,
  message: string,
  succMessage: string;
  messageType:string;
  validPhoneNumber:boolean,
  validEmail: boolean,
  messageWordsLength: number,
  isLoading: boolean,
  selectedFAQGroup: number,
  FAQGroupList: Array<any>,
  FAQList: Array<any>;
  filteredFAQList: Array<any>;
  searchedFaq:string;
  selectedFAQItem: number;
  countryCode:string;
  countryCodeSelected: string;
  errors:{nameError:string,phoneNumberError:string,dobError:string,emailError:string};
  apiError:string;
  errorMsg: string;
  codesFilter: Array<{
    isoCode2: string;
    countryCodes: Array<string>;
    country:string
  }>,
  formError:string;
}
interface SS {}

export default class AdditionalResourcesController extends BlockComponent<Props, S, SS>{

  FAQGroupsId: string = "";
  FAQsId: string = "";
  contactsId: string = "";

  constructor(props: Props){
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage)
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    
    this.state = {
      renderAdditionalResources: "Additional Resources",
      name: "",
      phoneNumber: "",
      email: "",
      validEmail:false,
      message: "",
      succMessage:"",
      messageType:"",
      validPhoneNumber:false,
      messageWordsLength:0,
      selectedTab: 0,
      isLoading: false,
      selectedFAQGroup:0,
      FAQGroupList:[],
      FAQList:[],
      filteredFAQList:[],
      searchedFaq: "",
      selectedFAQItem:0,
      countryCode:"1",
      countryCodeSelected: "",
      errors:{nameError:'',phoneNumberError:'',dobError:'',emailError:''},
      apiError:"string",
      errorMsg: "",
      codesFilter:codes,
      formError:""
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        if (apiRequestCallId === this.FAQGroupsId) {
          this.setState({FAQGroupList: responseJson.data});
          this.getFAQList(responseJson.data[0]['attributes']['name'], 0);
        }
        if(apiRequestCallId === this.FAQsId){
          this.setState({FAQList:responseJson.data, isLoading:false});
        }
        if(apiRequestCallId === this.contactsId){
          this.onContactUsApiRes(responseJson)
        }
      }
      if (errorReponse) {
        this.setState({
          isLoading: false
        })
      }
    }
  }

  apiCall = (data: ApiData) => {
    const { contentType, method, endPoint, body } = data;
    const token =  localStorage.getItem("authToken") || "";
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  
  onContactUsApiRes = (responseJson: any) => {
    this.setState({isLoading:false});
    if(responseJson.errors){
      this.setState({errorMsg: responseJson.errors[0]});
    }else{
      this.setMessage("suc", "Your message has been sent successfully.");
      setTimeout(() => {
        this.setState({messageType: "", succMessage: "", name:'', email:'', phoneNumber:'', message:'', messageWordsLength: 0, validPhoneNumber:false, validEmail:false, formError:"",countryCode:"1"});
      }, 2000)
    }
  }
  setMessage = (type:string, msg:string) => {
    this.setState({
      messageType: type,
      succMessage: msg
    })
  }

  getDataHelpCenter = () => {
    this.setState({renderAdditionalResources:'Help Center', isLoading:true});
    this.FAQGroupsId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiGetMethod,
      endPoint: "bx_block_contact_us/faq_groups"
    })
  }

  getFAQList = (query :string, groupId: number) => {
    this.setState({selectedFAQGroup:groupId, isLoading:true, selectedFAQItem:0});
    this.FAQsId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: configJSON.apiGetMethod,
      endPoint: `bx_block_contact_us/faqs?faq_group_id=${groupId+1}`
    })
  }

  changeTab = (selected: number) => {
    this.setState({selectedTab:selected});
  }

  handleFAQSearch = (value:string) => {
    this.setState({searchedFaq:value});
    this.setState({filteredFAQList:this.state.FAQList.filter(item => item.attributes.question.toLowerCase().includes(value.toLowerCase()) || item.attributes.answer.toLowerCase().includes(value.toLowerCase()))});
  }

  expandQuestionAndAns = (index:number) => {
    if(index === this.state.selectedFAQItem){
      this.setState({selectedFAQItem:-1})
    } else{
      this.setState({selectedFAQItem:index})
    }
  }

  changePhoneNumber = (phone: string) => {
    this.setState({ phoneNumber: phone });
  
    const country: any = this.state.codesFilter.find((countryCode) => {
      return countryCode.countryCodes[0] === this.state.countryCode;
    });
  
    const mobileNumberValidator = parsePhoneNumberFromString(
      `+${this.state.countryCode} ${phone}`,
      country?.isoCode2
    );
  
    if (mobileNumberValidator ? mobileNumberValidator.isValid() : false) {
      this.setState({
        validPhoneNumber: true,
        errors: { ...this.state.errors, phoneNumberError: '' },
        formError: ''
      });
    } else if (phone.length > 0 && this.state.formError) {
      this.setState({
        validPhoneNumber: false,
        errors: { ...this.state.errors, phoneNumberError: configJSON.enterValidNumber },
        formError: configJSON.enterValidNumber
      });
    } else {
      this.setState({
        validPhoneNumber: false,
        errors: { ...this.state.errors, phoneNumberError: configJSON.enterValidNumber },
        formError: configJSON.enterValidNumber
      });
    }
  };
  
  changeEmail = (email: string) => {
    this.setState({ email });
  
    if (this.isValidEmail(email)) {
      this.setState({
        validEmail: true,
        errors: { ...this.state.errors, emailError: '' },
        formError: ''
      });
    } else {
      this.setState({
        validEmail: false,
        errors: { ...this.state.errors, emailError: configJSON.enterValidEmail },
        formError: configJSON.enterValidEmail
      });
    }
  };
  
  
  changeMessage = (text:string) => {
    const words = text.match(configJSON.wordRegex) || [];
    this.setState({messageWordsLength: words.length});
    if(words.length <= 99){
      this.setState({message:text});
    }
  }

  onCountryCodeChange = (e: any) => {
    this.setState({ countryCode: e.target.value[0], phoneNumber:'', errors:{...this.state.errors,phoneNumberError: '' }})
  };

  resetError = () => {
    this.setState({errorMsg: ""})
  }

  isStringNullOrBlank(string: string) {
    return string === null || string.length === 0;
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }
    return error;
  }

  isValidEmail(email: string): boolean {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null;
}

  contactUsSubmit = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.message)
    ) {
      this.setState({
        validPhoneNumber: false,
        formError: configJSON.errorAllFieldsAreMandatory
      });
      return;
    }

    let phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCode,
      this.state.phoneNumber
    );

    if (phoneNumberError) {
      this.setState({
        formError: phoneNumberError
      });;
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.setState({
        formError: configJSON.enterValidEmail
      });
      return false;
    }
      this.setState({isLoading:true, errorMsg: ""});
      this.contactsId = this.apiCall({
        contentType: configJSON.ApiContentTypeJson,
        method: configJSON.apiPostMethod,
        endPoint:'bx_block_contact_us/contacts',
        body:{
        "name":this.state.name,
        "email":this.state.email,
        "full_phone_number":`+${this.state.countryCode}${this.state.phoneNumber}`,
        "description":this.state.message
        }
      })
  }

}
