// Customizable Area Start
import { Box, styled, Typography } from '@material-ui/core'
import React from 'react'
import { deleteIcon, editIcon } from './assets'
import { Subscription } from './Subscriptionbilling2Controller'
interface Props {
    data: Subscription
    onEdit:(data:Subscription) => void;
    onDelete:(data:Subscription) => void;

}
const SubscriptoinCard = ({ data, onDelete,onEdit }: Props) => {
    return (<StyleContainer>
        <div className='card'>
            <div className="planTitle">
                <Typography className='fontStyle'>{data.attributes.title}</Typography>
                <div style={{ gap: '9px', display: 'flex' }}>
                    <img src={editIcon} onClick={() =>onEdit(data)} data-test-id="editBtn"/>
                    <img src={deleteIcon} onClick={()=>onDelete(data)} data-test-id="deleteBtn"/>

                </div>
            </div>
            <Typography className='fontStyle price'>${data.attributes.price}/ {data.attributes.period}</Typography>
            <Typography className='fontStyle price' style={{ fontSize: '10px' }}>{data.attributes.details} </Typography>
        </div>
    </StyleContainer>)
}
const StyleContainer = styled(Box)({
    "& .card": {
        width: '330px',
        border: '1px solid #5B5B5B',
        backgroundColor: '#222222',
        borderRadius: '10px',
        gap: '10px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '2rem'
    },
    "& .planTitle": {
        display: 'flex',
        justifyContent: 'space-between'
    },
    "& .fontStyle": {
        fontSize: "18px",
        fontWeight: "800",
        fontFamily: "Manrope",
        color: "#FFFF"
    },
    "& .price": {
        fontSize: "14px",
        fontWeight: "500",
    }
})

export default SubscriptoinCard;
// Customizable Area End
