import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Box, Checkbox, Input, InputAdornment, styled, Typography } from '@material-ui/core';
import { profile, searchDark, searchWhite } from '../../blocks/chat/src/assets';
import { checkedCheckBox, uncheckedCheckBox } from '../../blocks/groups/src/assets';
import { CloseOutlined } from '@material-ui/icons';

interface IGoupsMemberListSectionProps {
    checked?: boolean;
    listMemberSelected: CometChat.User[];
    listContact: CometChat.User[];
    checkedContacts: { [uid: string]: boolean };
    onCheckboxChange: (uid: string) => void;
    onCheckedItemClick: (uid: string) => void;
}

interface IPropContactList {
    title: string;
    contacts: {
        getUid: () => string;
        getAvatar: () => string | null;
        getName: () => string;
    }[];
    checkedContacts: { [uid: string]: boolean };
    onCheckboxChange: (uid: string) => void;
}

const GroupsMemberListSection: React.FC<IGoupsMemberListSectionProps> = ({
    checked,
    listMemberSelected,
    listContact,
    checkedContacts,
    onCheckboxChange,
    onCheckedItemClick,
}) => {
    const [searchValue, setSearchValue] = useState("");
    const [currentListMember, setCurrentListMember] = useState<CometChat.User[]>([]);

    useEffect(() => {
        if (searchValue) {
            const listMemberMatch = listContact.filter(contact => {
                const contactName = contact?.getName();
                return contactName && contactName.toLowerCase().includes(searchValue.trim().toLowerCase());
            });
            setCurrentListMember(listMemberMatch);
        } else {
            setCurrentListMember(listContact);
        }
    }, [listContact, searchValue]);

    const handleSearchValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = event.target;
        setSearchValue(value);
    }

    const renderListMemberSelected = (member: CometChat.User) => {
        const uid = member.getUid();
        return <Box className="memberContainer" key={uid}>
            <Box className="memberSelected">
                <Box className="memberSelected__profile" >
                    <img src={member?.getAvatar() || profile} className="memberProfileImg" alt="i" />
                </Box>
                <CloseOutlined className="removeMemberSelect" onClick={() => onCheckedItemClick(uid)} />
            </Box>
            <Typography className="memberSelected__name" component="p">
                {member?.getName()}
            </Typography>
        </Box>
    }

    const renderContactList = ({ title, contacts, checkedContacts, onCheckboxChange }: IPropContactList) => {
        return (
            <SuggestPeopleContainerStyled >
                <Typography className="suggestPeopleTitle">{title}</Typography>
                <Box className="listContactUser">
                    {contacts.map(contact => {
                        const uid = contact.getUid();
                        const isChecked = checkedContacts[uid] || false;

                        return (
                            <Box key={uid} className="contactUser">
                                <Box className="contactUserInformation">
                                    <img src={contact.getAvatar() || profile} width={48} height={48} alt="i" style={{ borderRadius: "30px" }} />
                                    <Typography component="p" className={`${clsx(checked && "lightThemeText")}`}>{contact.getName()}</Typography>
                                </Box>
                                <Checkbox
                                    style={{ width: "20px", height: "20px" }}
                                    checked={isChecked}
                                    data-test-id="checkBoxSelectedMember"
                                    onChange={() => onCheckboxChange(uid)}
                                    icon={<img src={uncheckedCheckBox} alt="uncheck" />}
                                    checkedIcon={<img src={checkedCheckBox} alt="checked" />}
                                />
                            </Box>
                        );
                    })}
                </Box>
            </SuggestPeopleContainerStyled>
        )
    };


    return (
        <GroupsMemberListSectionStyled className="groupsMemberListSectionStyled">
            <Box className={"searchWrapper"}>
                <Input
                    id="input-with-icon-adornment"
                    className={"searchField"}
                    placeholder="Search"
                    value={searchValue}
                    onChange={handleSearchValue}
                    startAdornment={
                        <InputAdornment position="start">
                            <img src={checked ? searchDark : searchWhite} alt="Search Icon" />
                        </InputAdornment>
                    }
                />
            </Box>

            {!!listMemberSelected.length && (
                <Box className="membersSelectedContainer">
                    <Box className="listMemberGroup">
                        {listMemberSelected.map((member) =>
                            renderListMemberSelected(member)
                        )}
                    </Box>

                    <Typography
                        className={`memberCount ${clsx(
                            checked && "lightThemeText"
                        )}`}
                        component="p"
                    >
                        {`${listMemberSelected.length} of ${listContact.length} selected`}
                    </Typography>

                </Box>
            )}

            {renderContactList({
                title: 'Connections on Arrow',
                contacts: currentListMember,
                checkedContacts,
                onCheckboxChange,
            })}
        </GroupsMemberListSectionStyled>
    );
};

const SuggestPeopleContainerStyled = styled(Box)(() => ({
    marginTop: "5px",
    "& .suggestPeopleTitle": {
        fontSize: '16px',
        fontFamily: 'Manrope',
        fontWeight: 600,
        color: '#6B6B6B',
    },
    "& .listContactUser": {
        display: "flex",
        flexDirection: "column",
        marginTop: "20px",
        gap: 16,
        "& .contactUser": {
            display: "flex",
            justifyContent: "space-between",
            "& .contactUserInformation": {
                display: "flex",
                alignItems: "center",
                gap: 10,
                "& p": {
                    color: "#FFFFFF",
                    fontFamily: "Manrope",
                    fontWeight: 600,
                    fontSize: 16
                }
            }
        }
    }
}))


const GroupsMemberListSectionStyled = styled(Box)(() => ({
    gap: "16px",
    display: "flex",
    flexDirection: "column",
    "& .membersSelectedContainer": {
        display: "flex",
        flexDirection: "column",
        gap: 14,
        "& .memberCount": {
            fontFamily: "Manrope",
            fontSize: "14px",
            fontWeight: 500,
            color: "#FFFFFF"
        },
    },
    "& .searchWrapper": {
        height: "48px",
        padding: '0px 15px',
        border: '1px solid #6B6B6B',
        borderRadius: '10px',
        alignContent: 'center',
        "& .searchField": {
            '&::after': {
                display: "none",
            },
            '&::before': {
                display: "none",
            },
            width: "100%"
        },
    },
    "& .listMemberGroup": {
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        "& .memberContainer": {
            maxWidth: "48px",
            "& .memberSelected": {
                width: "48px",
                height: "48px",
                position: "relative",
                "& .memberSelected__profile": {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: "32px",
                    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
                    padding: "2px",
                    boxSizing: "border-box",
                    "& .memberProfileImg": {
                        display: "block",
                        width: "100%",
                        height: "100%",
                        borderRadius: "30px",
                        objectFit: "cover"
                    },
                },
                "& .removeMemberSelect": {
                    cursor: "pointer",
                    width: 12,
                    height: 12,
                    color: "white",
                    padding: "1px",
                    fontSize: "10px",
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
                    borderRadius: "16px"
                }
            },
            "& .memberSelected__name": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontFamily: "Manrope",
                fontWeight: 400,
                fontSize: "8px",
                color: "#81999E",
                textAlign: "center"
            }
        }
    },
    "& .lightThemeText": {
        color: "#222222!important"
    }
}))

export default GroupsMemberListSection;