import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import NetInfo from "@react-native-community/netinfo";
import { AppleLoginResponse } from "./types";
import { Message } from "../../../framework/src/Message";
import {
  objectType
} from 'reactjs-social-login'
const uuId=require('device-uuid')
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  testId: string;
  buttonTitle: string;
  callback: (response?: AppleLoginResponse) => void;
  onPress: () => void;
  checked?:boolean
  hideButton?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isNetConnected: boolean | null;
  userId: string;
  identityToken: string;
  email: string;
  firstName: string;
  lastName: string;
  appleError:{},
  apiError:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AppleLoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  appleSignInApiCallId: string;
  appleLoginId:string=''

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.appleSignInApiCallId = "";
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isNetConnected: true,
      userId: "",
      identityToken: "",
      email: "",
      firstName: "",
      lastName: "",
      appleError:{},
      apiError:''
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    NetInfo.addEventListener((state) => {
      this.setState({ isNetConnected: state.isConnected });
    });
    // Customizable Area End
  }

  // Customizable Area Start
 
  async receive(from: string, message: Message) {
   this.getLoggedInUser(message)
  }

  getLoggedInUser=(message: Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      let responseJsonSuccess = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonSuccess && !responseJsonSuccess.errors) {
        if (apiRequestCallId === this.appleLoginId) {
          this.handleAppleNavigation(responseJsonSuccess)
          
        }
      } else {
        this.setState({ apiError: responseJsonSuccess.errors[0] })
      }
    }

  }

  handleAppleNavigation=(responseJson:any)=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "OtpInputauth"
    );
    this.send(message);
    this.props.callback(responseJson);
  }

  handleSignInWithApple = async () => {
    if (!this.state.isNetConnected) {
      this.noInternetConnection();
      return;
    }
  };

  noInternetConnection = () => {
    this.showAlert(configJSON.alertTitle, configJSON.noInternetAlertMessage);
  };

  appleSignIn = async () => {};

  handleAppleCatchError(errorCode: string) {}

  getAppleErrorMessage(errorCode: string) {}

  getAppleAuthResponse = async () => {};

  setUserDetailIfItHas = async () => {};

  isValidate = () => {};

  validateData() {}

  setAppleLoginData=(provider:string, data:objectType|undefined)=>{
    if(data!==undefined){
      this.handleAppleLoginApi(data) 
    }
  }

  handleAppleLoginApi=(details:objectType)=>{
    const deviceUniqueId=new uuId.DeviceUUID().get()
    let headers = {
      "Content-Type": configJSON.validationApiContentType
    };
  
    let httpBody = {
      data: {
        attributes: {
          full_name:details.authorization && details.authorization.full_name || "Unknown Name",
          id_token: details.authorization && details.authorization.id_token,
          device_id:deviceUniqueId
        }
      }
    }
  
    const addAppleLoginMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.appleLoginId = addAppleLoginMessage.messageId;
  
    addAppleLoginMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.appleLoginEndpoint
    );
  
    addAppleLoginMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addAppleLoginMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    addAppleLoginMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
  
    runEngine.sendMessage(addAppleLoginMessage.id, addAppleLoginMessage);
  }
  
  setAppleLoginError=(error:{})=>{
  this.setState({appleError:error})
  }

  // Customizable Area End
}
