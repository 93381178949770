//@ts-nocheck
import { withStyles, createStyles } from "@material-ui/core";
import React from "react";
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from "@material-ui/core/TextField";
import { FieldProps, getIn } from "formik";

export interface TextFieldProps
  extends FieldProps,
  Omit<MuiTextFieldProps, "name" | "value" | "error"> { }

export function fieldToTextField({
  disabled,
  field: { ...field },
  form: { touched, errors },
  helperText,
  ...props
}: TextFieldProps): MuiTextFieldProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    variant: props.variant,
    disabled: disabled,
    ...field,
    ...props,
    error:showError
  };
}

const FormikInputField = ({
  children,
  InputLabelProps = {},
  InputProps = {},
  classes,
  margin = "none",
  padding,
  ...props
}: TextFieldProps) => {
  return (
    <MuiTextField
      {...fieldToTextField(props)}
      {...props}
      variant="outlined"
      InputLabelProps={{
        ...InputLabelProps, shrink: true, classes: {
        }
      }}
      InputProps={{
        ...InputProps,
        notched: false,
      }}
    >
      {children}
    </MuiTextField>
  );
};

export default FormikInputField;