// Customizable Area Start
import React from "react";
import { Box, withStyles, createStyles, Typography } from "@material-ui/core";
import ParentPostUiController, {
  Props,
} from "./ParentPostUiController.web";
import { userImage } from "../../hashtags/src/assets";
import { verifyImg } from "../../../components/src/utils";
import PostBody from "../../activityfeed/src/PostBody.web";

export class ParentPostUi extends ParentPostUiController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, postData, checked } = this.props;
    const { post_by, created_at } = postData.attributes

    return (
      <Box className={classes.container}>
        <Box className={classes.headerWrapper}>
          <img src={post_by.profile_photo || userImage} className={classes.headerImg} />
          <Typography 
            className={classes.headerFullname}
            style={{color: checked ? '#222222' : '#FFFFFF'}}
          >
            {post_by.full_name}
          </Typography>
          <img src={verifyImg(post_by.user_subscription)} alt='verify-icon' />
          <Typography className={classes.headerUsername}>{post_by.user_name}</Typography>
          <Typography className={classes.createdAt}>{created_at}</Typography>
        </Box>
        <PostBody postData={postData} checked={checked} />
      </Box>
    )
  }
}

const styles = createStyles({
  container: {
    padding: '8px 24px',
    borderRadius: '10px',
    border: '1px solid #222222',
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    marginBottom: '5px',
  },
  headerImg: {
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    marginRight: '10px',
  },
  headerFullname: {
    fontFamily: 'Manrope',
    fontWeight: 800,
    fontSize: '18px',
    color: '#FFFFFF',
  },
  headerUsername: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: '12px',
    color: '#6B6B6B',
  },
  createdAt: {
    fontFamily: 'Manrope',
    fontWeight: 400,
    fontSize: '11px',
    color: '#81999E',
  },
});

export default withStyles(styles)(ParentPostUi);
// Customizable Area End
