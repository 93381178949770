// Customizable Area Start

import React, { useEffect, useState } from 'react'
import { youtubeIcon } from '../assests';

interface YoutubePreviewProps {
	postContent: string
	checked: boolean
}

const YoutubePreview: React.FC<YoutubePreviewProps> = ({ postContent, checked }) => {
	const [videoTitle, setVideoTitle] = useState('')

	const getFirstYouTubeUrl = (text: string) => {
		const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S*\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const matches = text.match(regex);
    return matches ? matches[1] : null;
	}

	const firstVideoId = getFirstYouTubeUrl(postContent)

	useEffect(() => {
		if (firstVideoId) {
			fetch(`https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${firstVideoId}&format=json`)
			.then(response => response.json())
			.then(data => setVideoTitle(data?.title))
			.catch(error => console.log(error))
		}
	}, [firstVideoId])

	if (!firstVideoId) return null	

	return (
		<div style={{display: 'flex', alignItems: 'center', gap: '8px', borderRadius: '10px', overflow: 'hidden', border: '0.5px solid rgb(29, 29, 29)'}}>
			<img src={`https://i.ytimg.com/vi/${firstVideoId}/mqdefault.jpg`} width={120} height={120} />
			<div>
				<div style={{display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '10px'}}>
					<img src={youtubeIcon} alt='youtube-icon' />
					<p style={{fontFamily: 'Manrope', fontSize: '12px', fontWeight: '500', color: checked ? '#222' : '#FFF'}}>Youtube</p>
				</div>
				<p style={{fontFamily: 'Manrope', fontSize: '14px', fontWeight: '800', color: checked ? '#222' : '#FFF'}}>{videoTitle}</p>
			</div>
		</div>
	)
}

export default YoutubePreview


// Customizable Area End

