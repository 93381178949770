import { Box, Modal, styled, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IReaction } from "./CometChat";
import { LIST_REACTION, transformListReaction } from "./CometChatBubbleViewListReaction";
import { TAny } from "./utils";
import { summarizeReactions } from "./CometChatBubbleViewTemplate";
import clsx from "clsx";
const avatarProfile = require("./assets/avatarReactionIcon.svg");

interface IPropsModalTotalReaction {
    userInfo: CometChat.User | null;
    listReaction: IReaction[];
    isOpen: boolean;
    onCloseModal: () => void;
    checked?: boolean;
}

export default function ModalTotalReaction({
    userInfo,
    listReaction,
    isOpen,
    checked,
    onCloseModal
}: IPropsModalTotalReaction) {
    const [currentTab, setCurrentTab] = useState("all")
    const [listTabReaction, setListTabReaction] = useState<TAny[]>([])
    const [currentListReaction, setCurrentListReaction] = useState<TAny[]>([])

    useEffect(() => {
        setCurrentTab("all");
        setCurrentListReaction(listReaction);
        handleGetTabReactions(listReaction)
    }, [listReaction])

    useEffect(() => {
        if (currentTab !== "all") {
            const reactionByTab = listReaction.filter(reaction => (
                reaction.reaction === currentTab
            ));
            setCurrentListReaction(reactionByTab);
            return;
        }
        setCurrentListReaction(listReaction);
    }, [currentTab])

    const handleGetTabReactions = (listReaction: IReaction[]) => {
        const currentUserLoginUid = userInfo?.getUid() || "";
        const listReactionSummarize = summarizeReactions(listReaction, currentUserLoginUid);
        setListTabReaction(listReactionSummarize)
    }

    const handleSelectTab = (value: string) => {
        setCurrentTab(value)
    }

    return <Box>
        <Modal
            open={isOpen}
            onClose={onCloseModal}
        >
            <ModalContainerStyled checked={checked}>
                <Box className="reaction__container tab-reaction">
                    <Box
                        className={`tabReactionItem ${clsx(currentTab === "all" && "tabReactionItem-active")}`}
                        onClick={() => handleSelectTab("all")}
                    >
                        <Typography
                            className="themeText"
                            style={{ fontSize: 16, fontWeight: 600 }}
                        >
                            All
                        </Typography>
                    </Box>
                    {transformListReaction(listTabReaction).map(reaction => (
                        <Box
                            className={`tabReactionItem ${clsx(currentTab === reaction.value && "tabReactionItem-active")}`}
                            key={reaction.value}
                            onClick={() => handleSelectTab(reaction.value)}
                        >
                            <img width={20} height={20} alt="i" src={reaction.icon} />
                            <Typography
                                className="themeText"
                            >
                                {reaction.count}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box className="reaction__container list-reaction">
                    {currentListReaction.map(reaction => {
                        const { id, reaction: reactionValue, reactedBy } = reaction;
                        const { avatar = "", name = "" } = reactedBy;
                        return <Box key={id} className="reaction__info">
                            <img alt="i" className="profile-avatar" src={avatar || avatarProfile} width={30} height={30} />
                            <Box className="reaction__name">
                                <Typography component="p" className="reaction__name-text">
                                    {name}
                                </Typography>
                            </Box>
                            <img alt="i" width={24} height={24} src={LIST_REACTION.find(reaction => reaction.value === reactionValue)?.icon} />
                        </Box>
                    })}
                </Box>
            </ModalContainerStyled>
        </Modal>
    </Box>
}


const ModalContainerStyled = styled(Box)(({ checked }: { checked?: boolean }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 420,
    backgroundColor: checked ? '#FFFFFF' : "#0B0B0B",
    border: '1px solid #999999',
    borderRadius: 10,
    padding: "36px 18px",
    outline: "none",
    "& .reaction__container": {
        padding: "0 20px",
        display: "flex",
        alignItems: "center",
    },
    "& .reaction__container.tab-reaction": {
        justifyContent: "center",
        "& .tabReactionItem": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 58,
            height: 35,
            gap: 2,
            borderBottom: "3px solid #999999",
            "& .themeText": {
                fontFamily: "Manrope",
                fontWeight: 700,
                fontSize: 14,
                color: checked ? "#222222" : "#FFFFFF"
            }
        },
        "& .tabReactionItem.tabReactionItem-active": {
            borderImageSlice: 1,
            borderImageSource: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
            backgroundImage: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
            color: "transparent",
            backgroundClip: "text",
            "& .themeText": {
                color: "transparent"
            }
        }
    },
    "& .reaction__container.list-reaction": {
        flexDirection: "column",
        rowGap: "15px",
        maxHeight: 350,
        overflowY: "auto",
        "& .reaction__info": {
            display: "flex",
            gap: "10px",
            alignItems: "center",
            width: "100%",
            "& .profile-avatar": {
                borderRadius: "40px"
            },
            "& .reaction__name": {
                flex: 1,
                overflow: "hidden",
                "& .reaction__name-text": {
                    fontFamily: "Manrope",
                    fontSize: 14,
                    fontWeight: 500,
                    color: checked ? "#222222" : "#FFFFFF",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }
            }
        }
    }
}));
