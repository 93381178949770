import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start


export interface IApiData{
    contentType: string;
    method: string;
    endPoint: string; 
    body?: {}
  }
  
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    route: any;
    data: any;
    checked?: boolean
    classes?: { [key: string]: string }
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    isLoading:boolean;
    apiError:any
    // Customizable Area End

}

interface SS {
    id: any;
}

export default class CardController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    createCardId: any;

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            token: "",
            isLoading:false,
            apiError:''
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        runEngine.debugLog("Message Recived", message);
        
        this.submitCardAPIRes(message)
        // Customizable Area End

    }
    async componentDidMount() {
        super.componentDidMount();
    }

    createCard =  (data:any) => {
            this.createCardId = this.apiCallFun({
                contentType: configJSON.apiContentType,
                method: configJSON.confirmPaymentMethod,
                endPoint: "bx_block_stripe_integration/cards",
                body:{
                    source:data.id
                }
              });
    };
    apiCallFun = (data: IApiData) => {
        const token =  localStorage.getItem("authToken") || "";
        const { contentType,  body,method, endPoint, } = data;

        const header = {
          "Content-Type": contentType,
          token: token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
          );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
        
        body &&
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
      };
      backToSubscription = ()=>{
        this.props.navigation.goBack()
      }

      submitCardAPIRes=(message:Message)=>{
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
    
            if (apiRequestCallId === this.createCardId) {
              if (!responseJson.errors) {
              if(responseJson){
              
                this.backToSubscription()
    
              }
              this.parseApiCatchErrorResponse(errorReponse);
             }
             else{
              this.setState({apiError:responseJson.errors[0]})
             }
          }
        }
      }
}