import { IBlock } from "framework/src/IBlock";
// Customizable Area Start
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { FormikProps , FormikErrors} from 'formik';
import codes from "country-calling-code";
import { ISubmit } from "./ProfileController.web";

export interface ICategoryResp{
  data:Array<ICategroy>
}
export interface ICategroy{
  id:string,
  type:string,
  attributes:ICategoryAttributes
}

export interface ICategoryAttributes extends SubCategroy{
  sub_categories: Array<SubCategroy>
}

export interface SubCategroy{
is_selected:boolean,
rank:number,
name:string,
id:number
}

export interface ICountry{
  country:string,
  isoCode2:string
}

export interface InitialValues{
    profilePic: string,
    coverPic:string,
    website:string,
    name:string,
    occuption:string,
    country:string,
    category:string,
    subCategory:string,
    bio:string,
    twitter:string,
    instagram:string,
    facebook:string
}

export interface IUserInfo {
full_name:string|null,
bio:string|null,
occupation:string|null,
website:string|null,
location:string|null,
profile_photo:string|null,
cover_photo:string|null
}

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    open:boolean;
    userInfo:IUserInfo;
    onClose:()=>void;
    onSubmit:(values:ISubmit)=>void
    checked:boolean;
    // Customizable Area End
  }
  
  interface S {
    // Customizable Area Start
     activeTab:string
     formValue:InitialValues
     selectedCategories:Array<ICategroy>;
    selectedSubCategories:Array<SubCategroy>,
    categories:Array<ICategroy>;
    currentProfile:File|undefined;
    profileImage:null|string;
    coverImage:null|string,
    currentCoverProfile:File|undefined,
    countryFiter:Array<ICountry>
    country:string
    anchorEl:null | HTMLElement;
    // Customizable Area End
  }
  
  interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
  }
  
export default class EditProfileModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
    getCategoriesApiCallId:string="";
    updateCategoriesApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeTab:"Basic",
      formValue:{
        bio:'',
        category:'',
        country:'',
        coverPic:'',
        name:'',
        occuption:'',
        profilePic:'',
        subCategory:'',
        website:'',
        twitter:'',
        instagram:'',
        facebook:'',
      },
      currentProfile:undefined,
      profileImage:null,
      coverImage:null,
      currentCoverProfile:undefined,
      selectedCategories:[],
      categories:[],
      selectedSubCategories:[],
      countryFiter:codes,
      country:'',
      anchorEl:null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    this.categoriesRes(message)
    // Customizable Area Endc
  }

  // Customizable Area Start

  componentDidMount=async()=>{
    this.initialStates()
  }
  initialStates=()=>{
    this.setState((prev)=>({
      profileImage:this.props.userInfo.profile_photo,
      coverImage:this.props.userInfo.cover_photo,
      country:this.props.userInfo.location??'',
      formValue:{
        ...prev.formValue,
        bio:this.props.userInfo.bio??'',
        name:this.props.userInfo.full_name??'',
        website:this.props.userInfo.website??'',
        occuption:this.props.userInfo.occupation??'',
      }
       
     }))
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if(this.props.open!==prevProps.open){
      if(this.props.open){
        this.initialStates()
        this.getCategories()
      }
    }
  }

  getCategories = async()=>{
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),"bx_block_categories/categories");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  categoriesRes =(message: Message) =>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getCategoriesApiCallId) {
      if (!responseJson?.erros) {       
        this.setState({categories:responseJson.data},this.setSelectedCategories)
        }
      }
    }

  handleDeleteCategories = (id:string) => {    
    let cat = [...this.state.categories]
    const updatedCategories = cat.map((category:ICategroy) => {
      if (category.attributes.id.toString() == id) {
        return {
          ...category,
          attributes: {
            ...category.attributes,
            is_selected: false
          }
        };
      }
      return category;
    });
    this.setState({ categories: updatedCategories },this.setSelectedCategories)
  };

  handleChangePicture=(event: React.ChangeEvent<HTMLInputElement>)=>{
    const file = event.target.files?.[0];
    if (file  && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      this.setState({currentProfile:file},()=>{
         this.setState({profileImage:URL.createObjectURL(file)})
      })
    }
  }
  
  handleChangeCoverPicture=(event: React.ChangeEvent<HTMLInputElement>)=>{
    const file = event.target.files?.[0];
    if (file  && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      this.setState({currentCoverProfile:file},()=>{
         this.setState({coverImage:URL.createObjectURL(file)})
      })
    }
  }


  handleChangeCategories = (event:React.ChangeEvent<{name?: string,value: unknown}>) => {
    const selectedIds = event.target.value as Array<number>;
    const updatedCategories = this.state.categories.map((category: ICategroy) => ({
        ...category,
        attributes: {
            ...category.attributes,
            is_selected: selectedIds.includes(category.attributes.id)        
          }
    }));

    this.setState({ categories: updatedCategories }, this.setSelectedCategories);
   }

  setSelectedCategories = () => {
    const selectedCategories = this.state.categories.filter((category:ICategroy) => category.attributes.is_selected);
    this.setState({ selectedCategories },this.setSelectedSubCategories);
  };

  setSelectedSubCategories = () => {
    const selectedSubCategories = this.state.categories
      .filter((category:ICategroy) => category.attributes.is_selected) 
      .flatMap((category:ICategroy) => category.attributes.sub_categories)
      .filter((subCategory:SubCategroy) => subCategory.is_selected);
    this.setState({ selectedSubCategories });
  };

  handleDeleteSubCategories = (id:number) => {
    const updatedCategories = this.state.categories.map((category:ICategroy) => ({
      ...category,
      attributes: {
        ...category.attributes,
        sub_categories: category.attributes.sub_categories.map((subCategory:SubCategroy) => {
          if (subCategory.id === id) {
            return {
              ...subCategory,
              is_selected: false,
            };
          }
          return subCategory;
        }),
      },
    }));
    this.setState({ categories: updatedCategories }, this.setSelectedCategories);
  }; 


   handleDataChange=(errors:FormikErrors<InitialValues>,formikProps:FormikProps<InitialValues>)=>{
    const {setTouched} = formikProps
    if(Object.keys(errors).length === 0){
      return true
    }else{
      const touchedFields = Object.keys(errors).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {} as { [key: string]: boolean });
      setTouched(touchedFields);
     return false
    }
   }

  handleTabChange = async(event: React.ChangeEvent<{}>, newValue: string, formikProps:FormikProps<InitialValues>) => {
    const {validateForm } = formikProps
    const errors = await validateForm();
    const resp = this.handleDataChange(errors,formikProps)
    if(resp){
      this.setState({
        activeTab: newValue
      })
    }
  }


  handleChangeSubCategories = (id:number) => {
    const updatedCategories = this.state.categories.map((category:ICategroy) => ({
      ...category,
      attributes: {
        ...category.attributes,
        sub_categories: category.attributes.sub_categories.map((subCategory:SubCategroy) => {
          if (subCategory.id === id) {
            return {
              ...subCategory,
              is_selected: true,
            };
          }
          return subCategory;
        }),
      },
    }));

    this.setState({ categories: updatedCategories }, this.setSelectedCategories);
  };


  handleFormSubmit=(values:InitialValues)=>{
      const payload={
        name:values.name,
        bio:values.bio,
        occupation:values.occuption,
        website:values.website,
        profile_photo:this.state.currentProfile,
        cover_photo:this.state.currentCoverProfile,
        country:this.state.country
      }
      this.props.onSubmit(payload)
      this.categoriesForm()
  }


  categoriesForm =async()=>{
    const token = await getStorageData("authToken");
    const header = {
      token: token,
      "Content-Type": configJSON.searchApiContentType,
    };
    
  
    const selectedSUbcaregorisIds = this.state.selectedCategories
    .filter((category:ICategroy) => category.attributes.is_selected)
    .flatMap((category: ICategroy) => category.attributes.sub_categories)
    .filter((subCategory: SubCategroy) => subCategory.is_selected) 
    .map((subCategory: SubCategroy) => subCategory.id) 
     

    const selectedCategoryIds = this.state.selectedCategories
    .filter((category:ICategroy) => category.attributes.is_selected) 
    .map((category:ICategroy) => category.id)

  const httpBody ={
    selected_ids: {
      category_ids:selectedCategoryIds,
      sub_category_ids: selectedSUbcaregorisIds,
    },
  } 
   
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_categories/user_categories");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage)
     
  }

  openCountryPopover=(event:React.MouseEvent<HTMLElement>)=>{
    this.setState({
       anchorEl:event.currentTarget
    })
  }

  handleMenuClose=()=>{
    this.setState({
      anchorEl: null
    })
  }

  handleCountryChange=(country:string)=>{
    this.setState({
      country:country,
      anchorEl:null,
    })
  }
  // Customizable Area End
}