// Customizable Area Start
import React from "react";
import { Box, withStyles, createStyles, Modal, Typography } from "@material-ui/core";
import SelectRepostModalController, {
  Props,
} from "./SelectRepostModalController.web";

export class SelectRepostModal extends SelectRepostModalController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes, open, onClose, onRepost, onQuote, isUndoRepost, onUndoRepost } = this.props;

    return (
      <Modal
        open={open}
        className={classes.container}
        onClose={onClose}
      >
        <Box className={classes.modalWrapper}>
          <Box className={classes.itemWrapper} onClick={isUndoRepost ? onUndoRepost : onRepost}>
            <Typography className={classes.itemTitle}>{isUndoRepost ? 'Undo Reposted' : 'Repost'}</Typography>
            <Typography className={classes.itemDesc}>Share this post with your follower</Typography>
          </Box>
          <Box className={classes.itemWrapper} onClick={onQuote}>
            <Typography className={classes.itemTitle}>Quote</Typography>
            <Typography className={classes.itemDesc}>Add a comment, photo or GIF before you share this  post.</Typography>
          </Box>
        </Box>
      </Modal>
    )
  }
}

const styles = createStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  modalWrapper: {
    width: '420px',
    backgroundColor: '#0B0B0B',
    borderRadius: '10px',
    padding: '30px 24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    outline: 'none',
    border: '1px solid #35383F',
  },
  itemWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    padding: '0 20px',
    cursor: 'pointer',
  },
  itemTitle: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: '16px',
    color: '#FFFFFF',
  },
  itemDesc: {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: '12px',
    color: '#6B6B6B',
  },
});

export default withStyles(styles)(SelectRepostModal);
// Customizable Area End
