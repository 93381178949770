Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.patchMethod = "PATCH";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AutomaticRenewals";
exports.labelBodyText = "AutomaticRenewals Body";

exports.btnExampleTitle = "CLICK ME";

exports.cancelText = "Cancel";
exports.saveText = "Save";
exports.updateText = "Update";
exports.automaticRenewalList = "Automatic Renewal Lists";
exports.createAutomaticRenewal = "Create Automatic Renewal";
exports.updateAutomaticRenewal = "Update Automatic Renewal";
exports.subscriptionType = "Subscription Type";
exports.autoRenewal = "Auto Renewal";
exports.typePlaceholder = "Enter subscription type";

exports.automaticRenewalEndpoint = "automatic_renewals/automatic_renewals";
exports.automaticRenewalsListApiMethod = "GET";
exports.automaticRenewalsApiContentType = "application/json";
exports.automaticRenewalsCreateApiMethod = "POST";
exports.successMessage = "Automatic Renewals turned on." ;
exports.updateMessage = "Automatic Renewals updated." ;
exports.automaticRenewalsUpdateApiMethod = "PUT";
exports.idText = "ID";
exports.actionText = "Action";
exports.editText = "Edit";
exports.addNewText = "Add New";
// Customizable Area End