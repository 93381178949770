import React from "react";

// Customizable Area Start
import { Grid, Typography, Dialog, styled, Box } from "@material-ui/core";
import {WhatsappShareButton, TwitterShareButton, FacebookShareButton, EmailShareButton} from "react-share"
import {  copy, copyDark, facebook, instagram, mail, share, shareDark, twitter, whatsapp } from "./assets";
// Customizable Area End

import ShareController, { Props, configJSON } from "./ShareController.web";

export default class Share extends ShareController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  conditionRemover =(condition:boolean,trueStatement:string,falseStatement:string)=>{
    return condition? trueStatement: falseStatement
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <DialogBox 
        open={this.props.open}
        data-testid="dialogBox"
        onClose={this.props.onClose}
        PaperProps={{
          className: this.conditionRemover(!this.props.checked, "modelDarkStyle", "modelLightStyle")
        }}
        >
         <GridContainer container alignItems="center" direction="column" >
          <Box className={`${this.conditionRemover(this.props.checked,'shareContainerLightBorder','shareContainerDarkBorder')} headContainer`}>
              <Typography data-testid="headingText" className={"name"}>{configJSON.share}</Typography>
          </Box>
         <Box className={` shareContainer`}>
          <Box className={`${this.conditionRemover(this.props.checked,'shareContainerLightBorder','shareContainerDarkBorder')} shareOptions`}>
             <Box className="shareOptionContainer" data-testid="copy" onClick={this.handleClick}>
                <Box className={`${this.conditionRemover(this.props.checked,'boxContainerlight','boxContainerDark')} boxContainer`}>
                  <img src={this.conditionRemover(this.props.checked, copyDark,copy)} alt="copy"/>
                </Box>
                <Box><Typography data-testid="copyText" className="linkText">{configJSON.copy}</Typography></Box>
             </Box>
             <Box className="shareOptionContainer" data-testid="shareVia" onClick={this.handleShare}>
                <Box  className={`${this.conditionRemover(this.props.checked,'boxContainerlight','boxContainerDark')} boxContainer`}>
                <img src={this.conditionRemover(this.props.checked, shareDark,share)} alt="share"/>
                </Box>
                <Box><Typography className="linkText">{configJSON.shareVia}</Typography></Box>
             </Box>
          </Box>
          </Box>
          <Box className="shareContainer">
            <Box className={`${this.conditionRemover(this.props.checked,'shareContainerLightBorder','shareContainerDarkBorder')} socialMediaOptions`}>
            <WhatsappShareButton url={this.props.Url ?? ''}>
              <Box className="shareSocialMedia">
              <Box><img src={whatsapp} alt="" /> </Box>
              <Box><Typography className="linkText">{configJSON.whatsapp}</Typography></Box>
              </Box>
              </WhatsappShareButton>

             <TwitterShareButton url={this.props.Url??''}>
              <Box className="shareSocialMedia">
               <Box><img src={twitter} alt="" /> </Box>
               <Box><Typography className="linkText">{configJSON.x}</Typography></Box>
              </Box>
              </TwitterShareButton>
  
              <FacebookShareButton url={this.props.Url??''}>
              <Box className="shareSocialMedia">
               <Box><img src={facebook} alt="" /> </Box>
               <Box><Typography className="linkText">{configJSON.facebook}</Typography></Box>
              </Box>
              </FacebookShareButton>

              <Box className="shareSocialMedia">
               <Box><img src={instagram} alt="" /> </Box>
               <Box><Typography className="linkText">{configJSON.instagram}</Typography></Box>
              </Box>
            </Box>  

          </Box>
         
          <Box className="shareContainer">
            <Box className={`${this.conditionRemover(this.props.checked,'shareContainerLightBorder','shareContainerDarkBorder')} socialMediaOptions`}>
              <EmailShareButton url={this.props.Url??''}>
              <Box className="shareSocialMedia">
               <Box><img src={mail} alt="" /> </Box>
               <Box><Typography className="linkText">{configJSON.email}</Typography></Box>
              </Box>
              </EmailShareButton >
            </Box>  
          </Box>
        </GridContainer>
      </DialogBox>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const DialogBox = styled(Dialog)({
  "& .modelDarkStyle": {
     borderRadius: "10px",
     backgroundClip: "padding-box, border-box",
     backgroundOrigin: "padding-box, border-box",
     border: "1px solid transparent",
     backdropFilter: "blur(1 px)",
     background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)",
     boxShadow: "none"
   },
   "& .modelLightStyle": {
     borderRadius: "10px",
     boxShadow: "none",
     border: "1px solid transparent",
     background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #35383F ,#35383F)",
     backgroundClip: "padding-box, border-box",
     backgroundOrigin: "padding-box, border-box",
     backdropFilter: "blur(1 px)",
   },
})

const GridContainer = styled(Grid)({
  padding: "10px 16px", 
  rowGap: "17px", 
  width: "375px",
  height: "474px",
   
  "& .headContainer":{
    height:81,
    width:'100%',
    display:'flex',
    alignItems:'center',
    marginTop:-10,
    justifyContent:'center',
    borderBottom:'1px solid #222222',
  },

  "& .name": {
    fontSize: "18px",
    fontFamily: "Manrope",
    fontWeight: 800,
    lineHeight: "21.6px",
    whiteSpace: "nowrap" as "nowrap",
    cursor: 'pointer',
  },

  "& .shareOptions":{
    height:'102px',
    alignItems:'center',
    display:'flex',
    padding:'0px 12px',
    columnGap:'24px'
  },

  "& .socialMediaOptions":{
    height:'102px',
    alignItems:'center',
    display:'flex',
    cursor:'pointer',
    padding:'0px 12px',
    columnGap:'26px'
  },

  "& .shareContainer":{
   width:'100%',
  },

  "& .shareSocialMedia":{
    display:'flex',
    cursor:'pointer',
    flexDirection:'column',
    rowGap:'8px'
  },
  
  "& .shareOptionContainer":{
     display:'flex',
     flexDirection:'column',
     rowGap:'8px'
  },
   
  "& .boxContainerlight":{
    background:'#E9E9E9',
  },
  "& .boxContainerDark":{
    background:'#181818',
  },

  "& .shareContainerLightBorder":{
    borderBottom:'1px solid #BABABA'
  },

  "& .shareContainerDarkBorder":{
    borderBottom:'1px solid #222222',
  },

  "& .boxContainer":{
      height:60,
      width:60,
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      cursor:'pointer',
      borderRadius:'10px'
  },

  "& .linkText":{
      fontFamily: 'Manrope',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '14.4px',
      textAlign: 'center',
  }

})
// Customizable Area End
