// Customizable Area Start
import React from "react";
import { Box, withStyles, createStyles } from "@material-ui/core";
import PostFooterController, {
  Props,
} from "./PostFooterController.web";
import { commentIcon, favActive, favIcon, reportedIcon, reverseIcon, saveActive, saveIcon, shareIcon } from "./assests";

export class PostFooter extends PostFooterController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      classes,
      checked,
      onLikePost,
      onSavePost,
      showPostActivity,
      isComments,
      onClickComments,
      onClickRepost,
      postData,
    } = this.props;
    const { 
      is_repost, parent_post, is_reposted, id, total_comments,
      is_liked, model_name, total_likes, post_by, is_saved
    } = postData.attributes
    const postId = id.toString()
    const totalReposts = is_repost && parent_post ? parent_post.total_reposts : postData.attributes.total_reposts

    return (
      <Box 
        className={classes.postFooter}
        style={ isComments ? {borderBottom: "0.5px solid #222222", borderTop: "0.5px solid #222222", paddingTop: '15px'} : { borderBottom: checked ? "1px solid #c9c4c4" : "1px solid #3d3939" }}
      >
        <span 
          data-test-id="showCommentsBtn"
          className={classes.actionBtn}
          onClick={() => onClickComments(postId)}
        >
          <img src={commentIcon} alt="" />
          {total_comments}
        </span>
        <span 
          data-test-id="repostBtn"
          className={classes.actionBtn} 
          onClick={() => onClickRepost(postId)}
          style={{color: is_reposted ? '#34D399' : "#6B6B6B"}}
        >
          <img src={is_reposted ? reportedIcon : reverseIcon} alt="" /> {totalReposts}
        </span>
        <span className={classes.actionBtn}
          data-test-id="likeBtn"
          onClick={() => onLikePost(postId, is_liked ? "unlike" : "like", model_name)}
          style={{ color: is_liked ? "red" : "" }}>
          <img src={is_liked ? favActive : favIcon} alt="" />{total_likes}
        </span>
        <span className={classes.actionBtn}
        data-test-id="showActivityId"
         onClick={()=>showPostActivity&& showPostActivity(postId, post_by.id)}><img src={shareIcon} alt="" />0</span>
        <span className={classes.actionBtn}
          data-test-id="saveBtn"
          onClick={() => onSavePost(postId, is_saved ? "unsave" : "save")}
        >
          <img src={is_saved ? saveActive : saveIcon} alt="" />
        </span>
      </Box>
    )
  }
}

const styles = createStyles({
  postFooter: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "15px",
  },
  actionBtn: {
    display: "flex",
    alignItems: "center" as "center",
    gap: "5px",
    fontSize: "12px",
    fontFamily: "Manrope",
    fontWeight: 500,
    cursor: "pointer",
    color: "#6B6B6B"
  },
});

export default withStyles(styles)(PostFooter);
// Customizable Area End