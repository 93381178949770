// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getMessaging, getToken } from "firebase/messaging"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE,
  authDomain: "react-crud-9d84c.firebaseapp.com",
  databaseURL: "https://react-crud-9d84c-default-rtdb.firebaseio.com",
  projectId: "react-crud-9d84c",
  storageBucket: "react-crud-9d84c.firebasestorage.app",
  messagingSenderId: "431705356767",
  appId: "1:431705356767:web:5bc058ebb19adf95ae2c6a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app) 

export const generateToken=async()=>{
 const permission= await Notification.requestPermission()
  if(permission==="granted"){
  const token=await getToken(messaging,{
    vapidKey:process.env.REACTR_FCM_VAPID_KEY
  })
  console.log(token , "clientToken")
  return token
}
}
