import { Box, Grid, styled, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { fetchMediaMessages, MediaData } from "../CometChat";

interface IPropsMediaContainer {
    checked?: boolean;
    guid: string;
}

function MediaContainer({ checked, guid }: IPropsMediaContainer) {
    const [mediaData, setMediaData] = useState<MediaData>({});

    useEffect(() => {

        const fetchMessages = async () => {
            const { media } = await fetchMediaMessages(guid, true, 50);
            setMediaData(media)
        };
        if (guid) {
            fetchMessages()
        }
    }, [])


    const renderMediaSection = (title: string, mediaItems: { imageUrl: string, messageId: string }[]) => {
        let currentDate = new Date();
        let currentMonthName = currentDate.toLocaleString("default", { month: "long" });
        const monthTitle = currentMonthName.toLowerCase() === title.toLowerCase() ? "Recent" : title;
        return (
            <Box key={title} mb={2} style={{ display: "flex", flexDirection: "column", gap: 15 }}>
                <span className="mediaTitle" style={{
                    color: checked ? "#222222" : "#FFFFFF"
                }}>{monthTitle}</span>
                {
                    mediaItems?.length > 0 && (
                        <Grid container spacing={1}>
                            {mediaItems.map((item, index) => (
                                <Grid item xs={3} key={index} className="gridItem">
                                    <img src={item.imageUrl} alt={`media-${index}`} style={{ width: "100%", height: "100%" }} />
                                </Grid>
                            ))}
                        </Grid>
                    )
                }
            </Box>
        )
    };

    return <MediaContainerStyled className="mediaContainerStyled">
        {Object.keys(mediaData).length > 0
            ? Object.keys(mediaData).reverse().map((month) => renderMediaSection(month, mediaData[month] || []))
            : <Typography className="mediaTitle" style={{
                color: checked ? "#222222" : "#FFFFFF"
            }}>
                No media found
            </Typography>
        }
    </MediaContainerStyled>
}


const MediaContainerStyled = styled(Box)({
    overflow: "scroll",
    scrollbarWidth: "none",
    height: "100%",
    boxSizing: "border-box",
    border: "1px solid #35383F",
    padding: 20,
    borderRadius: "10px",
    "& .mediaTitle": {
        fontSize: '16px',
        fontFamily: 'Manrope',
        fontWeight: 600,
        lineHeight: '19.2px',
        textTransform: "capitalize",
    },
    "& .gridItem": {
        position: "relative",
    }
});

export default MediaContainer