import { Box, createTheme, styled, ThemeProvider, Typography } from '@material-ui/core';
import React from 'react';
import { LIST_REACTION } from './CometChatBubbleViewListReaction';
import clsx from 'clsx';

interface OptionItem {
    label: string;
    className?: string;
}

const baseOptions: OptionItem[] = [
    { label: 'Reply' },
    { label: 'Copy' },
    { label: 'Forward' },
];

interface MessageOptionItemProps {
    isMessageSentByCurrentUser: boolean;
    onOptionClick?: (optionLabel: string) => void;
    messageId: number;
    onAddReactionToMessage: (messageId: number, emoji: string) => Promise<void>;
    checked: boolean;
}

const ReactionListComponent = ({ messageId, onAddReactionToMessage, checked }: { messageId: number, onAddReactionToMessage: (messageId: number, emoji: string) => Promise<void>, checked: boolean }) => {
    return (
        <ThemeProvider theme={checked ? commonLightTheme : commonDarkTheme}>
            <ReactionListComponentStyled style={{ background: !checked ? "#222222" : "#FFFFFF" }}>
                {
                    LIST_REACTION.map(reaction => {
                        return <Box key={reaction.value} className={`reactionItem`} onClick={() => { onAddReactionToMessage(messageId, reaction.value) }}>
                            <img alt="r" src={reaction.icon} width={24} height={24} />
                        </Box>
                    })
                }
            </ReactionListComponentStyled>
        </ThemeProvider>
    )

}


const MessageOptionItem: React.FC<MessageOptionItemProps> = ({ isMessageSentByCurrentUser, onOptionClick, messageId, onAddReactionToMessage, checked }) => {
    const options = isMessageSentByCurrentUser
        ? [...baseOptions, { label: 'Delete', className: 'deleteBtn' }]
        : baseOptions;

    return (
        <ThemeProvider theme={checked ? commonLightTheme : commonDarkTheme}>
            <MenuOptionsStyled>
                {ReactionListComponent({ messageId, onAddReactionToMessage, checked })}
                <Box className="listOption" style={{ background: !checked ? "#222222" : "#FFFFFF" }}>
                    {options.map((option) => (
                        <Box
                            key={option.label}
                            className={`itemOption ${option.className || ''}`}
                            onClick={() => onOptionClick && onOptionClick(option.label)}
                        >
                            <Typography component="p" className={clsx(checked && "lightThemeTextColor")}>{option.label}</Typography>
                        </Box>
                    ))}
                </Box>
            </MenuOptionsStyled>
        </ThemeProvider>
    );
};

export default MessageOptionItem;


const MenuOptionsStyled = styled(Box)(() => ({
    borderRadius: 5,
    width: 278,
    "& .lightThemeBackground": {
        background: "#FFFFFF!important",
    },
    "& .lightThemeTextColor": {
        color: "#222222!important"
    },
    "& .listOption": {
        display: "flex",
        flexDirection: "column",
        padding: "10px 0",
        borderRadius: 5,
        "& .itemOption": {
            width: 150,
            height: 36,
            padding: "9px 20px 9px 16px",
            boxSizing: "border-box",
            cursor: "pointer",
            "& p": {
                fontFamily: "Manrope",
                fontWeight: 500,
                fontSize: 14,
            },
        },
        "& .itemOption.deleteBtn p": {
            color: "#DC2626!important"
        }
    }
}));

const ReactionListComponentStyled = styled(Box)({
    display: "flex",
    borderBottom: "1px solid #4E4E4E",
    justifyContent: "space-around",
    gap: 10,
    padding: "15px 10px",
    cursor: "pointer",
    "& .reactionItem": {
        display: "flex",
        alignItems: "center",
        gap: 4,
    }
});

const commonLightTheme = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#3858E3",
        },
        secondary: {
            main: 'rgba(0,0,0,0.75)'
        },
        info: {
            main: "#222222",
        },
        warning: {
            main: "#78716C",
        },
    },
});

const commonDarkTheme = createTheme({
    palette: {
        type: "dark",
        primary: {
            main: "#3858E3",
        },
        secondary: {
            main: "rgba(255,255,255,0.75)"
        },
        info: {
            main: "#FFFFFF",
        },
        warning: {
            main: "#78716C",
        },
    },
});
