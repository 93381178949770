import React, { useEffect, useState } from "react";
import { Box, Button, createTheme, IconButton, styled, TextField, ThemeProvider, Typography } from "@material-ui/core";
import clsx from "clsx";
import { BaseMessage } from "@cometchat/chat-sdk-javascript";
import { getMessageBySearchKeyword, IConversation, TYPE_CONVERSATION } from "./CometChat";
import ImageRenderer from "./ImageRenderer.web";
const leftArrowIcon = require("./assets/leftArrowIcon.svg")
const downArrowIcon = require("./assets/downArrowIcon.svg")
const upArrowIcon = require("./assets/upArrowIcon.svg")

interface IPropViewChatSearch {
    conversation: CometChat.User | CometChat.Group;
    searchValue: {
        message: BaseMessage | null;
        searchKeyword: string
    };
    onSetValueSearchChat: (value: {
        message: BaseMessage | null;
        searchKeyword: string;
    }) => void;
    checked: boolean;

}

export default function ViewChatSearch({
    checked,
    searchValue,
    conversation,
    onSetValueSearchChat,
}: IPropViewChatSearch) {
    const {
        uid = "",
        guid = "",
    } = conversation as unknown as IConversation;
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [listMessageSearch, setListMessageSearch] = useState<BaseMessage[]>([]);
    const [currentMatchesMessageIndex, setCurrentMatchesMessageIndex] = useState<null | number>(null);

    useEffect(() => {
        if (!searchValue.message) {
            setValue("");
            setListMessageSearch([]);
            setCurrentMatchesMessageIndex(null);
        }
    }, [searchValue])

    useEffect(() => {
        if (isSearching && listMessageSearch.length) setCurrentMatchesMessageIndex(0);
        setIsLoading(false);
    }, [listMessageSearch])

    useEffect(() => {
        if (isSearching && typeof currentMatchesMessageIndex === "number") {
            onSetValueSearchChat({
                message: listMessageSearch[currentMatchesMessageIndex],
                searchKeyword: value
            });
        }
    }, [currentMatchesMessageIndex])

    const conditionRemover = (condition: boolean, trueStatement: any, falseStatement: any) => {
        return condition ? trueStatement : falseStatement
    }

    const handleChangeValueSearch = (e: any) => {
        const valueSearch = e.target.value;
        setValue(valueSearch)
    }

    const handleDoneBtn = async () => {
        try {
            const valueSearch = value.trim();
            if (!valueSearch) return;
            setIsSearching(true);
            setIsLoading(true);
            const listMessages = await getMessageBySearchKeyword(value, uid || guid, guid ? TYPE_CONVERSATION.GROUP : TYPE_CONVERSATION.USER);
            setListMessageSearch(listMessages.reverse());
        } catch (err) {
            console.error("Handle click done button failed with error ", err)
        }
    }

    const handleCancelSearching = () => {
        setIsSearching(false);
        onSetValueSearchChat({
            message: null,
            searchKeyword: ""
        });
    }

    const goToNextMatch = () => {
        if (currentMatchesMessageIndex === (listMessageSearch.length - 1)) return;
        if (currentMatchesMessageIndex !== null)
            setCurrentMatchesMessageIndex(currentMatchesMessageIndex + 1);
    }

    const goToPreviousMatch = () => {
        if (!currentMatchesMessageIndex) return;
        if (currentMatchesMessageIndex !== null)
            setCurrentMatchesMessageIndex(currentMatchesMessageIndex - 1);
    }

    const renderNumberMatches = () => {
        if (listMessageSearch.length === 0) return "No matches found";
        return `${(currentMatchesMessageIndex || 0) + 1} of ${listMessageSearch.length} matches`;
    };

    return (
        <ThemeProvider theme={checked ? commonLightTheme : commonDarkTheme}>
            <ViewChatSearchStyled issearching={isSearching.toString()}>
                <TextField
                    variant="outlined"
                    placeholder="Search"
                    fullWidth
                    value={value}
                    onChange={handleChangeValueSearch}
                    disabled={isSearching}
                    InputProps={{
                        style: { borderRadius: "10px", },
                        startAdornment: isSearching ? (
                            <IconButton style={{ width: "22px", height: "22px", marginRight: 4 }} onClick={handleCancelSearching}>
                                <ImageRenderer src={leftArrowIcon} width={20} height={20} />
                            </IconButton>
                        ) : (
                            ""
                        ),
                        endAdornment: (
                            <Box style={{ color: conditionRemover(checked, "#222222", "#FFFFFF") }}>
                                {isSearching ? (
                                    <Typography className="matchesText" component="p">
                                        {renderNumberMatches()}
                                    </Typography>
                                ) : (
                                    <Button className="doneBtn" onClick={handleDoneBtn} disabled={isLoading}>
                                        Done
                                    </Button>
                                )}
                            </Box>
                        ),
                    }}
                />
                <Box className={`navigationMessageContainer ${clsx(!isSearching && "displayNone")}`}>
                    <IconButton onClick={goToNextMatch}>
                        <ImageRenderer
                            src={upArrowIcon}
                            width={20}
                            height={20}
                            style={{
                                color:
                                    (!listMessageSearch.length || currentMatchesMessageIndex === listMessageSearch.length - 1)
                                        ? "#6B6B6B"
                                        : "#F5F5F4",
                            }}
                        />
                    </IconButton>
                    <IconButton onClick={goToPreviousMatch}>
                        <ImageRenderer
                            src={downArrowIcon}
                            width={20}
                            height={20}
                            style={{
                                color:
                                    (!listMessageSearch.length || currentMatchesMessageIndex === 0)
                                        ? "#6B6B6B"
                                        : "#F5F5F4"
                            }}
                        />
                    </IconButton>
                </Box>
            </ViewChatSearchStyled>
        </ThemeProvider>
    )
}

const ViewChatSearchStyled = styled(Box)(({ issearching, checked }: { issearching: string, checked?: boolean }) => ({
    display: "flex",
    padding: "20px 14px",
    alignItems: "center",
    gap: "8px",
    paddingRight: issearching === "true" ? "8px" : "20px",
    background: issearching === "true" ? "#96969633" : "transparent",
    "& .displayNone": {
        display: "none"
    },
    "& .navigationMessageContainer": {
        display: "flex",
        "& button": {
            height: "30px",
            padding: "6px"
        }
    },
    "& .matchesText": {
        width: "120px",
        fontFamily: "Inter",
        fontWeight: 500,
        fontSize: "12px",
        textAlign: "right"
    },
    "& .doneBtn": {
        width: "50px",
        height: "24px",
        background: "linear-gradient(99.09deg, #6EE7B7 2.64%, #059669 100%)",
        fontFamily: "Manrope",
        fontWeight: 700,
        fontSize: "12px",
        textTransform: "capitalize"
    },
    "& .MuiInputBase-input.MuiOutlinedInput-input": {
        fontFamily: "Manrope",
        fontWeight: 500,
        fontSize: "14px",
        color: checked ? "222222" : "#E9E9E9"
    }
}));

const commonLightTheme = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#3858E3",
        },
        secondary: {
            main: 'rgba(0,0,0,0.75)'
        },
        info: {
            main: "#222222",
        },
        warning: {
            main: "#78716C",
        },
    },
});

const commonDarkTheme = createTheme({
    palette: {
        type: "dark",
        primary: {
            main: "#3858E3",
        },
        secondary: {
            main: "rgba(255,255,255,0.75)"
        },
        info: {
            main: "#FFFFFF",
        },
        warning: {
            main: "#78716C",
        },
    },
});

