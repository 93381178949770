// Customizable Area Start
import React from "react";

import GroupsPermissionController, {
    Props,
} from "./GroupsPermissionController";
import { Box, styled, Typography } from "@material-ui/core";
import { IOSSwitch } from "../../../components/src/ModifiedComponents.web";

export class GroupsPermission extends GroupsPermissionController {
    constructor(props: Props) {
        super(props);
    }

    handleNavigateLink = (navigateLink?: string) => {
        switch (navigateLink) {
            case "sendMessage": {
                if (this.props.isCanSendMessage) {
                    this.props.onChangeGroupScreen("send_messages");
                }
                break;
            }
            case "learnMore": {
                break;
            }
        }
    };

    handleRenderComponent = () => {
        return (
            <GroupsPermissionStyled className="groupsPermissionStyled">
                <Box className="settingPermissionGroupContainer">
                    {this.getListSettings().map(
                        ({ role, settings, keyValue: roleKeyValue = "" }) => (
                            <Box key={role} className="settingPermissionGroup">
                                <Box className="settingPermissionGroupLabel">
                                    <Typography
                                        className={`settingPermissionLabel ${this.props.checked && "lightTheme__text"
                                            }`}
                                        component="p"
                                    >
                                        {role.charAt(0).toUpperCase() + role.slice(1)} can
                                    </Typography>
                                </Box>
                                {settings.map(
                                    ({
                                        icon,
                                        title,
                                        subDescription,
                                        keyValue: settingKeyValue,
                                        navigateText,
                                        navigateLink,
                                    }) => (
                                        <Box className="settingPermissionGroupContent" key={title}>
                                            <Box className="settingPermissionIcon">
                                                <img
                                                    alt="icon"
                                                    className="iconSetting"
                                                    width={24}
                                                    height={24}
                                                    src={icon}
                                                />
                                            </Box>
                                            <Box className="settingPermissionGroup-title">
                                                <Typography
                                                    className={`settingPermission-title ${this.props.checked && "lightTheme__text"
                                                        }`}
                                                    component="h5"
                                                >
                                                    {title}
                                                </Typography>
                                                {subDescription && (
                                                    <Typography
                                                        className={`settingPermission-subText ${this.props.checked && "lightTheme__text"
                                                            }`}
                                                        component="p"
                                                    >
                                                        {subDescription}
                                                    </Typography>
                                                )}
                                                {navigateText && (
                                                    <Typography
                                                        className={`settingPermission-subText settingPermission-navigateText ${navigateLink && "navigateText-underline"
                                                            }`}
                                                        data-test-id="btnNavigateLink"
                                                        component="p"
                                                        onClick={() => this.handleNavigateLink(navigateLink)}
                                                    >
                                                        {navigateText}
                                                    </Typography>
                                                )}
                                            </Box>
                                            <IOSSwitch
                                                data-test-id="switchPermissionSetting"
                                                onChange={this.handleSwitchChange(
                                                    roleKeyValue,
                                                    settingKeyValue
                                                )}
                                                checked={
                                                    roleKeyValue
                                                        ? (
                                                            this.state.settingsState[roleKeyValue] as {
                                                                [key: string]: boolean;
                                                            }
                                                        )[settingKeyValue]
                                                        : this.state.settingsState[settingKeyValue]
                                                }
                                            />
                                        </Box>
                                    )
                                )}
                            </Box>
                        )
                    )}
                </Box>
            </GroupsPermissionStyled>
        )
    }

    render() {
        return this.handleRenderComponent();
    }
}

const GroupsPermissionStyled = styled(Box)({
    "& .lightTheme__text": {
        color: "#222222!important",
    },
    "& .settingPermissionGroupContainer": {
        display: "flex",
        flexDirection: "column",
        rowGap: 30,
    },
    "& .settingPermissionGroup": {
        display: "flex",
        flexDirection: "column",
        rowGap: 20,
        "& .settingPermission-subText": {
            fontFamily: "Manrope",
            fontWeight: 500,
            fontSize: 12,
            color: "#6B6B6B",
        },
        "& .settingPermission-subText.settingPermission-navigateText": {
            backgroundImage:
                "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
            color: "transparent",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            cursor: "pointer",
            display: "inline-block",
            position: "relative",
        },
        "& .settingPermission-subText.settingPermission-navigateText.navigateText-underline::after":
        {
            content: '""',
            display: "block",
            width: "100%",
            height: "1px",
            background:
                "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
            position: "absolute",
            bottom: "2px",
            left: 0,
        },
        "& .settingPermissionGroupLabel": {
            "& .settingPermissionLabel": {
                color: "#FFFFFF",
                fontFamily: "Manrope",
                fontWeight: 600,
                fontSize: 16,
            },
        },
        "& .settingPermissionGroupContent": {
            display: "flex",
            columnGap: 4,
            alignItems: "center",
            "& .settingPermissionIcon": {
                alignSelf: "start",
            },
            "& .settingPermissionGroup-title": {
                flex: 1,
                "& .settingPermission-title": {
                    color: "#FFFFFF",
                    fontFamily: "Manrope",
                    fontWeight: 600,
                    fontSize: "16px",
                },
            },
        },
    },
});

export default GroupsPermission;

// Customizable Area End
