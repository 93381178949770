export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const lightBackground=require("../assets/lightBackground.png");
export const darkBackground=require("../assets/darkBackground.png");
export const backArrowWhite=require("../assets/backArrow-white.png");
export const backArrowDark=require("../assets/backArrow-dark.png");
export const editIcon=require("../assets/edit_icon.png");
export const deleteIcon=require("../assets/delete_icon.png");
export const price = require("../assets/price.png")
export const period = require("../assets/period.png")

