import React from "react";
// Customizable Area Start
import {Typography,Paper,Box,Grid,Button,Card,CardContent,styled} from "@material-ui/core";
import Loader from '../../../components/src/Loader.web'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { checkedImage, } from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import {
    Props
} from './PaymentWeb.web'
import {
    backArrowDark,
    backArrowWhite,
} from "./assets";
import PaymentComponent from "../../../components/src/PaymentComponent.web";

const lightTheme = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#3858E3",
        },
        secondary: {
            main: 'rgba(0,0,0,0.75)'
        }
    }
});

const darkTheme = createTheme({
    palette: {
        type: "dark",
        primary: {
            main: "#3858E3",
        },
        secondary: {
            main: "rgba(255,255,255,0.75)"
        }
    }
})


interface MyProps extends Props {
    classes: any
    isLoading: boolean;
    navigation:any;
    getActiveSub:() =>void
    backToSubscribePage:() => void;
    orderSummary:any
    isAutoRenewal: boolean
    addCard:() => void
    
}
export const OrderSummary = (props:MyProps) =>{
    const activeSub = props.getActiveSub() as any
    return (
        <ThemeProvider theme={props.checked ? lightTheme : darkTheme}>

            <StyleContainer>
                <Paper className={props.checked ? "paperLightTheme" : "paperDarkTheme"}>
                    <Loader loading={props.isLoading} />
                    <Grid container >
                        <Grid item className="navigationSection">
                            <NavigationMenu navigation={props.navigation} id={""} checked={props.checked} />
                        </Grid>
                        <Grid item style={{ width: '337px' }}>
                            <div className="main-category">
                                <Button onClick={() => props.backToSubscribePage()} className="back-button-settings" variant="text">

                                    <span style={{marginLeft:'-6rem'}}><img src={props.checked ? backArrowDark : backArrowWhite} />
                                    </span><span>Order Summary</span>
                                    </Button>

                                <Box className="orderHeadingContainer">
                                    <Typography className="plan" style={{ fontSize: '18px' }}>Plan and Pricing</Typography>
                                    <Button className="edit-btn" onClick={props.backToSubscribePage}>Edit</Button>
                                </Box>
                                <Box className={"cardsContainer"}>
                                    <Card variant="outlined" key={"subscription.id"} className={"card"} style={{ width: '335px' }}>
                                        <CardContent >
                                            <Box className="orderHeadingContainer">
                                                <Typography className="businessHeading">{activeSub?.attributes.title}</Typography>
                                                <Button className="activeBtn">Active</Button>
                                            </Box>
                                            <Typography className="plan">
                                            ${activeSub?.attributes.price}/{activeSub?.attributes.period}
                                            </Typography>
                                            <Typography className={"description"}>
                                               {activeSub?.attributes.details}
                                            </Typography>
                                            <Box className="fetaureList">
                                                <Typography>
                                                    <img src={checkedImage} /> Reply Boost</Typography>
                                                <Typography>
                                                    <img src={checkedImage} /> Edit post</Typography>
                                                <Typography>
                                                    <img src={checkedImage} /> Ads in For You</Typography>

                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </div>

                        </Grid>
                        <Grid item style={{
                            marginLeft: '45px', marginTop: '45px', width: '481px', height: "95vh",
                            overflow: "scroll" as "scroll",
                            scrollbarWidth: "none" as "none",
                        }}>
                            <div className="main-category">

                                <PaymentComponent classes={props.classes}
                                    activeId={props.activeId}
                                    checked={false}
                                    handleSelectSubscription={props.handleSelectSubscription}
                                    subscriptionDetails={props.subscriptionDetails}
                                    backToSubscribePage={props.backToSubscribePage}
                                    onPromoCodeChange={props.onPromoCodeChange}
                                    promoCode={props.promoCode}
                                    applyPromoCode={props.applyPromoCode}
                                    promoCodeDetail={props.promoCodeDetail}
                                    subscribeUserAPI={props.subscribeUserAPI}
                                    orderSummary={props.orderSummary}
                                    isAutoRenewal={props.isAutoRenewal}
                                    autoRenewal={props.autoRenewal}
                                    addCard={props.addCard}
                                    cardList={props.cardList}
                                    activeSub={activeSub}
                                    handleSelectCard={props.handleSelectCard}
                                    selectedCard={props.selectedCard}
                                    apiError={props.apiError}
                                    cancelPromoCode={props.cancelPromoCode}
                                />

                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </StyleContainer>
        </ThemeProvider>
    )
}
const StyleContainer = styled(Box)({
    "& .main-category": {
        marginTop: "20px",
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: "Manrope",
        height: "95vh",
        overflow: "scroll" as "scroll",
        scrollbarWidth: "none" as "none",
        "&::-webkit-scrollbar": {
            display: "none" as "none",
        },
        "@media (max-width:1000px)": {
            display: "none" as "none"
        },
        marginBottom: "20px"

    },
    "& .back-button-settings": {
        height: "48px",
        width: "235px",
        fontFamily: "Manrope",
        fontSize: "18px",
        fontWeight: "800",
        paddingRight: "0",
        textTransform:'none',
        gap:'16px',
        display:'flex'
    },
    "& .orderHeadingContainer": {
        display: 'flex',
        justifyContent: 'space-between'
    },
    "& .businessHeading": {
        fontFamily: "Manrope",
        fontSize: "18px",
        fontStyle: "normal" as "normal",
        fontWeight: 800,
        color: '#FFFF',
    },
    "& .activeBtn": {
        borderRadius: '75px',
        background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
        border: '1px solid #3858E3',
        color: '#FFFF',
        fontWeight: 700,
        fontSize: "14px",
        padding: '2px 10px 0px 10px',
        textTransform:'none'
    },
    "& .plan": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal" as "normal",
        fontWeight: 500,
        color: '#FFFF',
    },
    "& .description": {
        fontFamily: "Manrope",
        fontSize: "10px",
        fontStyle: "normal" as "normal",
        fontWeight: 500,
        color: '#999999',
        marginTop: '8px'
    },
    "& .card": {
        backgroundColor: '#2121210A',
        borderRadius: '10px',
        backdropFilter: 'blur(12px)',
        position: 'relative' as 'relative'
    },
    "& .cardsContainer": {
        overflow: 'auto',
    },
    "& .edit-btn": {
        border: '1px solid #8122D0',
        padding: '4px 8px 4px 8px',
        borderRadius: '10px',
        color: '#FFFF',
        fontSize: '12px',
        textTransform: 'none'
    },
    "& .fetaureList": {
        marginTop: '16px'
    },
    "& .cardHeader":{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
      },
      "& .automaticRenewals":{
        fontFamily: "Manrope",
        fontSize: 16,
        fontWeight: "600",
      
      },
      "& .discountText":{
        fontFamily: "Manrope",
        fontSize: '10px',
        fontWeight: "500",
        color:'#FFFF'
      },
      "& .promoText":{
        fontFamily: "Manrope",
        fontSize: '14px',
        fontWeight: "500",
        color:"#FFFF"

      },
      
})
// Customizable Area End
