import { IBlock } from "framework/src/IBlock";
// Customizable Area Start
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { Archived, IArchivedResp, Stories } from "./CrerateHighlightStoriesController.web";
import { getStorageData } from "framework/src/Utilities";

export interface IUserAttributes{
    full_name:string,
    profile_photo:string|null
}

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation:any
    checked:boolean
    useInfo:IUserAttributes
    open:boolean
    onClose:()=>void
    // Customizable Area End
  }
  
  interface S {
    // Customizable Area Start
     pagination:number;
     totalPages:number;
     stories:Array<Archived>;
     selectedItem:number;
     openHighlight:boolean;
     viewStories:Array<Stories>
    // Customizable Area End
  }
  
  interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
  }
  
export default class ArchiveStoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getArchivedStoriesApiCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
        pagination:1,
        totalPages:0,
        stories:[],
        viewStories:[],
        selectedItem:0,
        openHighlight:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    this.handleArchivedResponse(message)
    // Customizable Area End
  }

  // Customizable Area Start

   componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
      if(this.props.open!==prevProps.open){
        if(this.props.open){
          this.getArchivedStories()
        }
      }
   }

   handleOpenStory=(item:Array<Stories>, selectedItem:number)=>{
    this.setState({
        viewStories:item,
        selectedItem,
        openHighlight:true
    })
  } 

  handleArchivedResponse=(message: Message)=>{
    let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
    let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
      
      if(apiRequestCallId===this.getArchivedStoriesApiCallId){
        if(!responseJson?.errors){
            const response = responseJson as IArchivedResp
            const data= response.data
            this.setState((prev)=>({
            stories:[...prev.stories,...data],
            totalPages:responseJson.pagination_details?.total_pages,
            pagination:prev.pagination+1
            }))
        }
      }
  }


  getDate=(dateString:string)=>{
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'short' });
    const formattedDate = `${day} ${month}`;
    return formattedDate
  }


  handleOnHighlightClose=()=>{
    this.setState({
        openHighlight:false
    })
  }

  getArchivedStories = async()=>{
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };
    const page =  this.state.pagination
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getArchivedStoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getArchiveStories}?page=${page}&per_page=12&sort_order=asc`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleGoBack=()=>{
    this.setState({
        openHighlight:false
    })
    this.props.onClose()
  }
  // Customizable Area End
}