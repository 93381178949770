// Customizable Area Start
import CardController, { Props } from "./CardController";
import React from 'react'
import { Avatar, Box, Grid, IconButton, Paper, styled, Typography } from '@material-ui/core';

import Loader from '../../../components/src/Loader.web'
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { darkThemeBg, lightThemeBg, darkView, view } from "./assets";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCardWeb from "./StripeCard.web";
const configJSON = require("./config");



const lightTheme = createTheme({
    palette: {
        type: "light",
        secondary: {
            main: 'rgba(0,0,0,0.75)'
        },
        primary: {
            main: "#3858E3",
        },
    }
});
const darkTheme = createTheme({
    palette: {
        type: "dark",
        
        secondary: {
            main: "rgba(255,255,255,0.75)"
        },
        primary: {
            main: "#3858E3",
        },
    }
})
const theme = createTheme({
   
    typography: {
        subtitle1: {
            margin: "20px 0px",
        },
        h6: {
            fontWeight: 500,
        },
        
    },
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
});


class AddNewCard extends CardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    renderSubScreen = () => {
        const { classes } = this.props
        const classStyles = classes ? classes : {}
        const stripePromise = loadStripe(configJSON.stripePublishableKey);
        return (
            <Grid container className={classStyles.gridContainer}>
                <Grid item className={classStyles.imageGridItem} md={5} sm={6} lg={6}>
                </Grid>
                <Grid item xs={10} sm={5} md={5} lg={5} className={classStyles.gridItem}>
                    <Box className={classStyles.formBox}>
                        <Avatar className={this.props.checked ? classStyles.avatarLightTheme : classStyles.avatar} data-test-id='backbuttonOtp'>
                            <IconButton style={{ borderRadius: 'unset', height: '100%', width: "100%" }} disableRipple disableFocusRipple disableTouchRipple>
                                <img src={this.props.checked ? darkView : view} onClick={() => this.props.navigation.goBack()} />
                            </IconButton>
                        </Avatar>
                        <Typography data-test-id='heading' className={classStyles.subscriptionHeading}>Add New Card</Typography>
                        <Typography className={classStyles.subscriptionDescription} style={{ marginBottom: '16px' }}>
                            Card Detail
                        </Typography>
                        <StyleContainer>

                            <div className="main-category">
                                <Elements stripe={stripePromise}>
                                    <StripeCardWeb getStripeToken={this.createCard} />
                                </Elements>
                            </div>
                        </StyleContainer>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    render() {
        const classStyles = this.props.classes || {}
        return (

            <ThemeProvider theme={this.props.checked ? lightTheme : darkTheme}>
                <Paper className={this.props.checked ? classStyles.thinColor : classStyles.color}>
                    <Box className={!this.props.checked ? classStyles.imageBackground : classStyles.thinBackground}>
                        <Loader loading={this.state.isLoading} />
                        {this.renderSubScreen()}

                    </Box>
                </Paper>
            </ThemeProvider>
        )

    }
}



const StyleContainer = styled(Box)({

   
    "& .StripeElement": {
        width: "150px",
        fontSize: "16px",
        padding: "20px",
        border: "1px solid #888",
        borderRadius: "10px",
        background: "transparent",
        color: "#fff"
    },
    "& .cardNumber":
    {
        width: "352px !important",

    },

    "& .StripeElement--focus": {
        outline: "none",
        borderColor: " #3858E3"
    },
    "& .subCategory": {
        // marginTop: "20px",
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: "Manrope",

    },
    "& .subCategory .list": {
        paddingTop: "0px",
    },
    "& .backButton:hover": {
        background: "transparent"
    },
    "& .backButton": {
        fontFamily: "Manrope",
        fontSize: "24px",
        fontWeight: "500",
        paddingRight: "0",
        textTransform: "none",
        paddingLeft: "0px"
    },
    "& .pl16": {
        paddingLeft: "16px"
    },
    "& .backButton .MuiButton-label": {
        justifyContent: "left"
    },
    "& .backButton span": {
        marginRight: "30px"
    },
    "& .backButton .icon": {
        height: "48px",
        width: "48px",
        textAlign: "center",
        alignContent: "center",
        marginRight: "0px"
    },
    "& .backButton .icon img": {
        marginTop: "5px"
    },
    "& .subCategory .backButton .MuiButton-label span": {
        marginTop: "12px",
        marginBottom: "auto"
    },
    "& .subCategory .backButton ": {
        paddingTop: "11px"
    },
    "& .justify-content-center": {
        justifyContent: "center"
    },
    "& .subCatIconContainer": {
        marginTop: "6px",
        marginBottom: "auto"
    },
    "& .textAlignInitial": {
        textAlign: "initial"
    },
    "& .textAlignInitial p,& .subcatTitleContainer p": {
        fontSize: "14px",
        margin: "0px",
        fontWeight: "500",
        // textTransform: "none",
        lineHeight: "21px",
        color: "#6B6B6B",
    },
    "& .subcatTitleContainer p": {
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "14.4px",
        color: "#6B6B6B",
        maxWidth: "67%"
    },

    "& .subcatTitleContainer span": {
        fontSize: "16px",
        // margin: "0px",
        fontWeight: "600",
        lineHeight: "19px",
    },
    "& .verificationContainer": {
        gap: "30px"
    },
    "& .verificationContainer p": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        padding: "10px 0px"
    },
    "& .verificationContainer .pasErrorContainer": {
        width: "auto",
        marginBottom: "20px"
    },
    "& .fieldContainer": {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "20px",
    },
    "& .custom-form-group": {
        maxWidth: "400px",
        margin: "auto",
        padding: "30px",
        background: "#1a1a2e",
        borderRadius: "8px",
        color: "#fff",
    },
    "& .fieldContainer-inline": {
        display: "flex",
        flexDirection: "row",
        paddingBottom: "20px",
        justifyContent: 'space-between'
    },
    "& .priceInput": {
        width: '106px'
    },

    "& .fieldContainer .MuiOutlinedInput-root": {
        borderRadius: "10px"
    },
    "& .fieldContainer-inline .MuiOutlinedInput-root": {
        borderRadius: "10px"
    },

    "& .fieldContainer label": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        marginBottom: "8px"
    },
    "& .submitButton": {
        height: "48px",
        padding: "15px 30px 15px 30px",
        gap: "5px",
        borderRadius: "10px",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        textTransform: "none"
    },
    '& .cancelButton': {
        width: "160px",
        height: "48px",
        padding: "15px 30px 15px 30px",
        gap: "5px",
        borderRadius: "10px",
        border: "1px solid",
        borderImage: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) 1",

    },
    "& .deactivateButton": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "700",
        color: "rgba(239, 68, 68, 1)",
        width: "160px",
        height: "48px",
        padding: "15px 30px 15px 30px",
        gap: "5px",
        borderRadius: "10px",
        border: "1px solid rgba(239, 68, 68, 1)"
        // lineHeight: 16.8px;
        // text-align: center;

    },

    "& .submitButton .label": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "16.8px",
        textAlign: "center",
        textTransform: "unset",
        color: "white"
    },
    "& .cancelButton .label": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "16.8px",
        textAlign: "center",
        textTransform: "unset",
        color: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)'
    },
    "& .userDataValue": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        justifyContent: "end"
    },
    "& .userDataValue .iconImage": {
        marginLeft: "10px"
    },
    "& .deactivateContainer p": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        margin: "0px"
    },
    "& .deactivateContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "30px"
    },
    "& .pasErrorContainer": {
        width: "420px",
        height: "36px",
        padding: "12px 16px 12px 16px",
        gap: "8px",
        borderRadius: "10px",
        border: "0px 0px 0px 1.3px",
        background: "linear-gradient(0deg, rgba(33, 33, 33, 0.29), rgba(33, 33, 33, 0.29)), linear-gradient(91.54deg, rgba(255, 35, 35, 0.13) -1.27%, rgba(255, 0, 0, 0) 28.72%)",
        alignContent: "center",
        color: "rgba(239, 68, 68, 1)"
    },
    "& .deactivateModal .logoutContainer p": {
        maxWidth: "319px"
    },
    "& .changepswrdContainer": {
        paddingTop: "24px"
    },
    "& .changePassForm": {
        display: "flex",
        flexDirection: "column",
    },
    "& .changePassForm .submitButton": {
        margin: "auto",
        width: "unset"
    },
    "& .changepswrdContainer .pasErrorContainer": {
        marginBottom: "30px"
    },
    "& .forgotPswd": {
        margin: "auto",
        marginTop: "15px"
    },
    "& .lable-content": {
        marginTop: '10px'
    }

});

const styles = {
    
    gridContainer: {
        alignItems: 'center',
        height: '90vh',
        justifyContent: 'space-between' as 'space-between',
        paddingTop: "3rem",
        [theme.breakpoints.down('xs')]:
            { justifyContent: 'center' as 'center' }
    },
    
    thinBackground: {
        backgroundImage: `url(${lightThemeBg})`,
        height: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        overflow: "auto",
        backgroundPosition: 'center',
        backgroundAttachment: "scroll",
        [theme.breakpoints.down('xs')]:
            { backgroundImage: 'none' }
    },
   
    imageGridItem: {
        flexDirection: "column" as "column",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: { display: "none" },
    },
    avatar: {
        borderRadius: "8px",
        backgroundColor: '#222222',
        cursor: 'pointer'
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'center'
    },
  
    avatarLightTheme: {
        borderRadius: "8px",
        backgroundColor: '#E9E9E9',
        cursor: 'pointer'
    },
    imageBackground: {
        backgroundImage: `url(${darkThemeBg})`,
        height: "100vh",
        width: "100%",
        overflow: "auto",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: 'center',
        backgroundAttachment: "scroll",
        [theme.breakpoints.down('xs')]:
            { backgroundImage: 'none' }
    },
    subscriptionHeading: {
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        fontFamily: "Manrope",
        fontSize: "31px",
        fontStyle: "normal" as "normal",
        fontWeight: 800,
        WebkitTextFillColor: "transparent",
        WebkitBackgroundClip: "text",
        backgroundClip: "text",
        lineHeight: '32px',
        marginTop: '30px',
        letterSpacing: '-0.005em'

    },
    subscriptionDescription: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontStyle: "normal" as "normal",
        fontWeight: 600,
        marginTop: '5px'
    },

}
export const AddNewCards = withStyles(styles as any)(AddNewCard);
// Customizable Area End
