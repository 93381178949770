// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

import { ReactNode } from "react";
import storage from "../../../framework/src/StorageProvider";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:{[key:string]:string}
  children?:ReactNode
  checked:boolean
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  
  dateOfBirth:string;
  checked:boolean;
  disable:boolean,
  showCalendar:boolean
  anchorEl:null | HTMLElement
  calendarDate:string | Date
  errors:{nameError:string,phoneNumberError:string,dobError:string,emailError:string};
  error:string
  openModal:boolean,
  checkboxError:any
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DateOfBirthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  dateOfBirthAPIId:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.dateOfBirthAPIId=""
    this.state = {
      // Customizable Area Start
      dateOfBirth:"",
      checked:false,
      disable:true,
      showCalendar:false,
      anchorEl:null,
      calendarDate:"",
      errors:{nameError:'',phoneNumberError:'',dobError:'',emailError:''},
      error:'',
      openModal:false,
      checkboxError:"",
     
      // Customizable Area End
    };

    // Customizable Area Start
  
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.dateOfBirthAPIId === requestCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson) {
          console.log("in dob",responseJson);
          
          this.navigateToUserName(responseJson)

        } else {
          const errorMessage = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
         
        }
    }
  }



 
    // Customizable Area End
  }

  // Customizable Area Start

  



  goToPrivacyPolicy() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  goToTermsAndCondition() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

 

  handleInputClick = (event:React.MouseEvent<HTMLElement>) => {
  this.setState({
    anchorEl: event.currentTarget
  })
  };


  handleCloseCalendar=()=>{
    this.setState({
      anchorEl:null
    })
  }

  handleDobChange=(val: Value)=>{
    const getFormatDate = this.formatDate(val as Date)
    this.setState({
      dateOfBirth:getFormatDate.dobDate,
      calendarDate:val as Date,
      anchorEl:null
    },()=>{ 
    })
    this.validateDateOfBirth(getFormatDate.validateDate)
    
  }

   formatDate = (date:Date) => {
    const thisDay = String(date.getDate()).padStart(2, '0');
    const thisMonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const thisYear = date.getFullYear();
    return {validateDate:`${thisYear}-${thisMonth}-${thisDay}`, dobDate:`${thisDay}-${thisMonth}-${thisYear}`};
  };

  validateDateOfBirth=(value:string)=>{
    const part = value.split('-');
    const fixedYear = parseInt(part[2], 10);
    const fixedMonth = parseInt(part[1], 10);
    const presentDay = parseInt(part[0], 10);
    const presentDate = new Date();
    const presentYear = presentDate.getFullYear();
    const presentDateOfMonth = presentDate.getDate();
    const presentMonth = presentDate.getMonth() + 1 
    if (value==='dd-mm-yyyy'){
      this.setState((prevState) => ({
        errors: { ...prevState.errors, dobError:configJSON.selectDate}
      }));

    }
    else if (presentDay>=presentDateOfMonth && fixedYear>=presentYear && fixedMonth>=presentMonth){
      this.setState((prevState) => ({
        errors: { ...prevState.errors, dobError:configJSON.invalidDate}
      }))
    }
    else if (presentDay>=presentDateOfMonth && fixedYear>presentYear && fixedMonth<presentMonth){
      this.setState((prevState) => ({
        errors: { ...prevState.errors, dobError:configJSON.invalidDate}
      }))
    }
    else if (fixedMonth>presentMonth && fixedYear>=presentYear && presentDay<presentDateOfMonth){
      this.setState((prevState) => ({
        errors: { ...prevState.errors, dobError:configJSON.invalidDate}
      }))
    }
    else if (fixedYear>presentYear){
      this.setState((prevState) => ({
        errors: { ...prevState.errors, dobError:configJSON.invalidDate}
      }));

    }
    else{
      this.setState((prevState) => ({
        errors: { ...prevState.errors, dobError: '' }
      }));

    }
  }


navigateToTermsAndConditions = () => {
  this.setState({ openModal: true });
};
handleClose = () => {
  this.setState({ openModal: false });
};

handleTermsAndConditions = () => {
  this.setState({
    checked: true,
    openModal: false,
  });
};

handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ checked: event.target.checked }, () => {this.enableButton
  });
  if (event.target.checked === false) {
    this.setState({ checkboxError: configJSON.termsAndConditions });
  } else {
    this.setState({ checkboxError: "" });
  }
};

handleDateOfBirthAPI = async () => {
  const dateOfBirth = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  const header = {
   
    "token": await storage.get("authToken"),
  };
  let formData = new FormData();
  formData.append("date_of_birth", this.state.dateOfBirth)
  formData.append("signup_state", 'user_name')
  this.dateOfBirthAPIId = dateOfBirth.messageId;
  dateOfBirth.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.dateOfBirthApiEndPoint
  );
  dateOfBirth.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  dateOfBirth.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );
  dateOfBirth.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypePatch
  );
  runEngine.sendMessage(dateOfBirth.id, dateOfBirth);
 
}

handleDob=(e:{target:{value:string}})=>{
  const dateFormat=/^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/(\d{4})$/
      if(e.target.value.length===1){
        this.setState((prevState)=>({errors: { ...prevState.errors, dobError:configJSON.dobErrorText}}),()=>{
          this.enableButton()
        })
      }
      else if (e.target.value.length>1 && !dateFormat.test(e.target.value)) {
        this.setState((prevState)=>({errors: { ...prevState.errors, dobError:configJSON.dobErrorText}}),()=>{
          this.enableButton()
        })
      }
      else{
        this.setState((prevState)=>({errors: { ...prevState.errors, dobError:''}}),()=>{
          this.enableButton() 
        })
        this.setState({dateOfBirth:e.target.value},()=>{
        this.validateDateOfBirth(e.target.value.replace(/\//g, '-'))
        this.enableButton()
        })
      }
  
  }

  enableButton = () => {
    if (this.state.errors.dobError === '' && this.state.dateOfBirth !== '' && this.state.checked===true) {
      this.setState({ disable: false })
    }
    else {
      this.setState({ disable: true })
    }
  }

navigateToUserName=(responseJson:any)=>{
  const message: Message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "OTPInputAuth"
  );
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage.addData(getName(MessageEnum.SessionResponseData), {screen:2,response:responseJson});
  message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(message);
}

navigateToProfile=(route:string,profile?:boolean)=>{
  const message: Message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),
    route
  );
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
    profile:profile,
  });
  message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(message);
}

handleGoogleNavigationLogin = (userObject: {
  profilePhoto: string | null,
  followingCount: number,
  followersCount: number, userSubscription: string,
  userOwnership: boolean, categories: boolean,
  subCategories: boolean
}, responseJson: any) => {
  if (Object.values(userObject)[0] === null) {
    this.navigateToUserName(responseJson)

  }
  else if (Object.values(userObject)[5] === false) {
    this.navigateToProfile('Categories')
  }
  else if (Object.values(userObject)[6] === false) {
    this.navigateToProfile('Categories', true)
  }
  else if (Object.values(userObject)[4] === false) {
    this.navigateToProfile('BecomeOwner')
  }
  else if (Object.values(userObject)[3] !== '' && (Object.values(userObject)[2] === 0 && Object.values(userObject)[1] === 0)) {
    this.navigateToProfile('Followers', true)
  }
  else if (Object.values(userObject)[2] !== 0 || Object.values(userObject)[1] !== 0) {
    this.navigateToProfile('LandingPage')
  }
  else {
    this.navigateToProfile('LandingPage')
  }

}

  // Customizable Area End
}
// Customizable Area End

