import { Box, styled } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const loadingItems = [
    {
        numberItem: 1,
        maxWidthContainer: "444px",
        isCurrentUser: false
    },
    {
        numberItem: 3,
        maxWidthContainer: "444px",
        isCurrentUser: false
    },
    {
        numberItem: 1,
        maxWidthContainer: "360px",
        isCurrentUser: true
    },
    {
        numberItem: 3,
        maxWidthContainer: "360px",
        isCurrentUser: true
    },
    {
        numberItem: 1,
        maxWidthContainer: "100%",
        isCurrentUser: false
    },
    {
        numberItem: 3,
        maxWidthContainer: "100%",
        isCurrentUser: false
    },

]

function ViewChatLoading() {
    return <ViewChatLoadingStyled>
        {loadingItems.map((item, index) => (
            <Box key={index} className="itemContainer"
                style={{
                    flexDirection: item.isCurrentUser ? "row-reverse" : "row"
                }}
            >
                <Box
                    key={index}
                    className="itemLoading"
                    style={{
                        background: item.isCurrentUser ? "#222222" : "#6B6B6B",
                        maxWidth: item.maxWidthContainer,
                        borderTopLeftRadius: item.isCurrentUser ? "8px" : "16px",
                        borderBottomRightRadius: item.isCurrentUser ? "8px" : "16px",
                        marginLeft: item.isCurrentUser ? 'auto' : '0', // Align right if current user
                    }}
                >
                    {Array.from({ length: item.numberItem }).map((_, idx) => (
                        <Skeleton key={idx} variant="text" />
                    ))}
                </Box>
            </Box>
        ))}

    </ViewChatLoadingStyled>
}

const ViewChatLoadingStyled = styled(Box)({
    width: "calc(100% - 40px)",
    padding: "0 20px",
    display: "flex",
    flexDirection: "column",
    rowGap: 20,
    "& .itemContainer": {
        display: "flex",
        width: "100%",
        "& .itemLoading": {
            padding: "16px 20px",
            borderRadius: "16px",
            width: "100%"
        }
    }
});

export default ViewChatLoading