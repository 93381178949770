import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton, Divider } from '@material-ui/core';
import { styled } from '@material-ui/core';


export const  starIcon = require('../src/starIcon.svg')
export const  starIcon2 = require('../src/starIcon2.svg')

interface FeedbackModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (feedback: { rating: number; comment: string }) => void;
    checked: boolean;

}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ open, onClose, onSubmit , checked}) => {
  const [rating, setRating] = useState<number>(0);
  const [comment, setComment] = useState<string>('');

  const handleRating = (newRating: number) => {
    setRating(newRating);
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleCancel = () => {
    setRating(0);
    setComment('');
    onClose();
  };

  const handleSend = () => {
    onSubmit({ rating, comment });
    setRating(0);
    setComment('');
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} 
    BackdropProps={{
      style: {
        backdropFilter: 'blur(1px)', 
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      },
    }}
    aria-labelledby="feedback-modal-title">
<Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: '481px',
          maxWidth: '375px',
          padding: '21px 24px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          backgroundColor: checked ? '#FFFFFF' : 'rgba(11, 11, 11, 1)',
          color: checked ? '#000' : '#FFF',
          border: '1px solid rgba(53, 56, 63, 1)',
          outline: 'none'
        }}
      >
      <StyledModalTitle style={{color: checked? '#000' : '#FFF'}} id="feedback-modal-title" variant="h6" >
  Share your Feedback
</StyledModalTitle>
<StyledDivider />

        <StyledSubTitle  style={{ color: checked ? '#000' : '#FFF',
}}>
          Rate Your Experience
        </StyledSubTitle>

        <StarRatingContainer>
          {[1, 2, 3, 4, 5].map((star) => (
            <IconButton  style={{padding:'0px'}}key={star} onClick={() => handleRating(star)}>
                <div>

                    {checked ?(
                         <img 
                         src={starIcon2} 
                         alt="star" 
                         style={{ 
                           width: '50px', 
                           height: '50px', 
                           filter: star <= rating ? 'brightness(0) saturate(100%) invert(36%) sepia(95%) saturate(3257%) hue-rotate(238deg) brightness(96%) contrast(101%)' : (checked ? 'white' : 'none') 
                         }} 
                       /> 
                    ):(
                    <img 
    src={starIcon} 
    alt="star" 
    style={{ 
      width: '50px', 
      height: '50px', 
      filter: star <= rating ? 'brightness(0) saturate(100%) invert(36%) sepia(95%) saturate(3257%) hue-rotate(238deg) brightness(96%) contrast(101%)' : (checked ? 'white' : 'none') 
    }} 
  />    )}
                </div>
       </IconButton>
          ))}
        </StarRatingContainer>

        <Box display="flex" alignItems="center">
          <StyledSubTitle  style={{ color: checked ? '#000' : '#FFF',
}}>Comment</StyledSubTitle>
          <OptionalText>(Optional)</OptionalText>
        </Box>

        <StyledTextField
          placeholder="Enter your bio here"
          multiline
          minRows={3}
          value={comment}
          onChange={handleCommentChange}
          InputProps={{
            style: { color: checked ? '#000' : '#FFF' } 
          }}        />

        <ButtonContainer>
          <StyledCancelButton onClick={handleCancel}>Cancel</StyledCancelButton>
          <StyledSendButton onClick={handleSend}>Send</StyledSendButton>
        </ButtonContainer>
      </Box>
    </Modal>
  );
};

export default FeedbackModal;

// Styled Components

const StyledModalTitle = styled(Typography)({
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontWeight: 800,
    textAlign: 'center',
  });
  const OptionalText = styled(Typography)({
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    color: 'rgba(153, 153, 153, 1)',
    marginLeft: '4px',
  });
  
  const StyledSubTitle = styled(Typography)({
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,

  });
  
  const StyledDivider = styled(Divider)({
    backgroundColor: 'rgba(78, 78, 78, 1)',
    marginTop: '11px',
    marginBottom: '11px',
    height: '1px',
  });
  
const StarRatingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
  marginTop: '15px',
  marginBottom:'15px'
});


  
const StyledTextField = styled(TextField)({
    '& .MuiInput-underline::before':{
      display:'none'
    },
    '& .MuiInput-underline::after':{
      display:'none'
    },
    width: '100%',
    marginTop:'10px',
    maxWidth: '375px',
    backgroundColor: 'transparent',
    fontFamily: 'Manrope',
    fontSize: '14px',
    '& .MuiInputBase-root': {
      padding: '10px',
      borderRadius: '10px',
      border: '1px solid rgba(53, 56, 63, 1)',
      transition: 'border-color 0.3s', 
      borderImageSlice: 1, 
      outline: 'none',
    },
    '& .MuiInputBase-inputMultiline': {
      resize: 'none',
    },
    '& .MuiInputBase-input': {
      '&::placeholder': {
        color: 'rgba(153, 153, 153, 1)',
        opacity: 1,
      },
    },
    '&:focus-within .MuiInputBase-root': {
      borderColor: 'rgba(186, 5, 247, 1)', 
      outline:'none'
    },
  });
    

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '16px',
  gap:'5px'
});


const StyledCancelButton = styled(Button)({
    color: 'rgba(186, 5, 247, 1)',
    border: '1px solid transparent', 
    textTransform: 'none',
    width: '160px',
    borderRadius: '10px',
    backgroundColor: 'transparent',
    position: 'relative',
    overflow: 'hidden',
    zIndex: 1,
    padding: '10px 20px',
    fontWeight:700,
    fontSize:'14px',
    fontFamily:'Manrope',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '10px',
      padding: '1px',
      background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
      WebkitMask:
        'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)', 
        mask:
        'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude',
      zIndex: -1,
    },
  
    '&:hover::before': {
      background: 'linear-gradient(330.07deg, #BA05F7 -51.03%, #3858E3 138.78%)',  },
  });
  

const StyledSendButton = styled(Button)({
    
    background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
    color: '#fff',
  textTransform: 'none',
  width: '160px',
  borderRadius:'10px',
  fontWeight:700,
  fontSize:'14px',
  fontFamily:'Manrope',

  
});
