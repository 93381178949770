// Customizable Area Start

import React, { useEffect, useRef } from 'react'
import { youtubeIcon } from '../assests';

interface YoutubeVideoProps {
	youtubeUrl: string | null
}

const YoutubeVideo: React.FC<YoutubeVideoProps> = ({ youtubeUrl }) => {
	const videoRef = useRef<HTMLDivElement | null>(null);
	const iframeRef = useRef<HTMLIFrameElement | null>(null);

	const getYouTubeVideoId = (url: string) => {
		const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?|watch|results|playlist)\/|.*[?&]v=)|(?:youtu\.be\/|v\/|e\/|embed\/|watch\?v=))([^&\n]{11})/;

		const match = url.match(youtubeRegex);
		return match ? match[1] : null;
	};

	const pauseVideo = () => {
		if (iframeRef.current) {
			const message = {
				event: 'command',
				func: 'pauseVideo',
				args: ''
			};
			iframeRef.current.contentWindow?.postMessage(JSON.stringify(message), '*')
		}
	};

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (!entry.isIntersecting) {
					pauseVideo()
				}
			},
			{
				threshold: 0.1,
			}
		);

		if (videoRef.current) {
			observer.observe(videoRef.current);
		}

		return () => {
			if (videoRef.current) {
				observer.unobserve(videoRef.current);
			}
		};
	}, [videoRef])

	if (!youtubeUrl) {
		return null
	}

	const videoId = getYouTubeVideoId(youtubeUrl as string);

	if (!videoId) {
		return null
	}

	return (
		<div ref={videoRef}>
			<div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '10px' }}>
				<img src={youtubeIcon} alt='youtube-icon' onClick={() => pauseVideo()} />
				<p style={{ fontFamily: 'Manrope', fontSize: '12px', fontWeight: '800' }}>Youtube</p>
			</div>
			<div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
				<iframe
					ref={iframeRef}
					title="YouTube Video"
					width="100%"
					height="100%"
					style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
					src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1&version=3`}
					frameBorder={0}
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				/>
			</div>
		</div>
	)
}

export default YoutubeVideo


// Customizable Area End

