import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { 
  imgPasswordInVisible, 
  imgPasswordVisible 
} from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  renderBlock?: (route: string) => void;
  checked?:boolean;
  intialValue?:any

  
  // Customizable Area End
}

//Customizable Area Start
export interface FormValues {
  title: string;
  details: string;
  period: string;
  price: string;
  id?:string
  group_uid?:string;
}
export interface ApiData{
  contentType: string;
  method: string;
  endPoint: string; 
  body?: {}
}
interface SubscriptionAttributes {
  id: number;
  account_id: number;
  title: string;
  details: string;
  period: string;
  price: number;
}

export interface Subscription {
  id: string;
  type: string;
  attributes: SubscriptionAttributes;
}

  // Customizable Area End

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  
  // Customizable Area Start
  checked: boolean;
  isLoading: boolean;
  subscriptionsList:Subscription[]
  initialValues:FormValues
  isEdit: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Subscriptionbilling2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  subScriptionsListId='';
  addSubscriptionsId="";
  deleteSubscriptionId="";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      checked: false,
      isLoading: false,
      subscriptionsList:[],
      initialValues: {
        title: "",
        details: "",
        period: "",
        price: ""
      },
      isEdit: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (responseJson) {
      this.onSubscriptionsApiRes(message)
      this.onAddSubscriptionsApiRes(message)
      this.onDeleteSubscriptionsApiRes(message)

    }
    if (errorReponse || responseJson.error) {
      this.setState({
        isLoading: false
      })
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  setNavigation=(route:string)=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),route
    );
    this.send(message);
  }

  getSubscriptionsList = () => {
    const id = this.getParamData()
    if(id){
      this.getGroupSubscriptionList(id)
    } else{
      this.subScriptionsListId = this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.getMethod,
        endPoint: "bx_block_custom_user_subs/subscriptions",
      });
    }
   
  }
  getGroupBEURL = () =>{
    return this.getParamData() ? 'bx_block_chat/group_subscriptions' : 'bx_block_custom_user_subs/subscriptions'
  }
  getGroupSubscriptionList = (groupId: string) => {
    this.subScriptionsListId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getMethod,
      endPoint: `bx_block_chat/group_subscriptions?group_uid=${groupId}`,
    });
  }
  addSubscriptionsList = (data:FormValues,method: string) =>{
    this.addSubscriptionsId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: method,
      endPoint: this.getGroupBEURL()+"/"+data.id,
      body:data
    }) 
  }
  deleteSubscription = (id: string) =>{
    this.deleteSubscriptionId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.deleteMethod,
      endPoint: this.getGroupBEURL()+"/"+id,
    }) 
  }
  onAddSubscriptionsApiRes = (message:Message) => {
    const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
    const responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage) );
    if(getName(MessageEnum.RestAPIResponceMessage)===message.id &&  this.addSubscriptionsId === apiRequestCallId && responseJson){
      if(responseJson.data){
        this.setState({
          ...this.state,
          isLoading:false,
          isEdit:false
        })
        this.getSubscriptionsList()
      }
    }
  }
  onDeleteSubscriptionsApiRes = (message:Message) => {
    const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
    const responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage) );
    if(getName(MessageEnum.RestAPIResponceMessage)===message.id &&  this.deleteSubscriptionId === apiRequestCallId && responseJson){
      this.getSubscriptionsList()

    }
  }

  apiCall = (data: ApiData) => {
    const { contentType, method, endPoint, body } = data;
    const token =  localStorage.getItem("authToken") || "";
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  onSubscriptionsApiRes = (message:Message) => {
    const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
    const responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage) );
    if(getName(MessageEnum.RestAPIResponceMessage)===message.id &&  this.subScriptionsListId === apiRequestCallId && responseJson){
      if(responseJson.data){
        const data = responseJson.data
        this.setState({
          ...this.state,
          subscriptionsList:  data,
          isLoading:false     
        })
      }
    }
  }
  async componentDidMount() {
    this.setState({
      isLoading:true
    })
   this.getSubscriptionsList()
  }
  addSubscription = (values: FormValues, { resetForm }: any) => {
    values ={
      ...values,
      group_uid:this.getParamData()
    }
    this.setState((prev) => {
      return {
        ...prev,
        isLoading: true,
      }
    }, () => {
      this.addSubscriptionsList(values,values.id ? configJSON.patchMethod : configJSON.postMethod )
      resetForm()
    })
  }
  onSubEdit = (data:Subscription) =>{
    this.setState({...this.state,initialValues:{
      title: data.attributes.title,
      price: data.attributes.price.toString(),
      details: data.attributes.details,
      period: data.attributes.period,
      id: data.id
    },
      isEdit: true
    })
  }
   onSubDelete = (data:Subscription) =>{
    this.setState({isLoading: true})
    this.deleteSubscription(data.id)
   }
   removeAddForm = () =>{
    this.setState({isEdit:false})
   }
   openAddFrom = () =>{
    this.setState({
      isEdit:true,
      initialValues:{
        title:'',
        price:'',
        period:'',
        details:'',
        id:''
      }
    })
   }
   getParamData = () =>{
    const groupId = this.props.navigation.getParam('id')
    return groupId;
  }
  // Customizable Area End
}
