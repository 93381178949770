Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.errorTitle = "Error";

exports.apiVerifyOtpContentType = "application/json";
exports.apiVerifyOtpEndPoint =
  "account/accounts/sms_confirmation?pin=";

exports.apiVerifyForgotPasswordOtpEndPoint =
  "forgot_password/otp_confirmation";

exports.apiVerifyOtpMethod = "POST";

exports.errorOtpNotValid = "OTP is not valid.";

exports.btnTxtSubmitOtp = "Submit otp";

exports.placeHolderOtp = "OTP";

exports.labelInfo =
  "We Just sent a 4 digits OTP to phone. Enter your OTP code below.";

exports.submitButtonColor = "#6200EE";
// Customizable Area Start
exports.accountCreationApiEndPoint='account_block/accounts';
exports.logoDescription='A social network created by the community for the community.';
exports.sendCode=' We sent you a code';
exports.enterOtpVerify='Enter it below to verify ';
exports.otp='OTP';
exports.codeNotReceived='Didn’t receive code ?';
exports.next='next';
exports.enterName='Enter name';
exports.checkText='You’ll need a password';
exports.charText='Make sure it’s 8 character or more.';
exports.check='Password';
exports.agree='I agree with ';
exports.terms='Terms & conditions';
exports.and='  and  ';
exports.privacy='Privacy Policy';
exports.next='next';
exports.otpRegex=/^\d{4}$/;
exports.enterOtp='Enter OTP';
exports.passwordRegex=/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/
exports.enterPassword='please enter password';
exports.enterValidPassword='password should contain atleast one uppercase,one lowercase and one special character';
exports.termsAndConditions='please accept terms & conditions';
exports.enterValidOtp='please enter valid otp';
exports.otpApiEndpoint='bx_block_forgot_password/confirm_otp';
exports.userHeading='What should we call you?';
exports.userDescription='your @username is unique. You can always change it later.';
exports.userName='Username';
exports.showMore='Show more';
exports.skip='Skip for Now';
exports.profileHeading='Choose your profile picture';
exports.profileDescription='Upload your profile picture here';
exports.uploadPicture='Upload picture';
exports.suggestionApiEndpoint='/account_block/generate_user_names';
exports.suggestionApiMethod='GET';
exports.uploadPictureApiEndPoint='account_block/update_profile';
exports.uploadPictureApiMethod='PATCH';
exports.uploadPictureContentType='multipart/form-data';
exports.updateProfile='profile updated';
exports.otpFieldError='please enter otp';
exports.registerAccount='Signup successfully';
exports.profileUpdate='profile updated successfully';
exports.picError='please upload jpg/png files';
exports.placeholderPassword='Enter password';
exports.termsAndConditionsApiEndpoint='bx_block_terms_and_conditions/terms_and_conditions';
exports.selectCategory='Select categories';
exports.chooseCategory='Choose at least 3 categories from the list below';
exports.categoriesApiEndpoint='bx_block_categories/categories';
exports.categoriesApiMethod='GET';
exports.subCategoryApiEndpoint='bx_block_categories/categories?id=';
exports.noTerms='No data found';
exports.submitCategoriesEndPoint='bx_block_categories/user_categories';
exports.selectSubCategory='Select sub-categories';
exports.chooseSubCategory='Choose sub-categories from the list below'
exports.getUserInfo="account_block/accounts/logged_user"; 
exports.resendOtpEndpoint="account_block/send_otp"; 
exports.crop='Done'

// Customizable Area End
