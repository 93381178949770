import { Box, Button, Dialog, DialogContent, DialogContentProps, IconButton, styled } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ImageRenderer from "./ImageRenderer.web";
import { closeLightBtn } from "../../blocks/postcreation/src/assets";
import CustomReactSlick from "./CustomReactSlick.web";
import { IMessageAttach } from "./CometChatBubbleViewMessageImageRenderer.web";
const plusIcon = require("./assets/plusIcon.svg");

interface IPropDialogContentProps extends DialogContentProps {
    urlcurrentfile: string;
}

interface IPropViewChatPreviewAttachment {
    files: File[] | IMessageAttach[];
    action?: string;
    senderName: string;
    onRemove?: (fileName: string) => void;
    onCloseSelectedFile?: () => void;
    onSelectFiles?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSendMessage?: () => Promise<void>;
}

export interface IFileWithUrl {
    file: File;
    url: string;
}

function ViewChatPreviewAttachment({
    files,
    action,
    senderName,
    onCloseSelectedFile,
    onSelectFiles,
    onSendMessage,
}: IPropViewChatPreviewAttachment) {
    const inputRefUpload = useRef<HTMLInputElement>(null);
    const [listFile, setListFile] = useState<any[]>([]);
    const [currentFile, setCurrentFile] = useState(0);

    const handleFileSelect = () => {
        if (inputRefUpload.current) {
            inputRefUpload.current.click();
        }
    };

    useEffect(() => {
        if (files.length === 0) return;

        let listImageUrl: IFileWithUrl[] = [];
        if (files[0] instanceof File) {
            const castFiles = files as File[];
            listImageUrl = castFiles.map((file: File) => ({
                file,
                url: URL.createObjectURL(file),
            }));
        } else if (action === "view") {
            listImageUrl = files as IFileWithUrl[];
        }

        if (listImageUrl.length > 0) {
            setListFile(listImageUrl);
        }

        return () => {
            listImageUrl.forEach(item => URL.revokeObjectURL(item.url));
        };
    }, [files, action]);

    const handleSelectCurrentFile = (index: number) => {
        setCurrentFile(index)
    }

    return <ViewChatPreviewAttachmentStyled
        fullWidth={true}
        maxWidth="lg"
        open={true}
        BackdropProps={{
            style: {
                background: "#0E0E0E",
                opacity: 0.95
            }
        }}
        PaperProps={{
            style: {
                width: "100%",
                height: "100%",
                maxHeight: "none",
                maxWidth: "none",
                background: "transparent",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row"
            }
        }}
    >
        <DialogContentStyled className="dialogContent" urlcurrentfile={listFile[currentFile]?.url}>
            <Box className="containerPreview">
                {files.length > 1 && (
                    <Box className="sliderContainer">
                        <CustomReactSlick
                            files={listFile}
                            currentFileSelected={currentFile}
                            onSelectCurrentFile={handleSelectCurrentFile}
                        />
                    </Box>
                )}
            </Box>
        </DialogContentStyled>
        <IconButton className="closeBtn" onClick={onCloseSelectedFile}>
            <ImageRenderer src={closeLightBtn} width={24} height={24} />
        </IconButton>
        {
            action !== "view" && (<OverlayElement>
                <Box className="senderInformation">
                    <Button
                        className={"sendMessageBtn"}
                        onClick={onSendMessage}
                    >
                        Send
                    </Button>
                </Box>
            </OverlayElement>)
        }
    </ViewChatPreviewAttachmentStyled>
}

const OverlayElement = styled('div')(({ }) => ({
    position: 'fixed',
    width: '100vw',
    height: '80px',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    padding: "16px 32px",
    boxSizing: "border-box",
    bottom: 0,
    zIndex: 1100,
    display: "flex",
    justifyContent: "center",
    "& .senderInformation": {
        padding: "8px 20px",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        borderRadius: "10px",
        width: "160px",
        height: "48px",
        boxSizing: "border-box",
        "& .sendMessageBtn": {
            width: "100%",
            fontFamily: 'Manrope',
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '16.8px',
            textTransform: "capitalize",
            color: "#FFFFFF"
        }
    },
}));

const ViewChatPreviewAttachmentStyled = styled(Dialog)({
    "& .closeBtn": {
        position: "absolute",
        top: "2vw",
        right: "10vw",
        padding: 12,
        background: "#222222",
        borderRadius: "100px"
    }
});

const DialogContentStyled = styled(DialogContent)(({ urlcurrentfile }: IPropDialogContentProps) => ({
    padding: "0!important",
    maxWidth: "1280px",
    position: "relative",
    backgroundImage: `url(${urlcurrentfile})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    "& .containerPreview": {
        position: "relative",
        height: "100%",
        overflow: "hidden",
        "& .sliderContainer": {
            position: "absolute",
            maxWidth: 576,
            height: 90,
            bottom: "calc(80px + 16px + 56px + 16px)",
            left: "50%",
            transform: "translate(-50%)",
            background: "#222222",
            borderRadius: "10px",
        },
        "& .captionContainer": {
            position: "absolute",
            width: 576,
            height: 56,
            bottom: "calc(80px + 16px)",
            left: "50%",
            transform: "translate(-50%)",
            display: "flex",
            gap: "16px",
            "& .captionInputField": {
                width: "calc(100% - 56px - 16px)",
                "& .MuiInputBase-input": {
                    fontFamily: "Manrope",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "#6B6B6B"
                },
                "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    background: "#222222",
                    border: "1px solid #999999",
                    "&.Mui-focused fieldset": {
                        border: "1px solid #7A2BF5",
                    },
                },
            },
        },
    }
}));

export default ViewChatPreviewAttachment