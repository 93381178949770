Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.loginEndpoint = "bx_block_login/login";
exports.welComeText='Welcome to';
exports.arrowsText='Arrows';
exports.login='Log In';
exports.forgotPassword='Forgot password?';
exports.loginNow='Login Now';
exports.account="Don't have an account?";
exports.signup='Sign Up';
exports.light='light';
exports.dark='dark';
exports.emailName='email';
exports.emailLabel='Phone, Email or @username';
exports.emailPlaceholder='Enter Phone, Email or @username';
exports.authName='password';
exports.authLabel='Password';
exports.authPlaceholder='Enter Password';
exports.emailRegex=/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
exports.phoneRegex=/^91\d{10}$/
exports.googleAuthEndPoint='bx_block_login/login';
exports.loggedInEndPoint='account_block/accounts/logged_user';
exports.clientId='888888598771-jsfhc86qahu30js99s0t9prm8t3g0ldi.apps.googleusercontent.com';


// Customizable Area End