import { Box, Button, Input, InputAdornment, styled, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ImageRenderer from "./ImageRenderer.web";
import { groupIcon, ownerShipIcon, profile, searchDark, searchWhite } from "../../blocks/chat/src/assets";
import { IOSSwitch } from "./ModifiedComponents.web";
import { verifyImg } from "./utils";
import { TTabGroupScreen } from "../../blocks/groups/src/GroupsController";
import clsx from "clsx";

interface IPropsGroupsListMember {
    checked?: boolean;
    typeList: "pending_request" | "permission" | "edit_group",
    listMember: CometChat.User[];
    listUserCanNotSendMessage?: string[];
    isAdminPermission?: boolean;
    onToggleUserSendMessage?: (uid: string) => void;
    onHandleChangeScreen?: (tabScreen: TTabGroupScreen) => void;
    onHandleRemoveMember?: (memberUid: string) => Promise<void>;
}

interface IPropsRenderAction {
    memberUid: string;
    isCanSendMessage?: boolean
}

function GroupsListMember({
    checked,
    typeList,
    listMember,
    listUserCanNotSendMessage,
    isAdminPermission = false,
    onToggleUserSendMessage,
    onHandleChangeScreen,
    onHandleRemoveMember,
}: IPropsGroupsListMember) {
    const [internalListMember, setInternalListMember] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        if (searchValue) {
            const listMemberMatch = listMember.filter(member => {
                const memberName = member?.getName();
                return memberName && memberName.toLowerCase().includes(searchValue.trim().toLowerCase());
            });
            setInternalListMember(listMemberMatch);
        } else {
            setInternalListMember(listMember);
        }
    }, [listMember, searchValue]);

    const handleSwitch = (uid: string) => {
        if (!onToggleUserSendMessage) return;
        onToggleUserSendMessage(uid);
    }

    const handleSearchChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { value } = e.target;
        setSearchValue(value);
    }

    const handleRenderActionByType = ({
        isCanSendMessage,
        memberUid
    }: IPropsRenderAction) => {
        switch (typeList) {
            case "permission": {
                return <Box className="memberActions">
                    <IOSSwitch
                        onChange={() => handleSwitch(memberUid)}
                        checked={isCanSendMessage} />
                </Box>
            }
            case "edit_group": {
                if (!isAdminPermission) return;
                return <Box className="memberActions">
                    <Button className="removeBtn" onClick={() => onHandleRemoveMember && onHandleRemoveMember(memberUid)}>
                        Remove
                    </Button>
                </Box>
            }
            default: {
                return <React.Fragment />
            }
        }
    }

    const renderMemberCount = () => {
        if (!internalListMember.length) {
            return searchValue ? "No members found" : "No members";
        }
        const pluralizedMemberText = internalListMember.length === 1 ? "member" : "members";
        const memberText = `${internalListMember.length} ${pluralizedMemberText}`;
        if (searchValue) {
            return `${memberText} found`;
        }
        return memberText;
    }

    return <GroupsListMemberStyled className="groupsListMemberStyled">
        <Box className="container__search">
            {/* Search Member */}
            <Box className={`searchWrapper`}>
                <Input
                    id="input-with-icon-adornment"
                    className={`searchField`}
                    placeholder="Search"
                    value={searchValue}
                    onChange={handleSearchChange}
                    startAdornment={
                        <InputAdornment position="start">
                            <img src={checked ? searchDark : searchWhite} />
                        </InputAdornment>
                    }
                />
            </Box>
            {/* Member count */}
            <Box className="container__memberCount">
                <Typography
                    component="p"
                    className={`headerText ${clsx(checked && "lightThemePrimaryTextColor")}`}>
                    {renderMemberCount()}
                </Typography>
            </Box>
        </Box>
        {/* Add member */}
        {
            isAdminPermission &&
            typeList === "edit_group" &&
            <Box className="container__addMember" onClick={() => onHandleChangeScreen && onHandleChangeScreen("add_members")}>
                <ImageRenderer src={groupIcon} width={48} height={48} />
                <Typography component="p" className={` ${clsx(checked && "lightThemePrimaryTextColor")}`}>
                    Add member
                </Typography>
            </Box>
        }
        {/* List members */}
        <Box className="container__listMember">
            {internalListMember.map((member: CometChat.User) => {
                const memberUid = member?.getUid();
                const metadata = member?.getMetadata();
                const memberProfileImg = member?.getAvatar();
                const isCanSendMessage = !listUserCanNotSendMessage?.find(uid => uid === memberUid);
                const { username = "", user_ownership = false, user_subscription = "" } = metadata as unknown as any;
                return (
                    <Box className="memberContainer" key={memberUid}>
                        <ImageRenderer className="profileAvatar" width={44} height={44} src={memberProfileImg || profile} />
                        <Box className="memberInformation">
                            <Box className="member__header">
                                <Typography className={`mainText ${clsx(checked && "lightThemePrimaryTextColor")}`} component="p">
                                    {member.getName()}
                                </Typography>
                                <Box className="member_infoBadge">
                                    <Box>
                                        <img alt="i" src={verifyImg(user_subscription)} width={14} height={14} />
                                    </Box>
                                    <Box>
                                        {
                                            user_ownership &&
                                            <img alt="i" src={ownerShipIcon} width={14} height={14} />}
                                    </Box>
                                </Box>
                            </Box>
                            <Typography component="p" className="subText" style={{
                                color: checked ? "#4E4E4E" : "#E0E0E0"
                            }}>
                                {username}
                            </Typography>
                        </Box>
                        {handleRenderActionByType({
                            isCanSendMessage,
                            memberUid
                        })}
                    </Box>
                )
            })}
        </Box>
    </GroupsListMemberStyled>
}

const GroupsListMemberStyled = styled(Box)({
    "& .lightThemePrimaryTextColor": {
        color: "#222222!important"
    },
    "& .container__search": {
        display: "flex",
        flexDirection: "column"
    },
    "& .searchWrapper": {
        height: "50px",
        padding: '0px 15px',
        border: '1px solid #6B6B6B',
        borderRadius: '10px',
        alignContent: 'center',
        marginBottom: "20px",
        "& .searchField": {
            '&::after': {
                display: "none",
            },
            '&::before': {
                display: "none",
            },
            width: "100%"
        },
    },
    "& .container__memberCount": {
        padding: "14px 0",
        marginBottom: "10px",
    },
    "& .container__addMember": {
        display: "flex",
        columnGap: 15,
        alignItems: "center",
        marginBottom: 15,
        cursor: "pointer",
        width: 200,
        "& p": {
            fontFamily: "Manrope",
            fontSize: 16,
            fontWeight: 600,
            color: "#FFFFFF"
        }
    },
    "& .container__listMember": {
        display: "flex",
        flexDirection: "column",
        rowGap: 15,
        "& .memberContainer": {
            display: "flex",
            alignItems: "center",
            columnGap: 10,
            "& .profileAvatar": {
                boxSizing: "border-box",
                border: "1.5px dashed #F0E5FF",
                borderRadius: 40
            },
            "& .memberInformation": {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                height: "44px",
                justifyContent: "space-between",
                "& .member__header": {
                    display: "flex",
                    columnGap: 5,
                    "& .member_infoBadge": {
                        display: "flex",
                        gap: 2,
                        alignItems: "center"
                    }
                }
            },
            "& .memberActions": {
                "& .removeBtn": {
                    border: "1px solid #DC2626",
                    color: "#DC2626",
                    padding: "8px 16px",
                    borderRadius: 10,
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "12px",
                    textTransform: "capitalize",
                    cursor: "pointer",
                }
            },
        }
    },
    "& .headerText": {
        fontFamily: "Manrope",
        fontWeight: 700,
        fontSize: 14,
        color: "#FFFFFF"
    },
    "& .mainText": {
        fontFamily: "Manrope",
        fontWeight: 600,
        fontSize: 16,
        color: "#FFFFFF"
    },
    "& .subText": {
        fontFamily: "Manrope",
        fontWeight: 500,
        fontSize: 10,
        color: "#E0E0E0"
    }
})

export default GroupsListMember