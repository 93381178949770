import { IBlock } from "framework/src/IBlock";
// Customizable Area Start
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { toast } from "react-toastify";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: number;
  // Customizable Area Start
  checked: boolean;
  postId: string;
  open: boolean;
  onClose: () => void;
  // Customizable Area End
}


export interface ISetting {
  id: number,
  value: number,
  permissionTo: string,
  title: string,
  active: boolean
}

interface S {
  // Customizable Area Start
  preference: Array<ISetting>;
  loader: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CommentPreferenceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCommentSettings: string = ""
  apiPatchCommentSettings: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start

      preference: [
        { active: false, id: 1, title: configJSON.EveryOne, value: 0, permissionTo: configJSON.everyonePermission },
        { active: false, id: 2, title: configJSON.AccoutsFollows, value: 1, permissionTo: configJSON.AccoutsFollowsPermission },
        { active: false, id: 3, title: configJSON.AccoutsMentions, value: 2, permissionTo: configJSON.AccoutsMentionsPermission },
        { active: false, id: 4, title: configJSON.noOne, value: 0, permissionTo: "" }
      ],
      loader: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    this.handleGetPermission(message)
    this.updateCommentPermission(message)
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (this.props.open !== prevProps.open) {
      this.getPostCommentSetting()
    }
  }

  getPostCommentSetting = async () => {
    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetCommentSettings = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCommentSetting}?post_id=${this.props.postId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSubmit = async () => {
    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.contentType,
      token: token,
    };

    const permission = this.state.preference.find(item => item.active)

    const body = {
      post_id: this.props.postId,
      permission_to: permission?.value

    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiPatchCommentSettings = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getCommentSetting}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apipatchMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleChangePreference = (preference: number) => {
    const updatePreference = this.state.preference.map(item => item.id === preference ? { ...item, active: true } : { ...item, active: false })
    this.setState({
      preference: updatePreference
    })
  }

  handleCancel = () => {
    this.props.onClose()
  }

  handleGetPermission = (message: Message) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.apiGetCommentSettings) {
      if (!responseJson?.errors) {
        const permission = this.state.preference.map(item => item.permissionTo === responseJson.data.permission_to ? { ...item, active: true } : { ...item, active: false })
        this.setState({
          preference: permission
        })
      }
    }
  }

  updateCommentPermission = (message: Message) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.apiPatchCommentSettings) {
      if (!responseJson?.errors) {
        toast.success(responseJson.messages[0])
        this.props.onClose()
      }
    }
  }

  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End