// Customizable Area Start
export const twitter = require("../assets/twitter.png");
export const instagram = require("../assets/instagram.png");
export const whatsapp = require("../assets/whatsapp.png");
export const mail = require("../assets/mail.png");
export const message = require("../assets/message.png");
export const facebook = require("../assets/facebook.png")
export const share = require("../assets/share.png")
export const shareDark = require("../assets/shareDark.png")
export const copy = require("../assets/copy.png")
export const copyDark = require("../assets/copyDark.png")
export const bookmark = require("../assets/bookmark.png")
export const bookmarkDark = require("../assets/bookmarkDark.png")
// Customizable Area End