Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.exampleAPiGetMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Repost";
exports.labelAddMessageText = "Add Message";
exports.labelBodyText = "Repost Body";
exports.successRepostMessage = "Post reposted successfully!";
exports.errorRepostMessage = "Error in reposting! Please try again";
exports.btnRepost = "Repost";
exports.btnSendPost = "Send";
exports.contentRepostApiContentType = "application/json";
exports.baseURL = "";
exports.sendPostEndPoint = "bx_block_repost/reposts";
exports.getPostEndPoint = "bx_block_posts/posts";
exports.sendPostApiMethod = "POST";

// Customizable Area End
