import React from 'react'
// Customizable Area Start 
import {
    Box,
    Dialog,
    Grid,
    Typography,
    styled,
    Button
} from '@material-ui/core'

import DeletePostModalController,{Props} from './DeletePostModalController.web';
import { configJSON } from './ActivityFeedController';
// Customizable Area End

export default class DeletePostModal  extends DeletePostModalController {

    constructor(props: Props) {
     // Customizable Area Start      
      super(props);  
     // Customizable Area End  
    }

    // Customizable Area Start  
    conditionRemover =(condition:boolean,trueStatement:string,falseStatement:string)=>{
        return condition? trueStatement: falseStatement
      }
    
      renderDeleteModal=()=>{
        return (
            <DialogBox data-testid="delete_post_modal"
                open={this.props.open}
                onClose={this.props.onClose}
                PaperProps={{
                  className: this.conditionRemover(!this.props.checked, "modelDarkStyle", "modelLightStyle")
                }}
              >
                <GridContainer container justifyContent="center" alignItems="center" direction="column" >
          
                  <Box className='HeadContainer'>
                    <Typography className={"name"}>{configJSON.DeletePostTitle}</Typography>
                  </Box>
          
                  <Box>
                    <Typography className={"emaptySubhead"}>{configJSON.DeleteModalDescription}
                      </Typography>
                  </Box>
          
                  <Box className='muteAction'>
                    <Button 
                       data-test-id="noBtn"
                       onClick={this.handleCancel}
                        className={"muteBtnDenyDark"}>
                      {configJSON.cancel}
                    </Button>
                    <Button 
                    onClick={this.props.onDeleteConfirmation} 
                    data-test-id="muteagree" 
                    className={"DeleteBtn"}>{configJSON.Delete}</Button>
                  </Box>
                </GridContainer>
              </DialogBox>
          )
      }
    
    // Customizable Area End  

    render(){
     // Customizable Area Start   
    return(
       <>
       {this.renderDeleteModal()}
       </>
    )
    // Customizable Area End 
    }

}

// Customizable Area Start  
const DialogBox = styled(Dialog)({
 
   "& .modelDarkStyle": {
     borderRadius: "10px",
     backgroundClip: "padding-box, border-box",
     backgroundOrigin: "padding-box, border-box",
     border: "1px solid transparent",
     backdropFilter: "blur(1 px)",
     background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)",
     boxShadow: "none"
   },
   "& .modelLightStyle": {
     borderRadius: "10px",
     boxShadow: "none",
     border: "1px solid transparent",
     background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #35383F ,#35383F)",
     backgroundClip: "padding-box, border-box",
     backgroundOrigin: "padding-box, border-box",
     backdropFilter: "blur(1 px)",
   },
 })

 const GridContainer = styled(Grid)({
    padding: "10px 20px", 
    rowGap: "17px", 
    width: "375px",
    height: "254px",
     
    "& .HeadContainer":{
      height:52,
      width:'100%',
      display:'flex',
      marginTop:-10,
      justifyContent:'center',
      borderBottom:'1px solid #BABABA',
    },


    "& .modelDarkStyle": {
      borderRadius: "10px",
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      border: "1px solid transparent",
      backdropFilter: "blur(1 px)",
      background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)",
      boxShadow: "none"
    },
    "& .modelLightStyle": {
      borderRadius: "10px",
      boxShadow: "none",
      border: "1px solid transparent",
      background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #35383F ,#35383F)",
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      backdropFilter: "blur(1 px)",
    },
    "& .name": {
      fontSize: "18px",
      fontFamily: "Manrope",
      fontWeight: 800,
      lineHeight: "21.6px",
      whiteSpace: "nowrap" as "nowrap",
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      }
    },
    "& .emaptySubhead": {
      fontFamily: "Manrope",
      fontWeight: 600,
      lineHeight:"19.2px",
      fontSize: "16px",
      textAlign: "center" as "center"
    },
  
    "& .DeleteBtn": {
        width:160,
        height:48,
        background:'#EF4444',
        fontWeigth:700,
        color:'#fff',
        textTransform:'unset',
        fontFamily:'Manrope',
        fontSize:14,
        borderRadius:10,
        "&:hover":{
          background:'#EF4444',
        }
    },
    "& .muteBtnDenyDark": {
        width:160,
        height:48,
        boxShadow: "none !important",
        background:'transparent !important' as 'transparent',
        fontWeigth:700,
        fontFamily:'Manrope',
        textTransform:'unset',
        fontSize:14,
        borderRadius:10,
        border:'1px solid #6B6B6B'
    },
  
    "& .muteAction":{
      display: "flex", 
      marginTop:5,
      columnGap: "12px" 
      }
  
     })
// Customizable Area End  