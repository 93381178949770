import { IBlock } from "framework/src/IBlock";
// Customizable Area Start
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: number;
    // Customizable Area Start
    checked:boolean;
    open:boolean;
    onClose:()=>void;
    // Customizable Area End
  }


  export interface ISetting{
    id:number,
    value:string,
    title:string,
    description:string,
    active:boolean
  }
  
  interface S {
     // Customizable Area Start
      preference:Array<ISetting>;
      loader:boolean
    // Customizable Area End
  }
  
  interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
  }
  
export default class NotificationPreferenceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getNotificationPreferenceApiCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      
      preference:[
        {active:false,description:"Get notification for all of this account’s Posts.",id:1,title:"All Posts", value:"all_posts"},
        {active:false,description:"Get notification for this account’s Posts & replies",id:2,title:"All Posts & Replies", value:"all_posts_and_replies"},
        {active:false,description:"Get notification only for live broadcasts.",id:3,title:"Only live video", value:"only_live_video"},
        {active:false,description:"Turn off notification for this account’s Posts.",id:4,title:"Off", value:"off"}
      ],
      loader:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    this.handlePrefernce(message)
    // Customizable Area End
  }

  // Customizable Area Start


  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if(this.props.open!==prevProps.open){
      if(this.props.open){
      this.getNotificationPreference()
      }
    }
  }
   
  handleChange=(id:number)=>{
   const updatePreference = this.state.preference.map(item=>item.id===id?{...item,active:true}:{...item,active:false})
     this.setState({
       preference:updatePreference
     },()=>this.updateNotificationPreference(id))
  }

  updateNotificationPreference=async(preference:number)=>{
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationPreferenceApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.notificationPreference + `notifier_id=${this.props.id}&preference=${preference}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpPatchMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getNotificationPreference = async() =>{
    this.setState({
      loader:true
    })
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationPreferenceApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.notificationPreference + `notifier_id=${this.props.id}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handlePrefernce=(message:Message)=>{
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getNotificationPreferenceApiCallId) {
      this.setState({
        loader:false
      })
      if (!responseJson?.erros) {       
        const updatePreference = this.state.preference.map(item=>item.value===responseJson.data.preference?{...item,active:true}:{...item,active:false})
         this.setState({
          preference:updatePreference,
         })
        }
      }
  }
  // Customizable Area End
}