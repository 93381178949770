import React, { useState } from 'react';
import { Popover, Box, Typography, Button } from '@material-ui/core';
import { styled, Theme, ThemeProvider } from '@material-ui/core/styles';
import { threeDots, threeDotsLight } from '../../../blocks/chat/src/assets';

interface MenuItem {
  id: number;
  name: string;
  action: (event?: any) => void; // Function to execute on item click
  disabled?: boolean;
  textColor?: string;
}

interface MoreOptionsProps {
  theme: Theme;
  menuItems: MenuItem[];
  checked?: boolean;
}

const MoreOptions: React.FC<MoreOptionsProps> = ({ theme, menuItems, checked }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <ThemeProvider theme={theme}>
      <Button onClick={handleOpen}><img
        style={{ height: 24, width: 24, cursor: "pointer" }}
        src={checked ? threeDots : threeDotsLight}
        alt="" /></Button>

      <CustomPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        style={{ background: 'rgba(0, 0, 0, 0.4)', zIndex: 99 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: { marginTop: "20px" }
        }}
      >
        <Box>
          {menuItems.map((item) => !item.disabled && (
            <MenuBoxContainer
              key={item.id}
              data-testid="menuItem"
              onClick={() => {
                item.action(anchorEl);
                handleClose();
              }}
              style={{ padding: '8px 16px', cursor: 'pointer' }}
            >
              <Typography className="item_list_name" style={{ color: item?.textColor }}>{item.name}</Typography>
            </MenuBoxContainer>
          ))}
        </Box>
      </CustomPopover>
    </ThemeProvider>
  );
};

export default MoreOptions;


const CustomPopover = styled(Popover)({
  "& .MuiPaper-elevation8-53": {
    boxShadow: 'none'
  },
})

const MenuBoxContainer = styled(Box)({
  width: "256px",
  display: 'flex',
  margin: "8px",
  alignItems: 'center',
  cursor: 'pointer',
  gap: "10px",
  "&:hover": {
    backgroundColor: "rgba(75, 75, 75, 0.3)"
  },

  "& .item_list_name": {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    fontFamily: 'Manrope'
  }

})
