export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const googleImage=require('../assets/google.png')
export const appleImage=require('../assets/apple.png')
export const lightThemeBg=require('../assets/lightBg.png')
export const darkThemeBg=require('../assets/darkBg.png')
export const next=require('../assets/next.svg')
export const prev=require('../assets/prev.svg')
export const RightIcon=require('../assets/RightIcon.svg')
export const leftArrow=require('../assets/leftArrow.svg')




