import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  Paper,
  Checkbox,
  IconButton,
  styled,
  Popover,
  InputAdornment
} from "@material-ui/core";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { lightThemeBg, darkThemeBg, prev, next, RightIcon, leftArrow } from "./assets";



const StyledIcon = styled(IconButton)({
  padding: 0,
  marginLeft: -4,
  "&.MuiIconButton-root:hover": {
    background: 'unset'
  }
})
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import TermsConditionModal from "../../otp-input-confirmation/src/TermsConditionModal.web";
import DateRangeIcon from '@material-ui/icons/DateRange';


// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
  }

});

const commonLightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary: {
      main: 'rgba(0,0,0,0.75)'
    }
  }
});

const commonDarkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#3858E3",
    },
    secondary: {
      main: "rgba(255,255,255,0.75)"
    }
  }
});
const IcheckBox = withStyles(({
  root: {

    "&.Mui-checked": {
      color: "#6200EA",
      borderRadius: "30px",
    },
    "&.MuiCheckbox-root:hover, &.MuiCheckbox-root.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "25px",
    },
    "&.MuiIconButton-root": {
      borderRadius: "150px !important",
      padding: "10px",
    },
    marginLeft: "-10px",

  }
}))(Checkbox)

// Customizable Area End

import DateOfBirthController, {
  Props,
  configJSON,
} from "./DateOfBirthController.web";
import { checkboxImage } from "../../otp-input-confirmation/src/assets";


export default class DOB extends DateOfBirthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

 

  conditionShort = (condition: boolean, trueStatement: string, falseStatement: string) => {
    return condition ? trueStatement : falseStatement
  }



  CalendarContainer = styled("div")(theme => ({
    "& .calendar_container .react-calendar__tile": {
      fontFamily: "Manrope !important",
      fontSize: 12,
      fontWeight: 500,
      height: 40,
      padding: 10,
      width: 40,
      color: this.conditionShort(this.props.checked, "#222222", "#fff"),
      borderRadius: '10px',
      margin: "2px",
      flex: '0 0 42px !important'
    },

    "& .calendar_container .react-calendar__century-view .react-calendar__tile": {
      fontWeight: 500,
      color: this.conditionShort(this.props.checked, "#222222", "#fff"),
      borderRadius: '10px',
      flex: '0 0 100px !important',
      height: 40,
      padding: 10,
      width: 40,
      fontFamily: "Manrope !important",
      fontSize: 12,
    },

    "& .calendar_container .react-calendar__month-view__days": {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },


    "& .react-calendar__tile": {
      maxWidth: '28% !important'
    },

    '& .react-calendar__year-view__months': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      gap: '8px'
    },

    "& .calendar_container .react-calendar": {
      padding: 10,
      borderRadius: "10px",
      backgroundColor: this.conditionShort(this.props.checked, "#fff", "#0B0B0B"),
      border: 'none !important'
    },

    "& .calendar_container .react-calendar__month-view__weekdays__weekday abbr": {
      fontFamily: "Manrope !important",
      fontSize: 12,
      fontWeight: 500,
      textDecoration: 'none !important',
      textTransform: 'none',
      background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      backgroundClip: 'text',
      color: 'transparent',
    },

    "& .calendar_container .react-calendar__tile:enabled:hover": {
      width: "40px",
      height: "40px",
      borderRadius: '20px',
      color: this.conditionShort(this.props.checked, "#222222", "#FFFFFF"),
      background: this.conditionShort(this.props.checked, "#BABABA", "#1F1F1F"),

    },


    "& .calendar_container .react-calendar__decade-view .react-calendar__tile": {
      fontFamily: "Manrope !important",
      fontSize: 12,
      fontWeight: 500,
      height: 40,
      padding: 10,
      width: 40,
      color: this.conditionShort(this.props.checked, "#222222", "#fff"),
      borderRadius: '10px',
      flex: '0 0 100px !important'

    },


    '& .react-calendar__navigation button:enabled:hover': {
      backgroundColor: 'transparent !important'
    },
    '& .react-calendar__navigation button:enabled': {
      backgroundColor: 'transparent !important'
    },

    "& .calendar_container .react-calendar__navigation__label__labelText": {
      background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      backgroundClip: 'text',
      color: 'transparent',
      fontWeight: 700,
      fontFamily: "Manrope",
      fontSize: '12px',
    },

    "& .calendar_container .react-calendar__tile--active": {
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) !important",
      width: "40px",
      height: "40px",
      borderRadius: '60px',
      color: "#fff !important",
    },

    "& .calendar_container .react-calendar__tile--active:enabled:hover": {
      width: "40px",
      height: "40px",
      borderRadius: '60px',
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) !important",
      color: this.conditionShort(this.props.checked, "#222222", "#ffff")
    },
    "& .calendar_container .react-calendar__tile--now": {
      background: 'none',
      width: "40px",
      height: "40px",
      borderRadius: '60px',
    },
    "& .calendar_container .react-calendar__tile--now:hover": {
      backgroundColor: this.conditionShort(this.props.checked, "#BABABA !important", "#1F1F1F !important"),
    },
    "& .calendar_container .react-calendar__tile--now:enabled:hover": {
      width: 40,
      height: 40,
      borderRadius: '20px',
      color: '#fff',
      background: 'none',
    },

    "& .calendar_container .react-calendar__month-view__days__day--neighboringMonth": {
      color: "#6B6B6B",
    },
    "& .calendar_container .react-calendar__year-view__months__month": {
      border: '1px solid #222222',
      margin: '2px',
      width: '10px !important',
      borderRadius: '4px !important'
    },

    "& .calendar_container .react-calendar__year-view__months__month:hover": {
      borderRadius: '4px !important',
      border: 'none !important'
    },

    '& .react-calendar__decade-view__years': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      gap: '10px',
      marginLeft: '10px',
    },

    '& .calendar_container .react-calendar__decade-view__years__year': {
      border: '1px solid #222222 !important',
      borderRadius: '4px !important',
      maxWidth: '30% !important'
    },

    '& .calendar_container .react-calendar__decade-view__years__year:hover': {
      border: 'none !important',
      borderRadius: '4px !important',
      maxWidth: '30% !important'
    },

    '& .calendar_container .react-calendar__decade-view__years__year--active': {
      border: 'none !important',
      borderRadius: '4px !important',
      maxWidth: '30% !important'
    },


    '& .react-calendar__navigation button:disabled': {
      backgroundColor: 'transparent !important'
    },

    '& .react-calendar__century-view__decades': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginLeft: '10px',
      gap: '8px'
    },

    '& .calendar_container .react-calendar__century-view__decades__decade': {
      border: '1px solid #222222 !important',
      borderRadius: '4px !important',
      maxWidth: '30% !important'
    },

    '& .calendar_container .react-calendar__century-view__decades__decade:hover': {
      border: 'none !important',
      borderRadius: '4px !important',
      maxWidth: '30% !important'
    },

    "& .react-calendar__tile--hasActive": {
      color: '#E9E9E9 !important',
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) !important",
      border: 'none !important',
      borderWidth: '0px !important'
    },

    "& .react-calendar__tile--hasActive:enabled": {
      color: '#E9E9E9 !important',
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) !important",
      border: 'none !important',
      borderWidth: '0px !important'
    },

    "& .calendar_container .react-calendar__year-view .react-calendar__tile": {
      flex: '0 0 100px !important',
      borderRadius: '10px',
      color: this.conditionShort(this.props.checked, "#222222", "#fff"),
      width: 40,
      padding: 10,
      height: 40,
      fontWeight: 500,
      fontSize: 12,
      fontFamily: "Manrope !important",
    },

  }))


  renderCalendar = () => {
    const popper = Boolean(this.state.anchorEl)
    const id = popper ? 'simple-popper' : undefined;
    const { classes } = this.props
    return (
      <Popover
        id={id}
        open={false}
        data-testid="popover"
        anchorEl={this.state.anchorEl}
        onClose={this.handleCloseCalendar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.popup}
      >
        <this.CalendarContainer data-testid="calendarContainer">
          <div className="calendar_container">
            <Calendar
              prev2Label={null}
              data-testid="calendar"
              prevLabel={<IconButton><img src={prev} /></IconButton>}
              nextLabel={<IconButton><img src={next} /></IconButton>}
              next2Label={null}
              onChange={this.handleDobChange}
              value={this.state.calendarDate}
            />
          </div>
        </this.CalendarContainer>
      </Popover>
    )
  }


  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props
    // Customizable Area End
    return (
      // Customizable Area Start

      <ThemeProvider theme={this.props.checked ? commonLightTheme : commonDarkTheme}>
        <Paper className={this.props.checked ? classes.lightColor : classes.darkColor}>
          <Box className={!this.props.checked ? classes.background : classes.lightBackground}>
            <Grid container className={classes.gridContainer}>
              <Grid item className={classes.imageGridItem} md={5} sm={6} lg={6}>
              </Grid>
              <Grid item xs={10} md={4} sm={6} lg={4} className={classes.formContainer}>
              <img src={leftArrow} style={{marginLeft:"-10%",marginBottom:"-15%",cursor:"pointer"}}/>
                <Box className={classes.formBox}>
                  <Typography className={classes.arrowStartText} data-test-id='welcome'style={{marginBottom:"10%"}}>
                    <Box component='span' className={classes.arrowEndText}>
                      Tell us your Date of Birth
                    </Box>
                  </Typography>

                  <Box>
                    <Box className={classes.inputContainer}>
                      <Typography className={classes.label}>{configJSON.dateOfBirth}</Typography>
                      <TextField
                        error={this.state.errors.dobError !== ''}
                        data-test-id='dateOfBirth'
                        id="date"
                        autoComplete="off"
                        variant="outlined"
                        placeholder="DD/MM/YYYY"
                        type='text'
                        onChange={this.handleDob}
                        className={this.conditionShort(
                          this.state.errors.dobError !== '', classes.textFieldError,
                          this.conditionShort(this.state.dateOfBirth.length > 0, classes.textFieldColor, classes.textField))}
                      />

                      {this.renderCalendar()}
                    </Box>
                    <Box display={"flex"} alignItems={"center"} >
                      <IcheckBox
                        data-test-id="checkbox"
                        onChange={this.handleCheckBox}
                        checked={this.state.checked}
                        icon={<RadioButtonUncheckedIcon className={classes.icons} />}
                        checkedIcon={<img src={checkboxImage} className={classes.customIcon} />}
                      />
                      <Typography className={classes.agree} >
                        I agree with
                        <Box component="span" data-test-id='terms' className={classes.terms} onClick={this.navigateToTermsAndConditions}>
                          Terms&conditions
                        </Box>
                        <Box component="span" className={classes.agree}>
                          and
                        </Box>
                        <Box component="span" onClick={this.navigateToTermsAndConditions} className={classes.terms}>
                          Privacy Policy
                        </Box>
                      </Typography>
                    </Box>
                    <Box>
                    <Button className={classes.button} fullWidth type='submit'
                      data-test-id='continue'
                      disabled={!(this.state.checked && this.state.errors.dobError==='' && this.state.dateOfBirth!=='')}
                      onClick={this.handleDateOfBirthAPI}
                      endIcon={<StyledIcon><img src={RightIcon} /></StyledIcon>}>
                      Next
                    </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <TermsConditionModal
              data-test-id="termsConditionModal"
              open={this.state.openModal}
              checked={this.props.checked}
              handleClose={this.handleClose}
              handlenext={this.handleTermsAndConditions}
            />
          </Box>
        </Paper>
      </ThemeProvider>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = {
  popup: {
    '& .MuiPaper-rounded': {
      borderRadius: '10px !important',
      border: '1px solid #35383F'
    }
  },
 
  arrowStartText: {
    fontFamily: "Manrope",
    fontSize: "31px",
    fontStyle: "normal" as 'normal',
    fontWeight: 800,
    textAlign: 'left' as "left",
  },

  arrowEndText: {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    fontFamily: "Manrope",
    fontSize: "31px",
    fontStyle: "normal" as 'normal',
    fontWeight: 800,
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    letterSpacing: '-0.02em'
  },

  textField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
        borderWidth: "1px",
        borderColor: "#8833FF"
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal" as 'normal',
      fontWeight: 500,
      height: '24px',
      border: "none",
      borderRadius: '10px'
    },
    "& .MuiOutlinedInput-input": {
      border: 'none',
      padding: "10px 16px",
    },

  },
  textFieldColor: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
        borderWidth: "1px",
        borderColor: "#8833FF",
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
        borderWidth: "1px",
        borderColor: "#8833FF"
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal" as 'normal',
      fontWeight: 500,
      height: '24px',
      borderRadius: '10px'
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 16px",
      border: 'none',
    },

  },
  textFieldError: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderRadius: '10px',
        borderWidth: "1px",
        borderColor: "#f44336"
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "Manrope",
      fontSize: "12px",
      fontStyle: "normal" as 'normal',
      fontWeight: 500,
      height: '24px',
      border: 'none',
      borderRadius: '10px'
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 16px",
    },

  },
 
  button: {
    borderRadius: "10px",
    background: "linear-gradient(134.78deg, #BD02F7 19.96%, #3858E3 109.45%)",
    textTransform: "capitalize" as "capitalize",
    color: "#E9E9E9",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 700,
    marginTop: '8px',
    height: '45px',
    "&.MuiButton-root.Mui-disabled": {
      color: '#E9E9E9',
      opacity: 0.64
    }
  },
  gridContainer: {
    justifyContent: 'space-between' as 'space-between',
    alignItems: 'center',
    height: '90vh',
    paddingTop: "3rem",
    [theme.breakpoints.down('xs')]:
      { justifyContent: 'center' as 'center' }
  },
  label: {
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    textAlign: 'left' as 'left',
    marginBottom: '6px'
  },

  imageGridItem: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: { display: 'none', paddingLeft: '0px' },
    [theme.breakpoints.down('sm')]: { paddingLeft: '0px' },
    height: '80vh',
    paddingLeft: '80px',
  },


  inputContainer: {
    marginBottom: '10px',
    marginTop: '10px'
  },

 
  lightColor: {
    backgroundColor: '#fff'
  },
  darkColor: {
    backgroundColor: '#070707'
  },
  background: {
    backgroundImage: `url(${darkThemeBg})`,
    height: "100vh",
    width: "100%",
    overflow: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'center',
    backgroundAttachment: "scroll",
    [theme.breakpoints.down('xs')]:
      { backgroundImage: 'none' }
  },
  lightBackground: {
    backgroundImage: `url(${lightThemeBg})`,
    height: "100vh",
    width: "100%",
    overflow: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'center',
    backgroundAttachment: "scroll",
    [theme.breakpoints.down('xs')]:
      { backgroundImage: 'none' }
  },
 
  formBox: {
    width: '80%',
    marginTop: '20px'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },

  agree: {
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    cursor: 'pointer',
    marginLeft: '-2px',
  },
  terms: {
    color: "#83F",
    background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    textAlign: "right" as "right",
    WebkitBackgroundClip: "text",
    textDecoration: "underline",
    marginLeft: "6px",
    marginRight: "6px",
  },
};
export const DateOfBirth = withStyles(styles)(DOB)
// Customizable Area End