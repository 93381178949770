 // Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked:any;
  classes:any;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  theme:string;
  audience:any;
  title:any;
  currentStep:number;
  stremUrl:any;
  stremKey:any;
  selectedAudience:any;
  
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LiveStreaming1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      theme:'lightTheme',
      audience:["Everyone","Followers","Paid Subscriber"],
      title:"",
      currentStep:1,
      stremUrl:"rtmps://edgetee-upload-bom1-1.xx.fbcdn.net:443/rtmp/",
      stremKey:"17924446292910268?s_bl=1&s_fbp=pnq1-1&s_prp=bom1-1&s_sw=08",
      selectedAudience:"Everyone"
     
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
  handleAudience =(event:any) =>{    
    this.setState({selectedAudience:event.target.value})  

  }
  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState(({
      [name]: value
    } as unknown) as Pick<S, keyof S>);

  };
  handleNext = () => {
    this.setState({currentStep:2})
  };
  // Customizable Area End
}


// Customizable Area End
