Object.defineProperty(exports, "__esModule", {
  value: true,
});

//APi Methods
exports.apiMethod = "GET";
exports.apiContentType = "application/json";
exports.apiEndPoint = "activity_feed/activity_feeds";
exports.apiContentType = "application/json";

// Customizable Area Start
exports.loadLabel = "Load more...";
exports.headerLabel = "Recent Activity";
exports.done = "Done";
exports.feedEndpoint ="bx_block_posts/posts"
exports.savePostEndpoint ="bx_block_posts/save_post"
exports.unSavePostEndpoint ="bx_block_posts/unsave_post"
exports.likePostEndpoint ="bx_block_like/likes"
exports.followEndpoint ="bx_block_followers/follows"
exports.suggestionsEndpoint ="account_block/tailored_accounts/suggestions"
exports.apiFormContentType = "multipart/form-data";
exports.apiPostMethod = "Post";
exports.apiDeleteMethod ="Delete"
exports.apipatchMethod="Patch"
exports.contentType="application/json"
exports.tailoredMemberEndpoint ="account_block/tailored_accounts" 
exports.muteUserEndpoint = "bx_block_followers/mute_account"
exports.loggedUserEndpoint ="account_block/accounts/logged_user"
exports.unmuteUserEndpoint = "bx_block_followers/unmute_account"
exports.blockAccountEndpoint = "bx_block_followers/block_account"
exports.getCommentSetting="bx_block_comments/comment_settings"
exports.postaActivityEndpoint = "bx_block_posts/post_activity"
exports.pinPostEndPoint="bx_block_posts/pin_post";
exports.unpinPostEndPoint="bx_block_posts/unpin_post";
exports.reviewPostEndPoint="bx_block_reviewprompt2/reviews"
exports.reviewGetEndPoint="bx_block_reviewprompt2/reviews/check_review"
exports.Follow="Follow"
exports.UnFollow="UnFollow"
exports.Block="Block"
exports.viewPostEngagement="View post engagement"
exports.embedPost="Embed post"
exports.reportPost="Report post"
exports.unpinProfile="Unpin to profile"
exports.pinProfile="Pin to profile"
exports.deletePost="Delete Post"
exports.changeReply="Change who can reply"

exports.cancel="cancel"
exports.Unpin="Unpin"
exports.Pin="Pin"
exports.unpinnedPostTitle="Pin this post"
exports.pinnedPostTitle="Unpin post from your profile"
exports.unpinnedDescription="This will appear at the top of your profile and replace any previously pinned post."
exports.pinnedDescription="This will no longer appear automatically at the top of your profile."

exports.Delete="Delete"
exports.DeleteModalDescription="This can’t be undone and it will be removed from your profile.the timeline of any accounts that follow you and from search results."
exports.DeletePostTitle="Delete Post"

exports.whoCanReply="Who can reply?"
exports.EveryOne="Everyone"
exports.AccoutsFollows="Accounts you follow"
exports.AccoutsMentions="Only accounts you mention"
exports.noOne="No one"
exports.Submit = "Submit"
exports.everyonePermission="every_one"
exports.AccoutsFollowsPermission="accounts_you_follw"
exports.AccoutsMentionsPermission="accounts_you_mention"
// Customizable Area End
