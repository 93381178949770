import { Dialog, Grid, IconButton, Typography, withStyles,Button, styled } from '@material-ui/core';
import React from 'react';
const darkArrowIcon = require('./assets/leftIconDark.png')
const RightIcon = require('./assets/RightIcon.svg')
export const logo = require("./assets/logo.png");
const StyledIcon = styled(IconButton)({
    padding:0,
    marginLeft:-4,
    "&.MuiIconButton-root:hover":{
     background:'unset'
    }
  })
const DialogModal = (props: any) => {
    const classes = props.classes || {}
    const conditionFilter = (condition: any, trueStatement: any, falseStatement: any) => {
        return condition ? trueStatement : falseStatement
    } 
    const getActiveSub = props.getActiveSub(props.activeSubId) || {attributes:{}}

    const handleNavigation =()=>{
        props.navigation.navigate('Profile',{navigationBarTitleText:getActiveSub.attributes.account_id})
      }
    return (
        <Dialog
            open={props.isOpen}
            onClose={() => { }}
            PaperProps={{
                className: conditionFilter(!props.checked, classes.modelDarkStyle, classes.modelLightStyle)
            }}
            classes={{ paperWidthSm: conditionFilter(!props.checked, classes.modelDarkStyle, classes.modelLightStyle) }}
            BackdropProps={{ className: conditionFilter(!props.checked, classes.backdropsStyleDark, classes.backdropsStyle) }}
        >
            <Grid container justifyContent="center" alignItems="center" direction="column" style={{
                padding: "50px 60px",
            }}>
                <IconButton style={{
                    color: "black",
                    height: "44px",
                    width: "40px",
                    borderRadius: "8px",
                    padding: "10px",
                    marginBottom: "-55px",
                    marginLeft: "-370px"
                }} disableRipple disableFocusRipple disableTouchRipple data-test-id="backBtn2"
                    onClick={() => { }}>
                    <img src={darkArrowIcon} alt="" />
                </IconButton>
                <img src={logo} className={classes.IconsStyle} alt="" />
                <Typography style={{
                    fontStyle: 'normal',
                    fontWeight: 800,
                    lineHeight: '32px',
                    letterSpacing: '-0.414px',
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    fontFamily: 'Manrope',
                    fontSize: "24px",
                    width: "327px",
                    textAlign: "center",
                    background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #191919 ,#505050)",
                    color:'white'
                }}>
                    Subscription Activated        </Typography>
                    <Typography
                    style={{
                        textAlign: "center",
                        fontFamily: 'Manrope',
                        fontSize: "16px",
                        fontWeight: 600,
                        color:'white'
                    }}>
                    Congratulations, you have successfully activated {getActiveSub?.attributes?.title} access to all the features of the Paid content Enjoy!
                    </Typography>
                    <Button
                  
                    data-test-id='submit'
                    disabled={props.activeId === 0}
                    className={classes.button}
                    fullWidth
                    endIcon={<StyledIcon><img src={RightIcon} /></StyledIcon>}
                onClick={handleNavigation}
                >Go to Dashboard
                </Button>
            </Grid>
        </Dialog>
    )
}

const styles = {
    IconsStyle: {
        margin: "16px",
        width: "54px",
        height: "53px",
    },
    modelDarkStyle: {
        borderRadius: "10px",
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
        border: "1px solid transparent",
        backdropFilter: "blur(1 px)",
        opacity: "0.88",
        background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #191919 ,#505050)",
        boxShadow: "none"
    },
    modelLightStyle: {
        borderRadius: "10px",
        boxShadow: "none",
        border: "1px solid transparent",
        background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #dfd6d6 ,#505050)",
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
        opacity: "0.82",
        backdropFilter: "blur(1 px)",
    },
    button: {
        borderRadius: "10px",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        textTransform: "capitalize" as "capitalize",
        color: "#FFF",
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        marginTop: '35px',
        height: '46px',
        "&.MuiButton-root.Mui-disabled": {
          color: '#E9E9E9',
          opacity:0.64
        }
      }
}
export const CongratsDialogModal = withStyles(styles)(DialogModal)