Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";

exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";

exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getUserInfo="account_block/accounts/logged_user"; 
exports.getRefreshedToen="bx_block_login/refresh_token"; 
exports.getMultiUsers="account_block/accounts/logged_users"; 
exports.getApiMethodType = "GET";
exports.no='No';
exports.remove='Remove now';
exports.removeText='Your account has been remove.';
exports.isRemove='Remove  ';
exports.confirmRemove='Are you sure you want to remove ';
exports.editProfile='Edit profile';
// Customizable Area End