import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {setStorageData, getStorageData} from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: { [key: string]: string };
  checked?:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  categoriesData:{id:number,isSelected:boolean,name:string,rank:number}[];
  isScreenChanged:boolean,
  isButtonVisible:boolean,
  subCategoryArray:{id:string,type:string,attributes:{id:number,name:string,rank:number,is_selected:boolean,sub_categories:{name:string,rank:number,id:number,is_selected:boolean}[]}}[]
  subCategoryIds:number[],
  isSubCategoryButtonSelected:boolean,
  categoryIds:number[],
  categoriesResponse:string,
  authToken:string,
  apiError:string,
  isLoading:boolean,
  subCategoryDisabled:boolean,
  activeState:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start  
  id: any;
  // Customizable Area End
}

export default class CategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
   // Customizable Area Start 
   categoriesApiCallId:string='';
   subCategoriesApiCallId:string='';
   submitCategoriesApiCallId:string='';
   submitCategoriesSubCategoriesApiId:string='';
   submiStateCategoryApi:string='';
   submiStateSubCategoryApi:string='';
   // Customizable Area End 
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.OTPScreenMessage),
      getName(MessageEnum.NavigationToOtpAuthPage)
    ];

    this.state = {
      categoriesData:[],
      isScreenChanged:false,
      isButtonVisible:true,
      subCategoryArray:[],
      subCategoryIds:[],
      isSubCategoryButtonSelected:false,
      categoryIds:[],
      categoriesResponse:'',
      authToken:'',
      apiError:'',
      isLoading:true,
      subCategoryDisabled:false,
      activeState:''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if(message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const data = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
      if(data.active==='sub'){
        this.setState({isScreenChanged:true},()=>setStorageData('selection','subcategory')) 
      }
    }
    
    this.categoriesApi(message)
 
    this.subCategoryApi(message)

   this.submitCategoriesApi(message)

   this.submitStateCategoryResponse(message)

   this.submitSubcategoryStateResponse(message)


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.submitCategoriesSubCategoriesApiId) {
            this.setState({categoriesResponse:responseJson.messages[0]},()=>{
              this.navigateToSubscriptionState()
            })
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else{
        this.setState({apiError:configJSON.noTerms})
      }
    }
    runEngine.debugLog("Message Recived", message);
    
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    this.redirectScreen()
    this.getCategories()
    
  }
  redirectScreen=async()=>{
    const screen=await getStorageData('selection')
    if(screen==='category'){
      this.setState({isScreenChanged:false}) 
    }
    else if(screen==='subcategory'){
      this.setState({isScreenChanged:true}) 
    }
  }
  getCategories=async()=>{
    const token=await getStorageData("authToken")
    const result=await getStorageData("subCategories")
    const subCategories=JSON.parse(result)??[]
    this.setState({authToken:token,subCategoryArray:subCategories},this.disabledButton)
    const header = {
      token:token
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.categoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoriesApiEndpoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.categoriesApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  submitCategoriesApi=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.submitCategoriesApiCallId) {
            this.setState({categoriesResponse:responseJson.messages[0]})
            this.navigateToSubscription()
            this.parseApiCatchErrorResponse(errorReponse);
        }
      }else{
        this.setState({apiError:configJSON.noTerms})
      }
    }
  }

  categoriesApi=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.setState({isLoading:false})
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.categoriesApiCallId) {
          if (responseJson.data) {
          const response=  responseJson.data.map((each:{attributes:{id:number,name:string,rank:number,is_selected:boolean}})=> 
          ({id:each.attributes.id,
            name:each.attributes.name,
            rank:each.attributes.rank,
            isSelected:each.attributes.is_selected}))
         
          this.setState({categoriesData:response},this.makeButtonVisible)
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }else{
        this.setState({apiError:configJSON.noTerms})
      }
    }
  }

  subCategoryApi=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.subCategoriesApiCallId) {
          this.setState({subCategoryArray:responseJson.data
          },this.disabledButton)
          this.gotoSubcategories() 
          setStorageData('subCategories',JSON.stringify(responseJson.data))
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else{
        this.setState({apiError:configJSON.noTerms})
      }
    }
  }
  
  gotoSubcategories=async()=>{
    const formData = new FormData();
    formData.append("signup_state",'select_sub_category')
    const token = await getStorageData("authToken");

    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submiStateCategoryApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadPictureApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadPictureApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  submitStateCategoryResponse=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.submiStateCategoryApi) {
        if (responseJson && !responseJson.errors) {
          this.setState({isScreenChanged:true},()=>setStorageData('selection','subcategory'))
        }
        } else {
          if (responseJson.errors) {
           this.setState({activeState:'invalid token'})

          }
        }
      }
    }
  

  handleSelectCategory=(checkId:number)=>{
  let result=this.state.categoriesData.map((each)=>{
    if(each.id===checkId){
      return {...each,isSelected:!each.isSelected}
    }
    else{
      return each
    }
  })
  this.setState({categoriesData:result,categoryIds:[...this.state.categoryIds,checkId]},()=>{
    this.makeButtonVisible()
  })
  }
  makeButtonVisible=()=>{
  const result=this.state.categoriesData.filter((each)=>each.isSelected===true).map((each)=>each.id)  
  if (result.length>2){
    this.setState({isButtonVisible:false})
  }else{
    this.setState({isButtonVisible:true})
  }
 }

  navigateToCategoryScreen=()=>{
    this.makeButtonVisible()
    this.setState({isScreenChanged:false},()=>setStorageData('selection','category'))
  }

  handleSubmitCategories=()=>{
    const result=this.state.categoriesData.filter((each)=>each.isSelected===true).map((each)=>each.id)
    const query=result.join(",")
    const header = {
      token:this.state.authToken
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.subCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.subCategoryApiEndpoint}+${query}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.categoriesApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

 disabledButton=()=>{
  let updatedSubCategoryArray:Array<{name:string,rank:number,id:number,is_selected:boolean}> =[]
      this.state.subCategoryArray.forEach(category => {
      category.attributes.sub_categories.forEach(subCategory => {
        if (subCategory.is_selected) {
          updatedSubCategoryArray.push(subCategory);
        }
      });
     })
  if(updatedSubCategoryArray.length>0){
     this.setState({
      subCategoryDisabled:false
     })
  }
  else{
    this.setState({
      subCategoryDisabled:true
     })
  }
 }

  selectSubCategories=(validateId:number)=>{
    const {subCategoryIds}=this.state
    let updatedSubCategoryArray = this.state.subCategoryArray.map((category) => {
      return {
          ...category,
          attributes: {
              ...category.attributes,
              sub_categories: category.attributes.sub_categories.map((subCategory) => {
                  if (subCategory.id === validateId) {
                      return { ...subCategory, is_selected: !subCategory.is_selected };
                  } else {
                      return subCategory;
                  }
              })
          }
      };
  });

  this.setState({subCategoryArray:updatedSubCategoryArray,subCategoryIds:[...subCategoryIds,validateId]},this.disabledButton)
  }

  handleSubmitCategoriesSubCategories=()=>{
    const details={selected_ids: {category_ids:this.state.categoryIds,sub_category_ids:this.state.subCategoryIds}}
    const httpBody = details

    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
      token:this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submitCategoriesSubCategoriesApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitCategoriesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

  submitCategories=()=>{
    const details={selected_ids: {category_ids:this.state.categoryIds}}
    const httpBody = details

    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
      token:this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submitCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitCategoriesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateToProfileScreen=()=>{
    const addMessage: Message = new Message(getName(MessageEnum.NavigationToOtpAuthPage));
    addMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    addMessage.addData(getName(MessageEnum.OTPScreenMessage),3);
    this.send(addMessage);
  }

  navigateToSubscription=()=>{
    this.navigateToSubscriptionState()
  }
  
  skipSubCategory=()=>{
    this.navigateToSubscription()
  }
  navigateToSubscriptionState=async()=>{
    const formData = new FormData();
    formData.append("signup_state",'choose_subscription')
    const token = await getStorageData("authToken");

    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submiStateSubCategoryApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadPictureApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadPictureApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  submitSubcategoryStateResponse=async(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.submiStateSubCategoryApi) {
        if (responseJson && !responseJson.errors) {
            const message: Message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            message.addData(
              getName(MessageEnum.NavigationTargetMessage),
              "Customisableusersubscriptions"
            );
            this.send(message);  
        }
        } else {
          if (responseJson.errors) {
            this.setState({activeState:'invalid token'})
          }
        }
      }
    
  }
  // Customizable Area End
}
