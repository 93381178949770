import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {setStorageData, getStorageData } from "../../../framework/src/Utilities";
import { Platform } from "react-native";
import React,{ ReactNode } from "react";
import {verifyIcon,verifyOrange,verifyYellow,ownerShipIcon,imgPasswordVisible} from "./assets"
import {
IResolveParams
} from 'reactjs-social-login';
export interface DataListItem {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    email: string;
    is_follow: string;
  };
}

export interface ISocialBtn{ 
  Twitter:boolean,
  Instagram:boolean,
  Facebook:boolean
}
export interface DataListItemTwo {
  id: string;
  attributes: {
    account_email: string;
    email: string;
    current_user_id: string;
    account_id: string;
  };
}

export interface DataListItemThree {
  id: string;
  attributes: {
    account_email: string;
    email: string;
    current_user_id: string;
    account_id: string;
    user_name: string;
    is_follow: boolean;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:{[key:string]:string}
  children?:ReactNode
  checked:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabPanelNo: number;
  listData: DataListItem[];
  followingData: DataListItem[];
  openModal: boolean;
  errorMsg: string;
  openFollowerModel: boolean;
  postsCount: number;
  followersCount: string;
  userList: boolean;
  token: string;
  followerList: boolean;
  loder: boolean;
  followingList: boolean;
  searchTerm: string;
  profilePhoto: string;
  showFollowersScreen: boolean;
  userListData: DataListItemThree[];
  userFollowerListData: DataListItemTwo[];
  userFollowingListData: DataListItemTwo[];
  isModalOpen:boolean,
  modelData:{type:string},
  isFollowers:boolean,
  followers:{id:number,name:string,description:string,followers:string ,isfollowing:boolean,profilePhoto:string|null,subscription:string|null,ownership:boolean}[]
  userToken:string,
  unFollowMessage:string,
  followMessage:string,
  isEnabled:boolean,
  facebookResponse:{},
  provider:string,
  instgramData:{}|undefined,
  error:{},
  fbProvider:string,
  fbData:{}|undefined,
  isLogin:boolean
  isContinue:ISocialBtn,
  instagramToken:string|null
  twitterProvider:string
  twitterData:{}|undefined
  twitterError:string
  twitterToken:string
  activeState:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class FollowersController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  followerCallId: string = "";
  userFollowerCallID: string = "";
  userFollowingCallID: string = "";
  addFollowerFromFollowingCallId: string = "";
  unFollowFromFollowingCallId: string = "";
  followersListCallId:string="";
  followCallId:string="";
  unFallowCallId:string="";
  submitMediaStatusApi:string='';
  submitFollowStatusApi:string='';
  socialLoginApi:string='';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      tabPanelNo: 0,
      listData: [],
      followingData: [],
      openModal: false,
      errorMsg: "",
      searchTerm: "",
      showFollowersScreen: true,
      userList: true,
      followerList: false,
      followingList: false,
      loder: false,
      userFollowerListData: [],
      userListData: [],
      userFollowingListData: [],
      followersCount: imgPasswordVisible,
      token: "",
      postsCount: 20,
      profilePhoto: "https://via.placeholder.com/150",
      openFollowerModel: false,
      isModalOpen:false,
      modelData:{type:""},
      isFollowers:false,
      followers :[],
      userToken:'',
      followMessage:'',
      unFollowMessage:'',
      isEnabled:false,
      facebookResponse:{},
      provider:'',
      instgramData:{},
      error:{},
      fbData:{},
      fbProvider:'',
      isLogin:false,
      isContinue:{
        Facebook:false,
        Instagram:false,
        Twitter:false
      },
      instagramToken:'',
      twitterProvider:'',
      twitterData:{},
      twitterError:'',
      twitterToken:'',
      activeState:''
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const urlParams = new URLSearchParams(window.location.search);
    const codeParam = urlParams.get('code');
    if(codeParam){
      this.setState({instagramToken:codeParam, isModalOpen: false},()=>
        this.submitSocialMediaStatus())
    }
    await this.getToken();
    setTimeout(async () => {
      await this.getListOfSuggestionData();
      await this.getListOfSuggestionDataFollowers();
      await this.getListOfSuggestionDataFollowing();
    }, 0);
    this.getUserToken()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if(message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const data = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
      if(data.active==='follow')
      this.setState({isFollowers:true
      }) 
    }
    this.handleResponseForSuggestion(from, message);
    this.handleResForUnFollowFromFollowing(from, message);
    this.handleResponseForAddFromFollowing(from, message);
    this.handleResponseForSuggestionFollowers(from, message);
    this.handleResponseForSuggestionFollowing(from, message);
    this.getUsersListApi(from, message);
    this.followUserApi(from, message);
    this.unfollowUserApi(from, message);
    this.showMediaStatus(message);
    this.showFollowStatus(message)
    this.showSocialLogin(message)
    // Customizable Area End
  }

  // Customizable Area Start
  getToken = async () => {
    const token: string =
      Platform.OS == "android" || Platform.OS == "ios"
        ? await getStorageData("token-access")
        : localStorage.getItem("authToken");
    this.setState({ token: token });
  };

  getListOfSuggestionData = async () => {
    this.setState({ loder: true });
    const token = await this.state.token;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const followerDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.followerCallId = followerDataMessage.messageId;

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userListEndPoint
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(followerDataMessage.id, followerDataMessage);
  };

  getListOfSuggestionDataFollowers = async () => {
    const token = await this.state.token;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const followerDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userFollowerCallID = followerDataMessage.messageId;

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userListFollowersEndPoint
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(followerDataMessage.id, followerDataMessage);
  };

  getListOfSuggestionDataFollowing = async () => {
    const token = await this.state.token;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const followerDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userFollowingCallID = followerDataMessage.messageId;

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userListFollowingEndPoint
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(followerDataMessage.id, followerDataMessage);
  };

  addFromFollowing = async (account_id: string) => {
    this.setState({ loder: true });
    const token = await this.state.token;
    let headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    let httpBody = {};
    httpBody = {
      data: {
        attributes: {
          account_id: account_id,
        },
      },
    };
    const addFollowerDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addFollowerFromFollowingCallId = addFollowerDataMessage.messageId;

    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addFollowingApiEndPoint}`
    );

    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${configJSON.postApiMethodType}`
    );

    runEngine.sendMessage(addFollowerDataMessage.id, addFollowerDataMessage);
  };

  unFollowFromFollowing = async (account_id: string) => {
    this.setState({ loder: true });
    const token = await this.state.token;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const unFollowDataUserMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unFollowFromFollowingCallId = unFollowDataUserMessage.messageId;

    unFollowDataUserMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unFollowApisApiEndPoint}/${account_id}`
    );

    unFollowDataUserMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    unFollowDataUserMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${configJSON.deleteApiMethodType}`
    );

    runEngine.sendMessage(unFollowDataUserMessage.id, unFollowDataUserMessage);
  };

  followingOnPress = () => {
    this.setState({
      userList: false,
      followerList: false,
      followingList: true,
    });
  };

  followerListOnPress = () => {
    this.setState({
      userList: false,
      followerList: true,
      followingList: false,
    });
  };

  userListOnPress = () => {
    this.setState({
      userList: true,
      followerList: false,
      followingList: false,
    });
  };

  handleResponseForSuggestion = (from: string, message: Message) => {
    if (
      this.followerCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loder: false });
      if (apiResponse.errors) {
        this.setState({ errorMsg: apiResponse.errors });
      } else {
        this.setState({ userListData: apiResponse.data });
      }
    }
  };
  handleResponseForSuggestionFollowers = (from: string, message: Message) => {
    if (
      this.userFollowerCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loder: false });

      if (apiResponse.errors) {
        this.setState({ errorMsg: apiResponse.errors });
        if (apiResponse.errors[0].message == "Not following to any user.") {
          this.setState({ userFollowerListData: [] });
        }
      } else {
        this.setState({ userFollowerListData: apiResponse.data });
      }
    }
  };
  handleResponseForSuggestionFollowing = (from: string, message: Message) => {
    if (
      this.userFollowingCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loder: false });

      if (apiResponse.errors) {
        this.setState({ errorMsg: apiResponse.errors });
      } else {
        this.setState({ userFollowingListData: apiResponse.data });
      }
    }
  };
  handleResponseForAddFromFollowing = async (
    from: string,
    message: Message
  ) => {
    if (
      this.addFollowerFromFollowingCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse.errors) {
        this.setState({ errorMsg: apiResponse.errors });
      } else {
        await this.getListOfSuggestionData();
        await this.getListOfSuggestionDataFollowers();
        await this.getListOfSuggestionDataFollowing();
      }
    }
  };
  handleResForUnFollowFromFollowing = async (
    from: string,
    message: Message
  ) => {
    if (
      this.unFollowFromFollowingCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse.errors) {
        this.setState({ errorMsg: apiResponse.errors });
      } else {
        await this.getListOfSuggestionData();
        await this.getListOfSuggestionDataFollowers();
        await this.getListOfSuggestionDataFollowing();
      }
    }
  };

  //for Web File
  hanleTabs = (event: object, value: number) => {
    this.setState({ tabPanelNo: value });
  };

  handleContinue=()=>{
    this.setState({isModalOpen:true})
  }
 

  handleSocialMediaModel=(type:"Twitter"|"Instagram"|"Facebook")=>{
   const object = {
    Twitter:false,
    Instagram:false,
    Facebook:false,
    [type]:!this.state.isContinue[type]
    }

    this.setState(({
      modelData:{type:type},
      isContinue:object
    }))
  }


  renderVerifyIcon= (val:{user_subscription:string|null}) =>{
    switch (val.user_subscription) {
      case 'I am an individual':
        return <img src={verifyIcon} alt="" height="13.3" width="13.3"/>;
      case 'I am a business':
        return <img src={verifyYellow} alt=""height="13.3" width="13.3" />;
      case 'I am a news organization':
        return <img src={verifyOrange} alt="" height="13.3" width="13.3" />;   
      case null:
        return ""               
   } 
}

renderOwnershipIcon= (val:{user_ownership:boolean}) =>{
  if(val.user_ownership ){
        return <img src={ownerShipIcon} alt="" height="13.3" width="13.3"/>;         
    }       
}

  handleModel =(type:string) =>{
    this.setState({modelData:{type:type},isModalOpen:true})
    this.submitFollowStatus()
  }

  submitFollowStatus=async()=>{
    const formData = new FormData();
    formData.append("signup_state",'completed')
    const token = await getStorageData("authToken");

    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submitFollowStatusApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadPictureApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadPictureApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFollow =(id:string) =>{
    let foloowers = [...this.state.followers]
    let newFollowers = foloowers.map((item:any)=>
      item.id == id
          ? { ...item, isfollowing: !item.isfollowing }
          : item       
    )    
    this.setState({followers:newFollowers},()=>{
      this.enableButton()
    })
   
    let headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.userToken,
    };
    let httpBody = {
      account_id:id
    }
    
    const addFollowerDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.followCallId = addFollowerDataMessage.messageId;

    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followApiEndpoint
    );

    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    runEngine.sendMessage(addFollowerDataMessage.id, addFollowerDataMessage);


  }

  enableButton=()=>{
    let result=this.state.followers.filter((each)=>each.isfollowing===true)
    if(result.length>0){
      this.setState({isEnabled:true})
    }else{
      this.setState({isEnabled:false}) 
    }
  }

  handleFollowing=(userId:number)=>{
    let followers = [...this.state.followers]
    let newFollowers = followers.map((item)=>
      item.id === userId
          ? { ...item, isfollowing: !item.isfollowing }
          : item       
    )    
    this.setState({followers:newFollowers},()=>{
      this.enableButton()
    })

    let headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.userToken,
    };
    let httpBody = {
      account_id:userId
    }
    
    const addFollowerDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unFallowCallId = addFollowerDataMessage.messageId;

    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unfollowUserEndpoint
    );

    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(addFollowerDataMessage.id, addFollowerDataMessage);
     

  }

  getUserToken=async()=>{
    const userToken=await getStorageData('authToken')
    this.setState({userToken:userToken},()=>{
      this.getListOfUsers()
    })

  } 

  unfollowUserApi=(from: string, message: Message)=>{
    if (
      this.unFallowCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.errors) {
        this.setState({ errorMsg: apiResponse.errors });
      } else {
        this.setState({unFollowMessage:apiResponse.messages[0]}) 
      }
    }

  }

  followUserApi=(from: string, message: Message)=>{
    if (
      this.followCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.errors) {
        this.setState({ errorMsg: apiResponse.errors });
      } else {
        this.setState({followMessage:apiResponse.messages[0]}) 
      }
    }

  }

  getUsersListApi=(from: string, message: Message)=>{
    if (
      this.followersListCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.errors) {
        this.setState({ errorMsg: apiResponse.errors });
      } else {
        const result=apiResponse.data.map((each:{attributes:{id:number,full_name:string,user_name:string,is_following:boolean,profile_photo:string|null,followers_count:number,user_subscription:string|null,user_ownership:boolean}})=>
        ({id:each.attributes.id,
          name:each.attributes.full_name,
          description:each.attributes.user_name,
          subscription:each.attributes.user_subscription,
          ownership:each.attributes.user_ownership,
          followers:each.attributes.followers_count ,
          isfollowing:each.attributes.is_following,
          profilePhoto:each.attributes.profile_photo}))
        this.setState({followers:result},()=>{
          this.enableButton()
        })  
      }
    }
  }


  getFollowers=(followNumber:number)=>{
    if(followNumber>0){
      return `${followNumber??0} Followers`
    }else{
      return `${followNumber} Follower`
    }
  }

  getListOfUsers=()=>{
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token:this.state.userToken,
    };
    const followerDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.followersListCallId = followerDataMessage.messageId;

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followersListEndPoint
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(followerDataMessage.id, followerDataMessage);

  }

  responseFacebook = (response:{}) => {
 this.setState({facebookResponse:response, isModalOpen: false},()=>{
  this.submitSocialMediaStatus()
 })
  }
  submitSocialMediaStatus=async()=>{
    const formData = new FormData();
    formData.append("signup_state",'follow_account')
    const token = await getStorageData("authToken");

    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submitMediaStatusApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadPictureApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadPictureApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  showMediaStatus=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.submitMediaStatusApi) {
        this.setState({isFollowers: true})
      } else {
        if (responseJson?.errors) {
          this.setState({activeState:'invalid token'})
        }
      }
    }
  }

  showFollowStatus=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.submitFollowStatusApi) {
        if (responseJson && !responseJson.errors) {
          this.setState({activeState:'profile updated'})
          this.parseApiCatchErrorResponse(errorReponse);
        } else {
          if (responseJson.errors) {
            this.setState({activeState:'invalid token'})
          }
        }
      }
    }
  }

 onLoginStart=()=>{
    this.setState({isLogin:true})
 }

 setUserData=(provider:string,data:{}|undefined)=>{
  this.setState({provider:provider,instgramData:data})
}

 setError=(err:{})=>{
   this.setState({error:err})
 }

 onLoginStartFacebook=()=>{
  this.setState({isLogin:true})
 }

 setFacebookUserData=(info:IResolveParams)=>{
  this.setState({fbProvider:info.provider,fbData:info.data})
 }

 handleSkip=()=>{
  this.submitSocialMediaStatus()
 }

 navigateToOwner=()=>{
  if(this.state.isFollowers===false){
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BecomeOwner"
    );
    this.send(message);
  }
  else{
    this.setState({isFollowers:false})
  }
  
 }
  
 navigateToDashboard=()=>{
  const accountUser='dashboard'  
  setStorageData("userAccount",accountUser)
  const message: Message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "LandingPage"
  );
  this.send(message);
 }


 handleTwitterLogin=()=>{
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token:this.state.token,
  };

  const getMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.socialLoginApi = getMsg.messageId;

  getMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.twitterEndPoint
  );

  getMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  getMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(getMsg.id, getMsg);
  }

  showSocialLogin=(message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      console.log(responseJson,'twitter before')
      if (apiRequestCallId === this.socialLoginApi) {
        if (responseJson && !responseJson.errors) {
         console.log(responseJson,'twiiter after')
         window.location.href = responseJson.url
        } 
        else {
          if (responseJson?.errors) {
            console.log(responseJson?.errors,'twitter error')
            this.setState({activeState:'invalid token'})
          }
        }
      }
    }

  }
 

  // Customizable Area End
}
