import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";

// Customizable Area Start
import {appleDarkLogo,appleLogo} from "./assets";
import {
  IResolveParams,
  LoginSocialApple
} from 'reactjs-social-login'
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import {Avatar} from "@material-ui/core";

// Customizable Area End

import AppleLoginController, {
  Props,
  configJSON,
} from "./AppleLoginController.web";

export default class AppleLogin extends AppleLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      
        <LoginSocialApple
            data-test-id='apple'
            client_id={process.env.REACT_APP_APPLE_ID||''}
            scope={'name email'}
            redirect_uri={process.env.REACT_APP_APPLE_URL||''}
            onResolve={({ provider, data }: IResolveParams) => {
              this.setAppleLoginData( provider, data)  
            }}
            onReject={err => {
             this.setAppleLoginError(err)
            }}
          >
            {this.props.hideButton ?<Avatar style={webStyle.avatar}>
                      <img src={appleLogo} />
                    </Avatar> :<Button color="primary" variant="outlined" startIcon={<img src={this.props.checked ? appleDarkLogo : appleLogo} alt="appleLogo" />} style={webStyle.iconButton}>{this.props.buttonTitle}</Button>}
          </LoginSocialApple>
     
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  iconButton:{
    marginTop: "1.2rem",
    border:'1px solid #8833FF',
    paddingTop:'22px',
    paddingBottom:'22px',
    height:56,
    fontSize:'14px',
    borderRadius:"8px",
    textTransform:'inherit' as 'inherit',
    fontWeight:700,
    fontFamily:'Manrope',
    width:"100%"
  },
  avatar: {
    borderRadius: "10px",
    background: "linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    cursor:"pointer"
  },
  container:{
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    maxWidth: "420px",
    width: '100%'
  }
};
// Customizable Area End
