import React from 'react';
import {
    Box,
    Dialog,
    Grid,
    Typography,
    Button,
    styled,
    ThemeProvider,
    createTheme,
} from '@material-ui/core';
import { handleConditionFunction } from '../utils';
import clsx from 'clsx';

interface DeleteProps {
    checked: boolean;
    isOpen: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

const DeleteMessageModal: React.FC<DeleteProps> = (props) => {

    const renderModal = () => (
        <DialogBox
            data-test-id="clearChatModel"
            onClose={() => props.onClose()}
            open={props.isOpen}
            PaperProps={{
                className: handleConditionFunction(
                    props.checked,
                    'modelDarkStyle',
                    'modelLightStyle'
                ),
            }}
        >
            <GridContainer container justifyContent="center" alignItems="center" direction="column" style={{backgroundColor: !props.checked ? "#FFFFFF" : "0B0B0B"}}>
                <Box style={{ paddingBottom: 12, borderBottom: "1px solid #222222", width: "100%", textAlign: "center" }}>
                    <Typography className={`headerText ${clsx(!props.checked && "lightThemeTextColor")}`}>Delete Message?</Typography>
                </Box>
                <Box>
                    <Typography className={`subhead ${clsx(!props.checked && "lightThemeTextColor")}`}>
                        Are you sure you want to  <br />
                        delete message?
                    </Typography>
                </Box>

                <Box className="deleteAction">
                    <Button
                        onClick={() => props.onClose()}
                        data-test-id="cancelBtn"
                        className={handleConditionFunction(!props.checked, "deleteBtnDeny", "deleteBtnDenyDark")}
                    >
                        <Typography className="cancelText">Cancel</Typography>
                    </Button>
                    <Button
                        onClick={() => props.onSubmit()}
                        data-test-id="deleteBtn"
                        className="deleteBtn"
                    >
                        Delete
                    </Button>
                </Box>
            </GridContainer>
        </DialogBox>
    );

    return (
        <ThemeProvider theme={!props.checked ? commonLightTheme : commonDarkTheme}>
            <BoxContainer>
                {renderModal()}
            </BoxContainer>
        </ThemeProvider>
    );
};

// Styled Components
const BoxContainer = styled(Box)({
});

const DialogBox = styled(Dialog)({
    '& .bottomModelDarkStyle': {
        borderRadius: '6px',
        backdropFilter: 'blur(1px)',
        boxShadow: 'none',
        position: 'absolute',
        top: '80%',
    },
    '& .bottomModelStyle': {
        borderRadius: '6px',
        backdropFilter: 'blur(1px)',
        boxShadow: 'none',
        position: 'absolute',
        top: '80%',
    },
    '& .modelDarkStyle': {
        borderRadius: '10px',
        backdropFilter: 'blur(1px)',
        background: 'linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)',
        boxShadow: 'none',
    },
    '& .modelLightStyle': {
        borderRadius: '10px',
        backdropFilter: 'blur(1px)',
        background: 'linear-gradient(to right, #fff, #fff),linear-gradient(to right, #35383F ,#35383F)',
        boxShadow: 'none',
    },
});

const GridContainer = styled(Grid)({
    padding: 20,
    rowGap: '24px',
    width: '375px',
    border: "1px solid #35383F",
    borderRadius: 10,
    background: "#0B0B0B",
    "& .lightThemeTextColor": {
        color: "#222222!important"
    },
    '& .headerText': {
        fontSize: '16px',
        fontFamily: 'Manrope',
        fontWeight: 600,
        lineHeight: '19px',
        cursor: 'pointer',
        color: "#FFFFFF",
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    '& .subhead': {
        fontFamily: 'Manrope',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: "21px",
        textAlign: 'center',
        color: "#FFFFFF"
    },
    '& .deleteBtn': {
        boxShadow: 'none !important',
        background: '#EF4444',
        borderRadius: '10px',
        padding: '16px',
        width: '160px',
        height: '48px',
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: 700,
        fontFamily: "Manrope",
        textTransform: "capitalize"
    },
    '& .deleteBtnDeny': {
        boxShadow: 'none !important',
        background: 'transparent',
        borderRadius: '10px',
        border: "1px solid #6B6B6B",
        padding: '16px',
        width: '160px',
        height: '48px',
        color: "#6B6B6B"
    },
    '& .deleteBtnDenyDark': {
        boxShadow: 'none !important',
        background: 'transparent',
        borderRadius: '10px',
        padding: '16px',
        width: '160px',
        height: '48px',
        border: "1px solid #6B6B6B",
        color: "#6B6B6B"
    },
    '& .deleteAction': {
        display: 'flex',
        columnGap: '12px',
    },
    '& .cancelText': {
        fontSize: '14px',
        fontFamily: 'Manrope',
        fontWeight: 700,
        lineHeight: '21px',
        color: "#6B6B6B",
        textTransform: "capitalize"
    },
});

const commonDarkTheme = createTheme({
    palette: {
        type: "dark",
        primary: {
            main: "#3858E3",
        },
        secondary: {
            main: "rgba(255,255,255,0.75)"
        },
        info: {
            main: "#FFFFFF",
        },
        warning: {
            main: "#78716C",
        },
    },
});

const commonLightTheme = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#3858E3",
        },
        secondary: {
            main: 'rgba(0,0,0,0.75)'
        },
        info: {
            main: "#222222",
        },
        warning: {
            main: "#78716C",
        },
    },
});

export default DeleteMessageModal;
