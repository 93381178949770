Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.findAccountEndpoint = "bx_block_forgot_password/find_account";
exports.btnExampleTitle = "CLICK ME";
exports.ApiContentTypeJson = "application/json";
exports.ApiContentTypeFormdata = "multipart/form-data";
exports.apiGetMethod = "GET";
exports.apiPostMethod = "POST";
exports.apiPutMethod = "PUT";
exports.apiDeleteMethod = "DELETE";
exports.apiPatchMethod = "PATCH";
exports.validatePasswordApi = "account_block/accounts/verify_password";
exports.getAccountDataAPi = "account_block/accounts/logged_user";

exports.passwordRegex=/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/
exports.placeholderPassword = "Enter your Password"

exports.phoneNumberRegex = /^\d{10}$/;
exports.wordRegex = /\b\w+\b/g;

exports.emailTitle= "Email"
exports.securityAndAccountAccessTxt = 'Manage your account’s security and keep track of your account’s usage including apps that you have connected to your account.'
exports.securityTxt = "Manage your account's security.";
exports.appsAndsessionTxt = "See information about when you logged into your account and the apps you connected to your account.";
exports.connectedAccountTxt = "Manage Google or Apple accounts connected to Arrows to log in."
exports.reportPostHeading = "What type of issue are you reporting?"
exports.notification='Notification';
exports.notificationRecommends='Select the kinds of notifications you get about your activities, interests, and recommendations.';
exports.muteNotifications='Mute notifications from people';
exports.filters='These filters won’t affect notifications from people you follow.';
exports.preferences='Preferences';
exports.selectPreference='Select your preferences by notification type.';
exports.unreadNotification='Unread notification count badge';
exports.displayBadge='Displays a badge with the number of notifications waiting for you inside the Arrows app. ';
exports.pushNotification='Push Notifications';
exports.selectPreference='Select your preferences by notification type.';
exports.unreadBadge='Unread notification count badge';
exports.numberNotification='Displays a badge with the number of notifications waiting for you inside the Arrows app. ';
exports.followPosts='Posts from people you follow';
exports.posts='Posts';
exports.people='0 People';
exports.tailor='Tailored for you';
exports.tag='Photo tags';
exports.followContacts='Followers and contacts';
exports.directMessage='Direct Messages';
exports.reactions='Message reactions';
exports.ownMessage='Your own messages';
exports.recommends='Recommendations from Arrows';
exports.newsArrows='News from Arrows';
exports.arrowProfessional='Arrows for Professionals';
exports.ads='Ads campaigns';
exports.persons=' Persons';
exports.name='Ariana Grande';
exports.bio='@arianagrande';
exports.muteNotification='Muted Notifications';
exports.postRelate='Related to you and your posts';
exports.mentionsReplies='Mentions and replies';
exports.muteNotificationApiEndpoint='bx_block_notifsettings/notification_settings';
exports.update='update';
exports.miss='Don’t miss out thing';
exports.title='@ralphed';
exports.updatePeferenceEndpoint='bx_block_notifsettings/post_notification_preference/preference_account_list';
exports.updatePreferApiEndpoint='bx_block_notifsettings/post_notification_preference';
exports.submit='submit';
exports.notifyUpdateApiEndpoint='bx_block_notifsettings/notification_settings';
exports.cancel='Cancel'
exports.errorTitle= "Error"
exports.errorAllFieldsAreMandatory = "All fields are mandatory."
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";
exports.enterValidNumber = "Please enter a valid phone number."
exports.enterValidEmail = "Please enter valid email.",
exports.emailRegex=/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
exports.findAccountEndpoint = "bx_block_forgot_password/find_account";
exports.sendOtpEndpoint ="bx_block_forgot_password/send_otp";
exports.otpApiEndpoint='bx_block_forgot_password/confirm_otp';
exports.cancel='Cancel';
exports.started='Get Started';
exports.resendOtpEndpoint="account_block/send_otp"; 
// Customizable Area End