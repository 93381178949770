export const imgBell = require("../assets/bell.png");
export const searchDark = require("../assets/searchDark.svg");
export const searchLight = require("../assets/searchLight.svg");
export const callLight = require("../assets/callLight.svg");
export const callDark = require("../assets/callDark.svg");
export const profileLogo = require("../assets/face.png");
export const verifyIcon = require("../assets/verifyIcon.svg");
export const filterIconDark = require("../assets/filterIconDark.svg");
export const filterIcon = require("../assets/filterIcon.svg");
export const notificationIcon = require("../assets/notificationIcon.png");
export const postLogo = require("../assets/postLogo.png");
export const checkbox = require("../assets/checkbox.png");
export const checkboxFill = require("../assets/checkboxFill.png");

export const reshotIcon = require("../assets/reshotIcon.svg");
export const reverseIcon = require("../assets/reverseIcon.svg");
export const saveIcon = require("../assets/saveIcon.svg");
export const shareIcon = require("../assets/shareIcon.svg");
export const favIcon = require("../assets/favIcon.svg");
export const commentIcon = require("../assets/commentIcon.svg");
export const reshotLightIcon = require("../assets/reshortLight.svg");
export const threeDots = require("../assets/3dots.png");
export const saveActive = require("../assets/saveActive.svg");
export const favActive = require("../assets/favActive.svg");
export const verifyOrange = require("../assets/verifyOrange.svg");
export const verifyYellow = require("../assets/verifyYellow.svg");
export const ownerShipIcon = require("../assets/ownerShipIcon.svg")
export const love = require("../assets/love.png")
export const repost = require("../assets/share.png")
export const comment = require("../assets/comment.png")
export const Following = require("../assets/follow.png")
export const report = require("../assets/view_.png")