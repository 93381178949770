// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.validationApiContentType = "application/json";
exports.formDataApiContentType = "multipart/form-data";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "bx_block_content_moderation/moderation";
exports.exampleWebAPiEndPoint = "bx_block_content_moderation/moderation";
exports.reportAccountEndPoint = "account_block/report_accounts/";
exports.reportPostEndPoint = "bx_block_posts/report_posts/";
exports.reportIssuesEndPoint = "bx_block_posts/report_issues";
exports.reportSubIssuesEndPoint = "bx_block_posts/report_sub_issues?id=";

exports.exampleAPiMethod = "POST";
exports.postMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Content Moderation";
exports.labelBodyText = "Content Moderation Body";
exports.validationApiMethodTypeUpdate = "PUT";
exports.btnExampleTitle = "CLICK ME";
exports.DeleteApiMethodType = "DELETE";
exports.InsertApiMethodType = "POST";
exports.loginEmail = "test11@gmail.com";
exports.loginPassword = "Password@123";
exports.loginEndpoint = "bx_block_login/logins";
exports.reportDetailsHeading = "Report detail / Arrows"
exports.report = "Report:"
exports.view = "View"
exports.whatsNext= "What's next"
exports.viewRule = "View Rule"
exports.descriptionText = "Our team and technology will review your report. If we find a rule violation, we’ll let you know what actions we're taking."
exports.whatsProcess = "What's our process?"
exports.reportContentLabel1="• Does the reported content target people because of their identity?"
exports.reporContentLabel2="• How severe is this violation?"
exports.reportContentLabel3 = "• Was the report submitted by the person being targeted?"
exports.reportContentDescription = "Creating duplicate reports will not accelerate our process."
exports.whatsProcessDescriptionText = " Content matters. We consider the following factors when enforcing our rules (this isn’t a complete list):"


exports.reportRule ="Report rule"
exports.whatTypeReportRule = "What type of issue are you reporting?"

// Customizable Area End
