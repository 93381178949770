import { IBlock } from "framework/src/IBlock";
// Customizable Area Start
import React from "react";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { IError, IHighlights } from "./ProfileController.web";



// Customizable Area End
export const configJSON = require("./config");
 export interface IHighlightResponse{
  data:IHighlights
 }

export interface Media{
  id: number
  url: string
  filename: string
  content_type: string
}

export interface HashTag {
  hash_tag: string;
  indices: number[];
}

export interface BodyMention {
  id: number;
  full_name: string;
  user_name: string;
  indices: number[];
}


export interface IArchivedResp{
  data:Array<Archived>,

  pagination_details:{
    total_pages:number
  }
}

export interface Archived{
  date:string,
  stories:Array<Stories>
}

interface IStoriesResponse{
  data:Array<Stories>
}


export interface IStoryAttributes{
  id: number,
  body: string,
  media:Media ,
  created_at: string,
  is_liked: boolean|null,
  model_name: string,
  is_viewed: boolean,
  hash_tags: Array<HashTag>,
  body_mentions:Array<BodyMention>,
  view_count: number,
  total_likes: number,
  added_to_highlight: boolean,
  original_story: boolean|null
}

export interface Stories{
  id: string
  type: string 
  attributes: IStoryAttributes
}

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    checked:boolean;
    onClose:()=>void;
    onAddHighlight:(highlight:IHighlights)=>void;
    open:boolean
    isEditable:boolean;
    storyDetails?:IHighlights

    // Customizable Area End
  }
  
  interface S {
     // Customizable Area Start
     name:string;
     openDialog:boolean;
     openArchivedStory:boolean;
     pagination:number;
     totalPages:number;
     selectedStories:Array<number>
     stories:Array<Stories>;
     isArchive:boolean
     coverPic:string,
     coverMedia:File|null,
     isEdit:boolean,
     userStories:Stories[]
     activeIndex:string
    // Customizable Area End
  }
  
  interface SS {
    // Customizable Area Start
     id: any;
    // Customizable Area End
  }
  
export default class CreateHighlightStoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  scrollViewerStoryParentRef = React.createRef<HTMLDivElement>();
  getArchivedStoriesApiCallId:string="";
  addHighlightAPICallId:string="";
  getHighlightApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start 
      name:'',
      isArchive:false,
      openArchivedStory:false,
      openDialog:false,
      pagination:1,
      coverPic:"",
      coverMedia:null,
      totalPages:0,
      selectedStories:[],
      stories:[],
      isEdit:false,
      userStories:[],
      activeIndex:'Selected'
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.handleResponse(apiRequestCallId, responseJson)
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if(this.props.open!==prevProps.open){
      if(this.props.open){
        this.HighlightNameDialog()
          if(this.props.isEditable && this.props.storyDetails){
           this.setState({
              isEdit:true,
              name:this.props.storyDetails.attributes.title,
              coverPic:this.props.storyDetails.attributes.cover
           },()=>this.gethighlightStories(this.props.storyDetails?.attributes.id as number))
          }
      }
    }
  }


  handleResponse=(apiRequestCallId:string,responseJson:IStoriesResponse & IHighlightResponse & IArchivedResp&IError)=>{
  
    if(apiRequestCallId===this.getArchivedStoriesApiCallId){
        this.handlegetArchivedStories(responseJson)
    }else if(apiRequestCallId===this.addHighlightAPICallId){
         this.handleAddHighlight(responseJson)
    }else if(apiRequestCallId===this.getHighlightApiCallId){
          this.handleGetUserStories(responseJson)
      }
  }

  handleGetUserStories=(responseJson:IStoriesResponse&IError)=>{
   if(!responseJson?.errors){
      this.setState({
        userStories:responseJson.data,
        selectedStories:responseJson.data.map(item=>item.attributes.id)
      })
   }
  }


  handleAddHighlight=(responseJson:IHighlightResponse&IError)=>{
   if(!responseJson?.errors){
    this.props.onAddHighlight(responseJson.data)
    this.setState({
      openArchivedStory:false
    })
    this.props.onClose()
   }
  }

  handlegetArchivedStories=(responseJson:IArchivedResp&IError)=>{
    if(!responseJson?.errors){
      const data= responseJson.data.map((item)=>item.stories).flat()

      this.setState((prev)=>({
       stories:[...prev.stories,...data],
       totalPages:responseJson.pagination_details.total_pages,
       pagination:prev.pagination+1
      }))
     }
  }

  handleChangeCoverPicture=(event: React.ChangeEvent<HTMLInputElement>)=>{
    const file = event.target.files?.[0];
    if (file  && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      this.setState({coverMedia:file},()=>{
         this.setState({coverPic:URL.createObjectURL(file)})
      })
    }
  }

  handleChangeName=(event:React.ChangeEvent<HTMLInputElement>)=>{
     this.setState({
      name:event.target.value
     })
  }

  handleClose=()=>{
    this.setState({
      openDialog:false,
      openArchivedStory:false
    })
  }

  handleHighlightNext=()=>{
    this.setState({
      openDialog:false,
      openArchivedStory:true
    },this.getArchivedStories)
  }

  handleOpenCover=()=>{
    this.setState({
      isArchive:true
    })
  }

  HighlightNameDialog=()=>{
    this.setState({
      openDialog:true,
      openArchivedStory:false
    })
  }

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({
      activeIndex: newValue
    })
  }

  handleSelectedStory=(storyId:number)=>{
    let selectedStories:Array<number> = this.state.selectedStories
    const checkElement  = this.state.selectedStories.includes(storyId)
    if(checkElement){
      selectedStories = this.state.selectedStories.filter(item=>item!==storyId)
    }else{
      selectedStories.push(storyId)
    }
    
    this.setState({
      selectedStories
    })

  }

  getDate=(dateString:string)=>{
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'short' });
    const formattedDate = `${day} ${month}`;
    return formattedDate
  }



  backtoArchive=()=>{
    this.setState({
      isArchive:false
    })
  }

  getArchivedStories = async()=>{
    const page =  this.state.pagination
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getArchivedStoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getArchiveStories}?page=${page}&per_page=12&sort_order=asc`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  gethighlightStories=async(storyId:number)=>{
    let token = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token:token,
    };
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getHighlightApiCallId= requestMessage.messageId;
    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addHighlightEndPoint+`/${storyId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpGetMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  addHighlightStory = async()=>{
    const endPoint =  this.props.isEditable? `${configJSON.addHighlightEndPoint}/${this.props.storyDetails?.attributes.id}` : `${configJSON.addHighlightEndPoint}`
    const RequestMessage = this.props.isEditable?  configJSON.httpPatchMethod: configJSON.httpPostMethod;
    const token = await getStorageData("authToken");
    const header = {
      token: token
    };

    let formData = new FormData()

    formData.append("title",this.state.name)
    this.state.coverMedia && formData.append("cover",this.state.coverMedia)
    formData.append("story_ids",this.state.selectedStories as unknown as Blob)
    
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addHighlightAPICallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),RequestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}