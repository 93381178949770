// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const form = require("../assets/clarity_form-line.png");
export const cameraIcon = require("../assets/camera_icon.svg");
export const rightIcon = require("../assets/right_icon.svg");
export const rightBlackIcon = require("../assets/rightArrowBlackIcon.svg");
export const profile = require("../assets/profile.png");
export const checkedCheckBox = require("../assets/checkedCheckBox.svg");
export const uncheckedCheckBox = require("../assets/uncheckedCheckBox.svg");
export const backArrowWhite=require("../assets/backArrow-white.png");
export const backArrowDark=require("../assets/backArrow-dark.png");
export const addMemberIcon=require("../assets/addMemberIcon.svg");
export const groupMemberIcon=require("../assets/groupMemberIcon.svg");
export const groupSettingIcon=require("../assets/groupSettingIcon.svg");
export const memberIcon=require("../assets/memberIcon.svg");
export const sendMessageIcon=require("../assets/sendMessageIcon.svg");
export const addMemberBlackIcon=require("../assets/addMemberBlackIcon.svg");
export const groupMemberBlackIcon=require("../assets/groupMemberBlackIcon.svg");
export const groupSettingBlackIcon=require("../assets/groupSettingBlackIcon.svg");
export const memberBlackIcon=require("../assets/memberBlackIcon.svg");
export const sendMessageBlackIcon=require("../assets/sendMessageBlackIcon.svg");

// Customizable Area End
