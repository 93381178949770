import React from "react";

// Customizable Area Start
import {
  Button, 
  ThemeProvider,
  Box,
  Typography,
  withStyles,
  createTheme,
  Dialog,
  TextField,
  InputAdornment,
  Grid,
  Tabs,
  Tab,
  CircularProgress,
  styled,
} from "@material-ui/core";
import darkTheme from "../../../components/src/darkTheme";
import lightTheme from "../../../components/src/lightTheme";
import { Profile,  leftArrow,  leftArrowDark,  ownerShipIcon,  searchDark,  searchLight,  verifyIcon, verifyOrange, verifyYellow } from "./assets";

// Customizable Area End

// Customizable Area Start
import ActivityFollowersController, { IFollower, IFollowerAttributes, IPaginationDetails, Props } from "./ActivityFollowersController";
import InfiniteScroll from "react-infinite-scroller";
// Customizable Area End

export  class ActivityFollowers extends ActivityFollowersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderVerifyIcon= (value:IFollowerAttributes) =>{
    if(value.user_subscription ){
      switch (value.user_subscription) {
        case 'I am a business':
          return <img src={verifyYellow} alt="" />;
        case 'I am an individual':
          return <img src={verifyIcon} alt="" />;
          case 'I am a news organization':
          return <img src={verifyOrange} alt="" />;          
      }       
     }
  }

  renderOwnershipIcon= (value:{ user_ownership:boolean }) =>{
    if(value.user_ownership ){
          return <img src={ownerShipIcon} alt="" />;         
      }       
  } 
  // Customizable Area End

  render() {
    // Customizable Area Start
    const pagination = this.state.pagination as IPaginationDetails
    const conditionShort = (condition:boolean,trueStatement:string,falseStatement:string) =>{
       return condition ?  trueStatement :falseStatement
    }      

    return (             
         <ThemeProvider theme={this.props.checked ? lightTheme:darkTheme}> 
           <DialogContainer data-test-id="loactionModel" onClose={() => 
             this.props.onClose()
           } 
             open={this.props.open} 
             PaperProps={{
               className: conditionShort(!this.props.checked,"modelDark","modelLight")
             }}
              fullWidth
           >
       <GridContainer container justifyContent="center" alignItems="center" direction="column">          
          
        <Box className="container">
          <img data-test-id="backbtn" onClick={() => this.props.onClose()} 
          src={conditionShort(this.props.checked,leftArrowDark,leftArrow)}  className={"backLogo"} alt="" />
          <Typography  className={"topHead"}>Followers</Typography>
          <Typography>{" "}</Typography>
        </Box>
             
        <Box style={{width:"100%"}}> 
           <TextField  
              variant="outlined"
               placeholder="Search"
               data-test-id="searchFollowers"
               onChange={this.handleSearchFollowers}
               className={"searchInputStyle"}
               style={{width:"100%"}}
               fullWidth
               InputProps={{
                style: {
                    height: "50px"
                  },          
               startAdornment: (
                 <InputAdornment position="start">
                   <img src={conditionShort(this.props.checked,searchDark,searchLight)} alt="" />
                 </InputAdornment>
               ),
             }}
            />
         </Box>

         <Box style={{display:"flex",rowGap:"20px",flexDirection:"column",width:"100%" }}>
         <Box className={"tabsContainer"}>
              <Tabs
                value={this.state.tabPanelNo}
                onChange={this.hanleTabs}
                classes={{ indicator: "indicator" }}
                textColor="primary"
                variant="fullWidth"
                data-test-id="tabBtn"
              >
                <Tab label={`${conditionShort(this.props.isLoggedUser,this.state.userDetail.followersCount.toString(),this.props.followerCount.toString())} Followers`}className={"tabstyle"} />
                <Tab label={`${conditionShort(this.props.isLoggedUser,this.state.userDetail.followingCount.toString(),this.props.followingCount.toString())} Following`} className={"tabstyle"} />
              </Tabs>
            </Box>   


        <div className={"suggestionDisplay"} style={{marginTop:"5px"}} ref={this.scrollParentRef}>
          <InfiniteScroll
            pageStart={this.state.page}
            loadMore={()=>this.getListOfFollowers(this.state.tabPanelNo==0?"followers":"following")}
            data-test-id="followersScroller"
            hasMore={this.state.page<=pagination?.total_pages}
            loader={<div className={"loader"} key={0}> {this.state.page <= pagination?.total_pages&&<CircularProgress disableShrink />}</div>}
            useWindow={false}
            getScrollParent={() => this.scrollParentRef.current}
        >
          <Box style={{display:"flex",flexDirection:"column",rowGap:"15px",minHeight:"300px"}}>
           {this.state.followers.length?this.state.followers.map((user:IFollower)=>{
           return  <Box style={{justifyContent:"space-between",display:"flex"}}>
              <Box  style={{display:"flex",alignItems:"center",gap:"15px"}}>            
                 <img
                    src={conditionShort(user?.attributes?.profile_photo!==null,user?.attributes.profile_photo,Profile)}
                    className={"postImage"}
                    alt="img"                    
                    style={{ border: conditionShort(this.props.checked,"2px dashed var(--Primary-Purple-100, black)","2px dashed var(--Primary-Purple-100, white)"),
                    background: conditionShort(this.props.checked,"var(--Basic-White,#FFF))","var(--Basic-White,black))")}}
                  />
              <Box className={"headconatin"}>                  
                  <Box className={"ContentHead"}>
                    <Box style={{display:"flex",alignItems:"center",gap:"5px"}}>
                      <Typography className={"name"}>{user?.attributes.full_name}</Typography>
                      {this.renderVerifyIcon(user.attributes)} {this.renderOwnershipIcon(user.attributes)}                      
                    </Box>
                    <Box style={{display:"flex",alignItems:"center",gap:"5px"}}>
                      <Typography className={"userName"}>{conditionShort(user.attributes.user_name!==null,user.attributes.user_name," ")} </Typography>
                    </Box>
                  </Box>                  
              </Box>
            </Box>
          {user?.attributes.is_following ? <Button  
            data-test-id="followBtn"
            className={conditionShort(this.props.checked,"followingBtnLight","followingBtn")}
            onClick={()=> this.handleFollowing(user.id)}
            >Following</Button>:
           <Button  
            data-test-id="FollowingBtn"
            className={"followBackBtn"}
            onClick={()=>this.handleFollow(user.id)}
            >{conditionShort(!this.props.isLoggedUser,'Follow' ,"Follow back")}</Button>}
            </Box>             
           }) : <div className={"memberError"}> 
                 <Typography className={"emaptyMessages"}>{conditionShort(this.state.tabPanelNo==0,"Looking for followers?","Looking for following?")} </Typography>
                 <Typography className={"emaptySubhead"} style={{width:"319px"}}>{conditionShort(this.state.tabPanelNo==0,"When someone follows this account, they’ll appear here.","When you following someone account, they’ll appear here.")}</Typography>
            </div>
           } 
           </Box>
           </InfiniteScroll>
          </div> 
        </Box>                      
      </GridContainer>
      </DialogContainer>  
        </ThemeProvider>      
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
  }
});


const GridContainer = styled(Grid)({
    padding: "28px 24px 28px 24px",
    rowGap:"17px",

    "& .container":{
      display:"flex",
      width:"100%",
      justifyContent:"space-between",
      alignItems:"center" 
    },
    "& .backLogo":{
      cursor:"pointer"
     },

    "& .searchInputStyle":{
      width:"80%",
      '& .MuiOutlinedInput-root': {
        borderRadius: "10px", 
        '&.Mui-focused fieldset': {
          border :"1px solid #7A2BF5",       
        },
      },     
    },

    "& .tabsContainer":{
      height:"35px"
    },

    "& .indicator":{
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      height: '2.9px',
    },

    "& .tabstyle":{
      fontFamily:"Manrope",
      fontWeight:600,
      fontSize:"16px",
      textTransform:"capitalize" as "capitalize",
      borderBottom:"3px solid gray",    
      '&.Mui-selected': {
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent' as "transparent",
      },
    },
    "& .suggestionDisplay":{
      width:"100%",
      height:"400px",
      overflowY:"scroll" as  "scroll",
      paddingRight:"10px"
    },
    "& .postImage":{
      height:"35px",
      width:"35px",
      borderRadius:"50%",
      objectFit:"fill" as "fill"
    },

    "& .headconatin":{
      display:"flex",
      width:"100%",
      justifyContent:"space-between"
    },
    "& .ContentHead":{
      display:"flex",
      flexDirection:"column" as "column",
      gap:"5px",
    },

    "& .name":{
      fontSize:"18px",
      fontFamily:"Manrope",
      fontWeight:800,
      lineHeight:"21px",
      whiteSpace:"nowrap" as "nowrap"
    },
    "& .userName":{
      fontSize:"12px",
      fontFamily:"Manrope",
      fontWeight:500,
      lineHeight:"14px",
      whiteSpace:"nowrap" as "nowrap",
      color:"#6B6B6B"
    },
    "& .followingBtn":{       
      textTransform: "capitalize" as "capitalize",
      background: "#222",
      fontSize: "clamp(12px,2vw,14px)",
      borderRadius:"10px",
      fontWeight:500,
      padding:"6px 20px",
      height:"32px",
      fontFamily: "Manrope",
      "&:hover": {
          backgroundColor: "#222",
        },
        color: "#FFFFFF",
    },
    "& .followingBtnLight":{
      textTransform: "capitalize" as "capitalize",
      background: "#E9E9E9",
      fontSize: "clamp(12px,2vw,14px)",
      borderRadius:"10px",
      height:"32px",
      fontWeight:500,
      padding:"6px 20px",
      fontFamily: "Manrope",
      "&:hover": {
        backgroundColor: "#E9E9E9",
        },
        color: "black",
    },
    "& .followBackBtn":{
      color: "#FFFFFF",
      padding:"6px 20px",
      borderRadius:"10px",
      fontSize: "clamp(12px,2vw,14px)",
      fontWeight:500,
      fontFamily: "Manrope",
      height:"32px"
    },
    "& .memberError":{
      display:"flex",
      alignItems:"center" as "center",
      justifyContent:"center" as "center",
      flexDirection:"column" as "column",
      height:"350px",
    },
    "& .emaptyMessages":{
      fontSize:"24px",
      fontFamily:"Manrope",
      fontWeight:800,
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      textAlign:"center" as "center",
    },
    "& .emaptySubhead":{
      fontFamily:"Manrope",
      fontWeight:600,
      fontSize:"16px",
      textAlign:"center" as "center"
    },

    "& .loader":{
      display:"flex",
      width:"100%",
      justifyContent:"center" as "center",
    },

    "& .topHead":{       
      lineHeight:"21px",
      whiteSpace:"nowrap" as "nowrap",
      fontSize:"18px",
      fontFamily:"Manrope",
      fontWeight:800,
    },
})

const DialogContainer = styled(Dialog)({

  "& .modelDark":{ 
    border: "1px solid transparent",
    backdropFilter: "blur(1 px)",
    background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)",
    boxShadow:"none" ,
    borderRadius: "10px",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",       
  },
  "& .modelLight":{
    borderRadius: "10px",        
    backgroundOrigin: "padding-box, border-box",  
    backdropFilter: "blur(1 px)", 
    boxShadow:"none",
    border: "1px solid transparent",
    background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #dfd6d6 ,#5B5B5B)",
    backgroundClip: "padding-box, border-box",   
   },


})

  
export default ActivityFollowers;

// Customizable Area End
