import React, { Fragment, useEffect } from "react";
import { Box, styled, Typography } from "@material-ui/core";
import clsx from "clsx";
import { convertTimestampToTime } from "./utils";

interface IPropCometChatBubbleViewReplyMessage {
    message: CometChat.BaseMessage;
    replyMessageItem: CometChat.BaseMessage | null;
    isMessageSentByCurrentUser: boolean;
    onScrollToBottom: () => void;
}


export default function CometChatBubbleViewReplyMessage({
    message,
    replyMessageItem,
    isMessageSentByCurrentUser,
    onScrollToBottom
}: IPropCometChatBubbleViewReplyMessage) {

    useEffect(() => {
        onScrollToBottom();
    }, [replyMessageItem])

    const renderTimeStamp = (unixTime: number) => {
        const timestampSentAt = convertTimestampToTime(unixTime);
        return timestampSentAt;
    }

    const renderRawMessage = () => {
        const typeMessage = castReplyMessageItem.getType();
        if(castReplyMessageItem.getDeletedAt()) return "This message was deleted";
        switch (typeMessage) {
            case "text": {
                return (castReplyMessageItem?.getRawMessage() as unknown as any)?.text
            }
            case "file": {
                return `File`
            }
            case "image": {
                return `Image file`
            }
        }
    }

    if (!replyMessageItem) return <Fragment />;

    const castReplyMessageItem = replyMessageItem as unknown as CometChat.BaseMessage;

    return (
        <CometChatBubbleViewReplyMessageStyled>
            {
                message.getParentMessageId() &&
                <Box className={`replyMessageContainer ${clsx(isMessageSentByCurrentUser && "myReplyMessageContainer")}`}>
                    <Box className={`messageReplyContainer ${clsx({
                        'user-message': isMessageSentByCurrentUser,
                        'other-message': !isMessageSentByCurrentUser,
                    })}`}>
                        <Typography component="p" className="messageText">
                            {renderRawMessage()}
                        </Typography>
                        <Box className="messageSentAt">
                            <span style={{ color: "#FFFFFF" }}>{renderTimeStamp(castReplyMessageItem?.getSentAt() || 0)}</span>
                        </Box>
                    </Box>
                </Box>
            }
        </CometChatBubbleViewReplyMessageStyled>
    )
}

const CometChatBubbleViewReplyMessageStyled = styled(Box)({
    "& .replyMessageContainer": {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
        "& .messageReplyContainer": {
            padding: "10px 20px",
            borderRadius: "16px",
            textAlign: "left",
            opacity: 0.5,
            position: "relative",
            "& .messageText": {
                fontFamily: "Manrope",
                fontWeight: 500,
                fontSize: 14,
                color: "#FFFFFF",
                paddingRight: "18px",
                wordBreak: "break-all"
            },
            "& .messageSentAt": {
                position: "absolute",
                height: 15,
                top: 14,
                width: "28px",
                right: "calc(36px - 28px)",
                textAlign: "right",
                color: "#FFFFFF",
                fontFamily: "Manrope",
                fontSize: 10,
                fontWeight: 500,
            }
        },
        "& .messageReplyContainer.other-message": {
            background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
            borderBottomRightRadius: 8,
            marginRight: "calc(16px + 12px)",
        },
        "& .messageReplyContainer.user-message": {
            background: "#222222",
            borderTopLeftRadius: "8px",
            marginLeft: "calc(16px + 12px)",
        }
    },
    "& .replyMessageContainer.myReplyMessageContainer": {
        textAlign: "right"
    },
});
