import { Box, IconButton, styled, Typography } from '@material-ui/core';
import { ISetMessageReply } from '../../blocks/chat/src/ViewChatController';
import React from 'react';
import ImageRenderer from './ImageRenderer.web';
import clsx from 'clsx';

const closeReplyIcon = require("./assets/closeReplyMessageIcon.svg")

interface ChatMessageReplyItemProps {
  checked?: boolean;
  messageReplyItem: ISetMessageReply | null;
  onRemoveReply: () => void;
}

const ChatMessageReplyItem: React.FC<ChatMessageReplyItemProps> = ({
  checked,
  messageReplyItem,
  onRemoveReply
}) => {
  if (!messageReplyItem?.parentMessageId) {
    return null;
  }

  const renderMessageContent = () => {
    const typeMessage = messageReplyItem.messageType;
    switch (typeMessage) {
      case "text": {
        return messageReplyItem.textMessageReply
      }
      case "image": {
        const nameAttachment = messageReplyItem.attachment?.name;
        return ""
      }
      case "file": {
        return "File"
      }
    }
  }

  const renderAttachment = () => {
    const typeMessage = messageReplyItem.messageType;
    switch (typeMessage) {
      case "image": {
        const urlFile = messageReplyItem.attachment?.url ?? "";
        return <ImageRenderer width={60} height={55} src={urlFile} alt="i" style={{ borderRadius: 10 }} />
      }
    }
  }

  return (
    <CometChatBubbleViewReplyMessageStyled>
      <Box className={`messageReplyItem ${clsx(checked && "lightThemeBackground")}`}>
        <Box className="contentMessage">
          <Box className={`messageText messageText__name ${clsx(checked && "lightThemeTextColor")}`}>
            {messageReplyItem.nameReply}
          </Box>
          <Typography
            component="p"
            className={`messageText ${clsx(checked && "lightThemeTextColor")}`}
            style={{
              display: renderMessageContent() ? "block" : "none"
            }}
          >
            {renderMessageContent()}
          </Typography>
        </Box>
        <Box className="attachmentContainer">
          {renderAttachment()}
        </Box>
        <IconButton
          className="removeReplySelected"
          data-test-id="removeReplySelected"
          onClick={onRemoveReply}
        >
          <ImageRenderer src={closeReplyIcon} width={24} height={24} />
        </IconButton>
      </Box>
    </CometChatBubbleViewReplyMessageStyled>
  );
};

export default ChatMessageReplyItem;

const CometChatBubbleViewReplyMessageStyled = styled(Box)({
  position: "absolute",
  top: "-80px",
  width: "calc(100% - 40px)",
  "& .lightThemeBackground": {
    background: "#222222!important"
  },
  "& .lightThemeTextColor": {
    color: "#FFFFFF!important"
  },
  "& .messageReplyItem": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxHeight: "58px",
    flexDirection: "row",
    background: "#222222",
    position: "relative",
    padding: "10px 16px",
    paddingRight: "calc(16px + 32px + 18px)",
    borderRadius: 10,
    "& .contentMessage": {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      width: "calc(100% - 72px)",
      "& .messageText": {
        color: "#BABABA",
        fontFamily: "Manrope",
        fontSize: 12,
        fontWeight: 700,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      "& .messageText.messageText__name": {
        color: "#FFFFFF",
        textTransform: "capitalize"
      },
    },
    "& .removeReplySelected": {
      position: "absolute",
      right: 16,
      padding: 4
    }
  }
})