// Customizable Area Start
import React from "react";
import { Box, withStyles, createStyles, Typography } from "@material-ui/core";

import CommentItemController, {
  Props,
} from "./CommentItemController.web";
import { replyIcon, replyIconLight } from "./assets";
import CommentItemUi from './CommentItemUi.web'

export class CommentItem extends CommentItemController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, comment, onLikeComment, onClickReply, getReplies, closeReplies, checked } = this.props;

    return (
      <Box className={classes.container}>
        <CommentItemUi
          checked={checked}
          data={comment} 
          onLikeComment={onLikeComment}
          commentId={comment.id}
        />
        <Box className={classes.replyContainer}>
          <img src={checked ? replyIconLight : replyIcon} width={20} height={20} alt="reply-icon" />
          <Typography
            data-testid="reply-button"
            onClick={() => onClickReply(comment)} 
            className={classes.replyText}
            style={{color: checked ? '#222222' : '#FFF'}}
          >
            Reply
          </Typography>
        </Box>
        {this.remainingReplies > 0 && (
          <Box 
            data-testid="view-replies-button"  
            style={{cursor: 'pointer'}} 
            onClick={() => getReplies(comment.id, comment.attributes.replyPage)}
          >
            <Typography 
              className={classes.viewReplyText}
              style={{color: checked ? '#222222' : '#FFF'}}
            >
              {`View ${this.remainingReplies} more ${this.converText(this.remainingReplies)}`}
            </Typography>
          </Box>
        )}
        {comment.attributes.total_replies > 0 && comment.attributes.replies.length > 0 && (
          comment.attributes.replies.map(reply => (
            <CommentItemUi
              key={reply.id}
              checked={checked}
              data={reply} 
              onLikeComment={onLikeComment}
              isReply
              commentId={comment.id}
            />
          ))
        )}
        {comment.attributes.replies.length > 0 && (
          <Box data-testid="hide-replies-button"  style={{cursor: 'pointer'}} onClick={() => closeReplies(comment.id)}>
            <Typography 
              className={classes.viewReplyText}
              style={{color: checked ? '#222222' : '#FFF'}}
            >
              {`Hide ${this.converText(comment.attributes.total_replies)}`}
            </Typography>
          </Box>
        )}
      </Box>
    )
  }
}

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '10px 0 10px 20px',
  },
  replyContainer: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    cursor: 'pointer',
  },
  replyText: {
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontWeight: 500,
  },
  viewReplyText: {
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontWeight: 500,
  },
});

export default withStyles(styles)(CommentItem);
// Customizable Area End