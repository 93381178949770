import React from 'react'
// Customizable Area Start 
import {
    Box,
    Dialog,
    Typography,
    styled,
    Switch
} from '@material-ui/core'
import NotificationPreferenceController, { Props } from './NotificationPrefereceController.web';
import { darkArrowIcon, ArrowIcon } from "./assets"
import Loader from '../../../components/src/Loader.web';
// Customizable Area End
export default class NotificationPreference extends NotificationPreferenceController {

    constructor(props: Props) {
        // Customizable Area Start      
        super(props);
        // Customizable Area End  
    }
    // Customizable Area Start
    conditionRemover = (condition: boolean, trueStatement: string, falseStatement: string) => {
        return condition ? trueStatement : falseStatement
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <DialogBox open={this.props.open} onClose={this.props.onClose}>
                <BoxContainer >
                    <Box className={this.conditionRemover(this.props.checked, 'lightTheme', "darktheme")}>
                        <Loader loading={this.state.loader} />
                        <Box style={{ padding: '24px' }}>
                            <Box className='HeaderContainer'>
                                <Box onClick={this.props.onClose}><img className='topIcon' src={this.conditionRemover(this.props.checked, darkArrowIcon, ArrowIcon)} /></Box>
                                <Box><Typography className='HeaderTitle'>Don’t miss out</Typography></Box>
                                <Box></Box>
                            </Box>
                            <Box className='container'>
                                {this.state.preference.map(item=>{
                               return(
                                <Box className='settingContainer'>
                                    <Box className='Notification'>
                                        <Box className='NotificationTitle'>{item.title}</Box>
                                        <Box><Typography className='NotificationContent'>{item.description}</Typography></Box>
                                    </Box>
                                    <Box> <CustomSwitch data-testid="prefernce" checked={item.active} onClick={()=>this.handleChange(item.id)}/></Box>
                                </Box>  
                                )
                            })}      
                            </Box>
                        </Box>
                    </Box>
                </BoxContainer>
            </DialogBox>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

const CustomSwitch =styled(Switch)({
    width:48,
    height:28,
    padding:0,
    borderRadius:40,
   "& .MuiSwitch-switchBase":{
      padding:0,
      top:2,
      left:2
   },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track":{
      background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)!important',
      opacity:1,
     },
   "& .MuiSwitch-thumb":{
    height:24,
    width:24,
    color:'#FFFFFF'
   }
})

const BoxContainer = styled(Box)({
    height: 357,
    width: 622,
    "& .lightTheme": {
        height: "100%",
        width: '100%',
        background: '#fff'


    },
    "& .darktheme": {
        height: "100%",
        width: '100%',
        background: '#0B0B0B'
    },

    "& .HeaderTitle": {
        fontWeight: 800,
        fontFamily: 'Manrope',
        fontSize: 18
    },

    "& .HeaderContainer": {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    "& .container": {
        marginTop: 24,
    },
    "& .settingContainer": {
        margin: '16px',
        height: '48px',
        display: 'flex',
        justifyContent:'space-between'
    },

    "& .NotificationContent": {
        fontFamily: 'Manrope',
        fontSize: '12px',
        fontWeight: 500,
        color: '#6B6B6B',

    },

    "& .NotificationTitle": {
        fontFamily: 'Manrope',
        fontSize: '16px',
        fontWeight: 600,

    },

    "& .Notification": {
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'space-between',
        gap: '5px'
    }


})

const DialogBox = styled(Dialog)({

    '& .MuiDialog-paperWidthSm': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: 357,
        maxWidth: 622,
        borderRadius: '10px',
        border: "1px solid #35383F",
    },
})
// Customizable Area End