import React from 'react'
// Customizable Area Start 
import {
    Box,
    Dialog,
    Typography,
    styled,
    Button
} from '@material-ui/core'
import CommentPreferenceController, { Props } from './CommentPreferenceController.web';
import { ckeckedLogo } from "../../customisableuserprofiles/src/assets"
import Loader from '../../../components/src/Loader.web';
import { configJSON } from './ActivityFeedController';
// Customizable Area End
export default class CommentPreference extends CommentPreferenceController {

    constructor(props: Props) {
        // Customizable Area Start      
        super(props);
        // Customizable Area End  
    }
    // Customizable Area Start
    conditionRemover = (condition: boolean, trueStatement: string, falseStatement: string) => {
        return condition ? trueStatement : falseStatement
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <DialogBox data-testid="comment_preference" open={this.props.open} onClose={this.props.onClose}>
                <BoxContainer >
                    <Box className={this.conditionRemover(this.props.checked, 'lightTheme', "darktheme")}>
                        <Loader loading={this.state.loader} />
                        <Box className='boxContainer'>
                            <Box className='HeaderContainer'>
                                <Typography className='HeaderTitle'>{configJSON.whoCanReply}</Typography>
                            </Box>
                            <Box className='container'>
                                {this.state.preference.map(item => {
                                    return (
                                        <Box className='settingContainer' key={item.id}>
                                            <Box className='Notification'>
                                                <Box className='NotificationTitle'>{item.title}</Box>
                                            </Box>
                                            <Box> {
                                                item.active ?
                                                    <img src={ckeckedLogo}
                                                        alt=""
                                                        data-testid="story"
                                                        onClick={() => this.handleChangePreference(item.id)}
                                                    /> :
                                                    <span onClick={() => this.handleChangePreference(item.id)} data-testid="story" className='unchecked'></span>
                                            }
                                            </Box>
                                        </Box>
                                    )
                                })}
                            </Box>
                            <Box className='buttonContainer'>
                                <Button
                                    data-test-id="noBtn"
                                    onClick={this.handleCancel}
                                    className={this.conditionRemover(this.props.checked, "muteBtnDeny", "muteBtnDenyDark")}
                                >
                                    <Typography className={"showmore"} >{configJSON.cancel}</Typography>
                                </Button>
                                <Button
                                    disabled={this.state.preference.every(item => item.active === false)}
                                    onClick={this.handleSubmit}
                                    data-test-id="submit"
                                    className={"muteBtn"}
                                >
                                    {configJSON.Submit}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </BoxContainer>
            </DialogBox>
        )
        // Customizable Area End
    }
}

// Customizable Area Start


const BoxContainer = styled(Box)({
    height: 352,
    width: 373,
    "& .lightTheme": {
        height: "100%",
        width: '100%',
        background: '#fff'


    },
    "& .darktheme": {
        height: "100%",
        width: '100%',
        background: '#0B0B0B'
    },

    "& .HeaderTitle": {
        fontWeight: 800,
        fontFamily: 'Manrope',
        fontSize: 18
    },

    "& .HeaderContainer": {
        display: 'flex',
        justifyContent: 'center',
        height: 60,
        borderBottom: '1px solid #222222',
        alignItems: 'center'
    },
    "& .container": {
        marginTop: 24,
    },
    "& .settingContainer": {
        margin: '16px auto',
        height: '24px',
        display: 'flex',
        justifyContent: 'space-between'
    },

    "& .NotificationContent": {
        fontFamily: 'Manrope',
        fontSize: '12px',
        fontWeight: 500,
        color: '#6B6B6B',

    },

    "& .unchecked": {
        width: "20px",
        height: "20px",
        border: "1px solid #6B6B6B",
        display: 'flex',
        borderRadius: "50%"
    },

    "& .NotificationTitle": {
        fontFamily: 'Manrope',
        fontSize: '16px',
        fontWeight: 600,

    },

    "& .boxContainer": {
        padding: 20
    },

    "& .Notification": {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '5px'
    },

    "& .muteBtnDeny": {
        boxShadow: "none !important",
        background: "linear-gradient( #fff, #fff),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
        border: "1px solid transparent",
        borderRadius: "10px",
        textTransform: "capitalize" as "capitalize",
        padding: "16px",
        width: "160px",
        height: "48px",
    },

    "& .muteBtnDenyDark": {
        boxShadow: "none !important",
        background: "linear-gradient(#0d0c0c, #0d0c0c),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
        border: "1px solid transparent",
        borderRadius: "10px",
        textTransform: "capitalize" as "capitalize",
        padding: "16px",
        width: "160px",
        height: "48px"
    },

    "& .showmore": {
        fontSize: "14px",
        fontFamily: "Manrope",
        fontWeight: 500,
        lineHeight: "21px",
        background: " linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    },

    "& .muteBtn": {
        boxShadow: "none !important",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        borderRadius: "10px",
        textTransform: "capitalize" as "capitalize",
        padding: "16px",
        width: "160px",
        height: "48px",
        color: "white",
        fontSize: "14px",
        fontWeight: 700,
        "&.MuiButton-root.Mui-disabled": {
            color: '#E9E9E9',
            opacity: 0.64
        }
    },

    "& .buttonContainer": {
        display: "flex",
        columnGap: "12px",
        marginTop: 34,
    }

})

const DialogBox = styled(Dialog)({

    '& .MuiDialog-paperWidthSm': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: 352,
        maxWidth: 373,
        borderRadius: '10px',
        border: "1px solid #35383F",
    },
})
// Customizable Area End