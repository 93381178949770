// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getLocation, sendAPIRequest } from "../../../components/src/utils";
import _ from 'lodash'

interface IPlace {
  id: string
  name: string;
  country: string | null;
}

const gooleApiKey = 'GOOGLE_API_KEY'

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any
  checked: boolean
  onSelectPlace: (place: string) => void
  // Customizable Area End
}

interface S {
  allowAccess: boolean;
  gpsLocation: string;
  isSpinnerShowing: boolean;
  // Customizable Area Start
  seachLocation: string
  open: boolean
  places: IPlace[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LocationController extends BlockComponent<Props, S, SS> {
  getCityApiCallId: any;
  updateDefaultCityApiCallId: any;
  googleMapAPIId: any;
  // Customizable Area Start
  getInitialPlacesId: string = ''
  getPlaceListId: string  = ''

  private debouncedFetchPlaces: (query: string) => void
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    // Customizable Area Start
    this.state = {
      allowAccess: true,
      gpsLocation: "",
      isSpinnerShowing: false,
      seachLocation: "",
      open: false,
      places: [],
    };
    this.debouncedFetchPlaces = _.debounce(this.callAPIGetPlaceList, 300)
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getInitialPlacesId === apiRequestCallId || this.getPlaceListId === apiRequestCallId) {
        if (responseJson?.status === 'OK') {
          const results = responseJson.results
          const customData: IPlace[] = results.map((place: any) => {
            const addressParts = place.formatted_address.split(',').map((part: string) => part.trim())
            const country = addressParts.length > 0 ? addressParts[addressParts.length - 1] : null

            return {
              id: place.place_id,
              name: place.formatted_address,
              country: country,
            }
          })
          this.setState({ places: customData })
        }
        if (responseJson?.status === 'ZERO_RESULTS') {
          this.setState({ places: [] })
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getPlaces()
  }

  getPlaces = async () => {
    let url: string = `https://maps.googleapis.com/maps/api/geocode/json?latlng=51.5074,-0.12786&key=${gooleApiKey}`
    
    try {
      const result = await getLocation()
      if (result) {
        const { latitude, longitude } = result
        url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${gooleApiKey}`
      }
    } catch (error) {
      console.warn(error)
    }

    this.getInitialPlacesId = sendAPIRequest(url, { method: 'GET' })
  }

  callAPIGetPlaceList = (search: string) => {
    this.getPlaceListId = sendAPIRequest(
      `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${search}&key=${gooleApiKey}`,
      {
        method: 'GET'
      }
    )
  }

  handlelCloseModal = () => {
    this.setState({ open: false })
  }

  handleOpenModal = () => {
    this.setState({ open: true })
  }

  handleSearchLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ seachLocation: event.target.value });
    const search = event.target.value
    this.setState({ seachLocation: search })
    if (!!search.trim()) {
      this.debouncedFetchPlaces(search)
    }
  }

  handleSelectPlace = (place: string) => {
    this.setState({ seachLocation: '', open: false})
    this.props.onSelectPlace(place)
  }

  // Customizable Area End
}
