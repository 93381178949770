import React, { Fragment } from "react";
// Customizable Area Start

import darkTheme from "../../../components/src/darkTheme";
import lightTheme from "../../../components/src/lightTheme";
import { searchDark, searchLight } from "./assets";
import { Box, Dialog, Grid, IconButton, InputAdornment, TextField, ThemeProvider, Typography, withStyles } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";


import LocationController, { Props } from "./LocationController.web";
import { lightLocation, location } from "../../postcreation/src/assets";
// Customizable Area End

export  class Location extends LocationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props
    const { open, places } = this.state

    return (
      <ThemeProvider theme={this.props.checked ? lightTheme : darkTheme}>
        <IconButton onClick={this.handleOpenModal}>
          <img src={this.props.checked ? location : lightLocation} />
        </IconButton>
        <Dialog
          data-test-id="loactionModel"
          onClose={this.handlelCloseModal}
          open={open}
          PaperProps={{
            className: !this.props.checked ? classes.modelDark : classes.modelLight
          }}
          fullWidth
          classes={{ paperWidthSm: !this.props.checked ? classes.modelDark : classes.modelLight }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            className={classes.container}
          >
            <Box className={classes.headerContainer}>
              <IconButton
                style={{ color: !this.props.checked ? "#fff" : "black" }}
                className={classes.closeBtn}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                data-test-id="backBtn2"
                onClick={this.handlelCloseModal}
              >
                <ChevronLeftIcon style={{ height: "44px", width: "28px" }} />
              </IconButton>
              <Typography className={classes.topHead}>Add Location</Typography>
              <Typography>{" "}</Typography>
            </Box>

            <Box style={{ width: "100%" }}>
              <TextField
                variant="outlined"
                placeholder="Search"
                data-test-id="searchLocation"
                onChange={this.handleSearchLocation}
                className={classes.searchStyle}
                style={{ width: "100%" }}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={this.props.checked ? searchDark : searchLight} alt="search-location" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box style={{ display: "flex", rowGap: "20px", flexDirection: "column", width: "100%" }}>
              <Box className={classes.locationList}>
                {places.length > 0 ? (
                  places.map((place) => {
                    return (
                      <Box 
                        key={place.id} 
                        className={this.props.checked ? classes.listStyle : classes.listStyleDark}
                        onClick={() => this.handleSelectPlace(place.name)}
                      >
                        <Typography className={classes.city}>{place.name}</Typography>
                        <Typography className={classes.country}>{place.country}</Typography>
                      </Box>
                    )
                  })
                ) : (
                  <Box style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <Typography className={classes.noPlaceFound}>No place found</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Dialog>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  modelDark: {
    borderRadius: "10px",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    backdropFilter: "blur(1 px)",
    background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)",
    boxShadow: "none"
  },
  modelLight: {
    borderRadius: "10px",
    boxShadow: "none",
    border: "1px solid transparent",
    background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #dfd6d6 ,#5B5B5B)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    backdropFilter: "blur(1 px)",
  },
  container: {
    padding: "15px 24px 15px 24px",
    rowGap: "20px",
  },
  headerContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeBtn: {
    height: "48px",
    width: "48px",
    padding: "10px 12px",
  },
  searchStyle: {
    borderRadius: '12px',

    '& [class*="MuiInputAdornment-positionStart"]': {
      marginLeft: '8px',
    },
    '& [class*="MuiInput-underline"]': {
      '&:before': {
        borderWidth: "0",
      },
      '&:after': {
        borderWidth: "0",
      },
      '&:hover': {
        borderWidth: "0",
      }
    },
    '& [class*="MuiInputBase-input"]': {
      padding: '12px 0 14px 12px',
      fontFamily: 'Manrope',
      fontSize: '14px',
      fontWeight: 500,
    },
  },
  topHead: {
    lineHeight: "21px",
    whiteSpace: "nowrap" as "nowrap",
    fontSize: "18px",
    fontFamily: "Manrope",
    fontWeight: 800,
  },
  city: {
    fontWeight: 600,
    fontSize: 16,
    fontFamily: 'Manrope',
    lineHeight: "19px"
  },
  country: {
    fontWeight: 500,
    fontSize: 12,
    fontFamily: 'Manrope',
    lineHeight: "12px"
  },
  listStyle: {
    display: "flex",
    flexDirection: "column" as "column",
    rowGap: "4px",
    '&:hover': {
      background: '#d3d3d3'
    },
    cursor: 'pointer',
  },
  listStyleDark: {
    display: "flex",
    flexDirection: "column" as "column",
    rowGap: "4px",
    '&:hover': {
      background: 'black'
    },
    cursor: 'pointer',
  },
  locationList: {
    display: "flex",
    flexDirection: "column" as "column",
    rowGap: "10px",
    height: "60vh",
    overflowY: "scroll" as "scroll",
    padding: "0 23px"
  },
  noPlaceFound: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: "18px",
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    WebkitBackgroundClip: 'text',
  },
};

export default withStyles(styles)(Location);
// Customizable Area End
