import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { Share } from "@capacitor/share"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  checked:boolean;
  open:boolean;
  Url?:string
  onClose:()=>void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ShareController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  handleShare=async()=>{
   
      const profileUrl = this.props.Url
        if(profileUrl){
        try {
          await Share.share({
            title: 'Check out this profile!',
            text: 'Take a look at this amazing profile.',
            url: profileUrl,
          });

        } catch (error) {
          toast.error('Error sharing:');
        }
      }
  }

  handleClick = async() => {
      const url = this.props.Url
      if(url){
      try {
        await navigator.clipboard.writeText(url);
        toast.success('URL copied to clipboard!');
      } catch (error) {

        toast.error('Failed to copy URL. Please try again.');
      }
    }
  }
  // Customizable Area End
}
