export const userProfile = require("../assets/user-profile.png");
export const chatIcon=require("../assets/chat.svg");
export const timelineIcon=require("../assets/timeline.svg");
export const notificationIcon=require("../assets/aterts.svg");
export const libraryIcon=require("../assets/library.svg");
export const moreIcon=require("../assets/more.svg");
export const bookmarkIcon=require("../assets/bookmark.svg");
export const groupIcon=require("../assets/group.svg");
export const arrowLogo=require("../assets/arrowLogo.png");
export const lightBackground=require("../assets/lightBackground.png");
export const darkBackground=require("../assets/darkBackground.png");
export const darkChatIcon=require("../assets/darkChat.svg");
export const darkTimelineIcon=require("../assets/darkTimline.svg");
export const darkNotificationIcon=require("../assets/darkAlert.svg");
export const darkLibraryIcon=require("../assets/darkLibrary.svg");
export const darkMoreIcon=require("../assets/darkMore.svg");
export const darkBookmarkIcon=require("../assets/darkBookmark.svg");
export const darkGroupIcon=require("../assets/darkGroup.svg");
export const AvatarImage=require("../assets/avatarImage.png");
export const VerifiedImage=require("../assets/verified.png");
export const darkView=require("../assets/dark_view.svg");
export const view=require("../assets/view_.svg");
export const checkbox=require("../assets/checkbox_.svg");
export const lightCheckbox=require("../assets/lightCheckBox.svg");
export const darkCheckBox=require("../assets/darkCheckBox.svg");

export const darkEarning=require("../assets/darkEarnings.svg");
export const darkSetting=require("../assets/darkSetting.svg");
export const darkSubscription=require("../assets/darkSubscription.svg");
export const earning=require("../assets/earnings.svg");
export const setting=require("../assets/setting.svg");
export const subscription=require("../assets/subscription.svg");
export const savedArrows=require("../assets/savedArrows.svg");
export const darkSavedArrows=require("../assets/darkSavedArrow.svg");
export const deleteImage=require('../assets/delete.png');