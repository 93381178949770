// Customizable Area Start
import React from "react";
import { Box, withStyles, createStyles } from "@material-ui/core";
import CreateRepostController, {
  Props,
} from "./CreateRepostController.web";
import PostCreation from '../../postcreation/src/PostCreation.web'
import { leftArrow } from "./assets";

export class CreateRepost extends CreateRepostController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, postData, onCloseQuote, onGoBackAfterSubmit } = this.props;

    return (
      <Box className={classes.container}>
        <img 
          src={leftArrow} 
          alt="left-arrow" 
          className={classes.leftArrow} 
          onClick={onCloseQuote}
        />
        <PostCreation 
          navigation={true} 
          checked={false} 
          onGetNewFeed={onGoBackAfterSubmit} 
          postData={postData}
        />
      </Box>
    )
  }
}

const styles = createStyles({
  container: {
    width: '100%',
  },
  leftArrow: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    marginBottom: '30px',
  },
});

export default withStyles(styles)(CreateRepost);
// Customizable Area End
