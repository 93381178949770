  // Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
  } from "../../../framework/src/Messages/MessageEnum";


export interface Props {
  navigation: any;
  id: string;
  checked?:boolean
  classes?:{[key:string]:string}
}

interface S {

}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DeactivateAccountController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      };
  }

  handleNavigate=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Home"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

}
// Customizable Area End
