// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import React from "react"
import {verifyIcon,verifyOrange,verifyYellow,ownerShipIcon} from "./assets"
import { runEngine } from "../../../framework/src/RunEngine";
import { setStorageData,getStorageData } from "framework/src/Utilities";
import { PostAttributes } from "../../activityfeed/src/ActivityFeedController"
import { IUserProfileAttributes } from "../../customisableuserprofiles/src/ProfileController.web";
export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  checked:boolean
}

export interface IReportIssue {
  id: string,
  type: string,
  attributes: {
    id: number,
    description: string,
    title: string,
    rank: number
  }
}

interface ReportCategory {
  title: string;
  description: string;
}


interface IReportAttributes{
  created_at:string,
  id:number,
  report_issue_id:number,
  report_sub_issue_id:number,
  reported_by: IUserProfileAttributes,
  post:PostAttributes,
  reported_account:IUserProfileAttributes
  
}

interface IReport{
  id:string,
  type:string,
  attributes: IReportAttributes
}

interface S {
  showQuote: boolean
  userPostState:boolean
  isMainModalOpen:boolean
  isSecondaryModalOpen: boolean
  reportCategories: IReportIssue[]
  subReportCategories:IReportIssue[]
  selectedReportIssue:IReportIssue|undefined
  reportId:string,
  reportType:string,
  reportData:IReport|undefined,
  content:string,
}

interface SS {
  id: any;
}

export default class ContentModerationController extends BlockComponent<
  Props,
  S,
  SS
> {
  reportDataApiCallId: string="";
  reportSubIssueApiCallId:string="";
  reportIssuesApiCallId:string="";
  loginDataApiCallId: string | null = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.ReportDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        reportType:'',
        reportData:undefined,
        reportId:'',
        showQuote:false,
        subReportCategories:[],
        userPostState:true,
        isMainModalOpen:false,
        selectedReportIssue:undefined,
        isSecondaryModalOpen:false,
        content:'',
        reportCategories: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const  data  = await message.getData(getName(MessageEnum.ReportDataMessage));
      if(data?.report_type!==""){
        this.setState((prev)=>({
          ...prev,
          reportId:data.report_id,
          reportType:data.report_type,
          content:data.contents
        }))
      }
    }
     

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        apiRequestCallId === this.loginDataApiCallId &&
        !responseJson.errors
      ) {
        await setStorageData("TOKEN", responseJson.meta.token);
      }
      this.handleReportResponse(message)
      this.handleReportIssueResponse(message)
      this.handleReportSubIssueResponse(message)

    }
  }

  async componentDidMount() {
    this.handleFakeLoginUser();
    this.getReportIssue()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if(this.state.reportId!==prevState.reportId){
      this.setState(prev=>({
        ...prev
      }))
      if(this.state.reportType.length>0){
      this.getReport()
      }
    }
  }


  handleReportResponse=(message:Message)=>{
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId===this.reportDataApiCallId){

      if(!responseJson?.errors){
       if( responseJson.data.type == "report_post"){
        this.setState(prev=>({
          ...prev,
          userPostState:false,
          reportData:responseJson.data
        }))
       }else{
        this.setState(prev=>({
          ...prev,
          userPostState:true,
          reportData:responseJson.data
        }))
       }
    }   

  }

  }


  handleReportIssueResponse=(message:Message)=>{
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiRequestCallId===this.reportIssuesApiCallId){
      if(!responseJson.errors){
        this.setState(prev=>({
          ...prev,
        reportCategories: responseJson.data
        }))
      }
    }

  }



  handleReportSubIssueResponse=(message:Message)=>{
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiRequestCallId===this.reportSubIssueApiCallId){
      if(!responseJson?.errors){
        this.setState(prev=>({
          ...prev,
          subReportCategories:responseJson?.data??[]
        }))
      }
       
    }

  }



  openMainModal = () => {
    this.setState({ isMainModalOpen: true ,  isSecondaryModalOpen: false});
  };
  
  closeMainModal = () => {
    this.setState({ isMainModalOpen: false });
  };
  
  openSecondaryModal = (reportedIssue:IReportIssue) => {
    const idNum = reportedIssue.attributes.id
    this.getReportSubIssue(idNum)
    this.setState({ isMainModalOpen: false, isSecondaryModalOpen: true, selectedReportIssue:reportedIssue});
  };

  closeSecondaryModal = () => {
    this.setState({ isSecondaryModalOpen: false });
  };


  getReportSubIssue=async(idNum:number)=>{
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.reportSubIssueApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.reportSubIssuesEndPoint+idNum);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getReportIssue=async()=>{
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.reportIssuesApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.reportIssuesEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getReport=async()=>{
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const endPoint = this.state.reportType == "report_post"? configJSON.reportPostEndPoint : configJSON.reportAccountEndPoint
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.reportDataApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint+`${this.state.reportId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  
  renderVerifyIcon = (val: { user_subscription: string | null }) => {

    switch (val.user_subscription) {
      case 'I am an individual':
        return <img src={verifyIcon} alt="" height={18} width={18} />;
      case 'I am a business':
        return <img src={verifyYellow} alt="" height={18} width={18} />;
      case 'I am a news organization':
        return <img src={verifyOrange} alt="" height={18} width={18} />;
      case null:
        return ""
    }
  }

  renderOwnershipIcon = (val: { user_ownership: boolean }) => {
    if (val.user_ownership) {
      return <img src={ownerShipIcon} alt="" height={18} width={18} />;
    }
  }

  
  handleNavigation=(id:number)=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Profile');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    this.send(message);
  }

  
  handleFakeLoginUser = () => {
    const body = {
      data: {
        attributes: {
          email: configJSON.loginEmail,
          password: configJSON.loginPassword,
        },
        type: "email_account",
      },
    };

    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  navigateWebToTagList = () => {
    this.props.navigation.navigate("CreateContentWeb");
  };
  navigateToTagList = () => {
    this.props.navigation.navigate("CreateContent");
  };

  navigateToAnalyse = () => {
    this.props.navigation.navigate("ContentList");
  };
  navigateWebToAnalyse = () => {
    this.props.navigation.navigate("ContentListWeb");
  };
}

// Customizable Area End
