import React from "react";
import { withStyles, Switch, FormControlLabel } from "@material-ui/core";

export const IOSSwitch = withStyles((theme) => ({
    root: {
      width: '2.625rem',
      height: "1.625rem",
      padding: "0px",
      margin: "0.5rem",
    },
    switchBase: {
      padding: "0.0625rem",
      "&$checked": {
        transform: "translateX(1rem)",
        color: "#fff",
        "& + $track": {
          backgroundColor: "#6200EA",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#6200EA",
        border: "0.375rem solid #181818",
      },
    },
    thumb: {
      width: "1.5rem",
      height: "1.5rem",
    },
    track: {
      borderRadius: "calc(26px/2)",
      border: `0.0625rem solid #181818`,
      backgroundColor: "#181818",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
      height: "auto"
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }: any) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

export const StyledFormControlLabel = withStyles({
  label: {
    fontWeight: 500
  }
})(FormControlLabel);