// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ReviewPrompt";
exports.labelBodyText = "ReviewPrompt Body";

exports.btnExampleTitle = "CLICK ME";
exports.postReviewApiUrl = `bx_block_reviewprompt2/reviews`;
exports.postAPiMethod = "POST";
exports.postApiContentType = "application/json";
exports.loginApiUrl = `bx_block_reviewprompt2/customer_logins`;
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";
exports.signupApiUrl = `bx_block_reviewprompt2/customers`;
exports.signupAPiMethod = "POST";
exports.signupApiContentType = "application/json";
exports.orderApiUrl = `bx_block_reviewprompt2/orders`;
exports.orderAPiMethod = "POST";
exports.orderApiContentType = "application/json";
exports.productAPiEndPoint = "bx_block_reviewprompt2/products";
exports.productAPiMethod = "GET";
exports.productApiContentType = "application/json";
exports.productDetailApiContentType= "application/json";
exports.productDetailApiUrl = "bx_block_reviewprompt2/products";
exports.productDetailAPiMethod = "GET";
exports.productReviewApiContentType= "application/json";
exports.productReviewApiUrl = "bx_block_reviewprompt2/reviews/product_reviews";
exports.productReviewAPiMethod = "GET";
exports.customerOrderApiContentType= "application/json";
exports.customerOrderApiUrl = "bx_block_reviewprompt2/orders/customers_orders";
exports.customerOrderAPiMethod = "GET";
exports.editRatingApiContentType= "application/json";
exports.editRatingrApiUrl = "bx_block_reviewprompt2/reviews/:";
exports.editRatingAPiMethod = "PUT";
exports.deleteRatingApiContentType= "application/json";
exports.deleteRatingApiUrl = "bx_block_reviewprompt2/reviews/:";
exports.deleteRatingAPiMethod = "DELETE";

// Customizable Area End
