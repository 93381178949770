// Customizable Area Start

import { CompositeNavigationProp, NavigationProp } from '@react-navigation/native';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


import { setStorageData } from "../../../framework/src/Utilities";

const BASE_URL = 'https://sewamoapp-185293-ruby.b185293.stage.eastus.az.svc.builder.ai/';

export const configJSON = require("./config");

type RootStackParamList = {
  ReviewPromptPostReview: {
    pDprops: AttributeItem,
    description: string,
    price: number,
    imagess: string,
    name:string,
    token:string
  },
  ReviewPrompt:{},
  ReviewPromptProductlist:{},
  ReviewPromptSignUp:{},
  ReviewPromptLogin:{}

};

type ScreenNavigationProp = NavigationProp<RootStackParamList, "ReviewPromptPostReview">;
type ScreenNavigationProp1 = NavigationProp<RootStackParamList, "ReviewPrompt">;
type ScreenNavigationProp2 = NavigationProp<RootStackParamList, "ReviewPromptProductlist">;
type ScreenNavigationProp3 = NavigationProp<RootStackParamList, "ReviewPromptSignUp">;


type CombinedNavigationProps = CompositeNavigationProp<
ScreenNavigationProp,

CompositeNavigationProp<
ScreenNavigationProp1,
CompositeNavigationProp<
ScreenNavigationProp2,
ScreenNavigationProp3
 >
 >
>;



export interface Props {
  navigation: CombinedNavigationProps;
  id: string;
  key: string;
  route: string;
  checked:boolean

}

interface ResultItem {
  customer: {
    id: number,
    firstName: string,
    email: string
  }
  token: string;
  data: []
}




export interface AttributeItem {
  id: number,
  attributes: {
    name: string,
    price: number,
    description: string,
    images: [
      {

        url: string,
      }
    ]
  }
}



interface S {
  
  
    
  isLoadingReviewPrompt: boolean;
  token: string;
  message: string;
  poc_service_id: string;
  name: string;
  email: string;
  comment: string;
  rating: number;
  product_id: number,
  order_id: number,
  selectedTab: number,
  firstName: string,
  lastName: string,
  signupEmail: string,
  isPopupOpen: boolean,
  isModalVisible: boolean,
  quantity: number,
  productReviewId: number,
  productReview: [],
 
  
  productData: {
    product_id: number;
    name: string;
    description: string;
    price: number;
    sourceImage: string;
  }[];

  registrationSuccess: boolean,
 
  starCount: number,
  productDetails: [],
  customerOrders: {},
  isOrderDetailsModalVisible: boolean,

}

interface SS {
  id: number;

}

export default class ReviewPromptController extends BlockComponent<
  Props,
  S,
  SS

> {

  loginApiCallId: string = "";
  abcApiCallId: string = "";
  productApiItemCallId: string = "";
  productDetailApiCallId: string = "";
  orderApiCallId: string = "";
  reviewsCallId: string = "";
  customerOrderCallId: string = "";
  dfApiCallId: string = "";



  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),


    ];

    this.state = {
      isLoadingReviewPrompt: false,
      token: "",
      message: "",
      poc_service_id: "",

      name: "",
      email: "",
      comment: "",

      rating: 1,
      product_id: 21,
      order_id: 5,
      selectedTab: 0,
      firstName: "",
      lastName: "",
      signupEmail: "",
      isPopupOpen: false,
      isModalVisible: false,
      starCount: 0,

      // rating: "",
      isOrderDetailsModalVisible: false,
      productData: [],
      productDetails: [],

      productReview: [],
      productReviewId: 0,
      quantity: 0,
      customerOrders: [],
      registrationSuccess: false,


    };
 
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  }

  async componentDidMount() {

    this.onEmailChangeApp('')
    this.handleFirstNameApp('')
    this.handleLastNameApp('')
    this.handleSignupEmailApp('')
    this.getData()

  }

  datas = async (item: AttributeItem) => {
    this.props.navigation.navigate("ReviewPromptPostReview", { pDprops: item, description: item.attributes.description, name: item.attributes.name, price: item.attributes.price, imagess: item.attributes.images[0].url, token: this.state.token })}

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);

      this.callFirstReviever(apiRequestCallId, responseJson)
      this.callSecondReviever(apiRequestCallId, responseJson)
      this.callThirdReviever(apiRequestCallId, responseJson)


    }


  }

  callFirstReviever = (apiRequestCallId: string, responseJson: ResultItem) => {
    if (apiRequestCallId != null) {


      if (apiRequestCallId === this.productDetailApiCallId) {
        if (responseJson) {

          this.setState({ productDetails: responseJson.data })

        }
      }
    }
  }

  callSecondReviever = (apiRequestCallId: string, responseJson: ResultItem) => {
    if (apiRequestCallId != null) {
      if (apiRequestCallId === this.abcApiCallId) {
        if (responseJson) {
          this.setState({ isLoadingReviewPrompt: false });

          if (responseJson.data) {
            this.setState({ registrationSuccess: true });


          }
          this.setState({ email: "", firstName: "", lastName: "", signupEmail: "" })
        }

      }


    }
  }


  callThirdReviever = async (apiRequestCallId: string, responseJson: ResultItem) => {
    if (apiRequestCallId != null) {

      if (apiRequestCallId === this.dfApiCallId) {

        if (responseJson) {

          if (responseJson.token) {
            this.getData()

            let customer_details = {
              id: responseJson.customer.id,
              name: responseJson.customer.firstName,
              email: responseJson.customer.email,
            }
            setStorageData("token", responseJson.token);
            setStorageData("customer_details", JSON.stringify(customer_details))

            this.setState({ token: responseJson.token, isLoadingReviewPrompt: false, email: "", firstName: "", lastName: "", signupEmail: "" })

          }

        }
        else {
          alert("Enter valid mail id")
        }

      }
    }
  }


  RedirectScreen = () => this.props.navigation.navigate("ReviewPrompt",{});


  RedirectMyOrder = () => this.props.navigation.navigate("ReviewPromptProductlist",{});

  RedirectSignUpScreen = () =>

    this.props.navigation.navigate("ReviewPromptSignUp",{});

  RedirectLogin = () => {

    this.signup()

    this.props.navigation.navigate("ReviewPromptLogin",{});
  }

  LoginReview = () => this.login()







  onEmailChangeApp = (email: string) => this.setState({ email })


  handleFirstNameApp = (firstName: string) => this.setState({ firstName })

  handleLastNameApp = (lastName: string) => this.setState({ lastName })

  handleSignupEmailApp = (email: string) => this.setState({ signupEmail: email })


  login = () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const attributes = {
      email: this.state.email,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.dfApiCallId = requestMessage.messageId;
    let login_api_url = `${BASE_URL}${configJSON.loginApiUrl}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      login_api_url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attributes)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  signup = () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      "Access-Control-Allow-Headers": "*",
    };
    const attributes = {
      email: this.state.signupEmail,
      first_name: this.state.firstName,
      last_name: this.state.lastName
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.abcApiCallId = requestMessage.messageId;
    let signup_api_url = `${BASE_URL}/${configJSON.signupApiUrl}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      signup_api_url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attributes)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signupAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  getData = () => {
    this.setState({ isLoadingReviewPrompt: true })
    const header = {
      "Content-Type": configJSON.productDetailApiContentType,
      "Access-Control-Allow-Headers": "*",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.productDetailApiCallId = requestMessage.messageId;
    let product_detail_api_url = `${BASE_URL}${configJSON.productDetailApiUrl}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      product_detail_api_url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productDetailAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


}



// Customizable Area End
