import { Box, Grid, Typography, styled, Switch, Dialog } from "@material-ui/core";
import AccessibilityAndDisplayController, { Props } from './AccessibilityAndDisplayController';
import { appsAndSessionDark, appsAndSessionLight, backArrowDark, backArrowWhite, checkedEmptyImage, checkedImage, dataUsesDark, sucIcon, dataUsesWhite, forwardArrowDark, forwardArrowLight, rightArrowDisabled } from "./assets";
import React from "react";
import { createTheme,withStyles} from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import { Link } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


const IOSSwitch = withStyles(({
  root: {
    width: 48,
    height: 28,
    padding: 0,
  },

  switchBase: {
    padding: 0,
    transform: "translate(5px,4.8px)",
    "&$checked": {
      transform: "translate(23px,4.4px)",
      color: theme.palette.common.white,
      "& + $track": {
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
        opacity: 1,
        border: "none",
      },
    },
    
  },
  thumb: {
    width: 24,
    height: 24,
    margin:"-2px -1px"
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: "#1F1F1F",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(Switch);


const webStyle={
  mainContainer:{
    position:'relative' as 'relative'
  },
  switchContainer:{
    display:'flex',
    flexDirection:'column' as 'column',
    alignItems:'flex-end',
    position:"absolute" as 'absolute',
    right:"0%"
  },
  lightText:{
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    color:'#FFFFFF',
    marginTop:'3px',
    marginRight:'30px',
    marginLeft:'2px'
  },
  darkText:{
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal" as 'normal',
    fontWeight: 500,
    color:'#222222',
    marginTop:'3px',
    marginRight:'30px',
    marginLeft:'2px'
  },
  switchTextContainer:{
    display:'flex',
    flexDirection:'row' as 'row',
  },
  model: {
    border:'1px solid #35383F',
    borderRadius:'10px',
    backgroundColor:'#0B0B0B',
    minWidth:'400px',
    padding:'20px' 
  },
  modelLight:{
    border:'1px solid #999999',
    borderRadius:'10px',
    backgroundColor:'#FFFFFF',
    minWidth:'400px',
    padding:'20px'
  },
  modelContainer:{
    minWidth:'453px',
    fontFamily: "Manrope",
  },
  horizontalLine:{
		width:'100%',
		height:'1px',
		backgroundColor:'#222222',
	},
  flexWrapper:{
    display:"flex",
    flexDirection:'column' as 'column',
		gap: '15px',
    fontFamily: "Manrope",
    margin:'16px 40px 20px 40px'
  },
  h1: {
		fontWeight: 'bold',
		fontSize: '32px',
		lineHeight: 1.25,
		letterSpacing: '0.27px',
    fontFamily: "Manrope",
	},
	h2: {
		fontWeight: 'normal',
		fontSize: '24px',
		lineHeight: 1.33,
		letterSpacing: '0.2px',
    fontFamily: "Manrope",
	},
	h3: {
		fontWeight: 'bold',
		fontSize: '20px',
		lineHeight: 1.2,
		letterSpacing: '0.2px',
    fontFamily: "Manrope",
	},
	h4: {
		fontWeight: 'normal',
		fontSize: '18px',
		lineHeight: 1.78,
		letterSpacing: 'normal',
    fontFamily: "Manrope",
	},
  h4Bold: {
    fontWeight: 800,
		fontSize: '18px',
		lineHeight: 1.78,
		letterSpacing: 'normal',
    paddingTop:'19px',
    paddingBottom:'10px',
    fontFamily: "Manrope",
	},
	h5: {
		fontWeight: 600,
		fontSize: '16px',
		lineHeight: 1.38,
		letterSpacing: '0.2px',
    fontFamily: "Manrope",
	},
	h6: {
		fontWeight: 'normal',
		fontSize: '12px',
		lineHeight: '14.4px',
    fontFamily: "Manrope",
	},
	h6Gray: {
		color:'#6B6B6B',
    fontFamily: "Manrope",
	}
}


const inputStyles={  
     
  darkBackdrop:{
     backgroundColor:'#0E0E0E',
     opacity:0.8
  },
   fade:{
      border:'1px solid #5B5B5B',
      borderRadius:'10px',
      display: 'flex'
  },
  lightBackdrop:{
    backgroundColor:'#FFFFFF',
    opacity:0.9
  },
  logoutButtonContainer: {
      display: "flex",
      gap: "12px",
  },
  logoutCancelButton: {
      width: "160px",
      height: "48px",
      padding: "18px 16px 18px 16px",
      gap: "10px",
      borderRadius: "10px",
      border: "1px solid"
  },
  logoutYesButton:{
      width: "160px",
      height: "48px",
      padding: "18px 16px 18px 16px",
      gap: "10px",
      borderRadius: "10px",
      background: "rgba(255, 77, 103, 1)"
  },
  iconButton:{
      color: "#6B6B6B",
  }
}

export default class AccessibilityAndDisplay extends AccessibilityAndDisplayController{

  constructor(props:Props){
    super(props);
  }

  renderHeader = (name: string, goTo: string) => {
    return (      
    <Box className="headerNav">
      {goTo=="accountAccess" ? <Link to="/settings" style={{ textDecoration: 'none' }}>
        <Grid container data-test-id="back-btn" style={{alignItems:'center'}}>
          <Grid item ><img src={this.props.checked? backArrowDark : backArrowWhite} /></Grid>
          <Grid item style={{marginLeft:'12px'}}><Typography className="h2" style={this.props.checked ? {color:'#222222'} : {color:'#fff'}}>{name}</Typography></Grid>
        </Grid> 
      </Link>:
      <Grid container onClick={() => this.setState({renderAccessibility: goTo})} data-test-id="back-btn" style={{alignItems:'center'}}>
      <Grid item ><img src={this.props.checked? backArrowDark : backArrowWhite} /></Grid>
      <Grid item style={{marginLeft:'12px'}}><Typography className="h2" style={this.props.checked ? {color:'#222222'} : {color:'#fff'}}>{name}</Typography></Grid>
    </Grid>}
    </Box>
    )
  }

  renderRightArrow = () => {
    const {checked} = this.props;
    return (<Grid item style={{marginLeft:'auto'}}><img src={checked ? forwardArrowLight : forwardArrowDark } /></Grid>)
  }

  renderSwitch = () => {
    return (
        <Box style={webStyle.mainContainer}>
          <Box style={webStyle.switchContainer}>
            <Box style={webStyle.switchTextContainer}>
            <IOSSwitch data-test-id="switch" checked={true} />
            </Box>
          </Box>
        </Box>
      )
  }

  renderSubCar = () => {
    const {checked} = this.props;
    return (
      <Box className="wrapper" data-test-id='Accessibility-and-display'>
        {this.renderHeader('Accessibility and display', 'accountAccess')}
        <Typography className="h6">Manage how Arrows content is displayed to you.</Typography>
        <Box className="flexWrapper" style={{gap:'20px'}}>
          <Grid container className="cursor" spacing={3} onClick={() => this.setState({renderAccessibility:'Display'})} data-test-id="display-btn">
            <Grid item>
              <img src={checked ? appsAndSessionLight : appsAndSessionDark}/>
            </Grid>
            <Grid item xs={7}>
              <Box>
                <Typography className="h5">Display</Typography>
                <Typography className="h6" style={{marginTop:'10px'}}>Manage your font size, colour, and background. These settings affect all the Arrows accounts on this device.</Typography>
              </Box>
            </Grid>
            {this.renderRightArrow()}
          </Grid>

          <Grid container spacing={3} className="cursor" onClick={() => this.setState({renderAccessibility:'Data Usage'})} data-test-id="data-usage-btn">
            <Grid item>
              <img src={checked ? dataUsesDark : dataUsesWhite}/>
            </Grid>
            <Grid item xs={7}>
              <Box>
                <Typography className="h5">Data usage</Typography>
                <Typography className="h6" style={{marginTop:'10px'}}>Limit how Arrows uses some of your network data. These settings affect all the Arrows accounts on this device.</Typography>
              </Box>
            </Grid>
            {this.renderRightArrow()}
          </Grid>
        </Box>
        
      </Box>
    )
  }

  renderDisplayAndSoundTab = () => {
    const {checked} = this.props;
    return <Box className='wrapper' data-test-id="display-and-sound-tab">
      {this.renderHeader('Display & Sound', 'Accessibility and display')}
      <Box className="flexWrapper" style={{gap:'20px'}}>

        <Box className={checked ? "grayBoxLight" : "grayBoxDark" }>
          <Typography className="h4bold">Media</Typography>
          <Grid container>
            <Grid item><Typography className="h5">Media previews</Typography></Grid>
            <Grid item style={{marginLeft:'auto'}}><IOSSwitch data-test-id="media-preview-switch" checked={this.state.mediaPreviewSwitch} onChange={() => this.onMediaPreviewChange()} /></Grid>
          </Grid>
        </Box>

        <Box className={checked ? "grayBoxLight" : "grayBoxDark" }>
          <Typography className="h4bold">Display</Typography>
          <Grid container className="cursor" onClick={() => this.onThemeModalOpen()} data-test-id="render-theme-modal">
            <Grid item>
              <Box>
                <Typography className="h5">{this.state.selectedThemeName}</Typography>
                <Typography className="h6" style={{marginTop:'5px'}}>Use device settings</Typography>
              </Box>
              </Grid>
            <Grid item style={{marginLeft:'auto'}}>{this.renderRightArrow()}</Grid>
          </Grid>
        </Box>

        <Box className={checked ? "grayBoxLight" : "grayBoxDark" }>
          <Typography className="h4bold">Sound</Typography>
          <Grid container>
            <Grid item><Typography className="h5">Sound effects</Typography></Grid>
            <Grid item style={{marginLeft:'auto'}}><IOSSwitch data-test-id="sound-effect-switch" checked={this.state.soundEffectSwitch} onChange={(event) => {this.onSoundEffectChange()}} /></Grid>
          </Grid>
        </Box>

        <Box className={checked ? "grayBoxLight" : "grayBoxDark" }>
          <Typography className="h4bold">Web Browser</Typography>
          <Grid container>
            <Grid item>
              <Box>
                <Typography className="h5">Use in-app browser</Typography>
                <Typography className="h6" style={{marginTop:'10px'}}>Open external links with Arrows browser</Typography>
              </Box>
            </Grid>
            <Grid item style={{marginLeft:'auto'}}><IOSSwitch data-test-id="use-in-app-broswer-switch" checked={this.state.inAppBrowserSwitch} onChange={(event) => {this.onBrowserChange()}} /></Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  }


  renderDataUsage = () => {
    const {checked} = this.props;
    return (
      <Box className="wrapper" data-test-id="data-usage-tab">
        {this.renderHeader('Data usage', 'Accessibility and display')}
        <Box className="flexWrapper">
          <Box className={checked ? "grayBoxLight" : "grayBoxDark" }>
            <Typography className="h4bold">Data saver</Typography>
            <Grid container>
              <Grid item xs={8}>
                <Box>
                  <Typography className="h5">Data saver</Typography>
                  <Typography className="h6" style={{marginTop:'10px'}}>When enabled, videos won't autoplay and lower-quality images load. This automatically reduces your usage for all Arrows accounts on this device.</Typography>
                </Box>
              </Grid>
              <Grid item style={{marginLeft:'auto'}}><IOSSwitch data-test-id="data-saver-switch" checked={this.state.dataSaverSwitch} onChange={(event) => this.onDataSaverChange()} /></Grid>
            </Grid>
          </Box>

          <Box className={checked ? "grayBoxLight" : "grayBoxDark" }>
            <Typography className="h4bold">Images</Typography>
            <Grid container className="cursor" onClick={() => this.onModalOpen("High quality image")} data-test-id="HQI-modal">
              <Grid item xs={8}>
                <Box>
                  <Typography className="h5" style={this.state.dataSaverSwitch ? {color:'#4E4E4E'} : {}} data-test-id="can-disabled-text">High quality images</Typography>
                  <Typography className="h6" style={this.state.dataSaverSwitch ? {color:'#4E4E4E', marginTop:'5px'} : {marginTop:'5px'}}>{this.state.imageQuality}</Typography>
                  <Typography className="h7" style={this.state.dataSaverSwitch ? {color:'#4E4E4E'} : {}}>Select when high-quality images(uo to 4K) should load automatically.</Typography>
                </Box>
              </Grid>
              {this.state.dataSaverSwitch ? <Grid item style={{marginLeft:'auto'}}><img src={rightArrowDisabled}/></Grid> : this.renderRightArrow()}
            </Grid>
            <Grid container className="cursor" onClick={() => this.onModalOpen("High-quality image uploads")} data-test-id="HQIU-modal">
              <Grid item xs={8}>
                <Box>
                  <Typography className="h5" style={this.state.dataSaverSwitch ? {color:'#4E4E4E'} : {}}>High-quality images uploads</Typography>
                  <Typography className="h6" style={this.state.dataSaverSwitch ? {color:'#4E4E4E', marginTop:'5px'} : {marginTop:'5px'}}>{this.state.imageUploadQuality}</Typography>
                  <Typography className="h7" style={this.state.dataSaverSwitch ? {color:'#4E4E4E'} : {}}>Select when to upload high-quality images(uo to 4K).</Typography>
                </Box>
              </Grid>
              {this.state.dataSaverSwitch ? <Grid item style={{marginLeft:'auto'}}><img src={rightArrowDisabled}/></Grid> : this.renderRightArrow()}
            </Grid>
          </Box>

          <Box className={checked ? "grayBoxLight" : "grayBoxDark" }>
            <Typography className="h4bold">Video</Typography>
            <Grid container className="cursor" onClick={() => this.onModalOpen("High quality video")} data-test-id='HQV-modal'>
              <Grid item xs={8}>
                <Box>
                  <Typography className="h5" style={this.state.dataSaverSwitch ? {color:'#4E4E4E'} : {}}>High quality Video</Typography>
                  <Typography className="h6" style={this.state.dataSaverSwitch ? {color:'#4E4E4E', marginTop:'5px'} : {marginTop:'5px'}}>{this.state.videoQuality}</Typography>
                  <Typography className="h7" style={this.state.dataSaverSwitch ? {color:'#4E4E4E'} : {}}>Select when the highest quality available should Play.</Typography>
                </Box>
              </Grid>
              {this.state.dataSaverSwitch ? <Grid item style={{marginLeft:'auto'}}><img src={rightArrowDisabled}/></Grid> : this.renderRightArrow()}
            </Grid>

            <Grid container className="cursor" onClick={() => this.onModalOpen("High-quality video uploads")} data-test-id="HQVU-modal">
              <Grid item xs={8}>
                <Box>
                  <Typography className="h5" style={this.state.dataSaverSwitch ? {color:'#4E4E4E'} : {}}>High-quality video uploads</Typography>
                  <Typography className="h6" style={this.state.dataSaverSwitch ? {color:'#4E4E4E', marginTop:'5px'} : {marginTop:'5px'}}>{this.state.videoUploadQuality}</Typography>
                  <Typography className="h7" style={this.state.dataSaverSwitch ? {color:'#4E4E4E'} : {}}>Select when to upload high-quality videos.</Typography>
                </Box>
              </Grid>
              {this.state.dataSaverSwitch ? <Grid item style={{marginLeft:'auto'}}><img src={rightArrowDisabled}/></Grid> : this.renderRightArrow()}
            </Grid>

            <Grid container className="cursor" onClick={() => this.onModalOpen("Video autoplay")} data-test-id="VA-modal">
              <Grid item xs={8}>
                <Box>
                  <Typography className="h5" style={this.state.dataSaverSwitch ? {color:'#4E4E4E'} : {}}>Video autoplay</Typography>
                  <Typography className="h6" style={this.state.dataSaverSwitch ? {color:'#4E4E4E', marginTop:'5px'} : {marginTop:'5px'}}>{this.state.videoAutoPlay}</Typography>
                  <Typography className="h7" style={this.state.dataSaverSwitch ? {color:'#4E4E4E'} : {}}>Select when videos should play automatically.</Typography>
                </Box>
              </Grid>
              {this.state.dataSaverSwitch ? <Grid item style={{marginLeft:'auto'}}><img src={rightArrowDisabled}/></Grid> : this.renderRightArrow()}
            </Grid>
          </Box>

          <Box className={checked ? "grayBoxLight" : "grayBoxDark" }>
            <Typography className="h4bold">Data sync</Typography>
            <Grid container>
              <Grid item><Typography className="h5" style={this.state.dataSaverSwitch ? {color:'#4E4E4E'} : {}}>Sync data</Typography></Grid>
              <Grid item style={{marginLeft:'auto'}}><IOSSwitch data-test-id="sync-data-switch" disabled={this.state.dataSaverSwitch} checked={this.state.syncDataSwitch} onChange={(event) => this.onSyncDataChange()} /></Grid>
            </Grid>

            <Grid container className="cursor" onClick={() => this.onModalOpen("Sync interval")} data-test-id="SI-modal">
              <Grid item xs={8}>
                <Box>
                  <Typography className="h5" style={this.state.dataSaverSwitch ? {color:'#4E4E4E'} : {}}>Sync interval</Typography>
                  <Typography className="h6" style={this.state.dataSaverSwitch ? {color:'#4E4E4E', marginTop:'5px'} : {marginTop:'5px'}}>{this.state.syncInterval}</Typography>
                  <Typography className="h7" style={this.state.dataSaverSwitch ? {color:'#4E4E4E'} : {}}>Allow Arrows sync data in the background to enhance your experience.</Typography>
                </Box>
              </Grid>
              {this.state.dataSaverSwitch ? <Grid item style={{marginLeft:'auto'}}><img src={rightArrowDisabled}/></Grid> : this.renderRightArrow()}
            </Grid>
          </Box>
        </Box>
      </Box>
    )
  }

  renderModel = () => {

    if(this.state.dataSaverSwitch){
      this.setState({renderModelName:''});
      return ;
    } 

    return (
      <Dialog data-test-id="render-modal" onClose={() => this.onModalClose()} open={(this.state.renderModelName.length > 0)} PaperProps={{style: this.props.checked ? webStyle.modelLight : webStyle.model}} BackdropProps={{className: (this.props.checked === true ? "backdropsAtyle" : "backdropsAtyleDark")}}>
        <Box style={webStyle.modelContainer}>
          <Typography style={{...webStyle.h4Bold, textAlign:'center', paddingBottom:'14px'}}>{this.state.renderModelName}</Typography>
          <Box style={webStyle.horizontalLine}></Box>
          <Box style={webStyle.flexWrapper}>
            {this.state.optionsList.map((item, index) => (
              <Grid container key={index}>
                <Grid item><Typography style={webStyle.h5}>{item}</Typography></Grid>
                <Grid item style={{marginLeft:'auto'}}><img data-test-id={`options-button-${index}`} src={this.state.selectedQualityModelOption === index ? checkedImage : checkedEmptyImage} onClick={() => {this.setState({selectedQualityModelOption:index})}}/></Grid>
              </Grid>)
            )}
          </Box>
        </Box>
      </Dialog>
    )
  }

  renderThemeModal = () => {

    return (<Dialog data-test-id="render-theme" onClose={() => this.onModalClose()} open={(this.state.renderModelName.length > 0)} PaperProps={{style: this.props.checked ? webStyle.modelLight : webStyle.model}} BackdropProps={{className: (this.props.checked === true ? "backdropsAtyle" : "backdropsAtyleDark")}}>
        <Box style={webStyle.modelContainer}>
          <Typography style={{...webStyle.h4Bold, textAlign:'center'}}>{this.state.renderModelName}</Typography>
          <Box style={webStyle.horizontalLine}></Box>
          <Box style={webStyle.flexWrapper}>
            {this.state.themeOptionList.map((item, index) => (
              <Grid container key={index}>
                <Grid item><Typography style={webStyle.h5}>{item}</Typography></Grid>
                <Grid item style={{marginLeft:'auto'}}><img data-test-id={`options-button-${index}`} src={this.state.selectedQualityModelOption === index ? checkedImage : checkedEmptyImage} onClick={() => {this.onThemeChange(item, index)}}/></Grid>
              </Grid>)
            )}
          </Box>
        </Box>
      </Dialog>)
  }

  renderMessage = () => {
    return(
        <div className="messageScreen">
            <div className="messageContainer">
                <img src={this.state.messageType === "suc" ? sucIcon : ""} />
                <span>{this.state.message}</span>
            </div>
        </div>
    )
  }

  renderSection = () => {
    return (
      <Box>
        {this.state.renderAccessibility === 'Accessibility and display' && this.renderSubCar()}
        {this.state.renderAccessibility === 'Display' && this.renderDisplayAndSoundTab()}
        {this.state.renderAccessibility === 'Data Usage' && this.renderDataUsage()}
        {this.state.renderModelName != '' && this.state.renderModelName != 'Theme' && this.renderModel()}
        {this.state.renderModelName === 'Theme' && this.renderThemeModal()}
      </Box>
    )
  }

  render(){
    return(
      <React.Fragment>
        <StyleContainer>
          {this.renderSection()}
        </StyleContainer>
      </React.Fragment>
    )
  }
}



const StyleContainer = styled(Box)({
  "& .backdropsAtyleDark":{
    background: "rgba(8,4,8,0.7) !important" ,
    backdropFilter: "blur(1px)",
  },
  "& .backdropsAtyle":{
    background:"rgba(255, 255, 255, 0.9)", 
    backdropFilter: "blur(1px)",
  },
  "& .fieldContainer": {
    position:'relative',
    display: "flex",
    flexDirection: "column",
    paddingBottom: "30px"
  },
  "& .fieldContainer .MuiOutlinedInput-root":{
      borderRadius: "10px"
  },
  "& .fieldContainer label": {
      fontFamily: "Manrope",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "21px",
      marginBottom: "8px"
  },
  "& .messageField": {
    height: '100px',
    borderRadius:'8px',
    border: '1px solid #6B6B6B',
    padding:'9px 16px',
    fontFamily: "Manrope",
  },
  "& .messageField::placeholder":{
    fontFamily: "Manrope",
    fontSize:'14px',
    color:'#6B6B6B',
    fontWeight:600
  },
  "& .messageField:focus":{
    outline:'none',
    border:'2px solid #3858E3'
  },
  "&. messageLimitTxt":{
    position:'absolute',
    color:'#6B6B6B',
    fontSize:'10px',
    bottom:'0'
  },
  "& .wrapper":{
    fontFamily: "Manrope",
    display:'flex',
		flexDirection:'column' as 'column',
		gap: '30px',
		padding:'10px 0',
  },
  "& .flexWrapper":{
    display:"flex",
    flexDirection:'column' as 'column',
		gap: '15px',
    fontFamily: "Manrope",
  },
  "& .headerNav":{
    fontSize:'24px',
		color:'#fff',
    cursor:'pointer'
  },
  "& .cursor":{
    cursor:'pointer'
  },
  "& .search-container": {
    border: '1px solid',
    borderRadius: '10px',
    height: "48px",
    padding: '0px 15px',
    alignContent: 'center',
  },
  "& .search-field::before": {
    display: "none"
  },
  "& .search-field::after": {
    display: "none"
  },
  "& .fillBtn":{
    borderRadius: "100px",
    padding: "8px 20px",
		background:'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
		color:"#fff",
		fontWeight:700,
		textTransform:'capitalize' as 'capitalize',
		display:'flex',
		justifyContent:'center',
		alignItems:'center',
		fontFamily: "Manrope",
		fontSize: "12px",
		cursor:'pointer',
  },
  "& .fillBtn .label":{
    fontWeight:700,
    fontSize:'14px',
    lineHeight:"16.9px"
  },
  "& .outlineBtnLight": {
		fontFamily: "Manrope",
		fontSize: "14px",
		fontStyle: "normal" as 'normal',
		boxShadow:"none !important",
		background: "linear-gradient(330.07deg, #fcfaff, #fcfaff) padding-box,linear-gradient(330.07deg, #3858E3 -51.03% , #BA05F7 138.78%) border-box",
		backgroundClip: "padding-box, border-box",
		backgroundOrigin: "padding-box, border-box",
		border :"1px solid transparent",
		borderRadius: "100px",
		textTransform: "capitalize" as "capitalize",
    padding: "7px 20px",
    display:'flex',
		justifyContent:'center',
		alignItems:'center',
  },
  "& .outlineBtnDark":{
    background: "linear-gradient(330.07deg, #110b1b, #110b1b) padding-box,linear-gradient(330.07deg, #3858E3 -51.03% , #BA05F7 138.78%) border-box",
		backgroundClip: "padding-box, border-box",
		backgroundOrigin: "padding-box, border-box",
		border :"1px solid transparent",
		borderRadius: "100px",
		textTransform: "capitalize" as "capitalize",
		color: "#FFF",
		fontFamily: "Manrope",
		fontSize: "14px",
		fontStyle: "normal" as 'normal',
		fontWeight: 700,
		boxShadow:"none !important",
    padding: "7px 20px",
    display:'flex',
		justifyContent:'center',
		alignItems:'center',
  },
  "& .coloredTxt": {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
		fontSize: "14px",
		fontStyle: "normal" as 'normal',
		fontWeight: 700,
		WebkitTextFillColor: 'transparent', 
		WebkitBackgroundClip: 'text',
		backgroundClip: 'text',  
		fontFamily: "Manrope",
		textTransform:"none" as "none",
    lineHeight:"16.9px"
  },
  "& .tabs .Mui-selected":{
    color: "rebeccapurple",
		fontSize: "16px",
		fontStyle: "normal" as 'normal',
		fontWeight: 600,
    lineHeight: '19.2px'
  },
  "& .tabs .MuiTabs-indicator":{
    background: "rebeccapurple !important",
    height:'4px',
    borderRadius:'100px',
  },
  "& .tabStyle":{
    fontFamily:"Manrope",
    fontWeight: 600,
    fontSize:"16px",
    textTransform:"capitalize" as "capitalize",
    borderBottom:"3px solid gray",
  },
  "& .submitButton": {
    width: "160px",
    height: "48px",
    padding: "15px 30px 15px 30px",
    gap: "5px",
    borderRadius: "10px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    marginLeft:'auto'
  },
  "& .submitButton .label":{
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "16.8px",
    textAlign: "center",
    textTransform: "unset",
    color: "white"
  },
  "& .horizontalLine":{
		width:'100%',
		height:'1px',
		backgroundColor:'#222222',
	},
  "& .grayBoxLight":{
    borderRadius:'10px',
		padding:'15px',
		display:'flex',
		gap:'15px',
		flexDirection:'column' as 'column',
    border: '1px solid transparent',
    background: "linear-gradient(to right, #E9E9E9, #E9E9E9) padding-box,linear-gradient(to right, #50505050, #5B5B5B) border-box",
  },
  "& .grayBoxDark":{
    borderRadius:'10px',
		padding:'15px',
		display:'flex',
		gap:'15px',
		flexDirection:'column' as 'column',
    border: '1px solid transparent',
    background: "linear-gradient(to right, #181818, #181818) padding-box,linear-gradient(to right, #50505050, #5B5B5B) border-box",
  },
  "& .h1":{
    fontWeight: 'bold',
		fontSize: '32px',
		lineHeight: 1.25,
		letterSpacing: '0.27px',
    fontFamily: "Manrope",
  },
  "& .h2":{
    fontWeight: 'normal',
		fontSize: '24px',
		lineHeight: 1.33,
		letterSpacing: '0.2px',
    fontFamily: "Manrope",
  },
  "& .h3":{
    fontWeight: 'bold',
		fontSize: '20px',
		lineHeight: 1.2,
		letterSpacing: '0.2px',
    fontFamily: "Manrope",
  },
  "& .h4":{
    fontWeight: 'normal',
		fontSize: '18px',
		lineHeight: 1.78,
		letterSpacing: 'normal',
    fontFamily: "Manrope",
  },
  "& .h4bold": {
    fontWeight:800,
    fontSize:'18px',
    lineHeight:"21px",
    fontFamily: "Manrope",
  },
  "& .h5":{
    fontWeight: 600,
		fontSize: '16px',
		lineHeight: 1.38,
		letterSpacing: '0.2px',
    fontFamily: "Manrope",
  },
  "& .h6":{
    fontWeight: 'normal',
		fontSize: '12px',
		lineHeight: '14.4px',
		color:'#6B6B6B',
    fontFamily: "Manrope",
  },
  "& .h7":{
    color:'#BABABA',
    fontSize:'10px',
    fontFamily: "Manrope",
    lineHeight:'12px',
    marginTop:'10px'
  }
})