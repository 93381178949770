import React from 'react'
// Customizable Area Start 
import {
    Box,
    Dialog,
    Grid,
    Typography,
    styled,
    Button
} from '@material-ui/core'

import PinedPostModalController,{Props} from './PinedPostModalController.web';
import { configJSON } from './ActivityFeedController';
// Customizable Area End

export default class PinedPostModal  extends PinedPostModalController {

    constructor(props: Props) {
     // Customizable Area Start      
      super(props);  
     // Customizable Area End  
    }
    // Customizable Area Start

    conditionRemover =(condition:boolean,trueStatement:string,falseStatement:string)=>{
        return condition? trueStatement: falseStatement
      }


    renderModal=()=>{
        return (
        <DialogBox data-testid="pin-post"
            open={this.props.open}
            onClose={this.props.onClose}
            PaperProps={{
              className: this.conditionRemover(!this.props.checked, "modelDarkStyle", "modelLightStyle")
            }}
          >
            <GridContainer container justifyContent="center" alignItems="center" direction="column" >
      
              <Box>
                <Typography className={"name"}>{this.conditionRemover(this.props.isPinned,configJSON.pinnedPostTitle,configJSON.unpinnedPostTitle)} </Typography>
              </Box>
      
              <Box>
                <Typography className={"emaptySubhead"}>{this.conditionRemover(
                  this.props.isPinned,
                  configJSON.pinnedDescription,
                  configJSON.unpinnedDescription)}
                  </Typography>
              </Box>
      
              <Box className='muteAction'>
                <Button 
                   data-test-id="noBtn"
                   onClick={this.handleCancel}
                    className={this.conditionRemover(this.props.checked,"muteBtnDeny","muteBtnDenyDark")}>
                  <Typography className={"showmore"} >{configJSON.cancel}</Typography>
                </Button>
                <Button 
                onClick={this.handleConfirmation} 
                data-test-id="pinConfirm" 
                className={"muteBtn"}>{this.conditionRemover(this.props.isPinned,configJSON.Unpin,configJSON.Pin)}</Button>
              </Box>
            </GridContainer>
          </DialogBox>
      )
    }
    // Customizable Area End
    render(){
    // Customizable Area Start
    return(
       <BoxContainer>
        {this.renderModal()}
       </BoxContainer>
    )
    // Customizable Area End    
    }
}
// Customizable Area Start
const BoxContainer = styled(Box)({

})

const DialogBox = styled(Dialog)({
   "& .bottomModelDarkStyle": {
    borderRadius: "6px",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    backdropFilter: "blur(1 px)",
    boxShadow: "none",
    position: "absolute" as "absolute",
    top: "80%"
  },

  "& .modelDarkStyle": {
    borderRadius: "10px",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    backdropFilter: "blur(1 px)",
    background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)",
    boxShadow: "none"
  },
  "& .modelLightStyle": {
    borderRadius: "10px",
    boxShadow: "none",
    border: "1px solid transparent",
    background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #35383F ,#35383F)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    backdropFilter: "blur(1 px)",
  },

  "& .bottomModelStyle":{
    borderRadius: "6px",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    backdropFilter: "blur(1 px)",
    boxShadow: "none",
    position: "absolute" as "absolute",
    top: "80%"
  }
})
const GridContainer = styled(Grid)({
  padding: "27px 24px", 
  rowGap: "24px", 
  width: "375px",
  height: "220px",
  "& .modelDarkStyle": {
    borderRadius: "10px",
    backgroundClip: "padding-box, border-box",
    background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)",
    boxShadow: "none",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    backdropFilter: "blur(1 px)",
  },
  "& .modelLightStyle": {
    background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #35383F ,#35383F)",
    borderRadius: "10px",
    boxShadow: "none",
    border: "1px solid transparent",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    backdropFilter: "blur(1 px)",
  },
  "& .name": {
    fontSize: "18px",
    whiteSpace: "nowrap" as "nowrap",
    fontFamily: "Manrope",
    fontWeight: 800,
    lineHeight: "21px",
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },

  "& .muteBtn": {
    boxShadow: "none !important",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    padding: "16px",
    width: "160px",
    height: "48px",
    color: "white",
    fontSize: "14px",
    fontWeight: 700
  },

  "& .emaptySubhead": {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "center" as "center"
  },
  "& .muteBtnDeny": {
    boxShadow: "none !important",
    background: "linear-gradient( #fff, #fff),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    padding: "16px",
    width: "160px",
    height: "48px"
  },

  "& .muteBtnDenyDark": {
    boxShadow: "none !important",
    background: "linear-gradient(#0d0c0c, #0d0c0c),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    padding: "16px",
    width: "160px",
    height: "48px"
  },

  "& .showmore": {
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
    lineHeight: "21px",
    background: " linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  },

  "& .muteAction":{
    display: "flex", 
    columnGap: "12px" 
    }

   })
   // Customizable Area End