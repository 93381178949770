// Customizable Area Start
import React from "react";
// Customizable Area Start
import { Box, Button, createStyles, InputAdornment, MenuItem, OutlinedInput, Select, Typography, withStyles } from "@material-ui/core";
import {
  CopyIcon,
  EyeIcon,
  Live,
  LiveIcon,
} from "./assets";
import {
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Divider from '@material-ui/core/Divider';


// Customizable Area End

// Customizable Area Start
const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary: {
      main: "#BA05F7",
    },
    info: {
      main: "#222222",
    },
    success: {
      main: "#000000",
    },
    warning: {
      main: "#222222",
    },
  },
});

const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#FF00FF",
    },
    info: {
      main: "#FFFFFF",
    },
    success: {
      main: "#FFFFFF",
    },
    warning: {
      main: "#78716C",
    },
  },
});
// Customizable Area End
import LiveStreaming1Controller, { Props } from "./LiveStreaming1Controller.web";


export default class LiveStreaming1 extends LiveStreaming1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  conditionFilter = (condition: any, trueStatement: any, falseStatement: any) => {
    return condition ? trueStatement : falseStatement
  }
  CustomMenuItem = withStyles((theme) => ({
    root: {
      backgroundColor: this.props.checked ? "var(--Neutrals-Cool-gray-50, #E9E9E9)" : "var(--Neutrals-Cool-gray-500, #222)",
      display: "flex",
      width: "100%",
      height: "44px",

      "&.MuiPaper-rounded": {
        borderRadius: "8px !important",
      },
      '&.MuiList-padding': {
        padding: "0px !important"
      },
      list: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      '&:hover': {
        backgroundColor: this.props.checked ? '#d3d3d3' : "black",
      },
      '&.Mui-selected': {
        background: "var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"
      },
      alignItems: "center",
      gap: "8px",
      justifyContent: 'space-between',
      fontWeight: 600
    },
  }))(MenuItem);



  // Customizable Area End

  render() {
    // Customizable Area Start
    const theme = this.props.checked ? lightTheme : darkTheme;


    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          style={
            this.props.checked
              ? staticStyles.backgroundImage
              : staticStyles.backgroundImageDark
          }
        >
          {this.state.currentStep === 1 ?
            <Box style={{ border: "1px solid #35383F", width: "621px", height: "594px", margin: "10% 30%" }}>
              <Box display={"flex"} justifyContent={"space-between"} >
                <Box>
                  <ArrowBackIosIcon style={{ color: "#FFF", marginLeft: "20px", marginTop: "30px" }} />
                </Box>
                <Box>
                  <Typography style={{ fontFamily: "Manrope", color: "#FFFFFF", fontWeight: 800, marginTop: "30px", marginLeft: "-35px", marginBottom: "10px" }}>Go Live</Typography>
                </Box>
                <Box></Box>
              </Box>
              <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gridRowGap={"20px"}>
                <Box>
                  <img src={Live} />
                </Box>
                <Box>
                  <Typography style={staticStyles.sectionHead}>Add Live Video Details</Typography>
                </Box>
                <Box>
                  <Typography style={{ fontFamily: "Manrope", fontSize: "16px", fontWeight: 600, color: "#FFF", marginLeft: "12%", marginRight: "11%" }}>Go live by connecting to your choice of streaming software. To get started, add a title and select the audience for your live video.</Typography>
                </Box>
                <Box width={"65%"}>
                  <OutlinedInput
                    fullWidth
                    data-testid={"Title"}
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange}
                    placeholder="Add a title"
                    style={{ borderRadius: "10px", borderColor:this.state.title? "#8833FF":""}}
                  />

                </Box>
                <Box width={"65%"}>
                  <Select
                   data-testid={"audience"}
                    style={{ borderRadius: "10px",borderColor:"#8833FF"}}
                    IconComponent={(iconProps) => (
                      <KeyboardArrowDownIcon {...iconProps} style={{ color: this.conditionFilter(this.props.checked, "black", "white") }} />
                    )}
                    
                    MenuProps={{
                      PaperProps: {
                        style: {
                          marginTop: '8px',
                          borderRadius: '10px',
                          background: "none",
                        }
                      },
                      MenuListProps: {
                        style: {
                          paddingTop: '0px',
                          paddingBottom: '0px',
                          maxHeight: "364px"
                        }
                      },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null
                    }}
                    name="audience"
                    fullWidth
                    onChange={this.handleAudience}
                    value={this.state.selectedAudience}
                   
                    variant="outlined"
                  >
                    {this.state.audience.map((val: any, index: number) => (
                      <this.CustomMenuItem key={index} value={val}>
                        {val}
                      </this.CustomMenuItem>
                    ))}
                  </Select>

                </Box>
                <Box>
                  <Button   data-testid={"Next"}style={staticStyles.NextBtn} endIcon={<ChevronRightIcon />} onClick={this.handleNext}>Next</Button>
                </Box>
              </Box>
            </Box> :
            <Box display={"flex"} style={{ border: "1px solid #35383F", width: "60%", height: "85dvh" }}>
              <Box display={"flex"} flexDirection={"column"} bgcolor={"#181818"} justifyContent={"center"} width={"23%"} alignItems={"center"} margin={"4%"} marginRight={"0%"} borderRadius={"15px"} padding={"4%"} gridRowGap={"5px"}>
                <Box>
                  <img src={EyeIcon} />
                </Box>
                <Box>
                  <Typography style={staticStyles.subHeading}>Preview</Typography>
                </Box>
                <Box>
                  <Typography style={{ fontFamily: "Manrope", fontSize: "12px", color: "#FFF", fontWeight: 700, textAlign: "center" }}>Connect Streaming software
                    to go live</Typography>
                </Box>
              </Box>
              <Box width={"70%"} style={{ margin: "3%" }} >
                <Box display={"flex"} justifyContent={"space-between"} marginBottom={"5px"}>
                  <Box>
                    <Typography style={staticStyles.subHeading}>Live Video</Typography>
                  </Box>
                  <Box>
                    <Typography style={{ ...staticStyles.sectionHead, fontSize: "18px" ,cursor:"pointer"}}>Go Live</Typography>
                  </Box>

                </Box>
                <Divider style={{ marginLeft: "-3%" }} />
                <Box display={"grid"} gridRowGap={"15px"} marginLeft={"2%"} marginTop={"2%"} sx={{ overflow: "auto" }} height={"90%"}>
                  <Box display={"grid"} gridRowGap={"10px"} marginBottom={"10px"}>
                    <Typography style={{ ...staticStyles.subHeading ,fontSize: "24px"}}>Go live with streaming software</Typography>
                    <Typography style={{ fontFamily: "Manrope", fontSize: "16px", color: "#BABABA", fontWeight: 600 }}>Copy and paste the stream key into your streaming software. This unique stream key tells your streaming software where to send your video feed and lets Instagram accept it.</Typography>
                  </Box>
                  <Box display={"grid"} gridRowGap={"5px"}>
                    <Typography style={{...staticStyles.subHeading,fontSize: "14px",fontWeight: 500 }}>Stream URL</Typography>
                    <OutlinedInput
                      fullWidth
                      value={this.state.stremUrl}
                      style={{ height: "75%", borderRadius: "6px", backgroundColor: "#181818",color:"#FFF" }}
                      disabled
                      name="stremUrl"
                      onChange={this.handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <Typography style={{ ...staticStyles.sectionHead, fontSize: "16px",cursor:"pointer" }}>
                            Copy
                          </Typography>
                        </InputAdornment>
                      }
                    />
                  </Box>
                  <Box display={"grid"} gridRowGap={"5px"}>
                    <Typography style={{ ...staticStyles.subHeading,fontSize: "14px", fontWeight: 500 }}>Stream Key</Typography>
                    <OutlinedInput
                      fullWidth
                      value={this.state.stremKey}
                      disabled
                      name="stremKey"
                      style={{ height: "75%", borderRadius: "6px", backgroundColor: "#181818",color:"#FFF" }}
                      onChange={this.handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <Typography style={{ ...staticStyles.sectionHead, fontSize: "16px",cursor:"pointer" }}>
                            Copy
                          </Typography>
                        </InputAdornment>
                      }
                    />
                    <Typography style={{...staticStyles.subHeading,fontSize: "12px",fontWeight: 500 }}>Once you start to preview the broadcast, you have up to five hours to go live.</Typography>
                  </Box>
                  <Box display={"grid"} gridRowGap={"30px"}>
                    <Box display={"grid"} gridRowGap={"30px"}>
                      <Box>
                        <Typography style={{ ...staticStyles.subHeading,fontSize: "24px"}}>How to stream</Typography>
                      </Box>
                      <Box display={"flex"} gridColumnGap={"5px"}>
                        <Box><img src={CopyIcon} /></Box>
                        <Box><Typography style={{ ...staticStyles.subHeading,fontSize: "12px",fontWeight: 500 }}>Copy the stream key and enter it into your streaming software.</Typography></Box>
                      </Box>
                      <Box display={"flex"} gridColumnGap={"5px"}>
                        <Box><img src={LiveIcon} /></Box>
                        <Box><Typography style={{ ...staticStyles.subHeading, fontSize: "12px", fontWeight: 500 }}>Select Go live once your streaming software has connected and displayed as a preview.</Typography></Box>
                      </Box>

                    </Box>
                    <Box>
                      <Typography style={{...staticStyles.subHeading,fontSize: "12px",  fontWeight: 500 }}>The streaming software that's best for you depends on the type of content you plan to stream.</Typography>
                      <Typography style={{ ...staticStyles.subHeading, fontSize: "12px", fontWeight: 500 }}>Learn more about going live with streaming software.</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>}
        </Box>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const style = createStyles({
  subscriptionBoxLight: {
    borderRadius: "12px",
    padding: "12px 20px 12px 20px",
    gap: "12px",
    display: "flex",
    flexDirection: "column" as "column",
    border: "0.5px solid #222222"
  },
  subscriptionBox: {
    borderRadius: "12px",
    padding: "12px 20px 12px 20px",
    gap: "12px",
    display: "flex",
    flexDirection: "column" as "column",
    border: "0.5px solid #1D1D1D",
  },
})

const staticStyles = {
  backgroundImage: {
    backgroundColor: "#070707",
    height: "100vh",
    width: "100%",
    overflow: "auto",
    backgroundSize: "cover",
    alignItem: "center"
  },
  backgroundImageDark: {
    height: "100vh",
    width: "100%",
    overflow: "auto",
    backgroundSize: "cover",
    alignItem: "center",
    backgroundColor: "#070707"
  },
  text: {
    fontFamily: "Manrope",
    fontSize: "18px",
    fontWeight: 800,

  },
  title: {
    color: '#9b51e0',
    marginBottom: "8px",
  },
  description: {
    color: '#bdbdbd',
    marginBottom: "8px",
  },
  selectInput: {
    backgroundColor: '#181818',
    border: '1px solid #333',
    borderRadius: '5px',
    color: '#fff',
    width: '100%',
    marginBottom: "8px",
    '& .MuiSelect-icon': {
      color: '#fff',
    },
  },
  menuItem: {
    backgroundColor: '#121212',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#333',
    },
  },
  NextBtn: {
    padding: "15px 30px 15px 30px",
    borderRadius: "10px",
    display: "flex",
    gap: "5px",
    ontSize: "14px",
    fontFamily: "Manrope",
    color: "white",
    marginTop: "15px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    background: "var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"

  },
  menuPaper: {
    marginTop: '8px',
    borderRadius: '8px !important',
    background: "none !important",
  },
  menuList: {
    paddingTop: '0px',
    paddingBottom: '0px',
    maxHeight: "364px"
  },
  sectionHead: {
    fontSize: "24px",
    fontStyle: 'normal',
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Manrope',
    fontWeight: 800,
    lineHeight: '120%',
    letterSpacing: '-0.414px',
  },
  subHeading:{
    fontFamily: "Manrope", fontSize: "18px", color: "#FFF", fontWeight: 800
  }

};
// Customizable Area End
// Customizable Area End
