// Customizable Area Start
import React from 'react'
import { Box, Button, styled } from '@material-ui/core';
import { backArrowDark, backArrowWhite, period, price } from './assets';
import { Field, Form, Formik } from 'formik';

import FormikInputField from "../../../components/src/FormikInputField.web";
import FormikSelectField from "../../../components/src/FormikSelectField.web";

import * as Yup from "yup";
const validationSchema = Yup.object().shape({
    title: Yup.string()
        .required('Title is required'),
    details: Yup.string().required('required'),
    price: Yup.string().required('required'),
    period: Yup.string().required('required'),
});
interface Props {
    addSubscription: (data: any, options: any) => void;
    removeAddForm: () => void;
    intialValue: any;
    checked: boolean;

}


const AddSubscription = (props: Props) => {
    return (

        <StyleContainer>

            <div className="main-category">
                <Button data-test-id="backSubBtn" onClick={props.removeAddForm} className="back-button-settings" variant="text"><span><img src={props.checked ? backArrowDark : backArrowWhite} /></span>{props.intialValue.id ? "Edit Subscription": "Add Subscription"}</Button>
                <div>
                    <Formik
                        initialValues={{ ...props.intialValue }}
                        onSubmit={props.addSubscription}
                        data-test-id="validate-fromik-form"
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                    >
                        {(formikProps) => {
                            const { values,setFieldValue } = formikProps;
                            return (
                                <Form noValidate autoComplete="off" id="addFormId">
                                    <div className="fieldContainer">
                                        <label htmlFor="title"> Title</label>
                                        <Field
                                            component={FormikInputField}
                                            id="titleInput"
                                            name="title"
                                            value={values.title}
                                            placeholder="Title"
                                            variant="outlined"
                                            fullWidth
                                            type="text"
                                            InputLabelProps={{
                                                className: "13",
                                            }}
                                            className="passField"

                                        />
                                    </div>
                                    <div className="fieldContainer">
                                        <label htmlFor="details"> Details</label>
                                        <Field
                                            component={FormikInputField}
                                            id="detailInput"
                                            name="details"
                                            value={values.details}
                                            placeholder="About plan"
                                            variant="outlined"
                                            fullWidth
                                            type="text"
                                            InputLabelProps={{
                                                className: "13",
                                            }}
                                            className="passField"
                                        />
                                    </div>
                                    <div className="fieldContainer-inline">
                                        <span className='lable-content'><img src={period} style={{ marginRight: '8px' }} />  <label htmlFor="title"> Period</label></span>
                                        <Field
                                            component={FormikSelectField}
                                            name="period"
                                            options={[
                                                { value: 'monthly', label: 'Monthly' },
                                                { value: 'yearly', label: 'Yearly' },
                                                { value: 'lifetime_access', label: 'Lifetime access' },
                                            ]}
                                        />
                                    </div>
                                    <div className="fieldContainer-inline">
                                        <span className='lable-content'><img src={price} style={{ marginRight: '8px' }} /> <label htmlFor="details"> Price</label></span>
                                        <Field
                                            component={FormikInputField}
                                            id="priceInput"
                                            name="price"
                                            value={values.price}
                                            placeholder="Price"
                                            variant="outlined"
                                            fullWidth
                                            type="text"
                                            InputLabelProps={{
                                                className: "13",
                                            }}
                                            className="priceInput"
                                            onChange={(e:any) =>{
                                                const numericValue = e.target.value.replace(/[^0-9]/g, "");
                                                setFieldValue('price',numericValue)
                                            }}
                                        />
                                    </div>
                                    <div className='form-btns'>
                                        <Button data-test-id="cancel-submit" className="cancelButton" onClick={props.removeAddForm}>
                                            <p className="label">Cancel</p>
                                        </Button>
                                        <Button id="save-btn" className="submitButton" type="submit">
                                            <p className="label">Save</p>
                                        </Button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </StyleContainer>


    )
}

const StyleContainer = styled(Box)({
    "& .form-btns": {
        display: 'flex',
        justifyContent: 'center',
        gap: '16px'
    },

    "& .logoutModal": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    " .logoutContainer": {
        height: "240px",
        width: "375px"
    },
    "& .messageScreen": {
        position: "fixed",
        width: "100vw",
        height: "100vh",
        top: "0px",
        left: "0px",
        zIndex: 100,
        background: "#000000c7",
        display: "flex",
        justifyContent: "center"
    },
    "& .messageScreen .messageContainer": {
        background: "#222222",
        padding: "10px 20px 10px 20px",
        gap: "8px",
        borderRadius: "6px",
        marginTop: "auto",
        marginBottom: "5%",
        display: "flex"
    },
    "& .messageContainer span": {
        margin: "auto",
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "14.4px"
    },
    "& .subCategory": {
        // marginTop: "20px",
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: "Manrope",

    },
    "& .subCategory .list": {
        paddingTop: "0px",
    },
    "& .backButton:hover": {
        background: "transparent"
    },
    "& .backButton": {
        fontFamily: "Manrope",
        fontSize: "24px",
        fontWeight: "500",
        paddingRight: "0",
        textTransform: "none",
        paddingLeft: "0px"
    },
    "& .pl16": {
        paddingLeft: "16px"
    },
    "& .backButton .MuiButton-label": {
        justifyContent: "left"
    },
    "& .backButton span": {
        marginRight: "30px"
    },
    "& .backButton .icon": {
        height: "48px",
        width: "48px",
        textAlign: "center",
        alignContent: "center",
        marginRight: "0px"
    },
    "& .backButton .icon img": {
        marginTop: "5px"
    },
    "& .subCategory .backButton .MuiButton-label span": {
        marginTop: "12px",
        marginBottom: "auto"
    },
    "& .subCategory .backButton ": {
        paddingTop: "11px"
    },
    "& .justify-content-center": {
        justifyContent: "center"
    },
    "& .subCatIconContainer": {
        marginTop: "6px",
        marginBottom: "auto"
    },
    "& .textAlignInitial": {
        textAlign: "initial"
    },
    "& .textAlignInitial p,& .subcatTitleContainer p": {
        fontSize: "14px",
        margin: "0px",
        fontWeight: "500",
        // textTransform: "none",
        lineHeight: "21px",
        color: "#6B6B6B",
    },
    "& .subcatTitleContainer p": {
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "14.4px",
        color: "#6B6B6B",
        maxWidth: "67%"
    },

    "& .subcatTitleContainer span": {
        fontSize: "16px",
        // margin: "0px",
        fontWeight: "600",
        lineHeight: "19px",
    },
    "& .verificationContainer": {
        gap: "30px"
    },
    "& .verificationContainer p": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        padding: "10px 0px"
    },
    "& .verificationContainer .pasErrorContainer": {
        width: "auto",
        marginBottom: "20px"
    },
    "& .fieldContainer": {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "20px"
    },
    "& .fieldContainer-inline": {
        display: "flex",
        flexDirection: "row",
        paddingBottom: "20px",
        justifyContent: 'space-between'
    },
    "& .priceInput": {
        width: '106px'
    },

    "& .fieldContainer .MuiOutlinedInput-root": {
        borderRadius: "10px"
    },
    "& .fieldContainer-inline .MuiOutlinedInput-root": {
        borderRadius: "10px"
    },

    "& .fieldContainer label": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        marginBottom: "8px"
    },
    "& .submitButton": {
        width: "160px",
        height: "48px",
        padding: "15px 30px 15px 30px",
        gap: "5px",
        borderRadius: "10px",
        background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)"
    },
    '& .cancelButton': {
        width: "160px",
        height: "48px",
        padding: "15px 30px 15px 30px",
        gap: "5px",
        borderRadius: "10px",
        border: "1px solid",
        borderImage: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%) 1",

    },
    "& .deactivateButton": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "700",
        color: "rgba(239, 68, 68, 1)",
        width: "160px",
        height: "48px",
        padding: "15px 30px 15px 30px",
        gap: "5px",
        borderRadius: "10px",
        border: "1px solid rgba(239, 68, 68, 1)"
        // lineHeight: 16.8px;
        // text-align: center;

    },

    "& .submitButton .label": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "16.8px",
        textAlign: "center",
        textTransform: "unset",
        color: "white"
    },
    "& .cancelButton .label": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "16.8px",
        textAlign: "center",
        textTransform: "unset",
        color: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)'
    },
    "& .userDataValue": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        justifyContent: "end"
    },
    "& .userDataValue .iconImage": {
        marginLeft: "10px"
    },
    "& .deactivateContainer p": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        margin: "0px"
    },
    "& .deactivateContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "30px"
    },
    "& .pasErrorContainer": {
        width: "420px",
        height: "36px",
        padding: "12px 16px 12px 16px",
        gap: "8px",
        borderRadius: "10px",
        border: "0px 0px 0px 1.3px",
        background: "linear-gradient(0deg, rgba(33, 33, 33, 0.29), rgba(33, 33, 33, 0.29)), linear-gradient(91.54deg, rgba(255, 35, 35, 0.13) -1.27%, rgba(255, 0, 0, 0) 28.72%)",
        alignContent: "center",
        color: "rgba(239, 68, 68, 1)"
    },
    "& .deactivateModal .logoutContainer p": {
        maxWidth: "319px"
    },
    "& .changepswrdContainer": {
        paddingTop: "24px"
    },
    "& .changePassForm": {
        display: "flex",
        flexDirection: "column",
    },
    "& .changePassForm .submitButton": {
        margin: "auto",
        width: "unset"
    },
    "& .changepswrdContainer .pasErrorContainer": {
        marginBottom: "30px"
    },
    "& .forgotPswd": {
        margin: "auto",
        marginTop: "15px"
    },
    "& .lable-content": {
        marginTop: '10px'
    }

});
export default AddSubscription;
// Customizable Area End
