import React from "react"
import { Box, IconButton, Popover, styled } from "@material-ui/core";
import { Picker } from "emoji-mart";
import { EmojiData } from "emoji-mart";
import { emojiIcon } from "../../blocks/chat/src/assets";

interface IPropsEmojiPicker {
    emojiAnchorEl: null | HTMLElement;
    onCloseEmoji: () => void;
    onClickEmoji: (event: React.MouseEvent<HTMLElement>) => void;
    onSelectEmoji: (emoji: EmojiData) => void;
}

function EmojiPicker({ emojiAnchorEl, onCloseEmoji, onClickEmoji, onSelectEmoji }: IPropsEmojiPicker) {
    const open = Boolean(emojiAnchorEl);
    const id = open ? 'emoji-popover' : undefined;

    return <Box>
        <IconButton data-testid="emoji-picker-button" onClick={onClickEmoji}>
            <img src={emojiIcon} />
        </IconButton>
        <EmojiPopover
            id={id}
            open={open}
            anchorEl={emojiAnchorEl}
            data-testid="emoji-picker-close"
            onClose={onCloseEmoji}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            style={{ marginTop: -66 }}
        >
            <Picker
                data-testid="emoji-picker"
                theme="dark"
                onSelect={onSelectEmoji}
                autoFocus
            />
        </EmojiPopover>
    </Box>
}

const EmojiPopover = styled(Popover)({
    '& .emoji-mart-search': {
        '& input': {
            borderColor: '#ae65c5',
        },
    },

    '& .emoji-mart-anchor': {
        cursor: 'pointer',
    },

    '& .emoji-mart-emoji': {
        '& span': {
            cursor: 'pointer !important',
        }
    },
})

export default EmojiPicker;