import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Dialog,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { createTheme,  withStyles } from "@material-ui/core/styles";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import MuteModal from "../../customisableuserprofiles/src/MuteModal.web";
import { callDark, callLight,  checkbox,  checkboxFill,  comment,  filterIcon,  filterIconDark, Following, love, notificationIcon,  profileLogo, report, repost, searchDark, searchLight,  verifyIcon } from "./assets";
import {
  Media,
  User,
  FollowersData,
  IAllNotificaiton,
  LikeAttributes,
  CommentAttributes,
} from "./NotificationsController";
import Comments from "../../comments/src/Comments.web";
import InfiniteScroll from "react-infinite-scroller";
import ReportUserModal from "../../customisableuserprofiles/src/ReportUserModal.web";
import CreateRepost from "../../repost/src/CreateRepost.web";
import SelectRepostModal  from "../../repost/src/SelectRepostModal.web";
import PostHeader from '../../activityfeed/src/PostHeader.web';
import PostBody from '../../activityfeed/src/PostBody.web';
import PostFooter from '../../activityfeed/src/PostFooter.web';
import { Post, PostAttributes } from "../../activityfeed/src/ActivityFeedController";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  conditionRemover =(condition:boolean|undefined,trueStatement:string|undefined,falseStatement:string|undefined)=>{
   return condition?trueStatement:falseStatement
  }


  filterModel =(classes?:{[key:string]:string})=>{
    return <Dialog data-test-id="filterModel" onClose={() => 
      this.handleClose()
     } 
        open={this.state.filterModel} 
        PaperProps={{
          className: this.conditionRemover(!this.props.checked,classes?.modelDarkStyle,classes?.modelLightStyle)
        }}
         
        classes={{paperWidthSm:this.conditionRemover(!this.props.checked,classes?.modelDarkStyle,classes?.modelLightStyle)}} 
     >
       <Grid container justifyContent="center" alignItems="center" direction="column" style={{
              padding: "20px" ,rowGap:"24px",width:"373px",height:"312px"                            
        }}>
          <Box className={classes?.filterBody}>           
        <Box>
          <Typography className={classes?.name}>Filter by </Typography>          
        </Box>   

         <span className={this.props.classes?.lineStyle}/>

         {this.state.filterOption.map((item, index) => (
          <div
            key={index}
            data-test-id="filterOption"
            className={this.props.classes?.filterMessageBody}
            onClick={() => this.handleItemClick(item)}
          >
            <Typography className={this.props.classes?.filterMessage}>{item}</Typography>
            <img
              src={this.state.selectedItems===item ? checkboxFill : checkbox}
              alt=""
              className={this.props.classes?.filterIcon}
            />
          </div>
        ))}

                <Box className={this.props.classes?.muteAction}>
                <Button 
                   data-test-id="noBtn"
                   onClick={this.handleClose}
                    className={this.conditionRemover(this.props.checked,this.props.classes?.muteBtnDeny,this.props.classes?.muteBtnDenyDark)}>
                  <Typography className={this.props.classes?.showmore} >{configJSON.Cancel}</Typography>
                </Button>
                <Button 
                onClick={this.handleConfirmation} 
                data-test-id="pinConfirm" 
                className={this.props.classes?.muteBtn}>{configJSON.Submit}</Button>
              </Box>
        </Box>                             
      </Grid>
     </Dialog>
  }

  renderError = ()=>{
    return   <Box className={this.props.classes?.emptyContainer}>
    <img src={notificationIcon} alt="" className={this.props.classes?.notificationIconStyle}  />
    <div className={this.props.classes?.emptyBodyOuter}>
    <Typography  className={this.props.classes?.errorMessage}> No notifications yet</Typography>
    <Typography  className={this.props.classes?.errorSubMessage}> Likes, Comments, & Replies will appear here</Typography>
    </div>
  </Box>
  }

  renderMuteModal=()=>{
    return(
      <MuteModal 
      navigation={this.props.navigation} 
      id={''} 
      activeUser={this.state.activeUser as User} 
      data-testid="MuteModal"
      onMuteModelClose={this.handleMuteModelClose} 
      onMuteUser={this.handleMuteModel} 
      checked={this.props.checked as boolean} 
      bottompoper={this.state.bottompoper}
      onUnmuteUser={this.handleUnmuteUser}
      onButtompoperlClose={this.handleButtompoperlClose}
      muteModel={this.state.muteModal} />
    )
  }

  renderCommentModal=()=>{
    const {selectedPostComment ,showComments} = this.state
    const {checked} = this.props
     return(
      <>
       {selectedPostComment && (
            <Comments
              checked={checked as boolean}
              open={showComments} 
              data-testid="comment"
              onClose={this.handleCloseCommentsModal}
              postData={selectedPostComment}
              userInfo={{
                id:this.state.LoggedUser?.attributes.id as number
              }}
              onFollow={this.handleFollow}
              onClickMoreOption={this.handleClick}
              onLikePost={this.handleLikePost}
              onSavePost={this.handleSavePost}
              onMuteConformation={this.muteConformation}
              onShowReportPost={this.showReportPost}
              onBlockAccount={this.handleBlockAccount}
              onUpdateNumberOfComments={this.handleCommentFunctions}
            />
          )}
      </>
     )      
  }


  renderReportModal=()=>{
    return(
      <ReportUserModal 
      navigation={this.props.navigation} 
      id={''}  
      showReportPostModal={this.state.showReportPostModal} 
      reportPostMainList={this.state.reportPostMainList}
      reportPostSubList={this.state.reportPostSubCatList} 
      setReportPostMainCat={this.setReportPostIssue} 
      setReportPostSubCat={this.setReportPostSubIssues}
      closeReportPostModal={this.closeReportPostModal}
      changeReportView={this.changeReportView}
      reportPostLoading={false} 
      reportCurrentView={this.state.reportCurrentView} 
      reportCatId={this.state.reportIssue} 
      reportSubCatId={this.state.reportSubIssue}
      checked={this.props.checked as boolean}   
      data-testid="ReportPost"     
      />
    )
  }

  renderRepostModal=()=>{
    return(
      <>
      {this.state.selectedPostComment && (
              <SelectRepostModal
                open={this.state.showRepostModal}
                onClose={this.handleCloseRepostModal}
                onRepost={this.handleRepost}
                onQuote={this.handleQuote}
                data-testid="repostModal"
                onUndoRepost={this.handleUndoRepost}
                isUndoRepost={this.state.selectedPostComment.attributes.is_reposted}
              />
            )}
      </>
    )
  }


  renderImage=(type:string)=>{
    switch(type){
      case "like":
        return love
      case "comment":
        return comment
      case "repost":
        return repost
      case "new_follower":
        return Following     
    }
  }

  renderProfileImage=(task:IAllNotificaiton)=>{
    switch(task.attributes.notification_type){
      case "like":
        const image = task.attributes.notifiable as LikeAttributes
        return image.likeable.post_by.profile_photo
      case "comment":
        const commentProfileImage = task.attributes.notifiable as CommentAttributes
        return  commentProfileImage.comment_by.profile_photo
      case "repost":
        const post = task.attributes.notifiable as PostAttributes
        return post.post_by.profile_photo
      case "new_follower":
        const User  = task.attributes.notifiable as User
        return  User.profile_photo
    }
  }

  renderBody=(task:IAllNotificaiton)=>{
    const body = task.attributes.notifiable as CommentAttributes
     return body.body
  }

  renderLikedNotifcation=(type:IAllNotificaiton)=>{
     return(
      <Box className={this.props.classes?.BoxContainer}>
        <Box className={this.props.classes?.LeftBoxContainer}>
         <Box  className={this.props.classes?.imageContainer}>
            {type.attributes.notification_type!==null&&
            <Box><img className={this.props.classes?.logoImage}  src = {this.renderImage(type.attributes.notification_type)} alt="like"/> </Box>}
            <Box><img className={this.props.classes?.profileImage} src = { type.attributes.notifiable!==null?this.renderProfileImage(type):profileLogo} alt="profile"/> </Box>
         </Box>
          <Box className={this.props.classes?.nameTitle}>{type.attributes.contents}</Box>
          {type.attributes.notification_type==="comment"&&<Box className={this.props.classes?.description}>{this.renderBody(type)}</Box>}
          </Box>
         {type.attributes.notification_type==="new_follower"? this.renderFollowButton(type)
         :type.attributes.app_url&& <Box> <img src={type.attributes.app_url} alt="" className={this.props.classes?.postlogo} /></Box>
         }
        </Box>
     )
  }

  renderFollowButton=(notification:IAllNotificaiton)=>{
    const follower = notification.attributes.notifiable as  User
    if(!(follower.is_follower&&follower.is_following)) 
    return <Box><Button data-testid="followBtn" onClick={()=>this.handleFollow(notification.attributes.followed_by_id,'follow')} className={this.props.classes?.followBtn1}>Follow Back</Button></Box>
  }

  renderPost=(post:Post)=>{
    return(
      <>
      <Box style={{ paddingLeft: '24px' }}>
          <PostHeader
            data-testid="postHeader"
            checked={this.props.checked as boolean}
            userInfo={{
              id: this.state.LoggedUser?.attributes.id as number,
            }}
            onFollow={this.handleFollow}
            onClickMoreOption={this.handleClick}
            navigation={this.props.navigation}
            onMuteConformation={this.muteConformation}
            onShowReportPost={this.showReportPost}
            onBlockAccount={this.handleBlockAccount} 
            postData={post}                        
            />
        </Box>
        <Box style={{marginLeft: "67px"}}>
        <PostBody postData={post} checked={this.props.checked as boolean} />
        </Box>
        <Box style={{marginLeft: "67px"}}>
          <PostFooter
            checked={this.props.checked as boolean}
            data-testid="PostFooter"
            postData={post}
            onLikePost={this.handleLikePost}
            onSavePost={this.handleSavePost}
            onClickComments={this.handleOpenCommentModal}
            showPostActivity={this.handlePostActivityModel}
            onClickRepost={this.handleShowRepostModel}
          />
        </Box>
      </>
    )
  }

  renderReportNotification=(notification:IAllNotificaiton)=>{
    return (
       <Box  className={this.props.classes?.reportBoxContainer}>
       <Box data-testid="reportPost" onClick={()=>this.handleReportedPage(notification)} className={this.props.classes?.reportBox}>
         <Box  className={this.props.classes?.imageContainer}>
            <Box><img className={this.props.classes?.profileImage} src = {report} alt="profile"/> </Box>
        </Box>
        <Box className={this.props.classes?.nameHeading}>{notification.attributes.headings}</Box>
       </Box>  
       </Box>
    )
  }

  renderNotificationType=(notification:IAllNotificaiton)=>{
     if(notification.attributes.notification_type==="mention"){
      const mentionType = notification.attributes.notifiable as PostAttributes
      const post =  { 
            id: mentionType.id.toString(),
            type:'post',
            attributes:mentionType
          }
      return this.renderPost(post)
     }else if(notification.attributes.notification_type==="report_post"||notification.attributes.notification_type==="report_account"){
       return this.renderReportNotification(notification)
     }
     
     else{
      return this.renderLikedNotifcation(notification)
     }
  }

  renderDate=(inputDate:string)=>{
    const today = new Date();
  const input = new Date(inputDate);

  const todayMidnight = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const inputMidnight = new Date(input.getFullYear(), input.getMonth(), input.getDate());

  const differenceInTime = todayMidnight.getTime() - inputMidnight.getTime();
  const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);

  if (differenceInDays === 0) {
    return "Today";
  } else if (differenceInDays === 1) {
    return "Yesterday";
  } else {
    return input.toISOString().split("T")[0];
  }
  }

  renderNotification =() =>{
    const {page,pagination}= this.state
    return  <div className={this.props.classes?.bodyStyle} ref={this.scrollParentRef}>
      {this.state.data.length>0? 
         <InfiniteScroll
            pageStart={page}
            loadMore={()=>this.getNotifications()}
            hasMore={page<=pagination?.total_pages}
            loader={<div className={this.props.classes?.loader} key={0}> 
            {page <= pagination?.total_pages&&<CircularProgress disableShrink />}</div>}
            useWindow={false}
            getScrollParent={() => this.scrollParentRef.current}
            data-test-id="notificatioScroller"
            className={this.props.classes?.scrollerStyle}
        >
    {this.state.data.map(task=>{
      return(
        <>
        <Box className={this.props.classes?.todayContain}>
          <Typography className={this.props.classes?.todayHead}> {this.renderDate(task.date)}</Typography>
          {task.Notificationdata.map(item=>{
              return this.renderNotificationType(item) 
          })}
        </Box>
        </>

      )
    })}

    </InfiniteScroll>:this.renderError()}
   </div>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <NavigationMenu  id="" checked={this.props.checked} tabItem="Alerts" navigation={this.props.navigation}>
      <Box className={this.props.classes?.wrapper}>    
     
      <div style={{display:"flex",columnGap:"24px",width:"100%"}} >
          
            {this.state.selectedPostComment && this.state.showQuote ? (
              <Box className={this.props.classes?.centerQuote} style={{overflow: 'scroll'}}>
                <CreateRepost 
                  postData={this.state.selectedPostComment} 
                  data-testid="createRepost"
                  onCloseQuote={this.handleCloseQuote} 
                  onGoBackAfterSubmit={this.handleGoBackAfterSubmit}
                />
              </Box>
            ):
            
            <Box className={this.props.classes?.notification}>
            {this.filterModel(this.props.classes)}
          <Box className={this.props.classes?.headContainer} >
            <Typography  className={this.props.classes?.headMessage}> Alerts</Typography>
             <img  
              data-test-id="filterbtn"
             onClick={this.handleOpenModel} src={this.conditionRemover(this.props?.checked,filterIconDark,filterIcon)} className={this.props.classes?.filterIconStyle} alt="" />
            </Box> 
            <Box className={this.props.classes?.tabsContainer}>
              <Tabs
                value={this.state.activeIndex}
                onChange={this.handleTabsChange}
                classes={{ indicator: this.props.classes?.indicator }}
                textColor="primary"
                variant="fullWidth"
                data-test-id="tabBtn"
              >
                <Tab label="All" className={this.props.classes?.tabstyle} />
                <Tab label="mentioned" className={this.props.classes?.tabstyle} />
               
              </Tabs>
            </Box>  
          
           { this.state.activeIndex==0 ? 
           this.renderNotification():
             <div className={this.props.classes?.feedBody}>
              <InfiniteScroll 
              loadMore={this.getMentionedPostList}
              pageStart={this.state.page}
              useWindow={false}
              hasMore={this.state.page <= this.state.pagination?.total_pages }
              loader={<div className={"loader"} key={0}> { this.state.page <= this.state.pagination?.total_pages && <CircularProgress disableShrink />}</div>}
              >
             {this.state.mentionData.length>0 && this.state.mentionData.map((post)=>{
            return <Box data-testid="posts" className={this.props.classes?.postDisplay} key={post.id}>     
               {this.renderPost(post)}
            </Box>
            })
            }
            </InfiniteScroll>

            </div>}
              </Box>
          }
        
        <Box className={this.props.classes?.sideContain}>
         <Box className={this.props.classes?.sideTop}>
          <TextField
           variant="outlined"
            placeholder="Search"
            onChange={this.setFilter}
            className={this.props.classes?.searchField}
            InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={this.props?.checked?searchDark:searchLight} alt="" />
              </InputAdornment>
            ),
          }}
         />

         <img src={this.conditionRemover(this.props?.checked,callDark,callLight)} alt="" />
         </Box>

          <Box style={{display:"flex",flexDirection:"column",rowGap:"18px"}}>
          <Box className={this.conditionRemover(this.props?.checked,this.props.classes?.subscriptionBoxLight,this.props.classes?.subscriptionBox)}>
            <Typography className={this.props.classes?.subscrptiontext}>{configJSON.chooseSubscriptionText}</Typography>
            <Typography className={this.props.classes?.subcriptionBody}>{configJSON.subscribeToUnlock}</Typography>
            <Button className={this.props.classes?.continueBtn}>{configJSON.Post}</Button>
          </Box>

          <Box className={this.props.classes?.whathappiningBox} style={{border:this.conditionRemover(this.props.checked,"0.5px solid #222222","0.5px solid #1D1D1D"),
          maxHeight:"310px"}} >
             <Typography className={this.props.classes?.name}>{configJSON.whoToFollow}</Typography>
   
             <Box className={this.props.classes?.followScreen} >            
          {this.state.followers.map((val:FollowersData)=>{
           return  <Box style={{display:"flex",justifyContent:"space-between"}}>
              <Box  style={{display:"flex",gap:"5px",alignItems:"center"}}>            
                 <img
                    src={profileLogo}
                    alt="img"
                    className={this.props.classes?.postImage}
                  />
              <Box className={this.props.classes?.headconatin}>                  
                  <Box className={this.props.classes?.ContentHead}>
                    <Box style={{display:"flex",gap:"5px",alignItems:"center"}}>
                      <Typography className={this.props.classes?.name}>{val.name}</Typography>
                      <img src={verifyIcon} alt="" />                      
                    </Box>
                    <Box style={{display:"flex",gap:"5px",alignItems:"center"}}>
                      <Typography className={this.props.classes?.userName}>{val.username} </Typography>
                    </Box>
                  </Box>                  
              </Box>
            </Box>
            <Button className={this.props.classes?.followBtn}>{configJSON.Follow} </Button>
            </Box>             
          })}            
             </Box>  
             <Typography className={this.props.classes?.showmore}>{configJSON.showMore}</Typography> 

          </Box>
          </Box>        
        </Box>
        
       </div> 
           
      </Box>
      {this.renderMuteModal()}
      {this.renderRepostModal()}
      {this.renderCommentModal()}
      {this.renderReportModal()}
      </NavigationMenu> 
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  notification:{
    display:"flex",
    width:"60%",
    maxWidth:"810px",
    flexDirection:"column" as "column",
    rowGap:"24px",
    padding:"0 20px 0px 20px",
    height: "calc(100vh - 20px)",
    "@media (max-width:1000px)": {
      width:"80%",
    },    
  },

  description:{ 
     marginLeft:40,
     marginBottom:8,
     color:'#6B6B6B',
     weight:500,
     fontSize:12,
     fontFamily:"Manrope",
     lineHeight:'14.4px',
     marginTop:'-4px',
  },

  muteBtnDeny: {
    boxShadow: "none !important",
    background: "linear-gradient( #fff, #fff),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    padding: "16px",
    width: "160px",
    height: "48px"
  },

  muteBtnDenyDark: {
    boxShadow: "none !important",
    background: "linear-gradient(#0d0c0c, #0d0c0c),linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    borderRadius: "10px",
    textTransform: "capitalize" as "capitalize",
    padding: "16px",
    width: "160px",
    height: "48px"
  },

  muteAction:{
    display: "flex", 
    columnGap: "12px", 
    marginTop:16,
    },

  muteBtn: {
      boxShadow: "none !important",
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      borderRadius: "10px",
      textTransform: "capitalize" as "capitalize",
      padding: "16px",
      width: "160px",
      height: "48px",
      color: "white",
      fontSize: "14px",
      fontWeight: 700
    },
  profileImage:{
    height:32,
    marginLeft:8,
    width:32,
    borderRadius:'50%',
    objectFit:'cover'as 'cover'
  },

  logoImage:{
    height:32,
    width:32
  },
  reportBoxContainer:{
    borderBottom:"0.5px solid #222222",
  },

  reportBox:{
    display:"flex",
    marginTop:12,
    marginBottom:12,
    columnGap:8,
    alignItems:'center',
  },

  BoxContainer:{
   display:'flex',
   marginTop:12,
   marginBottom:8,
   borderBottom:"0.5px solid #222222",
   alignItems:'start',
   justifyContent:'space-between'
  },
  LeftBoxContainer:{
    display:'flex',
    flexDirection:'column' as 'column',
    rowGap:'8px',
    marginBottom:8,
  },
  imageContainer:{
    display:'flex',
    
  },

  filterMessageBody:{
    display:"flex",
    width:"100%",
    justifyContent:"space-between"
  },
  avatarGroup: {
    display: 'flex',
    position: 'relative' as 'relative', 
    width: 'fit-content', 
    height: '40px',
    marginRight:"53px"
  },
  avatar: {
    position: 'absolute' as 'absolute', 
    left: 0,
    marginLeft: '-10%',
    height:"32px",
    width:"32px",
    border: '1px solid white', 
  },
  postBodyContain:{
    fontSize:"14px",
    fontFamily:"Manrope",
    fontWeight:500,
    lineHeight:"21px",
    paddingRight:"24px"
  },
  postFooter:{
    display:"flex",
    justifyContent:"start",
    gap:'20px',
    marginLeft:"67px",
    paddingBottom:"15px",
   },
   actionBtn:{
    display:"flex",
    alignItems:"center" as "center",
    gap:"5px",
    fontSize:"16px",
    minWidth:43,
    height:'16px',
    fontFamily:"Manrope",
    fontWeight:500, 
    cursor:"pointer",
    color:"#6B6B6B"   
  },
  feedImages :{
    display: "grid" as "grid",
    gap: "10px",   
  },
  postImageStyle: {
    width: "100%",
  },
  feedmultiImages:{
    display: "grid" as "grid",
    gap: "10px",  
    gridTemplateColumns:'1fr 1fr',    
  },
  postBody:{
    paddingLeft:"67px",
    display:"flex",
    flexDirection:"column" as "column",
    rowGap:"20px"   
   },
   reactPlayerStyle:{
    width: "100% !important",
    height:"100%  !important",    
    "& :first-child ":{
      objectFit:"fill  !important",
      borderRadius:"16px"
    }
  },
  modelDarkStyle:{ 
    borderRadius: "10px",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    border: "1px solid transparent",
    backdropFilter: "blur(1 px)",
    background: "linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)",
    boxShadow:"none"        
  },
  modelLightStyle:{
    borderRadius: "10px", 
    boxShadow:"none",
    border: "1px solid transparent",
    background: "linear-gradient(to right, #fff, #fff),linear-gradient(to right, #35383F ,#35383F)",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",  
    backdropFilter: "blur(1 px)",    
   },
  wrapper: {
    width: "100%",
    paddingTop:"20px",
    display:"flex",
  },
  filterBody:{
    padding:"20px 0px",
    display:"flex",
    flexDirection:"column" as "column",
    gap:"16px",
    width:"100%",
    alignItems:"center"
  },
  filterMessage:{
    fontSize:"16px",
    fontFamily:"Manrope",
    fontWeight:600,
  },
  postImage:{
    height:"35px",
    width:"35px",
    borderRadius:"50%",
    objectFit:"cover" as "cover"
  },
  borderRadius: {
    borderRadius: "16px",
  },
  topLeftRadius: {
    borderTopLeftRadius: "16px",
  },
  topRightRadius: {
    borderTopRightRadius: "16px",
  },
  bottomLeftRadius: {
    borderBottomLeftRadius: "16px",
  },
  bottomRightRadius: {
    borderBottomRightRadius: "16px",
  },
  headconatin:{
    display:"flex",
    width:"100%",
    justifyContent:"space-between"
  },
  continueBtn:{
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    Padding:"6px 20px 6px 20px",
    gap:"7px",
    borderRadius:"10px",
    display:"flex",
    width:"35%",
    color:"white",
    fontSize:"12px",
    fontFamily:"Manrope",
    fontWeight:500,
    textTransform:"none" as "none",
  },
  followBtn1:{
    width:"72pxpx",
    height:"32px",
    borderRadius:"10px",
    padding:"6px 16px",
    gap:"7px",
    fontSize:"14px",
    fontFamily:"Manrope",
    color:"white",
    fontWeight:500,
    textTransform:"capitalize" as "capitalize",
    background:"var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"
  },
  followBtn:{
    width:"102px",
    height:"32px",
    borderRadius:"10px",
    padding:"4px 8px 4px 8px",
    gap:"7px",
    fontSize:"12px",
    fontFamily:"Manrope",
    color:"white",
    fontWeight:500,
    textTransform:"capitalize" as "capitalize",
    background:"var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"
  },
  tabsContainer:{
    height:"35px"
  },
  todayContain:{
    display:"flex",
    flexDirection:"column" as "column",
    gap:"12px",
    width:"100%"
  },
  lineStyle:{
    width:"100%",
    height:"1px",
    BorderBottom:'1px solid #222222 ',
    background:"#222222"
  },
  todayHead:{
    fontSize:"14px",
    fontFamily:"Manrope",
    fontWeight:700,
  },
  notificationMessage:{
    fontSize:"12px",
    fontFamily:"Manrope",
    fontWeight:700,
    gap:"4px",
    display:"flex",
    alignItems:"center"
  },
  notificationDisplay:{
    display:"flex",
    gap:"9px",
    alignItem:"center"
  },
  notificationDisplayOuter:{
    display:"flex", 
    justifyContent:"space-between",
  },

  centerQuote:{
    display: "flex",
      width: "60%",
      maxWidth: "810px",
      flexDirection: "column" as "column",
      rowGap: "24px",
      padding: "20px",
      height: "calc(100vh - 20px)",
      "@media (max-width:1000px)": {
        width: "100%",
      },
  },
  imageStyle:{
   width:"32px",
   height:"32px",
   borderRadius:"50%",
   border:"1px solid #F2F4F7"
  },
  postlogo:{
    width:"38px",
    height:"38px",
    borderRadius:"4px"
  },
  notifiactionRegion:{
    fontSize:"12px",
    fontFamily:"Manrope",
    fontWeight:500,
    color:"#667085"
  },
  postDisplay:{
    padding:"8px 0px",
    display:"flex",
    flexDirection:"column" as "column",
    gap:"20px"
  },
  postHead:{
    width:"100%",
    height:"47px",
    padding:"0px 0px 0px 24px",
    display:"flex",
    gap:"10px",
    alignItems:"center"
  },
  feedBody:{
    width:"100%",
    height:"calc(100vh - 89px)",
    overflowY:"scroll" as "scroll",
    rowGap:"24px",
    display:"flex",
    scrollbarWidth: "none" as "none",
    "&::-webkit-scrollbar": {
      display: "none" as "none",
    },
    flexDirection:"column" as "column"
  },
  feedsDisplay:{
    display: "flex",
    flexDirection:"column" as "column",
    rowGap: "24px",
  },
  scrollerStyle:{
    width:"100%",
    gap:"20px",
    display:"flex",
    flexDirection:"column" as "column",
    alignItems:"center",
  },
  bodyStyle:{
    width:"100%",
    height:"calc(100vh - 89px)",
    overflowY:"scroll" as "scroll",
    rowGap:"24px",
    display:"flex",
    padding:"20px",
    alignItems:"center",
    scrollbarWidth: "none" as "none",
    "&::-webkit-scrollbar": {
      display: "none" as "none",
    },
    flexDirection:"column" as "column"
  },
  emptyContainer:{
   maxWidth:"375px",
   height:"173px",
   gap:"20px",
   display:"flex",
   flexDirection:"column" as "column",
   alignItems:"center",
   top:"40%",
   position:"absolute" as "absolute"
  },
  emptyBodyOuter:{
    padding:"0px 67px",
    display:"flex",
    flexDirection:"column" as "column",
    gap:"15px",
  },
  notificationIconStyle:{
    height:"60px",
    width:"60px"
  },
  errorMessage:{
    fontWeight:800,
    fontSize:"24px",
    fontFamily:"Manrope",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent' as "transparent",

  },
  errorSubMessage:{
    fontWeight:600,
    fontSize:"16px",
    fontFamily:"Manrope",
    lineHeight:"19.2px",
    textAlign:"center" as "center"
  },
  headContainer:{
    marginTpp:"24px",
    display:"flex",
    justifyContent:"space-between",
    padding:"4px 22px" ,
    alignItems: "center"
  },
  headMessage:{
    fontWeight:800,
    fontSize:"24px",
    fontFamily:"Manrope"
  },
  filterIconStyle:{
    height:"13.18px",
    width:"16.88px"
  },
  ContentHead:{
    display:"flex",
    flexDirection:"column" as "column",
    gap:"5px",
  },
  sideContain:{
    height:"96vh",
    maxWidth:"510px",
    overflow:"scroll" as "scroll",
    width:"40%",
    paddingRight:"98px",
    scrollbarWidth: "none" as "none",
    "&::-webkit-scrollbar": {
      display: "none" as "none",
    },
    "@media (max-width:1000px)": {
      display:"none" as "none"
    },
  },
  followScreen:{
    display:"flex",
    flexDirection:"column" as "column",
    rowGap:"12px",
  },
  sideTop:{
    display:"flex",
    gap:"21px",
    marginBottom:"28px"
  },
  searchField:{
    '& .MuiOutlinedInput-root': {
      borderRadius: "10px", 
      '&.Mui-focused fieldset': {
        border :"1px solid #7A2BF5",       
      },
    },
    width:"80%"
  },
  subscriptionBox:{
    borderRadius:"12px",
    padding:"12px 20px 12px 20px",
    gap:"12px",
    display: "flex",
    flexDirection: "column" as "column",
    border: "0.5px solid #1D1D1D",  
  },
  tabstyle:{
    fontFamily:"Manrope",
    fontWeight:600,
    fontSize:"16px",
    textTransform:"capitalize" as "capitalize",
    borderBottom:"3px solid gray",    
    '&.Mui-selected': {
      background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent' as "transparent",
    },
  } ,
  indicator:{
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    height: '2.9px',
  },
  whathappiningBox:{
    borderRadius:"12px",
    padding:"12px 20px 12px 20px",
    gap:"12px",
    display: "flex",
    flexDirection: "column" as "column"
  },
  subscriptionBoxLight:{
    borderRadius:"12px",
    padding:"12px 20px 12px 20px",
    gap:"12px",
    display: "flex",
    flexDirection: "column" as "column",
    border: "0.5px solid #222222"
  },
  subscrptiontext:{
    fontSize:"18px",
    fontFamily:"Manrope",
    fontWeight:800, 
    lineHeight:"21px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent' as "transparent"
  }, 
  subcriptionBody:{
    fontSize:"14px",
    fontFamily:"Manrope",
    fontWeight:500,
    lineHeight:"21px",
  },
  showmore:{
    fontSize:"14px",
    fontFamily:"Manrope",
    fontWeight:500,
    lineHeight:"21px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text' as 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  },

  nameHeading:{
    height:32,
    fontSize:"12px",
    fontFamily:"Manrope",
    display:'flex',
    alignItems:'center',
    fontWeight:700,
    lineHeight:"18px",
  },

  nameTitle:{
    height:32,
    fontSize:"12px",
    fontFamily:"Manrope",
    marginLeft:40,
    display:'flex',
    alignItems:'center',
    fontWeight:700,
    lineHeight:"18px",
  },
  name:{
    fontSize:"18px",
    fontFamily:"Manrope",
    fontWeight:800,
    lineHeight:"21px",
    whiteSpace:"nowrap" as "nowrap"
  },
  userName:{
    fontSize:"12px",
    fontFamily:"Manrope",
    fontWeight:500,
    lineHeight:"14px",
    whiteSpace:"nowrap" as "nowrap",
    color:"#6B6B6B"
  },
  statustime:{
    fontSize:"11px",
    fontFamily:"Manrope",
    fontWeight:400,
    lineHeight:"15px",
    color:"#81999E"
  },
  reshotImg:{
    height:"16px",
    borderRadius:"6px",
    padding:"5px 8px 5px 6px",
    gap:"4px",
    fontSize:"12px",
    fontFamily:"Manrope",
    fontWeight:500,
  },
};

export const WebNotifications = withStyles(webStyle)(Notifications);
// Customizable Area End
