// Customizable Area Start

import React, { Fragment } from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  Divider,
  Tabs, Tab,
  Dialog, DialogTitle, DialogContent, DialogActions,


} from "@material-ui/core";


import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { AccountCircle, Lock } from '@material-ui/icons';
import { dummyProduct } from "./assets";
import Rating from '@material-ui/lab/Rating';


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "grey"
        },
        "&:hover:not($disabled):before": {
          borderColor: "grey"
        },
        "&$focused $notchedOutline": {
          borderColor: "blue"
        },
        "&$focused::placeholder": {
          color: "#000"
        },
        "&$focused": {
          color: "#000"
        }
      },
      input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px #fff inset"
        }
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: '#000',
        },
        '&.Mui-error': {
          color: '#0f0', 
        },
        '&$focused': {
          color: 'green',
        },
        '&$error': {
          color: 'yellow',
        },

      },
    },
  

  
  }

});
import CircularProgress from '@material-ui/core/CircularProgress';



import ReviewPromptController, {
  Props
} from "./ReviewPromptController";

export default class ReviewPrompt extends ReviewPromptController {
  constructor(props: Props) {
    super(props);
    
  }

  

  render() {
    const token =  "abcd"



    return (
    
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"} >
          <Grid container spacing={2} style={{ marginTop: "30px" }}>
            {token && !this.state.isLoadingReviewPrompt ? (
              <Fragment>
                <Grid container style={{ display: 'block' }}>
                  <Grid item>
                    <img
                      alt="Product Image"
                      src={dummyProduct}
                      
                  
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="h6" gutterBottom>
                      Write a review for Aroma Floral Shampoo
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Share your thoughts with other customers
                    </Typography>
                  </Grid>
                </Grid>


                <Divider />
                <Grid container>
                  <form

                  

                  >

                    <TextField
                      data-test-id="txtInput"
                      id="comment"
                      label="Comment"
                      variant="outlined"
                      value={this.state.comment}
                      required
                      margin="normal"
                      fullWidth
                      multiline
                      minRows={4}
                    />
                    <Typography component="legend" gutterBottom>
                      Rating
                    </Typography>
                    <Box sx={webStyle.ratingContainer}>
                      <Rating
                        name="simple-controlled"
                        id="rating"
                        value={this.state.rating}
                      />
                    </Box>
                    <Button
                      variant="contained"
                      color="inherit"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </form>
                </Grid>
              </Fragment>
            ) : (
              <Fragment>
                {/* {this.state.isLoadingReviewPrompt ? ( */}
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%' }}>
                    <CircularProgress color="inherit" />
                  </div>
                  {/* ) : ( */}
                  <Box>
                    
                    <Tabs value={this.state.selectedTab} centered>
                      <Tab label="Login" icon={<AccountCircle />} />
                      <Tab label="Signup" icon={<Lock />} />
                    </Tabs>

                   </Box>
                  {/* )} */}

              </Fragment>
            )}
          </Grid>

        </Container>
        
        
      </ThemeProvider>
      
    );
  }
}


const webStyle = {

  ratingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "left",
    margin: "20px 0",
  },
  active: {
    color: '#ffcc00'
  },
  star: {
    fontSize: '30px',
    color: '#ddd',
    marginRight: '10px',
    cursor: 'pointer',
  },
  stars: {
    display: 'flex',
    justifyContent: 'center'
  },


  avatar: {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    marginRight: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    width: "100%",
    borderRadius: '8px',
  },
  submitButton: {
    marginTop: '30px',
    alignSelf: 'flex-end',
  },
};


// Customizable Area End
