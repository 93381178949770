// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { CommentDataWithReplies } from "./CommentsController.web";
export const configJSON = require("./config");

export interface Props {
  classes?: any
  checked: boolean
  comment: CommentDataWithReplies
  onLikeComment: (commentId: string, isLike: boolean, isReply: boolean, replyId: string) => void
  onClickReply: (comment: CommentDataWithReplies) => void
  getReplies: (commentId: string, replyPage: number) => void
  closeReplies: (commentId: string) => void
}

interface S {
  showReply: boolean
}

interface SS {
}

export default class CommentItemController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      showReply: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  get remainingReplies() {
    return this.props.comment.attributes.total_replies - this.props.comment.attributes.replies.length
  }

  converText = (count: number) => {
    return count === 1 ? 'reply' : 'replies'
  }

}
// Customizable Area End