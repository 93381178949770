import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,

  // Customizable Area Start
  Paper,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { 
  createTheme, 
  ThemeProvider 
} from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { 
  styled 
} from "@material-ui/styles"
import { 
  backArrowDark, 
  backArrowWhite, 
  darkBackground, 
  lightBackground 
} from "./assets";
import SubscriptoinCard from './SubscriptionCard.web'
import AddSubscription from "./AddSubscription.web";


const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary: {
      main: 'rgba(0,0,0,0.75)'
    }
  }
});

const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#3858E3",
    },
    secondary: {
      main: "rgba(255,255,255,0.75)"
    }
  }
});
// Customizable Area End

import Subscriptionbilling2Controller, {
  Props,
  configJSON,
} from "./Subscriptionbilling2Controller";

export default class Subscriptionbilling2 extends Subscriptionbilling2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderEmptySub = () => {
    return (<div className="emptyContainer">
      <div>
        <Typography className="emaptyMessages">
          Subscription list is empty.
        </Typography>
        <Typography className="emptyMSG">
          Once you have added the subscription plan, it will appear here.
        </Typography>
      </div>

    </div>)
  }
  renderSubscriptionList = () => {
    const { checked } = this.state

    return (<div className="main-category">
      <Button onClick={() => this.setNavigation("ActivityFeed")} className="back-button-settings" variant="text"><span><img src={checked ? backArrowDark : backArrowWhite} /></span>Create Subscription</Button>
      {this.state.subscriptionsList.length ? (
        <>{this.state.subscriptionsList.map((item) => (<SubscriptoinCard data={item} onEdit={this.onSubEdit} onDelete={this.onSubDelete}/>))}</>
      ) : (<>{this.renderEmptySub()}</>)}
      <div className="subBtn">
        <Button className="addSubscription"><span className="subBtnTitle" onClick={this.openAddFrom}>Add Subscription</span></Button>
      </div>
    </div>)
  }
  renderSubCat = () => {
    return( 
      <React.Fragment>
      { this.state.isEdit && <AddSubscription addSubscription ={this.addSubscription} removeAddForm={this.removeAddForm}  intialValue={this.state.initialValues} checked={false}/>}
     </React.Fragment>)}
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={this.state.checked ? lightTheme : darkTheme}>
        <StyleContainer>
          <Paper className={this.state.checked ? "paperLightTheme" : "paperDarkTheme"}>
            <Loader loading={this.state.isLoading} />
            <Grid container>
              <Grid item className="navigationSection">
                <NavigationMenu navigation={this.props.navigation} id={""} checked={this.state.checked} />
              </Grid>
              <Grid item className="settingsMainCat">
                {!this.state.isLoading && this.renderSubscriptionList()}
              </Grid>
              <Grid item className="settingsSubCat">
                {!this.state.isLoading && this.renderSubCat()}          
              </Grid>  
            </Grid>
          </Paper>
        </StyleContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyleContainer = styled(Box)({
  "& .main-category": {
    marginTop: "20px",
    gap: '10px',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: "Manrope",
    height:"95vh",
    overflow:"scroll" as "scroll",
    scrollbarWidth: "none" as "none",
    "&::-webkit-scrollbar": {
      display: "none" as "none",
    },
    "@media (max-width:1000px)": {
      display:"none" as "none"
    },
    marginBottom:"20px"

  },
  "& .paperDarkTheme": {
    display: "flex",
    backgroundColor: '#070707',
    backgroundImage: `url(${darkBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: '100% 100%',
    height: '100%',
    width: "100vw"
  },
  "& .paperLightTheme": {
    display: "flex",
    backgroundColor: '#fff',
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${lightBackground})`,
    backgroundSize: '100% 100%',
    height: '100%',
    width: "100vw",
  },
  "& .search-container": {
    border: '1px solid',
    borderRadius: '10px',
    height: "48px",
    padding: '0px 15px',
    alignContent: 'center',
  },
  "& .search-field::before": {
    display: "none"
  },
  "& .search-field::after": {
    display: "none"
  },
  "& .back-button-settings": {
    height: "48px",
    width: "222px",
    fontFamily: "Manrope",
    fontSize: "18px",
    fontWeight: "800",
    paddingRight: "0"
  },
  "& .back-button-settings .MuiButton-label": {
    justifyContent: "space-between",
    textTransform: "capitalize"
  },
  "& .justify-content-right": {
    justifyContent: "end"
  },
  "& .mainCatLabel .MuiTypography-body1": {
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Manrope",
  },
  "& .list .listItem:hover": {
    backgroundColor: "unset"
  },
  "& .list .listItem": {
    cursor: "pointer"
  },
  "& .emaptyMessages": {
    fontSize: "31px",
    fontFamily: "Manrope",
    fontWeight: 800,
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textAlign: "center" as "center",
  },
  "& .emptyMSG": {
    fontSize: "16px",
    fontFamily: "Manrope",
    fontWeight: 600,
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textAlign: "center" as "center",
    background: '#FFFFFF'
  },
  "& .emptyContainer": {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    gap: '50px',
    alignItems: 'center',
    marginTop: '50%'

  },
  "& .addSubscription": {
    padding: '16px',
    gap: '8px',
    borderRadius: '10px',
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    width: '190px'

  },
  "& .subBtnTitle": {
    fontSize: "16px",
    fontFamily: "Manrope",
    fontWeight: 600,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textAlign: "center" as "center",
    background: '#FFFFFF',
    textTransform: 'none'
  },
  "& .subBtn": {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem'
  }

});
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
