import React from "react";
// Customizable Area Start
import {
  Typography,
  Paper,
  Box,
  Grid,
  Avatar,
  Button,
  Card,
  CardContent,
  IconButton,
  styled,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { createStyles, createTheme,ThemeProvider, withStyles} from "@material-ui/core/styles";
import { callDark, callLight, commentIcon, favActive, favIcon, infoIcon, ownerShipIcon, profileLogo, repostActive, reverseIcon, searchDark, searchLight, verifyIcon, verifyOrange, verifyYellow } from "./assests";

const StyledIcon = styled(IconButton)({
  padding:0,
  marginLeft:-4,
  "&.MuiIconButton-root:hover":{
   background:'unset'
  }
})
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
  const lightTheme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#3858E3",
      },
      secondary:{
        main:'rgba(0,0,0,0.75)'
      }
     }
    });
  
  const darkTheme=createTheme({
      palette: {
        type: "dark",
        primary: {
          main: "#3858E3",
        },
        secondary:{
        main:"rgba(255,255,255,0.75)"
        }
       }
      })

// Customizable Area End

import ActivityFeedController, {
  Props,
  configJSON,
} from "./ActivityFeedController";

export default class RecentFeed extends ActivityFeedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  conditionRemover = (condition: boolean, trueStatement: any, falseStatement: any) => {
    return condition ? trueStatement : falseStatement
  }

  // Customizable Area End

  render() {
      // Customizable Area Start
      const {classes,checked}=this.props    
      // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={this.props.checked ? lightTheme:darkTheme}>
        <RecentFeedContainer>
       <Box className={"sideContain"}>
              <Box className={"sideTop"}>
                <TextField
                  variant="outlined"
                  placeholder="Search"
                  onChange={this.setFilter}
                  className={"searchField"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={this.conditionRemover(checked, searchDark, searchLight)} alt="" />
                      </InputAdornment>
                    ),
                  }}
                />

                <img src={this.conditionRemover(checked, callDark, callLight)} alt="" />
              </Box>

              <Box style={{ display: "flex", flexDirection: "column", rowGap: "18px" }}>
                <Box className={this.conditionRemover(checked, "subscriptionBoxLight", "subscriptionBox")}>
                  <Typography className={"subscrptiontext"}>Choose the right subscription for you!</Typography>
                  <Typography className={"subcriptionBody"}>Subscribe to unlock new features and if eligible, receive a share of ads revenue.</Typography>
                  <Button className={"continueBtn"}>Continue</Button>
                </Box>

                <Box className={"whathappiningBox"} style={{ border: this.conditionRemover(checked, "0.5px solid #222222", "0.5px solid #1D1D1D") }}>
                  <Typography className={"name"} style={{ whiteSpace: "wrap" } as any}  >Whats Happening</Typography>
                  {["", "", "", ""].map((val) => <Box style={{ display: "flex", flexDirection: "column", rowGap: "4px" }}>
                    <Typography className={"subcriptionBody"}>Trending in India</Typography>
                    <Typography className={"name"}>#shardot</Typography>
                    <Typography className={"subcriptionBody"}>19.5k posts</Typography>
                  </Box>)}
                  <Typography className={"showmore"}>Show more</Typography>

                </Box>

                <Box className={"whathappiningBox"} style={{ border: this.conditionRemover(checked, "0.5px solid #222222", "0.5px solid #1D1D1D"), maxHeight: "299px" }} >
                  <Typography className={"name"}>Who to follow</Typography>

                  <Box className={"followScreen"} >
                    {this.state.followers.map((val: any) => {
                      return <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                          <img
                            src={profileLogo}
                            alt="img"
                            className={"postImage"}
                          />
                          <Box className={"headconatin"}>
                            <Box className={"ContentHead"}>
                              <Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                <Typography className={"name"}>{val.name}</Typography>
                                <img src={verifyIcon} alt="" />
                              </Box>
                              <Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                <Typography className={"userName"}>{val.username} </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Button className={"followBtn"}>Follow </Button>
                      </Box>
                    })}
                  </Box>

                </Box>
              </Box>
              </Box>
            </RecentFeedContainer>
     </ThemeProvider>                  
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const RecentFeedContainer = styled(Box)({
  "& .followScreen": {
    display: "flex",
    flexDirection: "column" as "column",
    rowGap: "12px",
    height: "270",
    overflowY: "scroll" as "scroll",
    padding: "12px",
  },
  "& .sideTop": {
    display: "flex",
    gap: "21px",
    marginBottom: "28px"
  },
  "& .subscriptionBox": {
    borderRadius: "12px",
    padding: "12px 20px 12px 20px",
    gap: "12px",
    display: "flex",
    flexDirection: "column" as "column",
    border: "0.5px solid #1D1D1D",
  },
  "& .whathappiningBox": {
    borderRadius: "12px",
    padding: "12px 20px 12px 20px",
    gap: "12px",
    display: "flex",
    flexDirection: "column" as "column"
  },
  "& .subscriptionBoxLight": {
    borderRadius: "12px",
    padding: "12px 20px 12px 20px",
    gap: "12px",
    display: "flex",
    flexDirection: "column" as "column",
    border: "0.5px solid #222222"
  },
  "& .subscrptiontext": {
    fontSize: "18px",
    fontFamily: "Manrope",
    fontWeight: 800,
    lineHeight: "21px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent' as "transparent"
  },
  "& .subcriptionBody": {
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
    lineHeight: "21px",
  },
  "& .showmore": {
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 500,
    lineHeight: "21px",
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  },
  "& .continueBtn": {
    background: "linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)",
    Padding: "6px 20px 6px 20px",
    gap: "7px",
    borderRadius: "10px",
    display: "flex",
    width: "35%",
    color: "white",
    fontSize: "12px",
    fontFamily: "inter",
    fontWeight: 500,
    textTransform: "none" as "none",
  },

  "& .searchField": {
    '& .MuiOutlinedInput-root': {
      borderRadius: "10px",
      '&.Mui-focused fieldset': {
        border: "1px solid #7A2BF5",
      },
    },
    width: "80%"
  },

  "& .sideContain": {
    height: "96vh",
    maxWidth: "510px",
    overflow: "scroll" as "scroll",
    width: "80%",
    paddingRight: "98px",
    scrollbarWidth: "none" as "none",
    "&::-webkit-scrollbar": {
      display: "none" as "none",
    },
    "@media (max-width:1000px)": {
      display: "none" as "none"
    },
  },
 
  
  "& .name": {
    fontSize: "18px",
    fontFamily: "Manrope",
    fontWeight: 800,
    lineHeight: "21px",
    whiteSpace: "nowrap" as "nowrap",
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  "& .userName": {
    fontSize: "12px",
    fontFamily: "Manrope",
    fontWeight: 500,
    lineHeight: "14px",
    whiteSpace: "nowrap" as "nowrap",
    color: "#6B6B6B"
  },
 
  "& .followBtn": {
    width: "65px",
    height: "25px",
    borderRadius: "10px",
    padding: "4px 8px 4px 8px",
    gap: "7px",
    fontSize: "12px",
    fontFamily: "Manrope",
    color: "white",
    fontWeight: 500,
    textTransform: "capitalize" as "capitalize",
    background: "var(--Main-Gradient, linear-gradient(330deg, #3858E3 -51.03%, #BA05F7 138.78%))"
  },
  
  "& .postImage": {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    objectFit: "cover" as "cover"
  },
  "& .ContentHead": {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "5px",
  },
  "& .headconatin": {
    display: "flex",
    width: "100%",
    justifyContent: "space-between"
  },
})

// Customizable Area End
