import React, { useState } from 'react';
import {
  Box,
  Dialog,
  Grid,
  Typography,
  styled,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  ThemeProvider,
  createTheme,
} from '@material-ui/core';
import { lightCheckbox, darkCheckBox, checkbox } from "../../../blocks/navigationmenu/src/assets";

interface DisappearProps {
  checked?: boolean;
  isOpen: boolean;
  onSubmit: (value: string) => void;
  onClose: () => void;
  value: string;
}

interface DisappearingMessageSetting {
  label: string;
  value: number;
}

export const DISAPPEARING_MESSAGES_SETTINGS: DisappearingMessageSetting[] = [
  {
    label: "24 hours",
    value: 24 * 60 * 60 * 1000
  },
  {
    label: "7 days",
    value: 7 * 24 * 60 * 60 * 1000
  },
  {
    label: "90 days",
    value: 90 * 24 * 60 * 60 * 1000
  },
  {
    label: "Off",
    value: 0
  },
]

const DisappearingMessagesModal: React.FC<DisappearProps> = (props) => {

  const [disappearFor, setDisappearFor] = useState(props.value);
  const conditionRemover = (
    condition: boolean | undefined,
    trueStatement: string,
    falseStatement: string
  ) => {
    return condition ? trueStatement : falseStatement;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setDisappearFor(event.target.value);
  };

  const renderFormControlLabel = (setting: DisappearingMessageSetting) => {
    return (
      <FormControlLabel
        key={setting.label}
        className="radio-control"
        value={setting.value?.toString()}
        control={
          <Radio
            icon={<img src={!props.checked ? lightCheckbox : darkCheckBox} alt="checkbox" />}
            checkedIcon={<img src={checkbox} alt="checkbox" />}
          />
        }
        label={setting.label}
        labelPlacement="start"
      />
    )
  };

  // Renders the main mute confirmation modal
  const renderModal = () => (
    <DialogBox
      data-test-id="disappearModel"
      onClose={() => {
        props.onClose();
        setDisappearFor(props.value);
      }}
      open={props.isOpen}
      PaperProps={{
        className: conditionRemover(
          props.checked,
          'modelDarkStyle',
          'modelLightStyle'
        ),
      }}
    >
      <GridContainer container justifyContent="center" alignItems="center" direction="column">
        <Box>
          <Typography className="name">Disappearing messages</Typography>
        </Box>
        <Box style={{ paddingBottom: 12, borderBottom: "1px solid #222222" }}>
          <Typography className="subhead">
            All new messages in this chat will disappear after the selected duration
          </Typography>
        </Box>
        <Box className='radioGroup'>
          <RadioGroup aria-label="disappearFor" name="disappearFor" value={disappearFor} onChange={handleChange}>
            {DISAPPEARING_MESSAGES_SETTINGS.map(renderFormControlLabel)}
          </RadioGroup>
        </Box>
        <Box className="muteAction">
          <Button
            onClick={() => props.onClose()}
            data-test-id="noBtn"
            className={conditionRemover(!props.checked, "muteBtnDeny", "muteBtnDenyDark")}
          >
            <Typography className="showmore">Cancel</Typography>
          </Button>
          <Button
            onClick={() => props.onSubmit(disappearFor)}
            data-test-id="submitDisappearing"
            className="muteBtn"
          >
            Submit
          </Button>
        </Box>
      </GridContainer>
    </DialogBox>
  );

  return (
    <ThemeProvider theme={!props.checked ? commonLightTheme : commonDarkTheme}>
      <BoxContainer>
        {renderModal()}
      </BoxContainer>
    </ThemeProvider>
  );
};

// Styled Components
const BoxContainer = styled(Box)({
});

const DialogBox = styled(Dialog)({
  '& .bottomModelDarkStyle': {
    borderRadius: '6px',
    backdropFilter: 'blur(1px)',
    boxShadow: 'none',
    position: 'absolute',
    top: '80%',
  },
  '& .modelDarkStyle': {
    borderRadius: '10px',
    backdropFilter: 'blur(1px)',
    background: 'linear-gradient(to right, #0E0E0E, #0E0E0E),linear-gradient(to right, #35383F ,#35383F)',
    boxShadow: 'none',
  },
  '& .modelLightStyle': {
    borderRadius: '10px',
    backdropFilter: 'blur(1px)',
    background: 'linear-gradient(to right, #fff, #fff),linear-gradient(to right, #35383F ,#35383F)',
    boxShadow: 'none',
  },
  '& .bottomModelStyle': {
    borderRadius: '6px',
    backdropFilter: 'blur(1px)',
    boxShadow: 'none',
    position: 'absolute',
    top: '80%',
  },
});

const GridContainer = styled(Grid)({
  padding: 20,
  rowGap: '24px',
  width: '375px',
  border: "1px solid #35383F",
  borderRadius: 10,
  '& .name': {
    fontSize: '18px',
    fontFamily: 'Manrope',
    fontWeight: 800,
    lineHeight: '21px',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '& .subhead': {
    fontFamily: 'Manrope',
    fontWeight: 500,
    fontSize: '12px',
    color: "#6B6B6B",
    lineHeight: "14.4px",
    textAlign: 'center',
  },
  '& .muteBtn': {
    boxShadow: 'none !important',
    background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
    borderRadius: '10px',
    padding: '16px',
    width: '160px',
    height: '48px',
    color: 'white',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: "Manrope"
  },
  '& .muteBtnDeny': {
    boxShadow: 'none !important',
    background: 'linear-gradient(to right, #fff, #fff),linear-gradient(to right, #3858E3 , #BA05F7)',
    borderRadius: '10px',
    padding: '16px',
    width: '160px',
    height: '48px',
    border: " 1px solid #8103ab",
  },
  '& .muteBtnDenyDark': {
    boxShadow: 'none !important',
    background: 'linear-gradient(to right, #0d0c0c, #0d0c0c),linear-gradient(to right, #3858E3 , #BA05F7)',
    borderRadius: '10px',
    padding: '16px',
    width: '160px',
    height: '48px',
    border: "1px solid #6938eb",
  },
  '& .showmore': {
    fontSize: '14px',
    fontFamily: 'Manrope',
    fontWeight: 500,
    lineHeight: '21px',
    background: 'linear-gradient(330.07deg, #3858E3 -51.03%, #BA05F7 138.78%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  "& .muteBtn,.showMore": {
    textTransform: "capitalize"
  },
  "& .radioGroup": {
    width: "100%",
  },
  "& .radio-control": {
    justifyContent: "space-between",
    margin: 0,
  },
  '& .muteAction': {
    display: 'flex',
    columnGap: '12px',
  },
});

const commonLightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3858E3",
    },
    secondary: {
      main: 'rgba(0,0,0,0.75)'
    },
    info: {
      main: "#222222",
    },
    warning: {
      main: "#78716C",
    },
  },
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "19.2px",
        textAlign: "left",
      }
    }
  }
});

const commonDarkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#3858E3",
    },
    secondary: {
      main: "rgba(255,255,255,0.75)"
    },
    info: {
      main: "#FFFFFF",
    },
    warning: {
      main: "#78716C",
    },
  },
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "19.2px",
        textAlign: "left",
      }
    }
  }
});

export default DisappearingMessagesModal;
